import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AccountMemberAcceptInvitationInput } from "./__generated__/AcceptInvitationMutation.graphql";

const mutation = graphql`
  mutation AcceptInvitationMutation(
    $input: AccountMemberAcceptInvitationInput!
  ) {
    accountMemberAcceptInvitation(input: $input) {
      success
      accountMember {
        accountId
      }
    }
  }
`;

function commit(
  environment,
  input: AccountMemberAcceptInvitationInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,

    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
