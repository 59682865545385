import React from "react";
import styles from "./AccountPendingApprovalScreen.module.scss";
import { SUPPORT_EMAIL } from "businessLogic/constants";
import buttonStyles from "styles/buttons.module.scss";
import { ReactComponent as InspectingIllustration } from "assets/images/InspectingIllustration.svg";

const AccountPendingApprovalScreen = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <InspectingIllustration />
        </div>

        <div className={styles.title}>Thanks for registering with us</div>

        <div className={styles.description}>
          We need just a little more time to review your registration details
          and make sure everything is good to go. We will be in touch soon!
        </div>
      </div>

      <div className={styles.footer}>
        In the meantime you can always reach out to us at{" "}
        <a href={`mailto:${SUPPORT_EMAIL}`} className={buttonStyles.btn_link}>
          {SUPPORT_EMAIL}
        </a>
      </div>
    </div>
  );
};

export default AccountPendingApprovalScreen;
