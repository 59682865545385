import { AccountActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { AccountDetails } from "businessLogic/model";
import { AuthActions } from "../../Auth/redux/actions";

export interface AccountState {
  errorMessage?: string;
  accountDetails?: AccountDetails;
  isLoading?: boolean;
  isRegistered?: boolean;
}

export const INITIAL_STATE: AccountState = {};

const reducerMap = {};

reducerMap[AuthActions.LOGOUT_SUCCESS] = (): AccountState => {
  return INITIAL_STATE;
};

const registerHandler = (
  state: AccountState,
  action: Action<{ accountDetails: AccountDetails }>
): AccountState => {
  return {
    ...state,
    accountDetails: action.payload.accountDetails,
    errorMessage: undefined,
    isLoading: true,
    isRegistered: false,
  };
};

reducerMap[AccountActions.REGISTER] = registerHandler;
reducerMap[AccountActions.REGISTER_FROM_AWS] = registerHandler;

reducerMap[AccountActions.REGISTER_SUCCESS] = (
  state: AccountState
): AccountState => {
  return {
    ...state,
    errorMessage: undefined,
    isLoading: false,
    isRegistered: true,
  };
};
reducerMap[AccountActions.SET_ERROR] = (
  state: AccountState,
  action: Action<string>
): AccountState => {
  return {
    ...state,
    errorMessage: action.payload,
    isLoading: false,
    isRegistered: false,
  };
};
reducerMap[AccountActions.SAVE_ACCOUNT_DATA] = (
  state: AccountState,
  action: Action<AccountDetails>
): AccountState => {
  return {
    ...state,
    accountDetails: action.payload,
  };
};

export default handleActions<AccountState>(reducerMap, INITIAL_STATE);
