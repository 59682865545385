import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation ReSendVerificationEmailMutation(
    $input: ReSendVerificationEmailInput!
  ) {
    reSendVerificationEmail(input: $input) {
      success
    }
  }
`;

function commit(environment, input, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
