import React from "react";
import * as _ from "lodash";
import { ReactComponent as RamIcon } from "assets/icons/instanceTypes/Ram.svg";
import { ReactComponent as StorageIcon } from "assets/icons/instanceTypes/SSD.svg";
import { ReactComponent as CPUIcon } from "assets/icons/instanceTypes/Cpu.svg";
import { ReactComponent as BalancedIcon } from "assets/icons/instanceTypes/Balanced.svg";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";
import { EnginePermission } from "./constants";
import { EngineStatusSummary } from "pages/Database/DatabaseList/v2/DatabasesContainer/__generated__/DatabasesContainer_account.graphql";
import { SettingsPreset } from "components/EngineForm/constants";

export enum InstanceCategory {
  CPU = "CPU",
  Storage = "Storage",
  RAM = "RAM",
  Balanced = "Balanced",
}

export const INSTANCE_CATEGORY_ICONS = {
  [InstanceCategory.RAM]: <RamIcon />,
  [InstanceCategory.Storage]: <StorageIcon />,
  [InstanceCategory.CPU]: <CPUIcon />,
  [InstanceCategory.Balanced]: <BalancedIcon />,
};

export const INSTANCE_CATEGORY_TITLES = {
  [InstanceCategory.RAM]: "Optimized for Memory (RAM)",
  [InstanceCategory.Storage]: "Optimized for Storage (SSD Cache)",
  [InstanceCategory.CPU]: "Optimized for Compute (CPU)",
  [InstanceCategory.Balanced]: "Balanced",
};

const INSTANCE_CATEGORY_PREFIXES = {
  [InstanceCategory.RAM]: [
    "r4",
    "r5",
    "r5a",
    "r5n",
    "r6g",
    "u-",
    "x1",
    "x1e",
    "z1d",
    "M",
  ],
  [InstanceCategory.Storage]: ["d2", "h1", "i3", "i3en", "S"],
  [InstanceCategory.CPU]: [
    "c4",
    "c5",
    "c5a",
    "c5n",
    "c6g",
    "p4",
    "p3",
    "p2",
    "inf1",
    "g4",
    "g3",
    "f1",
    "C",
  ],
  [InstanceCategory.Balanced]: [
    "a1",
    "m4",
    "m5",
    "m5a",
    "m5n",
    "m6g",
    "t2",
    "t3",
    "t3a",
    "t4g",
    "B",
  ],
};

export const getInstanceTypeCategoryByName = _.memoize(
  (instanceTypeName: string): InstanceCategory | null => {
    for (const instanceCategory of Object.keys(InstanceCategory)) {
      for (const prefix of INSTANCE_CATEGORY_PREFIXES[instanceCategory]) {
        if (instanceTypeName.startsWith(prefix)) {
          return instanceCategory as InstanceCategory;
        }
      }
    }

    return null;
  }
);

export const getInstanceTypeInfo = _.memoize(
  (
    instanceName: string
  ): {
    info: InstanceCategory;
    icon: React.ReactNode;
  } | null => {
    const category = getInstanceTypeCategoryByName(instanceName);

    if (!category) {
      return null;
    }

    return {
      info: category,
      icon: INSTANCE_CATEGORY_ICONS[category],
    };
  }
);

export const generateInstanceTypeFromEngine = en => {
  if (en?.isSystem) {
    return { instanceTypeInfo: null, instanceType: null };
  } else {
    const computeInstancesType =
      en?.latestRevision?.specification?.dbComputeInstancesType;
    const instanceTypeInfo = getInstanceTypeInfo(computeInstancesType.name);
    const fallbackCost = Number.isFinite(computeInstancesType.pricePerHourCents)
      ? computeInstancesType.pricePerHourCents
      : 0;
    const cost = Number.isFinite(computeInstancesType.costPerHour)
      ? computeInstancesType.costPerHour
      : fallbackCost;
    const instanceType = {
      name: computeInstancesType.name,
      cores: computeInstancesType.cpuVirtualCoresCount,
      ram: computeInstancesType.memorySizeBytes,
      storage: computeInstancesType.storageSizeBytes,
      price: cost / 100,
    };

    return { instanceTypeInfo, instanceType };
  }
};

export const engineCanBeDeleted = engineStatus =>
  engineStatus !== UIEngineStatus.STATUS_DROPPING &&
  engineStatus !== UIEngineStatus.STATUS_DELETED;

export const engineCanBeRestarted = engineStatus => {
  return [UIEngineStatus.STATUS_FAILED, UIEngineStatus.STATUS_ON].includes(
    engineStatus
  );
};

export const engineCanBeQueried = engineStatus =>
  [UIEngineStatus.STATUS_REPAIRING, UIEngineStatus.STATUS_ON].includes(
    engineStatus
  );

const engineStartCanBeTerminated = (statusSummary: EngineStatusSummary) => {
  return ["STARTING_INITIALIZING", "RESTARTING_INITIALIZING"].includes(
    statusSummary
  );
};

export const generalEngineRunning = engine => {
  const { currentStatusSummary, settingsPreset } = engine;
  return (
    settingsPreset === SettingsPreset.GeneralPurpose &&
    [
      "STARTING",
      "STARTING_INITIALIZING",
      "RUNNING",
      "RESTARTING",
      "RESTARTING_INITIALIZING",
      "REPAIRING",
      "STOPPING",
    ].includes(currentStatusSummary)
  );
};

export const engineCanBeStopped = engine => {
  if (!engine.status) {
    return false;
  }

  if (engineStartCanBeTerminated(engine.currentStatusSummary)) {
    return true;
  }

  return engine.status === UIEngineStatus.STATUS_ON;
};

export const isEngineOff = engineStatus =>
  !engineStatus ||
  [UIEngineStatus.STATUS_FAILED, UIEngineStatus.STATUS_STOPPED].includes(
    engineStatus
  );

export const canStartEngine = permissions =>
  permissions.includes(EnginePermission.START);

export const canEditEngine = permissions =>
  permissions.includes(EnginePermission.UPDATE);

export const canDeleteEngine = permissions =>
  permissions.includes(EnginePermission.DELETE);

export const canStopEngine = permissions =>
  permissions.includes(EnginePermission.STOP);
