import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import { ReactComponent as NoAccess } from "assets/images/errorScreen/noAccess.svg";
import { ReactComponent as DuplicateImageUrl } from "assets/images/errorScreen/duplicate.svg";
import { ROUTES } from "businessLogic/routes.constants";
import { logout } from "pages/Auth/redux/actions";
import ErrorScreen, {
  ErrorScreenImagePosition,
} from "../../ErrorScreen/ErrorScreen";
import { AsyncUnsubscribe } from "./routes";

const LogoutRoute = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout(false));
  }, [dispatch]);
  return null;
};

export const getGeneralRoutes = () => {
  return [
    <Route
      key={ROUTES.LINK_FAILURE}
      path={ROUTES.LINK_FAILURE}
      element={<ErrorScreen title="AWS Marketplace linking failure" />}
    />,
    <Route
      key={ROUTES.LINK_ALREADY_LINED}
      path={ROUTES.LINK_ALREADY_LINED}
      element={
        <ErrorScreen
          imagePosition={ErrorScreenImagePosition.Top}
          title="It appears that your company already has a linked account"
          image={<DuplicateImageUrl />}
        />
      }
    />,
    <Route
      key={ROUTES.UNSUBSCRIBE}
      path={ROUTES.UNSUBSCRIBE}
      element={<AsyncUnsubscribe />}
    />,
    <Route
      key={ROUTES.NO_ACCESS}
      path={ROUTES.NO_ACCESS}
      element={
        <ErrorScreen
          title="Access Denied"
          description="You don’t have access to this account"
          image={<NoAccess />}
          imagePosition={ErrorScreenImagePosition.Top}
        />
      }
    />,
    <Route
      key={ROUTES.LOGOUT}
      path={ROUTES.LOGOUT}
      element={<LogoutRoute />}
    />,
  ];
};
