import {
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import _ from "lodash";

export const useScroll = ({ onScrolledToBottom, wrapper }) => {
  const dependencies = useRef({
    onScrolledToBottom,
  });

  useLayoutEffect(() => {
    dependencies.current = {
      onScrolledToBottom,
    };
  });

  const handleScroll = useCallback(event => {
    const element = event.target;
    const { onScrolledToBottom } = dependencies.current;

    if (
      element.scrollHeight - element.scrollTop - 300 <=
      element.clientHeight
    ) {
      onScrolledToBottom && onScrolledToBottom();
    }
  }, []);

  const debouncedHandleScroll = useMemo(() => {
    return _.debounce(handleScroll, 100);
  }, [handleScroll]);

  useEffect(() => {
    wrapper.current?.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      wrapper.current?.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  return {};
};
