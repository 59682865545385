import { isEmpty } from "lodash";

export interface Version {
  major: number;
  minor: number;
  patch: number;
}

const FEATURES_REQUIRED_VERSION = {
  hybrid: { major: 2, minor: 9, patch: 3 },
};

const QUERY_STATUS_KEY_MAP_BY_VERSION = {
  [versionToString(FEATURES_REQUIRED_VERSION.hybrid)]: "status",
  none: "eventType",
};

function versionToString(version) {
  if (!version) return "";
  return `${version.major}.${version.minor}.${version.patch}`;
}

const canUseFeature = (version: Version, minVersion: Version): boolean => {
  if (isEmpty(version)) return false;

  return (
    version.major >= minVersion.major &&
    version.minor >= minVersion.minor &&
    version.patch >= minVersion.patch
  );
};

export const canUseNewWSSHeaders = (version: Version): boolean => {
  return canUseFeature(version, FEATURES_REQUIRED_VERSION.hybrid);
};

export const canUseQueryHybridFlag = (version: Version): boolean => {
  return canUseFeature(version, FEATURES_REQUIRED_VERSION.hybrid);
};

export const canUseBypassQueueFlag = (version: Version): boolean => {
  return canUseFeature(version, FEATURES_REQUIRED_VERSION.hybrid);
};

export function getStatusKeyByProxyVersion(proxyVersion) {
  return (
    QUERY_STATUS_KEY_MAP_BY_VERSION[versionToString(proxyVersion)] ||
    QUERY_STATUS_KEY_MAP_BY_VERSION.none
  );
}
