import { SUPPORT_EMAIL } from "businessLogic/constants";
import buttonStyles from "styles/buttons.module.scss";
import PendingSubscription, {
  PendingSubscriptionTemplate,
} from "../PendingSubscription/PendingSubscription";

export const LinkingError = () => {
  return (
    <PendingSubscription
      template={PendingSubscriptionTemplate.Error}
      title={
        <>
          Problem connecting your Firebolt account
          <br />
          to the AWS Marketplace
        </>
      }
      description={
        <span>
          There was a problem linking your AWS Marketplace account to Firebolt.
          <br />
          Contact{" "}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className={buttonStyles.btn_link}
          >
            {SUPPORT_EMAIL}
          </a>
          . We're probably already looking into it.
        </span>
      }
      showContactSupportButton={false}
    />
  );
};
