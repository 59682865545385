export enum UserRole {
  AccountAdmin = "ACCOUNT_ADMIN",
  DatabaseAdmin = "DATABASE_ADMIN",
  Viewer = "VIEWER",
}

export enum UserInvitationStatus {
  PendingInvitation = "PENDING_INVITATION",
  PendingApproval = "PENDING_APPROVAL",
}

export interface MyUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  welcomeMessageShown?: boolean;
}

export interface BasicUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  memberId: string;
}

export enum UiMemberStatus {
  ActiveUser = "ActiveUser",
  PendingInvitation = "PendingInvitation",
  PendingApproval = "PendingApproval",
}

export interface User extends BasicUser {
  role: UserRole;
  isExternal: boolean;
  invitationStatus: UserInvitationStatus | null;
  memberId: string;
  status: UiMemberStatus;
  createTime: string;
  ipAllowList?: string[];
  ipDenyList?: string[];
}

export enum MemberJoinFlow {
  ACCOUNT_MEMBER_JOIN_FLOW_INVITATION = "INVITATION",
  ACCOUNT_MEMBER_JOIN_FLOW_APPROVAL = "APPROVAL",
  ACCOUNT_MEMBER_JOIN_FLOW_FIRST_REGISTRATION = "FIRST_REGISTRATION",
}

export enum MemberStatus {
  ACCOUNT_MEMBER_STATUS_CREATED = "CREATED",
  ACCOUNT_MEMBER_STATUS_ACCEPTED = "ACCEPTED",
  ACCOUNT_MEMBER_STATUS_CANCELED = "CANCELED",
  ACCOUNT_MEMBER_STATUS_REJECTED = "REJECTED",
}

export interface PolicyAssignment {
  resource: string;
  roleIds: string[];
}

export interface Member {
  assignedRole: { displayName: string };
  id: string;
  accountId: string;
  accountTitle: string;
  joinFlow: MemberJoinFlow;
  status: MemberStatus;
  user: User;
}
