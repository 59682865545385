import { fetchQuery } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";

export const query = graphql`
  query RoleQuery {
    roles(first: 1000) {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`;

const fetch = (environment, params = {}): Promise<any> => {
  return fetchQuery(environment, query, params).toPromise();
};

export default { fetch };
