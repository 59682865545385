import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { ScriptCreateInput } from "relay/mutations/userScript/__generated__/ScriptCreateMutation.graphql";

const mutation = graphql`
  mutation ScriptCreateMutation($input: ScriptCreateInput!) {
    scriptCreate(input: $input) {
      success
      script {
        id
        data
        databaseId
        isStarred
        displayName
        lastUpdateTime
        createTime
      }
    }
  }
`;

function commit(environment, input: ScriptCreateInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
  });
}

export default { commit };
