import { useLDClient } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "./useAllFlags";

export const useEnginesPageFlagByUser = () => {
  const flags = useAllFlags();
  const client = useLDClient();
  const key = client?.getContext()?.key;

  if (key === process.env.REACT_APP_LD_GLOBAL_KEY) {
    // await for customer flags to be loaded,
    // this has a dependeci of usr session, so always return false in case of global flags
    return {
      isEnginesPageEnabled: false,
    };
  }

  const isEnginesPageEnabled = !!flags[FeatureFlag.FireboltAppEnginesPage];

  return {
    isEnginesPageEnabled,
  };
};
