import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AwssubscriptionLinkToAccountInput } from "./__generated__/CreateUserFromAWSMutation.graphql";

const mutation = graphql`
  mutation SubscriptionLinkToAccountMutation(
    $input: AwssubscriptionLinkToAccountInput!
  ) {
    awsSubscriptionLinkToAccount(input: $input) {
      success
      subscription {
        accountId
        customerId
        expirationTime
        id
        status
        tier
      }
    }
  }
`;

function commit(
  environment,
  input: AwssubscriptionLinkToAccountInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
