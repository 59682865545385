import React from "react";
import { ReactComponent as EngineStatusIconOn } from "assets/icons/engineStatus/EngineStatusIconOn.svg";
import { ReactComponent as EngineStatusIconStopped } from "assets/icons/engineStatus/EngineStatusIconStopped.svg";
import { ReactComponent as EngineStatusIconFailed } from "assets/icons/engineStatus/EngineStatusIconFailed.svg";
import { ReactComponent as EngineStatusIconStarting } from "assets/icons/engineStatus/EngineStatusIconStarting.svg";
import { ReactComponent as EngineStatusIconRestarting } from "assets/icons/engineStatus/EngineStatusIconRestarting.svg";
import { ReactComponent as EngineStatusIconStopping } from "assets/icons/engineStatus/EngineStatusIconStopping.svg";
import { ReactComponent as EngineStatusIconDropping } from "assets/icons/engineStatus/EngineStatusIconDropping.svg";
import { ReactComponent as EngineStatusIconRepairing } from "assets/icons/engineStatus/EngineStatusIconRepairing.svg";
import Spinner from "../common/Spinner";
import CustomTooltip from "../Tooltip";
import styles from "./EngineStatusIcon.module.scss";

export enum UIEngineStatus {
  STATUS_ON = "On",
  STATUS_STOPPED = "Stopped",
  STATUS_FAILED = "Failed",

  STATUS_STARTING = "Starting",
  STATUS_REPAIRING = "Repairing",
  STATUS_STOPPING = "Stopping",

  STATUS_DROPPING = "Dropping",
  STATUS_RESTARTING = "Restarting",
  STATUS_DELETED = "Deleted",
}

interface IEngineStatusIcon {
  status: UIEngineStatus;
  hideTitle?: boolean;
  statusText?: string;
}

const STATUS_ICON_MAP = {
  [UIEngineStatus.STATUS_ON]: <EngineStatusIconOn />,
  [UIEngineStatus.STATUS_STOPPED]: <EngineStatusIconStopped />,
  [UIEngineStatus.STATUS_FAILED]: <EngineStatusIconFailed />,
  [UIEngineStatus.STATUS_STARTING]: <EngineStatusIconStarting />,
  [UIEngineStatus.STATUS_STOPPING]: <EngineStatusIconStopping />,
  [UIEngineStatus.STATUS_DROPPING]: <EngineStatusIconDropping />,
  [UIEngineStatus.STATUS_REPAIRING]: <EngineStatusIconRepairing />,
  [UIEngineStatus.STATUS_RESTARTING]: (
    <EngineStatusIconRestarting className={styles.rotation} />
  ),
  [UIEngineStatus.STATUS_DELETED]: <EngineStatusIconStopped />,
};

const LOADING_COLOR = {
  [UIEngineStatus.STATUS_STARTING]: "colorSuccess",
  [UIEngineStatus.STATUS_STOPPING]: "colorDisabled",
  [UIEngineStatus.STATUS_REPAIRING]: "colorSuccess",
  [UIEngineStatus.STATUS_DROPPING]: "colorWarning",
  [UIEngineStatus.STATUS_RESTARTING]: null,
};

export const intermediaryStatuses = [
  UIEngineStatus.STATUS_STARTING,
  UIEngineStatus.STATUS_STOPPING,
  UIEngineStatus.STATUS_REPAIRING,
  UIEngineStatus.STATUS_DROPPING,
  UIEngineStatus.STATUS_RESTARTING,
];

const EngineStatusIcon = ({
  status,
  hideTitle,
  statusText,
}: IEngineStatusIcon) => {
  const tooltipText = statusText || status;
  return (
    <CustomTooltip
      title={!hideTitle ? tooltipText : ""}
      placement="top"
    >
      <div
        className={styles.root}
        data-testid={status}
      >
        {intermediaryStatuses.includes(status) ? (
          <>
            {LOADING_COLOR[status] && (
              <div className={styles.loading}>
                <Spinner
                  thickness={4}
                  size={20}
                  color={LOADING_COLOR[status]}
                />
              </div>
            )}

            {STATUS_ICON_MAP[status]}
          </>
        ) : (
          STATUS_ICON_MAP[status]
        )}
      </div>
    </CustomTooltip>
  );
};

export default EngineStatusIcon;
