import { createAction } from "redux-actions";

export interface Costs {
  ec2Costs: number;
  s3Costs: number;
  storageInTb: number;
  additionalUsageFees: number;
}

export const CostsActions = {
  LOAD_COSTS: "USER/LOAD_COSTS",
  LOAD_COSTS_FAILURE: "USER/LOAD_COSTS_FAILURE",
  LOAD_COSTS_SUCCESS: "USER/LOAD_COSTS_SUCCESS"
};

export const loadCosts = createAction(CostsActions.LOAD_COSTS, () => {});

export const loadCostsFailure = createAction(
  CostsActions.LOAD_COSTS_FAILURE,
  (errorMessage: string) => errorMessage
);

export const loadCostsSuccess = createAction(
  CostsActions.LOAD_COSTS_SUCCESS,
  (costs: Costs) => costs
);
