import ErrorScreen, {
  ErrorScreenImagePosition,
} from "../ErrorScreen/ErrorScreen";
import { openDbModal } from "pages/Database/redux/actions";
import { connect } from "react-redux";
import { selectUserRole } from "redux/user/selectors";
import { UserRole } from "pages/Users/User.interface";
import { ReactComponent as NoAccess } from "assets/images/errorScreen/noAccess.svg";
import * as _ from "lodash";
import {
  AccountDetails,
  AccountStatus,
  TenantStatus,
} from "businessLogic/model";
import { SubscriptionState } from "redux/subscription/reducer";
import { SubscriptionStatus } from "redux/subscription/actions";
import WelcomeAwsScreen from "../WelcomeAwsScreen/WelcomeAwsScreen";
import PendingSubscription, {
  PendingSubscriptionTemplate,
} from "../PendingSubscription/PendingSubscription";
import AccountPendingApprovalScreen from "components/WelcomeScreen/AccountPendingApprovalScreen/AccountPendingApprovalScreen";
import { AdminError } from "components/WelcomeScreen/SubscriptionError/AdminError";
import { ReactComponent as PendingSubscriptionError } from "assets/images/awsWelcome/pendingSubscriptionError.svg";
import { GeneralError } from "components/WelcomeScreen/SubscriptionError/GeneralError";
import { ReactComponent as FailedIcon } from "assets/images/errorScreen/failed.svg";
import { useTranslation } from "react-i18next";
import { LinkingError } from "./LinkingError";

interface Props {
  userRole: UserRole | null;
  openDbModal: () => void;
  account: AccountDetails;
  subscriptionState: SubscriptionState;
}

const WelcomeScreenContainer = ({
  userRole,
  account,
  subscriptionState,
}: Props) => {
  const { t } = useTranslation();

  const renderLoading = () => (
    <PendingSubscription
      template={PendingSubscriptionTemplate.Loading}
      title={<>Setting up your Firebolt account</>}
      description={
        <span>
          Completing the setup can take up to 15 minutes. We'll notify you as
          soon as it's ready.
        </span>
      }
    />
  );

  if (account.status === AccountStatus.ApprovalPending) {
    return <AccountPendingApprovalScreen />;
  }

  if (!subscriptionState.subscription?.customerId) {
    return <WelcomeAwsScreen />;
  }

  if (
    account.status === AccountStatus.ProvisioningFailed ||
    account.tenantStatus === TenantStatus.Failed
  ) {
    return <LinkingError />;
  }

  if (
    subscriptionState.subscription?.status ===
      SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED ||
    (account?.tenantStatus && account?.tenantStatus === TenantStatus.Creating)
  ) {
    return renderLoading();
  }

  // Broken subscription cases
  if (
    subscriptionState.subscription?.status ===
    SubscriptionStatus.SUBSCRIPTION_STATUS_STOPPED
  ) {
    return userRole === UserRole.AccountAdmin ? (
      <AdminError
        title={t("errors.subscription_canceled")}
        message={t("errors.subscription_canceled_message")}
        icon={<PendingSubscriptionError />}
      />
    ) : (
      <GeneralError title={t("errors.subscription_canceled")} />
    );
  }

  if (
    subscriptionState.subscription?.status ===
    SubscriptionStatus.SUBSCRIPTION_STATUS_FAILED
  ) {
    return userRole === UserRole.AccountAdmin ? (
      <AdminError
        title={t("errors.subscription_failed_admin")}
        message={t("errors.subscription_failed_message")}
        icon={<FailedIcon />}
      />
    ) : (
      <GeneralError title={t("errors.subscription_failed")} />
    );
  }

  // If user with non AccountAdmin role is trying to access,
  // while subscription is not active
  return (
    <ErrorScreen
      title="You don’t have access to this account"
      description="Subscription is not active"
      image={<NoAccess />}
      imagePosition={ErrorScreenImagePosition.Top}
    />
  );
};

const mapStateToProps = state => {
  return {
    userRole: selectUserRole(state),
    account: _.get(state, "account.accountDetails"),
    subscriptionState: state.subscription,
  };
};

export default connect(mapStateToProps, { openDbModal })(
  WelcomeScreenContainer
);
