import { ReactComponent as DBViewerIcon } from "assets/icons/roleIcons/DBViewer.svg";
import { ReactComponent as AccountAdminIcon } from "assets/icons/roleIcons/AccountAdmin.svg";
import { ReactComponent as DBAdminIcon } from "assets/icons/roleIcons/DBAdmin.svg";
import { UserInvitationStatus, UserRole } from "./User.interface";

export const USER_ROLE_NAMES_MAP = {
  [UserRole.AccountAdmin]: "Admin",
  [UserRole.DatabaseAdmin]: "Editor",
  [UserRole.Viewer]: "Viewer",
};

export const UI_USER_ROLE_NAMES_MAP = {
  [UserRole.AccountAdmin]: "Account Admin",
  [UserRole.DatabaseAdmin]: "DB Admin",
  [UserRole.Viewer]: "Viewer",
};

export const RBAC_NAME_TYPE_MAP = {
  ACCOUNT_ADMIN: UserRole.AccountAdmin,
  DB_ADMIN: UserRole.DatabaseAdmin,
  VIEWER: UserRole.Viewer,
};

export const USER_INVITATION_STATUS_NAMES_MAP = {
  [UserInvitationStatus.PendingApproval]: "Pending Approval",
  [UserInvitationStatus.PendingInvitation]: "Pending Invitation",
};

export const UI_USER_ROLE_DESCRIPTION_MAP = {
  [UserRole.AccountAdmin]: [
    "Allowed to invite, remove, update, accept, and deny Users.",
    "Allowed to create, remove, and update Databases.",
    "Allowed to create, remove, update, start, and stop Engines.",
    "Allowed to create, remove, save, and run SQL scripts.",
  ],
  [UserRole.DatabaseAdmin]: [
    "Allowed to create, remove, and update Databases.",
    "Allowed to create, remove, update, start, and stop Engines.",
    "Allowed to create, remove, save, and run SQL scripts.",
  ],
  [UserRole.Viewer]: [
    "Allowed to view and select Databases.",
    "Allowed to view and select Engines.",
    "Allowed to create, remove, save, and run SQL scripts.",
  ],
};

export const USER_ROLES_ICON_MAP = {
  [UserRole.Viewer]: <DBViewerIcon />,
  [UserRole.AccountAdmin]: <AccountAdminIcon />,
  [UserRole.DatabaseAdmin]: <DBAdminIcon />,
};
