/**
 * @generated SignedSource<<b78ed95bbdb423898a2beca3d553708b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Emoji = "UNSPECIFIED" | "KICK_SCOOTER" | "BULLET_TRAIN" | "ROCKET" | "TRACTOR" | "AUTOMOBILE" | "SPEEDBOAT" | "SAILBOAT" | "CANOE" | "LOCOMOTIVE" | "BICYCLE" | "DELIVERY_TRUCK" | "PASSENGER_SHIP" | "RACING_CAR" | "HELICOPTER" | "MINIBUS" | "POLICE_CAR" | "AERIAL_TRAMWAY" | "MOTOR_SCOOTER" | "ONCOMING_AUTOMOBILE" | "SMALL_AIRPLANE" | "GEM_STONE" | "CREDIT_CARD" | "MONEY_BAG" | "MOBILE_PHONE" | "BRIEFCASE" | "CROWN" | "GRADUATION_CAP" | "SKULL" | "JACK_O_LANTERN" | "SMILING_FACE_WITH_HORNS" | "FACE_SCREAMING_IN_FEAR" | "FIRE" | "ALIEN" | "ALIEN_MONSTER" | "ROBOT" | "MOVIE_CAMERA" | "%future added value";
export type EngineSettingsPreset = "UNSPECIFIED" | "GENERAL_PURPOSE" | "DATA_ANALYTICS" | "%future added value";
export type EngineSettingsWarmUpLevel = "UNSPECIFIED" | "MINIMAL" | "INDEXES" | "ALL" | "SHARD_ON_READ" | "%future added value";
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineDetailsQuery$variables = {
  id: string;
};
export type EngineDetailsQuery$data = {
  readonly engine: {
    readonly id: string;
    readonly name: string;
    readonly emoji: Emoji;
    readonly endpoint: string | null;
    readonly computeRegion: {
      readonly id: string;
      readonly name: string;
    };
    readonly currentStatusSummary: EngineStatusSummary;
    readonly allowedActions: ReadonlyArray<string>;
    readonly settingsPreset: EngineSettingsPreset;
    readonly settings: {
      readonly autoStopDelayDurationSeconds: number;
      readonly warmUpLevel: EngineSettingsWarmUpLevel;
    };
  };
};
export type EngineDetailsQuery = {
  variables: EngineDetailsQuery$variables;
  response: EngineDetailsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Engine",
    "kind": "LinkedField",
    "name": "engine",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emoji",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endpoint",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "computeRegion",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentStatusSummary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "desiredActions",
            "value": [
              "core.engines.start",
              "core.engines.stop",
              "core.engines.update",
              "core.engines.delete"
            ]
          }
        ],
        "kind": "ScalarField",
        "name": "allowedActions",
        "storageKey": "allowedActions(desiredActions:[\"core.engines.start\",\"core.engines.stop\",\"core.engines.update\",\"core.engines.delete\"])"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "settingsPreset",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EngineSettings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoStopDelayDurationSeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "warmUpLevel",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngineDetailsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngineDetailsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5daba413ccfad2cf463c419eabbc912b",
    "id": null,
    "metadata": {},
    "name": "EngineDetailsQuery",
    "operationKind": "query",
    "text": "query EngineDetailsQuery(\n  $id: ID!\n) {\n  engine(id: $id) {\n    id\n    name\n    emoji\n    endpoint\n    computeRegion {\n      id\n      name\n    }\n    currentStatusSummary\n    allowedActions(desiredActions: [\"core.engines.start\", \"core.engines.stop\", \"core.engines.update\", \"core.engines.delete\"])\n    settingsPreset\n    settings {\n      autoStopDelayDurationSeconds\n      warmUpLevel\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c13d037d65e1c517c85e96108386606";

export default node;
