import { Popover } from "@material-ui/core";
import styles from "./Select.module.scss";

export const CustomMenu = props => {
  const {
    handleClose,
    open,
    renderMenu,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    classes,
    popoverProps = {},
    ...rest
  } = props;

  if (!open) {
    return null;
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
        ...transformOrigin,
      }}
      classes={{
        paper: styles.paper,
        ...classes,
      }}
      open={true}
      anchorEl={anchorEl}
      onClose={handleClose}
      {...popoverProps}
      {...rest}
    >
      {renderMenu && renderMenu(handleClose)}
    </Popover>
  );
};
