import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { createFilter } from "redux-persist-transform-filter";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createRootReducer from "./reducers";
import remoteUiStateMiddleware from "redux/middlewares/remoteUiStateMiddleware";
import { MIGRATION_VERSION, stateMigrations } from "./stateMigrations";

// Set redux-persist properties
const appReducer = createFilter("app", ["language"]);
const authReducer = createFilter("auth", ["userDetails", "session"]);
const queryReducer = createFilter("query", [
  "queries.activeDbId",
  "queries.databasesQueries",
  "queries.expandedDbItem",
  "tables",
  "tableDetails.detailsByTable",
  "views.viewsByDatabase",
  "sidebar",
  "userScripts.activeSections",
]);
const userReducer = createFilter("user", ["selectedAccountId"]);
const persistConfig = {
  key: "app_v2",
  storage: storage,
  whitelist: ["app", "auth", "query", "database", "user"],
  transforms: [appReducer, authReducer, queryReducer, userReducer],
  migrate: createMigrate(stateMigrations, { debug: true }),
  version: MIGRATION_VERSION,
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(
  thunk,
  sagaMiddleware,
  remoteUiStateMiddleware
);

export default rootSaga => {
  const devtools: any = window["__REDUX_DEVTOOLS_EXTENSION__"]
    ? window["__REDUX_DEVTOOLS_EXTENSION__"]()
    : (f: any) => f;

  const store: any = createStore(
    persistedReducer,
    {},
    compose(middleware, devtools)
  );

  if (rootSaga) {
    sagaMiddleware.run(rootSaga);
  }

  const persistor = persistStore(store);
  return { store, persistor };
};
