import { createAction } from "redux-actions";
import { DatabasesList } from "businessLogic/model";
import {
  getUiEngineStatus,
  getUiEngineStatusText,
} from "components/EngineStatusIcon/helpers";
import * as _ from "lodash";

export const DatabaseActions = {
  LOAD_DATABASES: "DATABASE/LOAD_DATABASES",
  LOAD_DATABASES_IN_BACKGROUND: "DATABASE/LOAD_DATABASES_IN_BACKGROUND",
  SET_ERROR: "DATABASE/SET_ERROR",
  UPDATE_STATUS: "DATABASE/UPDATE_STATUS",
  SAVE_DATABASES: "DATABASE/SAVE_DATABASES",
  RESET_DB_STATE: "DATABASE/RESET_DB_STATE",
  OPEN_DB_MODAL: "DATABASE/OPEN_DB_MODAL",
  SET_ENGINES: "DATABASE/SET_ENGINES",
  ADD_ENGINES: "DATABASE/ADD_ENGINES",
  UPDATE_ENGINE: "DATABASE/UPDATE_ENGINE",
  UPDATE_ENGINE_SUCCESS: "DATABASE/UPDATE_ENGINE_SUCCESS",
  SET_BROWSER_TAB_ID: "DATABASE/SET_BROWSER_TAB_ID",
};

export interface EngineUpdateParams {
  databaseId: string;
  engine: any;
}

export interface EngineUpdateSuccessParams {
  databaseId?: string;
  engine: any;
}

export const loadDatabases = createAction(
  DatabaseActions.LOAD_DATABASES,
  (enableVirtualDb?: boolean) => ({ enableVirtualDb })
);

export const loadDatabasesInBackground = createAction(
  DatabaseActions.LOAD_DATABASES_IN_BACKGROUND,
  (enableVirtualDb?: boolean) => ({ enableVirtualDb })
);

export const saveDatabase = createAction(
  DatabaseActions.SAVE_DATABASES,
  (databasesList: DatabasesList) => databasesList
);

export const setDbError = createAction(
  DatabaseActions.SET_ERROR,
  (errorDatabase: string) => errorDatabase
);
export const updateDatabaseStatusIfNeeded = database => {
  return (dispatch, getState) => {
    const db = _.find(_.get(getState(), "database.databasesList", []), {
      id: database.id,
    });

    if (db && db.currentStatus !== database.currentStatus) {
      dispatch(updateDatabaseStatus(database));
    }
  };
};

export const updateDatabaseStatus = createAction(DatabaseActions.UPDATE_STATUS);

export const resetDbState = createAction(
  DatabaseActions.RESET_DB_STATE,
  () => {}
);

export const openDbModal = createAction(
  DatabaseActions.OPEN_DB_MODAL,
  (database?: any) => database
);

export const setEngines = createAction(
  DatabaseActions.SET_ENGINES,
  (databaseId: string, engines: any[]) => ({ databaseId, engines })
);

export const updateEngine = createAction(
  DatabaseActions.UPDATE_ENGINE,
  ({ databaseId, engine }: EngineUpdateParams) => ({
    databaseId,
    engine,
  })
);

export const updateEngineStatusIfNeeded = ({ databaseId, engine }) => {
  return (dispatch, getState) => {
    const state = getState();
    const db = _.find(_.get(state, "database.databasesList", []), {
      id: databaseId,
    });

    if (!db) {
      return;
    }

    const currentEngine = _.find(db.engines, {
      id: engine.id,
    });

    const status = getUiEngineStatus(engine.currentStatusSummary);
    const statusText = getUiEngineStatusText(engine.currentStatusSummary);

    if (currentEngine.status !== status) {
      dispatch({
        type: DatabaseActions.UPDATE_ENGINE,
        payload: {
          databaseId,
          engine: {
            id: engine.id,
            status,
            statusText,
          },
        },
      });
    }
  };
};

export const updateEngineSuccess = createAction(
  DatabaseActions.UPDATE_ENGINE_SUCCESS,
  ({ databaseId, engine }: EngineUpdateSuccessParams) => ({
    databaseId,
    engine,
  })
);
export const setBrowserTabId = createAction(
  DatabaseActions.SET_BROWSER_TAB_ID,
  browserTabId => ({ browserTabId })
);

export const addEngines = createAction(
  DatabaseActions.ADD_ENGINES,
  (databaseId: string, engines: any[]) => ({ databaseId, engines })
);
