import * as types from "../actions/queries";
import * as _ from "lodash";
import { DbObjectType } from "../../constants";
import { QueryStatus } from "../../constants/queries";
import { isScriptNameDuplicate } from "../../utils/scripts";
import { ProgressInfoState } from "../QueryEditor.interfaces";
import {
  isQueryStatementRunning,
  queriesByIdToSortedArray,
} from "../../utils/queries";
import { getCurrentQueryBy } from "redux/query/selectors";

const QUERY_EDITOR_HEIGHT = 200;

export const queryDefaultState = {
  id: null,
  database: null,
  name: null,
  isDefaultSelect: false,
  didInvalidate: true,
  query: "",
  editorHeight: QUERY_EDITOR_HEIGHT,
  settings: {},
  isQueryRunning: false,
  userScriptId: null,
  serverQueryId: null,
  multiQueryStatement: [],
  engineId: "",
};

export const INITIAL_STATE = {
  databasesQueries: {
    initialQueryState: {
      lastCreatedId: 0, // to be moved on user script
      currentQueryTabId: 0, // since only one query may be active this is not needed anymoe
      queriesById: {},
    },
  },
  queryResultInfo: {},
  databasesResumedQueries: {},

  expandedDbItem: {
    [DbObjectType.COLUMNS]: {
      expanded: true,
    },
    [DbObjectType.TABLES]: {
      expanded: true,
    },
    [DbObjectType.EXTERNAL_TABLES]: {
      expanded: true,
    },
    [DbObjectType.INDEXES]: {
      expanded: true,
    },
  },
  activeDbId: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.NEW_QUERY: {
      return addNewQuery(state, action);
    }

    case types.SELECT_QUERY: {
      const { queryId, database } = action.payload;
      const selectedScriptTab = getCurrentQueryBy(state, database);
      return {
        ...state,
        databasesQueries: {
          ...state.databasesQueries,
          [database]: {
            ...state.databasesQueries?.[database],
            previouslySelectedEngineId: selectedScriptTab?.engineId,
            currentQueryTabId: queryId,
          },
        },
      };
    }

    case types.CHANGE_TAB_EDITOR_HEIGHT: {
      const { editorHeight, database, queryId } = action.payload;
      return changeQueryState(
        state,
        {
          editorHeight,
        },
        { queryId, database }
      );
    }

    case types.CHANGE_EDITOR_TAB_STATE: {
      const { expanded, database, queryId, editorLastHeight } = action.payload;
      return changeQueryState(
        state,
        {
          editorLastHeight,
          expanded,
        },
        { queryId, database }
      );
    }

    case types.CHANGE_ORDER: {
      const { itemsOrder, database } = action;

      const currentQueriesById = state.databasesQueries?.[database].queriesById;
      const queriesById = itemsOrder.reduce((acc, id, index) => {
        const current = currentQueriesById[id] ?? {};
        acc[id] = { ...current, order: index };
        return acc;
      }, {});

      return {
        ...state,
        databasesQueries: {
          ...state.databasesQueries,
          [database]: {
            ...state.databasesQueries?.[database],
            queriesById,
          },
        },
      };
    }

    case types.CLOSE_TABS: {
      const { excludeQuery, database } = action.payload;
      const excludeQueryId = excludeQuery ? [excludeQuery.id] : [];
      const selectedScriptTab = getCurrentQueryBy(state, database);
      const actionCopy = {
        ...action,
        payload: {
          ...action.payload,
          engineId: selectedScriptTab ? selectedScriptTab.engineId : "",
        },
      };

      const newState = {
        ...state,
        databasesQueries: {
          ...state.databasesQueries,
          [database]: {
            ...state.databasesQueries?.[database],
            queriesById: _.pick(
              state.databasesQueries?.[database].queriesById,
              excludeQueryId
            ),
          },
        },
        queryResultInfo: {
          ...state.queryResultInfo,
          [database]: _.pick(state.queryResultInfo?.[database], excludeQueryId),
        },
      };

      if (excludeQuery) {
        return newState;
      }

      return addNewQuery(newState, actionCopy);
    }

    case types.REMOVE_QUERY: {
      const { database, queryId } = action.payload;
      const selectedScriptTab = getCurrentQueryBy(state, database);
      const actionCopy = {
        ...action,
        payload: {
          ...action.payload,
          engineId: selectedScriptTab ? selectedScriptTab.engineId : "",
        },
      };

      const newState = {
        ...state,
        databasesQueries: {
          ...state.databasesQueries,
          [database]: {
            ...state.databasesQueries?.[database],
            queriesById: _.omit(
              state.databasesQueries?.[database].queriesById,
              queryId
            ),
          },
        },
        queryResultInfo: {
          ...state.queryResultInfo,
          [database]: _.omit(state.queryResultInfo?.[database], queryId),
        },
      };

      const lastTabQuery = getLastQuery(newState, database);

      if (_.isEmpty(lastTabQuery)) {
        // all tabs where closed
        // automatically open a new tab
        const newAction = {
          ...actionCopy,
          payload: {
            ...actionCopy.payload,
            allScripts: actionCopy.payload.allScripts.filter(
              s => s.id !== queryId
            ),
          },
        };
        return addNewQuery(newState, newAction);
      }

      if (queryId === state.databasesQueries?.[database]?.currentQueryTabId) {
        // active tab was closed
        // search for last tab and make it active
        return {
          ...newState,
          databasesQueries: {
            ...newState.databasesQueries,
            [database]: {
              ...newState.databasesQueries?.[database],
              currentQueryTabId: lastTabQuery.id,
              previouslySelectedEngineId: selectedScriptTab?.engineId,
            },
          },
        };
      }

      return newState;
    }

    case types.QUERY_SAVED_AS_SCRIPT: {
      const { queryId, database, userScriptId } = action.payload;

      const query = state.databasesQueries?.[database].queriesById[queryId];

      if (!query) {
        return state;
      }

      return changeQueryState(
        state,
        {
          userScriptId,
        },
        { queryId, database }
      );
    }
    case types.SAVE_QUERY_STATEMENTS: {
      const { database, queryId, querySqlStatements } = action.payload;
      return changeQueryState(
        state,
        {
          querySqlStatements,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_STATEMENT_REQUEST: {
      const { database, queryId, queryStatementId, query } = action.payload;
      const currentQueryStatement = {
        queryStatementId,
        query,
        progress: null,
        status: QueryStatus.Running,
      };

      const queryResultInfo =
        state.queryResultInfo?.[database]?.[queryId] || [];

      const multiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...queryResultInfo,
            executedQuery: false,
            database: database,
          },
          multiQueryStatement: [...multiQueryStatement, currentQueryStatement],
          database: database,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_EXPLAIN_REQUEST: {
      const { database, queryStatementId, explainType, currentExplainType } =
        action.payload;

      const queryId = state.databasesQueries?.[database]?.currentQueryTabId;

      const queryResultInfo =
        state.queryResultInfo?.[database]?.[queryId] || [];

      const currentMultiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      const multiQueryStatement = [...currentMultiQueryStatement];
      const currentQueryStatement = multiQueryStatement[queryStatementId];

      const results = queryResultInfo?.results || [];

      const newResults = [...results];

      const explainTypeData = _.get(results, [
        queryStatementId,
        "explain",
        explainType,
      ]);

      if (!explainTypeData) {
        const explain = _.get(results, [queryStatementId, "explain"], {});
        newResults[queryStatementId] = {
          ...results[queryStatementId],
          explain: {
            ...explain,
            explainType,
            currentExplainType,
            loading: true,
          },
        };

        multiQueryStatement[queryStatementId] = {
          ...currentQueryStatement,
          status: QueryStatus.ExplainRunning,
        };
      }

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...queryResultInfo,
            results: newResults,
          },

          advancedExplain:
            state.databasesQueries?.[database]?.queriesById?.[queryId]?.settings
              ?.firebolt_advanced_explain === "1",
          multiQueryStatement,

          database,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_EXPLAIN_RESET: {
      const { database, query } = action.payload;
      const { id: queryId } = query;
      const currentMultiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      const multiQueryStatement = [...currentMultiQueryStatement].map(
        statement => {
          if (statement.status === QueryStatus.ExplainRunning) {
            return {
              ...statement,
              status: QueryStatus.Success,
            };
          }
          return statement;
        }
      );

      const queryResultInfo = state.queryResultInfo?.[database]?.[queryId];

      return changeQueryState(
        state,
        {
          multiQueryStatement,
          database,
          queryResultInfo,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_EXPLAIN_SUCCESS: {
      const { queryStatementId, database, explain, explainType, isLQP2 } =
        action.payload;
      const queryId = state.databasesQueries?.[database]?.currentQueryTabId;

      const queryResultInfo = state.queryResultInfo?.[database]?.[queryId];

      const results = queryResultInfo?.results || [];
      const currentMultiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      const newResults = [...results];

      const multiQueryStatement = [...currentMultiQueryStatement];
      const currentQueryStatement = multiQueryStatement[queryStatementId];
      const currentExplain = _.get(results, [queryStatementId, "explain"], {});

      newResults[queryStatementId] = {
        ...results[queryStatementId],
        explain: {
          ...currentExplain,
          explainType,
          loading: false,
          [explainType]: explain,
          isLQP2,
        },
      };

      multiQueryStatement[queryStatementId] = {
        ...currentQueryStatement,
        status: QueryStatus.Success,
      };

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...queryResultInfo,
            results: newResults,
          },
          multiQueryStatement,
          database,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_EXPLAIN_FAILURE: {
      const { queryStatementId, database, explainType } = action.payload;
      const queryId = state.databasesQueries?.[database]?.currentQueryTabId;

      const queryResultInfo = state.queryResultInfo?.[database]?.[queryId];

      const results = queryResultInfo?.results || [];
      const currentMultiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      const multiQueryStatement = [...currentMultiQueryStatement];
      const currentQueryStatement = multiQueryStatement[queryStatementId];
      const currentExplain = _.get(results, [queryStatementId, "explain"], {});
      const newResults = [...results];

      const newExplain = currentExplain[currentExplain.currentExplainType]
        ? {
            ...currentExplain,
            explainType: currentExplain.currentExplainType,
            loading: false,
          }
        : { error: explainType, loading: false };

      newResults[queryStatementId] = {
        ...results[queryStatementId],
        explain: newExplain,
      };

      multiQueryStatement[queryStatementId] = {
        ...currentQueryStatement,
        status: QueryStatus.Success,
      };

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...queryResultInfo,
            results: newResults,
          },
          multiQueryStatement,
          database,
        },
        { queryId, database }
      );
    }

    case types.MARK_QUERY_AS_STOPPED: {
      const { database, queryId } = action.payload;

      return changeQueryState(
        state,
        {
          serverQueryId: null,
          database: database,
          isQueryRunning: false,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_REQUEST: {
      const {
        database,
        queryId,
        query: requestedQuery,
        isDefaultSelect,
      } = action.payload;

      const queryState =
        state.databasesQueries?.[database]?.queriesById[queryId] ?? {};
      const { query } = queryState;

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            executedQuery: false,
            isDefaultSelect: isDefaultSelect,
            didInvalidate: false,
            database: database,
          },

          advancedExplain:
            state.databasesQueries?.[database]?.queriesById?.[queryId]?.settings
              ?.firebolt_advanced_explain === "1",
          serverQueryId: null,
          database: database,
          isQueryRunning: true,
          multiQueryStatement: [],
          requestedQuery: requestedQuery !== query ? requestedQuery : undefined,
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_SUCCESS: {
      const {
        queryStatementId,
        database,
        results,
        queryId,
        isLastQueryStatement,
      } = action.payload;
      const queryFailed = _.get(results, "error", false);

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...generateQueryResultInfo(state, action.payload),
          },
          database: database,
          isQueryRunning: !queryFailed && !isLastQueryStatement,
          serverQueryId: null,

          multiQueryStatement: changeMultiQueryStatements(
            state,
            {
              database,
              queryId,
              queryStatementId,
            },
            {
              isQueryRunning: false,
              fetchingAsyncData: false,
              fetchedAsyncDataOnce: !!results.fetchedAsyncDataOnce,
              statistics: results.statistics,
              expiredResults: results?.expiredResults,
              status: queryFailed ? QueryStatus.Failed : QueryStatus.Success,
            }
          ),
        },
        { queryId, database }
      );
    }

    case types.FETCH_ASYNC_DATA: {
      const { queryStatementId, database, results, queryId } = action.payload;

      return changeQueryState(
        state,
        {
          database: database,
          multiQueryStatement: changeMultiQueryStatements(
            state,
            {
              database,
              queryId,
              queryStatementId,
            },
            {
              fetchingAsyncData: true,
              statistics: results.statistics,
              executionState: QueryStatus.Success,
            }
          ),
        },
        { queryId, database }
      );
    }

    case types.EXECUTE_QUERY_FAILURE: {
      const { queryStatementId, database, results, queryId } = action.payload;
      const queryResultInfo = generateQueryResultInfo(state, action.payload);

      return changeQueryState(
        state,
        {
          queryResultInfo,
          database: database,
          isQueryRunning: false,
          serverQueryId: null,

          multiQueryStatement: changeMultiQueryStatements(
            state,
            {
              database,
              queryId,
              queryStatementId,
            },
            {
              isQueryRunning: false,
              fetchingAsyncData: false,
              statistics: results?.statistics,
              fetchedAsyncDataOnce: results?.fetchedAsyncDataOnce,
              expiredResults: results?.expiredResults,
              status: QueryStatus.Failed,
            }
          ),
        },
        { queryId, database }
      );
    }

    case types.CANCEL_QUERY_REQUEST: {
      const { queryId, database } = action.payload;
      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...state.queryResultInfo?.[database]?.[queryId],
            isCanceling: true,
          },
        },
        { queryId, database }
      );
    }

    case types.CANCEL_QUERY_SUCCESS: {
      const { queryId, database } = action.payload;

      const multiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      ).map(statement => {
        if (isQueryStatementRunning(statement.status)) {
          return {
            ...statement,
            status: QueryStatus.Canceled,
          };
        }
        return statement;
      });

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...state.queryResultInfo?.[database]?.[queryId],
            error: null,
            isCanceling: false,
          },
          multiQueryStatement,
          isQueryRunning: false,
          database,
        },
        { queryId, database }
      );
    }

    case types.CANCEL_QUERY_EXECUTION: {
      const { database, queryId } = action.payload;

      const newResults =
        state.queryResultInfo?.[database]?.[queryId]?.results || [];

      const multiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      const last = multiQueryStatement[multiQueryStatement.length - 1];

      if (last) {
        const { queryStatementId, statistics } = last;

        newResults.push({
          queryStatementId,
          statistics,
          canceled: true,
        });
      }

      const queryResultInfo = {
        ...state.queryResultInfo?.[database]?.[queryId],
        executedQuery: true,
        error: null,
        results: newResults,
        database: database,
      };

      return changeQueryState(
        state,
        { queryResultInfo },
        { queryId, database }
      );
    }

    case types.CANCEL_QUERY_FAILURE: {
      const { queryId, database, error } = action.payload;
      const multiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      return changeQueryState(
        state,
        {
          queryResultInfo: {
            ...state.queryResultInfo?.[database]?.[queryId],
            error: error,
          },
          multiQueryStatement: _.map(multiQueryStatement, elem => {
            if (isQueryStatementRunning(elem.status)) {
              return {
                ...elem,
                status: QueryStatus.Failed,
              };
            }
            return elem;
          }),
          isQueryRunning: false,
          database: database,
        },
        { queryId, database }
      );
    }

    case types.SAVE_TAB_NAV_POSITION: {
      const { position, database } = action.payload;
      return {
        ...state,
        databasesQueries: {
          ...state.databasesQueries,
          [database]: {
            ...state.databasesQueries?.[database],
            tabNavPosition: position,
          },
        },
      };
    }

    case types.UPDATE_QUERY: {
      const { sqlQuery, query, database } = action.payload;
      return changeQueryState(
        state,
        {
          query: sqlQuery,
          isChanged: true,
        },
        { queryId: query.id, database }
      );
    }

    case types.RENAME_QUERY: {
      const { name, queryId, database } = action.payload;
      if (!name.length) {
        return state;
      }
      return changeQueryState(
        state,
        {
          name,
        },
        { queryId, database }
      );
    }

    case types.RESET_ALL_QUERIES:
      return INITIAL_STATE;

    case types.SET_SERVER_QUERY_ID: {
      const { queryId, database, serverQueryId, asyncStatementId } =
        action.payload;

      return changeQueryState(
        state,
        {
          serverQueryId,
          database,
          multiQueryStatement: changeMultiQueryStatements(
            state,
            {
              database,
              queryId,
              queryStatementId: asyncStatementId,
            },
            {
              serverQueryId,
              isAsync: true,
            }
          ),
        },
        { queryId, database }
      );
    }

    case types.SET_QUERY_STATEMENT_PROGRESS: {
      const { database, progressInfo, queryStatementId, queryId } =
        action.payload;

      const multiQueryStatement = getMultiQueryStatement(
        state,
        database,
        queryId
      );

      const newQueryResultInfo = {
        multiQueryStatement: _.map(multiQueryStatement, elem => {
          if (elem.queryStatementId === queryStatementId) {
            const progressState: ProgressInfoState = {
              ...progressInfo,
              elapsed: progressInfo.elapsed,
            };

            return {
              ...elem,
              progress: progressState,
            };
          }

          return elem;
        }),
      };

      return changeQueryState(
        state,
        {
          ...newQueryResultInfo,
          database,
        },
        { queryId, database }
      );
    }

    case types.ADD_QUERY_SETTINGS: {
      const { database, queryId, settings } = action.payload;

      return updateQuerySettings(
        state,
        {
          ...state.databasesQueries?.[database]?.queriesById?.[queryId]
            .settings,
          [settings.key]: settings.value,
        },
        { database, queryId }
      );
    }

    case types.REMOVE_QUERY_SETTING: {
      const { flag, database } = action.payload;
      const queryId = state.databasesQueries?.[database]?.currentQueryTabId;
      const settings =
        state.databasesQueries?.[database]?.queriesById?.[queryId].settings;

      return updateQuerySettings(state, _.omit(settings, flag), {
        database,
        queryId,
      });
    }

    case types.REMOVE_QUERY_SETTINGS: {
      const { database } = action.payload;
      const queryId = state.databasesQueries?.[database]?.currentQueryTabId;
      return updateQuerySettings(state, {}, { database: database, queryId });
    }

    case types.CLEAR_QUERIES_RESULT: {
      return { ...state, queryResultInfo: {} };
    }

    case types.CLOSE_QUERY_DIALOG: {
      return {
        ...state,
        openSnackbar: false,
      };
    }

    case types.SAVE_EXPANDED_DB_ITEM: {
      const { dbItem, expanded } = action.payload;
      const { expandedDbItem } = state;
      return {
        ...state,
        expandedDbItem: {
          ...expandedDbItem,
          [dbItem]: {
            expanded: expanded,
          },
        },
      };
    }

    case types.UPDATE_ACTIVE_DB_ID: {
      return {
        ...state,
        activeDbId: action.payload,
      };
    }

    case types.UPDATE_QUERY_ENGINE_ID: {
      const { database, queryId, engineId } = action.payload;

      const lastTabQuery = getLastQuery(state, database);

      const newState = _.isEmpty(lastTabQuery)
        ? addNewQuery(state, action)
        : state;

      return changeQueryState(
        newState,
        {
          engineId,
        },
        { queryId, database }
      );
    }

    case types.REHYDRATE_ALL_USER_QUERIES: {
      return {
        ...state,
        databasesQueries: {
          ...state.databasesQueries,
          ...action.payload.databasesQueries,
        },
      };
    }

    case types.QUERY_RESUMED_IN_BACKGROUND: {
      const { database } = action.payload;
      return {
        ...state,
        databasesResumedQueries: {
          ...state?.databasesResumedQueries,
          [database]: {
            ...state?.databasesResumedQueries?.[database],
            attemptToResume: true,
          },
        },
      };
    }

    case types.QUERY_STATEMENT_RESUMED_IN_BACKGROUND: {
      const { database, serverQueryId } = action.payload;
      return {
        ...state,
        databasesResumedQueries: {
          ...state?.databasesResumedQueries,
          [database]: {
            ...state?.databasesResumedQueries?.[database],
            [serverQueryId]: true,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

function insertQueryNextToLocation({ queriesById, location, queryToAdd }) {
  let orderOfQueryAdded = Number.MAX_SAFE_INTEGER;
  return queriesByIdToSortedArray(queriesById).reduce(
    (acc: any, q: any, idx) => {
      const currentQuery = Number.isInteger(q?.order)
        ? { ...q }
        : { ...q, order: idx };

      acc[currentQuery.id] = {
        ...currentQuery,
        order:
          currentQuery.order >= orderOfQueryAdded
            ? currentQuery.order + 1
            : currentQuery.order,
      };

      if (currentQuery.id === location.id) {
        orderOfQueryAdded = currentQuery.order + 1;
        acc[queryToAdd.id] = { ...queryToAdd, order: orderOfQueryAdded };
      }

      return acc;
    },
    {}
  );
}

function addNewQuery(state, action) {
  const { database, savedQuery, engineId = "", allScripts } = action.payload;

  const queryDetails =
    state.databasesQueries?.[database] ||
    INITIAL_STATE.databasesQueries.initialQueryState;

  const lastQueryEditorHeight =
    queryDetails?.queriesById?.[queryDetails.currentQueryTabId]?.editorHeight ||
    QUERY_EDITOR_HEIGHT;

  const lastQueryById = getLastQuery(state, database);
  const currentQueryById = getCurrentQueryBy(state, database);

  const lastCreatedId = lastQueryById ? lastQueryById.id : 0;
  const newId = lastCreatedId + 1;

  const newEngineId =
    currentQueryById && currentQueryById.engineId
      ? currentQueryById.engineId
      : engineId;

  const name = savedQuery?.name || createQueryName(newId, allScripts);

  const newQuery = {
    ...queryDefaultState,
    id: newId,
    name,
    query: savedQuery?.query || "",
    userScriptId: savedQuery?.id || null,
    editorHeight: lastQueryEditorHeight,
    engineId: newEngineId,
    createdAt: new Date().getTime(),
  };

  const newQueriesById = savedQuery?.originalQueryLocation
    ? insertQueryNextToLocation({
        queriesById: queryDetails.queriesById,
        location: savedQuery?.originalQueryLocation,
        queryToAdd: newQuery,
      })
    : {
        ...queryDetails.queriesById,
        [newQuery.id]: newQuery,
      };

  const queryInfo = {
    tabNavPosition: queryDetails.tabNavPosition
      ? queryDetails.tabNavPosition
      : 0,
    currentQueryTabId: newQuery.id,
    previouslySelectedEngineId: currentQueryById?.engineId,
    queriesById: {
      ...newQueriesById,
    },
  };

  const newQueryResultInfo = {
    results: null,
    error: null,
  };

  const newResultInfo = {
    ...state.queryResultInfo,
    [database]: {
      ...state.queryResultInfo?.[database],
      [newQuery.id]: newQueryResultInfo,
    },
  };

  return {
    ...state,
    databasesQueries: {
      ...state.databasesQueries,
      [database]: {
        ...state.databasesQueries?.[database],
        ...queryInfo,
      },
    },
    queryResultInfo: newResultInfo,
    expandedDbItem: {
      ...state.expandedDbItem,
    },
  };
}

function changeQueryState(
  oldFullState,
  newCurrentQueryState,
  options: any = {}
) {
  const { queryResultInfo, ...currentQueryState } = newCurrentQueryState;
  const oldQueryDbDetails = oldFullState.databasesQueries;
  const { database } = options;
  const queryId =
    options.queryId || oldQueryDbDetails?.[database].currentQueryTabId;
  const oldQueryState = oldQueryDbDetails?.[database].queriesById[queryId];

  oldQueryState.name = newCurrentQueryState.name || oldQueryState.name;

  let newQueryResultInfo = {};
  if (queryResultInfo) {
    newQueryResultInfo = {
      ...oldFullState.queryResultInfo,
      [database]: {
        ...oldFullState.queryResultInfo?.[database],
        [queryId]: {
          ...queryResultInfo,
        },
      },
    };
  } else {
    newQueryResultInfo = oldFullState.queryResultInfo;
  }

  return {
    ...oldFullState,
    databasesQueries: {
      ...oldFullState.databasesQueries,
      [database]: {
        ...oldFullState.databasesQueries?.[database],
        queriesById: {
          ...oldFullState.databasesQueries?.[database].queriesById,
          [queryId]: {
            ...oldQueryState,
            ...currentQueryState,
          },
        },
      },
    },
    queryResultInfo: {
      ...newQueryResultInfo,
    },
    expandedDbItem: {
      ...oldFullState.expandedDbItem,
    },
  };
}

function updateQuerySettings(state, settings, { queryId, database }) {
  return {
    ...state,
    databasesQueries: {
      ...state.databasesQueries,
      [database]: {
        ...state.databasesQueries?.[database],
        queriesById: {
          ...state.databasesQueries?.[database]?.queriesById,
          [queryId]: {
            ...state.databasesQueries?.[database]?.queriesById?.[queryId],
            settings,
          },
        },
      },
    },
  };
}

function changeMultiQueryStatements(
  state,
  config: {
    database: string;
    queryId: string;
    queryStatementId?: string;
  },
  options = {}
) {
  const { database, queryId, queryStatementId } = config;
  const multiQueryStatement = getMultiQueryStatement(state, database, queryId);
  return _.map(multiQueryStatement, elem => {
    if (elem.queryStatementId !== queryStatementId) return { ...elem };

    return {
      ...elem,
      ...options,
    };
  });
}

function generateQueryResultInfo(state, payload) {
  const { queryStatementId, database, results, queryId } = payload;

  const newResults =
    state.queryResultInfo?.[database]?.[queryId]?.results || [];

  // in case query was resumed make sure to add result entry in to results
  if (queryStatementId && newResults.length < queryStatementId) {
    for (let i = 0; i < queryStatementId; i++) {
      newResults.push({
        queryStatementId: i,
      });
    }
  }

  newResults.push(results);

  return {
    ...state.queryResultInfo?.[database]?.[queryId],
    executedQuery: true,
    error: null,

    results: newResults,
    database: database,
  };
}

function checkExistScriptName(usersScripts, scriptName) {
  return isScriptNameDuplicate(usersScripts, scriptName);
}

function createQueryName(id, usersScripts) {
  const scriptName = `Script ${id}`;
  let findScriptName = checkExistScriptName(usersScripts, `Script ${id}`);
  let _scriptName = scriptName;
  let i = id;
  while (findScriptName) {
    i++;
    _scriptName = `Script ${i}`;
    findScriptName = checkExistScriptName(usersScripts, _scriptName);
  }
  return _scriptName;
}

function getLastQuery(state, database) {
  return _.chain(state.databasesQueries?.[database]?.queriesById)
    .toArray()
    .maxBy("id")
    .value();
}

function getMultiQueryStatement(state, database, queryId) {
  return _.get(
    state,
    `databasesQueries.${database}.queriesById.${queryId}.multiQueryStatement`,
    []
  );
}
