/**
 * @generated SignedSource<<57181120afbd46e748152a895546d827>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountApproveInput = {
  id: string;
};
export type ApproveAccountMutation$variables = {
  input: AccountApproveInput;
};
export type ApproveAccountMutation$data = {
  readonly accountApprove: {
    readonly success: boolean;
  };
};
export type ApproveAccountMutation = {
  variables: ApproveAccountMutation$variables;
  response: ApproveAccountMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountApprovePayload",
    "kind": "LinkedField",
    "name": "accountApprove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApproveAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApproveAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a1edfb1ae08ce3a16f0e6198500ff6b9",
    "id": null,
    "metadata": {},
    "name": "ApproveAccountMutation",
    "operationKind": "mutation",
    "text": "mutation ApproveAccountMutation(\n  $input: AccountApproveInput!\n) {\n  accountApprove(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1c3d41d1d6856705098937a5017a234";

export default node;
