import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { UserCreateInput } from "./__generated__/CreateUserMutation.graphql";

const mutation = graphql`
  mutation CreateUserMutation($input: UserCreateInput!) {
    userCreate(input: $input) {
      success
    }
  }
`;

function commit(environment, input: UserCreateInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
