/**
 * @generated SignedSource<<4bf6824068e72c35ae95e67af8e534f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngineSettingsPreset = "UNSPECIFIED" | "GENERAL_PURPOSE" | "DATA_ANALYTICS" | "%future added value";
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineStatusSummaryChangedSubscription$variables = {
  id: string;
  accountId: string;
};
export type EngineStatusSummaryChangedSubscription$data = {
  readonly engineStatusSummaryChanged: {
    readonly id: string;
    readonly currentStatusSummary: EngineStatusSummary;
    readonly endpoint: string | null;
    readonly settingsPreset: EngineSettingsPreset;
    readonly latestRevision: {
      readonly id: string;
      readonly specification: {
        readonly dbComputeInstancesCount: number;
        readonly dbComputeInstancesUseSpot: boolean;
        readonly dbComputeInstancesType: {
          readonly id: string;
          readonly pricePerHourCents: number;
          readonly name: string;
          readonly cpuVirtualCoresCount: number;
          readonly memorySizeBytes: number;
          readonly storageSizeBytes: number;
        };
      };
    };
    readonly " $fragmentSpreads": FragmentRefs<"EngineRow_engine" | "EngineModal_engine">;
  };
};
export type EngineStatusSummaryChangedSubscription = {
  variables: EngineStatusSummaryChangedSubscription$variables;
  response: EngineStatusSummaryChangedSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentStatusSummary",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endpoint",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingsPreset",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EngineRevision",
  "kind": "LinkedField",
  "name": "latestRevision",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngineRevisionSpecification",
      "kind": "LinkedField",
      "name": "specification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbComputeInstancesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbComputeInstancesUseSpot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InstanceType",
          "kind": "LinkedField",
          "name": "dbComputeInstancesType",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "accountId",
                  "variableName": "accountId"
                }
              ],
              "kind": "ScalarField",
              "name": "pricePerHourCents",
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cpuVirtualCoresCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "memorySizeBytes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "storageSizeBytes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EngineStatusSummaryChangedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Engine",
        "kind": "LinkedField",
        "name": "engineStatusSummaryChanged",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EngineRow_engine"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EngineModal_engine"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EngineStatusSummaryChangedSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Engine",
        "kind": "LinkedField",
        "name": "engineStatusSummaryChanged",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "desiredActions",
                "value": [
                  "core.engines.start",
                  "core.engines.stop",
                  "core.engines.update",
                  "core.engines.delete"
                ]
              }
            ],
            "kind": "ScalarField",
            "name": "allowedActions",
            "storageKey": "allowedActions(desiredActions:[\"core.engines.start\",\"core.engines.stop\",\"core.engines.update\",\"core.engines.delete\"])"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "computeRegion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngineSettings",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoStopDelayDurationSeconds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "warmUpLevel",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingConnection",
            "kind": "LinkedField",
            "name": "bindings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Binding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Database",
                        "kind": "LinkedField",
                        "name": "database",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dataSizeFull",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "computeRegionId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3ed07144e859fe94815f0d97e1baf31",
    "id": null,
    "metadata": {},
    "name": "EngineStatusSummaryChangedSubscription",
    "operationKind": "subscription",
    "text": "subscription EngineStatusSummaryChangedSubscription(\n  $id: ID!\n  $accountId: ID!\n) {\n  engineStatusSummaryChanged(id: $id) {\n    id\n    currentStatusSummary\n    endpoint\n    settingsPreset\n    latestRevision {\n      id\n      specification {\n        dbComputeInstancesCount\n        dbComputeInstancesUseSpot\n        dbComputeInstancesType {\n          id\n          pricePerHourCents(accountId: $accountId)\n          name\n          cpuVirtualCoresCount\n          memorySizeBytes\n          storageSizeBytes\n        }\n      }\n    }\n    ...EngineRow_engine\n    ...EngineModal_engine\n  }\n}\n\nfragment EngineModal_engine on Engine {\n  id\n  name\n  description\n  emoji\n  computeRegionId\n  settingsPreset\n  endpoint\n  currentStatusSummary\n  settings {\n    autoStopDelayDurationSeconds\n    warmUpLevel\n  }\n  latestRevision {\n    id\n    specification {\n      dbComputeInstancesCount\n      dbComputeInstancesUseSpot\n      dbComputeInstancesType {\n        id\n        name\n      }\n    }\n  }\n  bindings {\n    edges {\n      node {\n        id\n        database {\n          id\n          name\n          emoji\n          dataSizeFull\n        }\n      }\n    }\n  }\n}\n\nfragment EngineRow_engine on Engine {\n  id\n  name\n  description\n  emoji\n  allowedActions(desiredActions: [\"core.engines.start\", \"core.engines.stop\", \"core.engines.update\", \"core.engines.delete\"])\n  computeRegion {\n    id\n    name\n  }\n  settingsPreset\n  endpoint\n  currentStatusSummary\n  settings {\n    autoStopDelayDurationSeconds\n  }\n  latestRevision {\n    id\n    specification {\n      dbComputeInstancesCount\n      dbComputeInstancesUseSpot\n      dbComputeInstancesType {\n        id\n        name\n      }\n    }\n  }\n  bindings {\n    edges {\n      node {\n        id\n        database {\n          id\n          name\n          emoji\n          dataSizeFull\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69791f2e82644c1ce9d62b89b3fab31a";

export default node;
