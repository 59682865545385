import { Action, handleActions } from "redux-actions";
import { DatabaseActions } from "./actions";
import { DatabasesList } from "businessLogic/model";
import * as _ from "lodash";

export interface DatabaseState {
  errorDbMessage?: string;
  databasesList: any[];
  loadingDatabases: boolean;
  failedDatabases?: any[];
  errorDialog?: boolean;
  loadingDatabasesDetails?: boolean;
  openDbModal: boolean;
  openDb?: any;
  browserTabId: string | null;
  loadingDatabasesInBackground: boolean;
}

export const INITIAL_STATE: DatabaseState = {
  databasesList: [],
  errorDbMessage: "",
  loadingDatabases: true,
  loadingDatabasesInBackground: false,
  openDbModal: false,
  browserTabId: null,
};

const reducerMap = {};

reducerMap[DatabaseActions.SAVE_DATABASES] = (
  state: DatabaseState,
  action: Action<DatabasesList>
): DatabaseState => {
  return {
    ...state,
    databasesList: action.payload as any,
    errorDbMessage: "",
    errorDialog: false,
    loadingDatabases: false,
    loadingDatabasesInBackground: false,
  };
};

reducerMap[DatabaseActions.UPDATE_STATUS] = (
  state: DatabaseState,
  action: Action<any>
): DatabaseState => {
  const { id: dbId, currentStatus } = action.payload;

  return {
    ...state,
    databasesList: state.databasesList
      ?.map(d => {
        if (d.id === dbId) {
          if (currentStatus === "DELETED") {
            return null;
          }

          return {
            ...d,
            currentStatus,
          };
        }

        return d;
      })
      .filter(d => !!d),
  };
};

reducerMap[DatabaseActions.LOAD_DATABASES] = (
  state: DatabaseState
): DatabaseState => {
  return {
    ...state,
    databasesList: [],
    errorDbMessage: undefined,
    loadingDatabases: true,
  };
};

reducerMap[DatabaseActions.LOAD_DATABASES_IN_BACKGROUND] = (
  state: DatabaseState
): DatabaseState => {
  return {
    ...state,
    errorDbMessage: undefined,
    loadingDatabasesInBackground: true,
  };
};

reducerMap[DatabaseActions.SET_ERROR] = (
  state: DatabaseState,
  action: Action<string>
): DatabaseState => {
  return { ...state, errorDbMessage: action.payload, loadingDatabases: false };
};

reducerMap[DatabaseActions.OPEN_DB_MODAL] = (
  state: DatabaseState,
  action: Action<any>
): DatabaseState => {
  return {
    ...state,
    openDb: action.payload,
    openDbModal: true,
  };
};

reducerMap[DatabaseActions.RESET_DB_STATE] = (): any => {
  return INITIAL_STATE;
};

reducerMap[DatabaseActions.SET_ENGINES] = (
  state: DatabaseState,
  action: Action<{ databaseId: string; engines: any[] }>
): any => {
  const { databaseId, engines } = action.payload;
  return {
    ...state,
    databasesList: state.databasesList?.map(d => {
      if (d.id === databaseId) {
        return {
          ...d,
          engines,
        };
      }

      return d;
    }),
  };
};

reducerMap[DatabaseActions.ADD_ENGINES] = (
  state: DatabaseState,
  action: Action<{ databaseId: string; engines: any[] }>
): any => {
  const { databaseId, engines } = action.payload;
  return {
    ...state,
    databasesList: state.databasesList?.map(d => {
      if (d.id === databaseId) {
        return {
          ...d,
          engines: [...d.engines, ...engines],
        };
      }

      return d;
    }),
  };
};

reducerMap[DatabaseActions.UPDATE_ENGINE_SUCCESS] = (
  state: DatabaseState,
  action: Action<{ databaseId?: string; engine: any }>
): any => {
  const { databaseId, engine } = action.payload;

  const mapEngine = e => {
    if (e.id === engine.id) {
      if (engine.currentStatusSummary === "DELETED") {
        return null;
      }
      return {
        ..._.merge(e, engine),
      };
    }

    return e;
  };

  // Traverse all DBs to find the engine if databaseId is not provided
  if (!databaseId) {
    return {
      ...state,
      databasesList: state.databasesList?.map(d => {
        const foundEngine = d.engines.find(en => en.id === engine.id);

        if (!foundEngine) {
          return d;
        }

        return {
          ...d,
          engines: d.engines.map(mapEngine).filter(engine => !!engine),
        };
      }),
    };
  }

  return {
    ...state,
    databasesList: state.databasesList?.map(d => {
      if (d.id === databaseId) {
        return {
          ...d,
          engines: d.engines.map(mapEngine).filter(engine => !!engine),
        };
      }

      return d;
    }),
  };
};

reducerMap[DatabaseActions.SET_BROWSER_TAB_ID] = (
  state: DatabaseState,
  action: Action<any>
): any => {
  const { browserTabId } = action.payload;
  return {
    ...state,
    browserTabId,
  };
};

export default handleActions<DatabaseState, any>(reducerMap, INITIAL_STATE);
