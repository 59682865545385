import React, { ReactElement } from "react";
import styles from "./MaterialButton.module.scss";
import cn from "classnames";
import Spinner from "components/common/Spinner";
import CustomTooltip from "components/Tooltip";

export enum ButtonTemplate {
  Primary = "primary",
  Secondary = "secondary",
}

export enum ButtonSize {
  Large = "large",
  Small = "small",
  Tiny = "tiny",
  Medium = "medium",
}

export interface Props {
  text: string;
  className?: string;
  size?: ButtonSize;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: (e?: any) => void;
  dataTestid?: string;
  id?: string;
  template?: ButtonTemplate;
  isLoading?: boolean;
  href?: string;
  tooltip?: {
    title: string;
  };
  disablePortal?: boolean;
}

const MaterialButton = React.forwardRef((props: Props, ref) => {
  const {
    text,
    size = ButtonSize.Large,
    startIcon,
    disabled = false,
    onClick,
    fullWidth = false,
    dataTestid,
    endIcon,
    id,
    template = ButtonTemplate.Primary,
    isLoading = false,
    className,
    href,
    tooltip,
    disablePortal = false,
  } = props;

  const buttonChild = (
    <>
      {startIcon && (
        <span className={styles.leftIconContainer}>{startIcon}</span>
      )}
      <span className={styles.text}>{text}</span>

      {endIcon && <span className={styles.rightIconContainer}>{endIcon}</span>}

      {isLoading && (
        <div className={styles.loadingContainer}>
          <Spinner size={size === ButtonSize.Small ? 14 : 18} />
        </div>
      )}
    </>
  );

  const buttonProps = {
    "data-testid": dataTestid,
    id,
    onClick: event => {
      if (!disabled && !isLoading) {
        onClick && onClick(event);
      }
    },
    className: cn(className, styles.root, styles[template], styles[size], {
      [styles.fullWidth]: fullWidth,
      [styles.loading]: isLoading,
      [styles.disabled]: disabled,
    }),
    ref,
  };

  const wrapWithTooltip = elem => {
    if (!tooltip) {
      return elem;
    }

    return (
      <CustomTooltip
        title={tooltip.title}
        placement="top"
        PopperProps={{ disablePortal }}
      >
        {elem}
      </CustomTooltip>
    );
  };

  const buttonComponent = href ? "a" : "button";

  href && Object.assign(buttonProps, { href, target: "_blank" });

  return wrapWithTooltip(
    React.createElement(buttonComponent, buttonProps, buttonChild)
  );
});

export default MaterialButton;
