import { fetchQuery } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";

export const query = graphql`
  query ScriptQuery($id: ID!, $databaseIdNull: Boolean) {
    me {
      ... on User {
        editorScripts(
          first: 10000
          filter: { databaseIdEq: $id, databaseIdNull: $databaseIdNull }
        ) {
          edges {
            node {
              id
              createTime
              data
              userId
              databaseId
              isStarred
              displayName
              lastUpdateTime
            }
          }
        }
      }
    }
  }
`;

const fetch = (environment, params = {}): Promise<any> => {
  return fetchQuery(environment, query, params).toPromise();
};

export default { fetch };
