import { createTheme } from "@material-ui/core";
import colors from "../styles/variables.colors.module.scss";

export default (theme: string) => {
  return createTheme({
    transitions: {
      // So we have `transition: none;` everywhere
      create: () => "none",
    },
    palette: {
      // @ts-ignore
      type: theme || "light",
      background: {
        paper: theme === "dark" ? colors.black00 : "white",
      },
      primary: {
        main: colors.red,
        dark: colors.redDark,
      },
      secondary: {
        main: colors.black2,
        dark: colors.black5,
      },
      text: {
        primary: theme === "light" ? colors.black0 : colors.black6,
      },
    },
    overrides: {
      MuiInput: {
        root: {
          maxWidth: "420px",
          border: "none",
          borderBottom: "1px solid #a4a4a4",
          boxSizing: "border-box",
          fontSize: "15px",
          fontFamily: "Inter",
          "&::before , &::after": {
            borderBottom: "none !important",
          },
          "&$error": {
            color: "#5A5859 !important",
            borderBottom: "1px solid #E72D2D",
          },
        },
        input: {
          marginTop: "4px",
          paddingBottom: "3px",
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: "15px",
          fontFamily: "Inter",
          color: theme === "light" ? colors.black5 : colors.black4,
          "&$focused": {
            color: theme === "light" ? colors.black5 : colors.black4,
          },
          "&$shrink": {
            color: theme === "light" ? colors.black5 : colors.black4,
            fontSize: "13px",
            transform: "translate(0, 1.5px) scale(1)",
            lineHeight: "1.42",
          },
          "&$error": {
            color: "#ADACAD !important",
          },
        },
      },
      MuiSnackbar: {
        root: {
          top: "20px !important",
          width: "100%",
          right: "auto",
          left: "auto !important",
          transform: "unset !important",
        },
      },
      MuiList: {
        root: {
          padding: "0px",
        },
      },
      MuiListItemText: {
        root: {
          padding: 0,
          color: colors.black2,
        },
        primary: {
          fontFamily: "Inter",
          fontSize: "13px",
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: colors.black9,
        },
      },
    },
  });
};
