import { Suspense, useMemo } from "react";
import { Offline } from "react-detect-offline";
import { Route, Routes } from "react-router-dom";
import withTracker from "components/common/GoogleAnalytics";
import LoadingOverlap from "components/LoadingOverlap";
import OfflineIndicator from "./OfflineIndicator/OfflineIndicator";
import ErrorBoundary from "../ErrorBoundary";
import { AsyncError404 } from "./routes/routes";
import { getGeneralRoutes } from "./routes/generalRoutes";
import { getGuestRoutes } from "./routes/guestRoutes";
import { getProtectedRoutes } from "./routes/protectedRoutes";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";

const Router = () => {
  const flags = useAllFlags();

  const generalRoutes = useMemo(() => {
    return getGeneralRoutes();
  }, []);

  const guestRoutes = useMemo(() => {
    return getGuestRoutes();
  }, []);

  const protectedRoutes = useMemo(() => {
    return getProtectedRoutes(flags);
  }, [flags]);

  const routes = [...generalRoutes, ...guestRoutes, ...protectedRoutes];

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingOverlap isLoading />}>
        <Routes>
          {routes}
          <Route
            path="*"
            element={<AsyncError404 />}
          />
        </Routes>
      </Suspense>
      <Offline polling={false}>
        <OfflineIndicator />
      </Offline>
    </ErrorBoundary>
  );
};

export default withTracker(Router);
