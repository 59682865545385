import * as types from "../actions/sidebar";
import {
  LEFT_DEFAULT_SIDEBAR,
  RIGHT_DEFAULT_WIDTH_SIDEBAR
} from "../../constants";

const INITIAL_STATE = {
  sidebarWidth: {
    right: RIGHT_DEFAULT_WIDTH_SIDEBAR,
    left: LEFT_DEFAULT_SIDEBAR
  },
  expanded: true,
  leftSidebarExpanded: true,
  openDatabaseSearch: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CHANGE_SIDEBAR_WIDTH: {
      const { sidebarWidth, position } = action.payload;
      const updatedState = Object.assign({}, state.sidebarWidth, {
        [position]: sidebarWidth
      });
      return {
        ...state,
        sidebarWidth: {
          ...updatedState
        }
      };
    }
    case types.COLLAPSE_SIDEBAR: {
      const { expanded } = action.payload;
      return {
        ...state,
        expanded: expanded
      };
    }
    case types.COLLAPSE_LEFT_SIDEBAR: {
      const { expanded } = action.payload;
      return {
        ...state,
        leftSidebarExpanded: expanded
      };
    }

    case types.OPEN_DATABASE_SEARCH: {
      const { expanded } = action.payload;
      return {
        ...state,
        openDatabaseSearch: expanded
      };
    }

    default:
      return state;
  }
}
