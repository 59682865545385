import * as _ from "lodash";
import { stripComments } from "businessLogic/helpers/Query/strip-comments/strip";
import { ACTIONS } from "workers/workerActions";

const mStripComments = _.memoize(stripComments);

export default class LegacyStripWorker {
  constructor() {
    // legacy worker for testing and old browser
    this.port = {
      start() {},
      onmessage() {},
      postMessage(data) {
        if (data.type === ACTIONS.STRIP_START) {
          this.onmessage({
            data: {
              input: data.input,
              result: mStripComments(data.input),
              type: ACTIONS.STRIP_FINISHED,
            },
          });
        }
      },
    };
  }
}
