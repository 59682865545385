export enum EngineActions {
  Start = "start",
  Stop = "stop",
  Restart = "restart",
  Delete = "delete",
  MakeDefault = "makeDefault",
  CopyEndpoint = "copyEndpoint",
  Edit = "edit"
}

export enum EnginePermission {
  CREATE = "core.engines.create",
  DELETE = "core.engines.delete",
  START = "core.engines.start",
  STOP = "core.engines.stop",
  UPDATE = "core.engines.update"
}

export const ALL_ENGINE_ACTIONS = Object.values(EngineActions);
