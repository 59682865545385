import * as _ from "lodash";
import { DatabasePermission } from "./constants";
import {
  getUiEngineStatus,
  getUiEngineStatusText,
} from "components/EngineStatusIcon/helpers";

export const canCreateDatabase = permissions =>
  permissions.includes(DatabasePermission.CREATE);

export const canEditDatabase = permissions =>
  permissions.includes(DatabasePermission.UPDATE);

export const logEngineInstanceTypeError = ({
  dbName,
  engines,
  filteredEngines,
}) => {
  const faultyEngines = _.differenceBy(engines, filteredEngines, "name");
  const faultyEngineNames = faultyEngines.map((en: any) => en?.name).join(", ");
  console.error(
    `Unmapped engine instance types on db "${dbName}", on engines: ${faultyEngineNames}`
  );
};

export const getNormalizedStatusEngine = (engine: any) => {
  return {
    ...engine,
    status: getUiEngineStatus(engine.currentStatusSummary),
    statusText: getUiEngineStatusText(engine.currentStatusSummary),
  };
};
