import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { ReactComponent as FailureImg } from "assets/images/failure.svg";
import styles from "./ErrorScreen.module.scss";

export enum ErrorScreenImagePosition {
  Top = "TOP",
  Bottom = "BOTTOM",
}

interface Props {
  title: string;
  description?: string | ReactElement;
  image?: ReactElement;
  footer?: any;
  imagePosition: ErrorScreenImagePosition;
  footerSmallMargin?: boolean;
  classes?: any;
}

const ErrorScreen = ({
  title,
  description,
  image,
  footer,
  imagePosition,
  footerSmallMargin,
  classes = {},
}: Props) => {
  const { t } = useTranslation();
  const imageContainer = (
    <div
      data-testid="error-image-container"
      className={cn(
        styles.imageContainer,
        styles[imagePosition],
        classes?.image
      )}
    >
      {image}
    </div>
  );

  const footerClassNames = cn(styles.footer, classes?.footer, {
    [styles.smallMargin]: footerSmallMargin,
  });

  return (
    <div className={styles.root} data-testid="error-container">
      {imagePosition === ErrorScreenImagePosition.Top && imageContainer}
      <div className={styles.title} data-testid="error-title">
        {typeof title === "string" ? t(title as any) : title}
      </div>
      {description && (
        <div
          className={cn(styles.description, classes.description)}
          data-testid="error-description"
        >
          {typeof description === "string"
            ? t(description as any)
            : description}
        </div>
      )}
      {imagePosition === ErrorScreenImagePosition.Bottom && imageContainer}
      {footer && (
        <div className={footerClassNames} data-testid="error-footer">
          {footer}
        </div>
      )}
    </div>
  );
};

ErrorScreen.defaultProps = {
  imagePosition: ErrorScreenImagePosition.Bottom,
  image: <FailureImg />,
};

export default ErrorScreen;
