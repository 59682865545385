export const FETCH_SYSTEM_ENGINE = "QUERIES/FETCH_SYSTEM_ENGINE";
export const FETCH_SYSTEM_ENGINE_SUCCESS =
  "QUERIES/FETCH_SYSTEM_ENGINE_SUCCESS";
export const FETCH_SYSTEM_ENGINE_FAILURE =
  "QUERIES/FETCH_SYSTEM_ENGINE_FAILURE";
export const CLEAR_SYSTEM_ENGINE = "QUERIES/CLEAR_SYSTEM_ENGINE";

export const fetchSystemEngine = accountId => ({
  type: FETCH_SYSTEM_ENGINE,
  payload: {
    accountId,
  },
});

export const fetchSystemEngineSuccess = engine => ({
  type: FETCH_SYSTEM_ENGINE_SUCCESS,
  payload: engine,
});

export const fetchSystemEngineFailure = () => ({
  type: FETCH_SYSTEM_ENGINE_FAILURE,
});

export const clearSystemEngine = () => ({
  type: CLEAR_SYSTEM_ENGINE,
});
