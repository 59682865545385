import {
  StatusMessage,
  StatusMessageActions,
  StatusMessagePosition,
} from "./actions";
import { Action, handleActions } from "redux-actions";

interface StatusMessageState {
  statusMessages: StatusMessage[];
}

export const INITIAL_STATE: StatusMessageState = {
  statusMessages: [],
};

const STATUS_BAR_MAX_MESSAGES_NUMBER = 3;

const reducerMap = {};

const addMessage = (
  messages: StatusMessage[],
  newMessage: StatusMessage
): StatusMessage[] => {
  if (newMessage.options.insertToPosition === StatusMessagePosition.Bottom) {
    return [...messages, newMessage];
  }
  if (newMessage.options.insertToPosition === StatusMessagePosition.Top) {
    return [newMessage, ...messages];
  }

  return messages;
};

reducerMap[StatusMessageActions.PUT_MESSAGE_SUCCESS] = (
  state: StatusMessageState,
  action: Action<StatusMessage>
): StatusMessageState => {
  const { options } = action.payload;

  if (options.preventDeletionOnReset && !options.id) {
    console.warn(
      "PUT_MESSAGE_SUCCESS was ignored. Message ID must be defined if preventDeletionOnReset used"
    );
    return state;
  }

  // check if needed to replace message
  if (options.id) {
    const foundMessage = state.statusMessages.find(
      message => message.options.id === options.id
    );

    if (foundMessage) {
      return {
        statusMessages: state.statusMessages.map(message => {
          if (message.options.id === options.id) {
            return action.payload;
          }

          return message;
        }),
      };
    }
  }

  // reduce messages with autoRemove
  const filteredMessages = state.statusMessages.filter(sMessage => {
    if (sMessage.options.autoRemove) {
      return false;
    }

    return true;
  });

  const newMessages = addMessage(filteredMessages, action.payload);

  // check list overflow
  if (newMessages.length > STATUS_BAR_MAX_MESSAGES_NUMBER) {
    if (options.insertToPosition === StatusMessagePosition.Top) {
      newMessages.pop();
    }
    if (options.insertToPosition === StatusMessagePosition.Bottom) {
      newMessages.shift();
    }
  }

  return { statusMessages: newMessages };
};

reducerMap[StatusMessageActions.REMOVE_MESSAGE] = (
  state: StatusMessageState,
  action: Action<string>
): StatusMessageState => {
  const id: string = action.payload;

  if (!id) {
    return state;
  }

  return {
    statusMessages: state.statusMessages.filter(msg => msg?.options?.id !== id),
  };
};

reducerMap[StatusMessageActions.REMOVE_MESSAGE_BY_TIMEOUT_ID] = (
  state: StatusMessageState,
  action: Action<number>
): StatusMessageState => {
  const id: number = action.payload;

  if (!id) {
    return state;
  }

  return {
    statusMessages: state.statusMessages.filter(
      msg => msg?.options?.timeoutId !== id
    ),
  };
};

reducerMap[StatusMessageActions.RESET] = (
  state: StatusMessageState
): StatusMessageState => {
  return {
    statusMessages: state.statusMessages.filter(
      msg => msg.options.preventDeletionOnReset
    ),
  };
};

export default handleActions<StatusMessageState, any>(
  reducerMap,
  INITIAL_STATE
);
