import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation EngineStopMutation($input: EngineStopInput!) {
    engineStop(input: $input) {
      success
      engine {
        id
        currentStatusSummary
      }
    }
  }
`;

function commit(environment, engineId, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: { id: engineId }
    },
    optimisticUpdater: store => {
      const engine = store.get(engineId);

      if (engine) {
        engine.setValue("STOPPING", "currentStatusSummary");
      }
    }
  });
}

export default { commit };
