/**
 * @generated SignedSource<<9058b84b3d84e63e51d3a2be05f1eaed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AwsmarketplaceSubscriptionStatus = "UNSPECIFIED" | "ACTIVE" | "UNSUBSCRIBE_PENDING" | "STOPPED" | "FAILED" | "%future added value";
export type AwsmarketplaceSubscriptionTier = "UNSPECIFIED" | "BASIC" | "TRIAL" | "PRO" | "%future added value";
export type AwssubscriptionLinkToAccountInput = {
  accountId: string;
  token: string;
};
export type SubscriptionLinkToAccountMutation$variables = {
  input: AwssubscriptionLinkToAccountInput;
};
export type SubscriptionLinkToAccountMutation$data = {
  readonly awsSubscriptionLinkToAccount: {
    readonly success: boolean;
    readonly subscription: {
      readonly accountId: string;
      readonly customerId: string;
      readonly expirationTime: any;
      readonly id: string;
      readonly status: AwsmarketplaceSubscriptionStatus;
      readonly tier: AwsmarketplaceSubscriptionTier;
    };
  };
};
export type SubscriptionLinkToAccountMutation = {
  variables: SubscriptionLinkToAccountMutation$variables;
  response: SubscriptionLinkToAccountMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AwssubscriptionLinkToAccountPayload",
    "kind": "LinkedField",
    "name": "awsSubscriptionLinkToAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AwsmarketplaceSubscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expirationTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tier",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionLinkToAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriptionLinkToAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bbbad0ac5cddca94308ca7a85fa04faa",
    "id": null,
    "metadata": {},
    "name": "SubscriptionLinkToAccountMutation",
    "operationKind": "mutation",
    "text": "mutation SubscriptionLinkToAccountMutation(\n  $input: AwssubscriptionLinkToAccountInput!\n) {\n  awsSubscriptionLinkToAccount(input: $input) {\n    success\n    subscription {\n      accountId\n      customerId\n      expirationTime\n      id\n      status\n      tier\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c89fa7892702d27aa2d393b42d211dc3";

export default node;
