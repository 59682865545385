import React from "react";
import Spinner from "components/common/Spinner";
import styles from "./styles.module.scss";
import cn from "classnames";

interface CheckEmailProps {
  resendEmail(): void;
  email?: any;
  isSendingEmail?: boolean;
}

function CheckEmail(props: CheckEmailProps) {
  const { resendEmail, email, isSendingEmail } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.checkContainer}>
        <h2 className={styles.cardTitle}>Great, now check your email</h2>
        <h2 className={styles.emailText} data-heap-redact-text={true}>
          {email}
        </h2>
        {!isSendingEmail && (
          <p
            onClick={() => resendEmail()}
            className={cn(styles.cardSubTitle, styles.link)}
          >
            Resend Email
          </p>
        )}
        {isSendingEmail && (
          <div className={styles.cardSubTitle}>
            <Spinner size={14} text="Sending..." />
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckEmail;
