import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  loadSubscription,
  loadSubscriptionFailure,
  loadSubscriptionSuccess,
  SubscriptionActions,
  SubscriptionStatus,
} from "./actions";
import { AWSService } from "businessLogic/services/aws";
import { selectUserAccountId } from "../user/selectors";
import {
  putStatusMessage,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import { Action } from "redux-actions";
import { getAccountDetailsSaga } from "pages/Account/redux/sagas";
import { AccountStatus } from "businessLogic/model";

function* isLoggedIn() {
  const userData = yield select(state => state.user?.userData);
  return !!userData;
}

export function* loadSubscriptionSaga(action) {
  try {
    const { accountId } = action.payload;
    if (!(yield isLoggedIn())) {
      return;
    }
    const response = yield call(AWSService.getActiveSubscription, accountId);
    const subscription = response?.account?.awsMarketplaceSubscription;
    if (!(yield isLoggedIn())) {
      return;
    }
    yield put(loadSubscriptionSuccess(subscription));

    if (subscription.customerId) {
      if (
        subscription.status ===
        SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED
      ) {
        yield delay(5000);
        yield put(loadSubscription(accountId, true));
        return;
      }

      if (
        subscription.status === SubscriptionStatus.SUBSCRIPTION_STATUS_ACTIVE
      ) {
        const accountDetails = yield getAccountDetailsSaga();
        if (accountDetails.status !== AccountStatus.Active) {
          yield delay(5000);
          yield put(loadSubscription(accountId, true));
        }
      }
    }
  } catch (err) {
    yield put(putStatusMessage(err.message, StatusMessageType.Error));
    yield put(loadSubscriptionFailure(err.message || "errors.general"));
  }
}

export function* linkAwsSaga(
  action: Action<{
    token: string;
    resolve: () => void;
    reject: (e: string) => void;
  }>
) {
  const { token, resolve, reject } = action.payload;

  try {
    const accountId = yield select(selectUserAccountId);

    yield call(AWSService.linkAws, accountId, token);

    yield put(loadSubscription(accountId, false));
    resolve();
  } catch (err) {
    reject(err.message);
  }
}

export function* SubscriptionSagas() {
  return yield all([
    takeLatest(SubscriptionActions.LOAD_SUBSCRIPTION, loadSubscriptionSaga),
    takeEvery(SubscriptionActions.LINK_AWS, linkAwsSaga),
  ]);
}
