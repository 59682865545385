import { useLocation, Link } from "react-router-dom";
import ActionMenuItem from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import ActionMenu from "components/ActionMenu/ActionMenu";
import { useRoleManagement } from "featureFlags/hooks/useRoleManagement";
import { ReactComponent as UsersIcon } from "assets/icons/mainMenuIcons/UsersIconMenu.svg";
import { ReactComponent as RolesIcon } from "assets/icons/mainMenuIcons/UserRoles.svg";
import { ReactComponent as UserPermissionsIcon } from "assets/icons/mainMenuIcons/UserPermissions.svg";
import { ROUTES } from "businessLogic/routes.constants";
import MenuItem from "components/MainSidebar/MenuItem/MenuItem";
import { useMenu } from "components/ActionMenu/useMenu";
import { AQA_USER } from "./AqaIds";

const UserAndRoleManagement = props => {
  const { accountIsReadyForUse } = props;

  const { menuElement, openMenu, closeMenu } = useMenu();

  const selected =
    location.pathname === ROUTES.USERS_LIST ||
    location.pathname === ROUTES.ROLES;

  const menuItems = [
    [
      <Link
        to={ROUTES.USERS_LIST}
        key="user_management"
      >
        <ActionMenuItem
          text="User Management"
          onClick={closeMenu}
          icon={<UsersIcon />}
        />
      </Link>,
      <Link
        to={ROUTES.ROLES}
        key="role_management"
      >
        <ActionMenuItem
          text="Role Management"
          onClick={closeMenu}
          icon={<RolesIcon />}
        />
      </Link>,
    ],
  ];

  return (
    <>
      <MenuItem
        icon={<UserPermissionsIcon />}
        tooltip="User and Role Management"
        testId="user-role-menu"
        disabled={!accountIsReadyForUse}
        selected={selected}
        onClick={openMenu}
      />
      {!!menuElement && (
        <ActionMenu
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          lists={menuItems}
          open={true}
          anchorEl={menuElement}
          onClose={closeMenu}
          testId="user-role-dropdown"
        />
      )}
    </>
  );
};

export const UserManagement = props => {
  const { accountIsReadyForUse } = props;
  const location = useLocation();
  const roleManagementEnabled = useRoleManagement();

  if (roleManagementEnabled) {
    return (
      <UserAndRoleManagement accountIsReadyForUse={accountIsReadyForUse} />
    );
  }

  return (
    <MenuItem
      icon={<UsersIcon />}
      testId={AQA_USER}
      tooltip="User Management"
      disabled={!accountIsReadyForUse}
      selected={location.pathname === ROUTES.USERS_LIST}
      to={ROUTES.USERS_LIST}
    />
  );
};
