import { createAction } from "redux-actions";
import { ResetPass, SessionData, UserDetails } from "businessLogic/model";

export const AuthActions = {
  LOGIN: "AUTH/LOGIN",
  LOGOUT: "AUTH/LOGOUT",
  LOGOUT_SUCCESS: "AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "AUTH/LOGOUT_FAILURE",
  LOGIN_SUCCESS: "AUTH/LOGIN_SUCCESS",
  SET_SESSION: "AUTH/SET_SESSION",
  CLEAR_SESSION: "AUTH/CLEAR_SESSION",
  SET_ERROR_LOGIN: "AUTH/SET_ERROR_LOGIN",
  SET_ERROR_RESET_PASS: "AUTH/SET_ERROR_RESET_PASS",
  RESET_PASSWORD: "AUTH/RESET_PASSWORD",
  SET_NEW_PASSWORD: "AUTH/SET_NEW_PASSWORD",
  EMAIL_SENT: "AUTH/EMAIL_SENT",
  EMAIL_SENT_RESET: "AUTH/EMAIL_SENT_RESET",
  SET_PASSWORD_SUCCESS: "AUTH/SET_PASSWORD_SUCCESS",
  CLEAR_USER_DETAILS: "AUTH/CLEAR_USER_DETAILS",
};

export const logout = createAction(
  AuthActions.LOGOUT,
  (preventRedirect: boolean) => ({
    preventRedirect,
  })
);

export const clearUserDetails = createAction(
  AuthActions.CLEAR_USER_DETAILS,
  () => {}
);

export const logoutSuccess = createAction(
  AuthActions.LOGOUT_SUCCESS,
  loginType => {
    return {
      loginType,
    };
  }
);

export const logoutFailure = createAction(AuthActions.LOGOUT_FAILURE, () => {});

export const resetPassEmail = createAction(
  AuthActions.RESET_PASSWORD,
  (email: string, recaptchaChallenge: string) => ({
    email,
    recaptchaChallenge,
  })
);

export const setResetPassError = createAction(
  AuthActions.SET_ERROR_RESET_PASS,
  (errorResetMessage: string) => errorResetMessage
);

export const setNewPassword = createAction(
  AuthActions.SET_NEW_PASSWORD,
  (passInfo: ResetPass) => passInfo
);

export const passwordReset = createAction(
  AuthActions.SET_PASSWORD_SUCCESS,
  () => {}
);

export const emailSent = createAction(AuthActions.EMAIL_SENT, () => {});

export const emailSentReset = createAction(
  AuthActions.EMAIL_SENT_RESET,
  () => {}
);

/* remove after complete auth release */
export const setSession = createAction(
  AuthActions.SET_SESSION,
  (data: SessionData) => data
);

export const setLoggedInUser = createAction(
  AuthActions.LOGIN_SUCCESS,
  (userInfo: UserDetails) => userInfo
);

export const setLoginError = createAction(
  AuthActions.SET_ERROR_LOGIN,
  (errorLoginMessage: string | undefined) => errorLoginMessage
);

export const clearSession = createAction(AuthActions.CLEAR_SESSION, () => null);
