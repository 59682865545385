import * as React from "react";
import * as GoogleAnalytics from "react-ga";
import withRouter, { WithRouterProps } from "components/common/withRouter";
import { useEffect } from "react";

if (process.env.NODE_ENV !== "test") {
  GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
}

const trackPage = (page: any, options: any) => {
  GoogleAnalytics.set({
    page,
    ...options,
  });
  GoogleAnalytics.pageview(page);
};

const withTracker = <P,>(WrappedComponent, options = { debug: true }) => {
  const Tracked = (props: WithRouterProps & P) => {
    useEffect(() => {
      const page = props.location.pathname;
      trackPage(page, options);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return withRouter(Tracked);
};

export default withTracker;
