import * as SystemEngineActions from "../actions/systemEngine";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { EngineService } from "../../../../businessLogic/services/engine";
import { SettingsPreset } from "../../../EngineForm/constants";
import { getEncodedSystemEngineId } from "../helpers";

export function* systemEngineFetchSaga(action) {
  try {
    const accountId = atob(action.payload.accountId).split("/").pop();

    if (!accountId) {
      throw new Error("Invalid account id");
    }

    const systemEngineId = getEncodedSystemEngineId(accountId);

    const systemEngine = yield call(
      EngineService.getEngineById,
      systemEngineId,
      action.payload.accountId
    );

    const systemEngineResponse = {
      ...systemEngine,
      isSystem: true,
      name: "System Engine",
      scale: 1,
      isSpot: false,
      settingsPreset: SettingsPreset.DataAnalytics,
      autoStopAfter: 2400,
      warmup: "MINIMAL",
      engineCost: 1,
      currentStatusSummary: "RUNNING",
      latestRevision: {
        ...systemEngine.latestRevision,
        currentStatus: "RUNNING",
      },
      status: "On",
    };

    yield put({
      type: SystemEngineActions.FETCH_SYSTEM_ENGINE_SUCCESS,
      payload: systemEngineResponse,
    });
  } catch (err) {
    yield put({
      type: SystemEngineActions.FETCH_SYSTEM_ENGINE_FAILURE,
    });
  }
}

export function* SystemEngineSagas() {
  return yield all([
    takeEvery(SystemEngineActions.FETCH_SYSTEM_ENGINE, systemEngineFetchSaga),
  ]);
}
