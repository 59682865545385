import { all } from "redux-saga/effects";
import { QueriesSagas } from "./saga/queriesSagas";
import { UserScriptsSagas } from "./saga/userScriptsSagas";
import { SidebarSagas } from "./saga/sidebar";
import { ExplainSagas } from "./saga/explain";
import { DatabaseMetadataSagas } from "./saga/metadataSagas";
import { ProxySagas } from "./saga/proxySagas";
import { ScriptFormattingSagas } from "./saga/scriptFormattingSagas";
import { QueryStateSagas } from "./saga/queryStateSagas";
import { SystemEngineSagas } from "./saga/systemEngine";

export function* QuerySagas() {
  return yield all([
    QueryStateSagas(),
    UserScriptsSagas(),
    QueriesSagas(),
    SidebarSagas(),
    ExplainSagas(),
    DatabaseMetadataSagas(),
    ProxySagas(),
    ScriptFormattingSagas(),
    SystemEngineSagas(),
  ]);
}
