import React, { AnimationEvent, Component } from "react";
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import styles from "./styles.module.scss";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { ReactComponent as ErrorIcon } from "assets/icons/Exclamation.svg";

import omit from "lodash/omit";

interface InputFieldProps {
  type: string;
  name: string;
  label?: string;
  value?: string;
  error?: boolean;

  onChange?: (name: any, value: any) => void;

  onBlur?: (name: any, value: any) => any;
  onFocus?: (name: any, value: any) => any;
  onKeyDown?: (e: any) => any;
  onKeyPress?: (e: any) => any;
  onAutofill?: (name: string) => void;
  onAutofillDrop?: (name: string) => void;
  onAnimationStart?: (e: AnimationEvent) => void;
  onAnimationEnd?: (e: AnimationEvent) => void;
  fullWidth?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  InputProps?: Partial<StandardInputProps>;
  inputProps?: StandardInputProps["inputProps"];
  testId?: string;
  inputRef?: (ref: HTMLInputElement) => void;
  helperText?: React.ReactNode;
  InputLabelProps?: any;
  errorText?: string;
  classes?: any;
  errorTextOverflow?: boolean;
  placeholder?: string;
  defaultValue?: string;
  showErrorContainer?: boolean;
  readOnly?: boolean;
}

export const ErrorMessage = props => {
  const error = (
    <div
      className={classnames(styles.errorTextContainer, {
        [styles.active]: !!props.errorText,
      })}
      title={props.errorText}
      data-testid={props.testId ? `${props.testId}-error` : `input-field-error`}
    >
      <ErrorIcon className={styles.errorIcon} />
      <span
        className={classnames(styles.errorText, {
          [styles.overflowVisible]: !!props.errorTextOverflow,
        })}
      >
        {props.errorText}
      </span>
    </div>
  );

  return props.addWrapper ? (
    <div className={styles.errorHelperContainer}>{error}</div>
  ) : (
    error
  );
};

class InputField extends Component<InputFieldProps, any> {
  static defaultProps = {
    showErrorContainer: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: this.props.autoFocus,
    };
  }

  onAnimationStart = (e: AnimationEvent<HTMLDivElement>): void => {
    const { name } = e.target as HTMLInputElement; // as HTMLInputElement because input tag hosts event (and is target)
    if (this.props.onAutofill && !!e.animationName.match("onAutoFillStart")) {
      this.props.onAutofill(name);
    }
    if (this.props.onAnimationStart) {
      this.props.onAnimationStart(e);
    }
  };

  onAnimationEnd = (e: AnimationEvent<HTMLDivElement>): void => {
    const { name } = e.target as HTMLInputElement; // as HTMLInputElement because input tag hosts event (and is target)
    if (
      this.props.onAutofillDrop &&
      !!e.animationName.match("onAutoFillCancel")
    ) {
      this.props.onAutofillDrop(name);
    }
    if (this.props.onAnimationEnd) {
      this.props.onAnimationEnd(e);
    }
  };

  render() {
    const { props } = this;

    const testAttributes = !!props?.testId
      ? { "data-testid": `${props?.testId}` }
      : {};

    return (
      <TextField
        classes={{ root: classnames(styles.root, props?.classes?.root) }}
        {...omit(props, [
          "testId",
          "errorText",
          "errorTextOverflow",
          "showErrorContainer",
          "onAutofill",
          "onAutofillDrop",
        ])}
        helperText={
          props.showErrorContainer ? <ErrorMessage {...props} /> : undefined
        }
        FormHelperTextProps={{
          classes: { root: styles.errorHelperContainer },
          // @ts-ignore
          component: "div",
        }}
        InputProps={{
          ...props.InputProps,
          onAnimationStart: this.onAnimationStart,
          onAnimationEnd: this.onAnimationEnd,
          classes: {
            root: classnames(
              styles.inputRoot,
              props?.InputProps?.classes?.root
            ),
            error: styles.inputError,
            input: styles.input,
          },
        }}
        InputLabelProps={{
          classes: {
            asterisk: styles.asterisk,
          },
          shrink: props.value || this.state.focused ? undefined : false,
          ...props.InputLabelProps,
        }}
        inputProps={{
          ...props.inputProps,
          ...testAttributes,
        }}
        onChange={({ target }) =>
          this.props.onChange
            ? this.props.onChange(target.name, target.value)
            : undefined
        }
        onBlur={({ target }) => {
          this.setState({ focused: false });

          this.props.onBlur
            ? this.props.onBlur(target.name, target.value)
            : undefined;
        }}
        onFocus={({ target }) => {
          this.setState({ focused: true });
          this.props.onFocus
            ? this.props.onFocus(target.name, target.value)
            : undefined;
        }}
        onKeyDown={e =>
          this.props.onKeyDown ? this.props.onKeyDown(e) : undefined
        }
        onKeyPress={e =>
          this.props.onKeyPress ? this.props.onKeyPress(e) : undefined
        }
        placeholder={props.placeholder}
        inputRef={props.inputRef}
        readOnly={props.readOnly}
      />
    );
  }
}

export default InputField;
