import { AppActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { View } from "businessLogic/model";

interface ListType {
  databases?: number;
  engines?: number;
}

interface SortDetails {
  cursor: string | null;
  orderBy: string;
  order: string;
  isLoadingOrder: boolean;
}

export enum List {
  Engines = "engines",
  Databases = "databases",
}

export interface AppState {
  language?: "en";
  databaseView?: View;
  sortDetails: { [key: string]: SortDetails };
  scrollPosition: ListType;
  searchDetails: { [key: string]: DatabaseSearchDetail | EngineSearchDetail };
  lastSeenReleaseDate: number | null;
  appStateLoading: boolean;
  theme: string;
}

export interface SortPayload {
  entity: keyof ListType;
  sortDetails: SortDetails;
}

interface EngineSearchDetail {
  searchQuery: string;
}

export interface DatabaseSearchDetail {
  databaseNameQuery?: string;
  engineNameQuery?: string;
  creatorId?: string;
}

export interface ScrollPayload {
  entity: keyof ListType;
  scroll: number;
}

export interface SearchDetailsPayload {
  list: List;
  detail: DatabaseSearchDetail | EngineSearchDetail;
}

export interface SaveLastSeenReleaseDatePayload {
  lastSeenReleaseDate: number;
}

function filterAppState(state) {
  const newState = {
    ...state,
  };

  if (newState.sortDetails) {
    for (let key in newState.sortDetails) {
      newState.sortDetails[key].cursor = null;
    }
  }

  return newState;
}

export const DATABASE_INITIAL_SEARCH_DETAILS = {
  databaseNameQuery: "",
  engineNameQuery: "",
  creatorId: "",
};

export const DATABASE_INITIAL_SORT_DETAILS = {
  cursor: null,
  orderBy: "CREATE_TIME",
  order: "desc",
  isLoadingOrder: false,
};

export const getValidDatabaseSortDetails = sortDetails => {
  return { ...DATABASE_INITIAL_SORT_DETAILS, ...sortDetails };
};

export const getValidDatabaseSearchDetails = searchDetails => {
  return { ...DATABASE_INITIAL_SEARCH_DETAILS, ...searchDetails };
};

export const INITIAL_STATE: AppState = {
  language: "en",
  databaseView: View.CARD,
  scrollPosition: {
    engines: 0,
    databases: 0,
  },
  sortDetails: {
    engines: {
      cursor: null,
      orderBy: "CREATE_TIME",
      order: "desc",
      isLoadingOrder: false,
    },
    databases: DATABASE_INITIAL_SORT_DETAILS,
  },
  searchDetails: {
    [List.Engines]: {
      searchQuery: "",
    },
    [List.Databases]: DATABASE_INITIAL_SEARCH_DETAILS,
  },
  appStateLoading: false,
  lastSeenReleaseDate: null,
  theme: "light",
};

const reducerMap = {};

reducerMap[AppActions.SAVE_SORT_DETAILS] = (
  state: AppState,
  action: Action<SortPayload>
): AppState => {
  const { entity, sortDetails } = action.payload;
  return {
    ...state,
    sortDetails: {
      [entity]: sortDetails,
    },
  };
};

reducerMap[AppActions.SAVE_SEARCH_DETAILS] = (
  state: AppState,
  action: Action<SearchDetailsPayload>
): AppState => {
  const { list, detail } = action.payload;
  return {
    ...state,
    searchDetails: {
      ...state.searchDetails,
      [list]: detail,
    },
  };
};

reducerMap[AppActions.SAVE_SCROLL_POSITION] = (
  state: AppState,
  action: Action<ScrollPayload>
): AppState => {
  const { entity, scroll } = action.payload;
  return {
    ...state,
    scrollPosition: {
      ...state.scrollPosition,
      [entity]: scroll,
    },
  };
};

reducerMap[AppActions.SAVE_LAST_SEEN_RELEASE_DATE] = (
  state: AppState,
  action: Action<SaveLastSeenReleaseDatePayload>
): AppState => {
  const { lastSeenReleaseDate } = action.payload;
  return {
    ...state,
    lastSeenReleaseDate,
  };
};

reducerMap[AppActions.PUT_APP_STATE] = (
  state: AppState,
  action: Action<AppState>
): AppState => {
  if (!action.payload) {
    return { ...state, ...INITIAL_STATE, appStateLoading: false };
  }

  const newState = filterAppState(action.payload);

  return {
    ...state,
    ...newState,
    appStateLoading: false,
  };
};

reducerMap[AppActions.FETCH_APP_STATE] = (state): AppState => {
  return { ...state, appStateLoading: true };
};

reducerMap[AppActions.CHANGE_DATABASE_VIEW] = (state, action): AppState => {
  return { ...state, databaseView: action.payload };
};

export default handleActions<AppState, any>(reducerMap, INITIAL_STATE);
