/**
 * @generated SignedSource<<8bf3d1fb7dabbc83a7f3ef3dcaa06e92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RoleQuery$variables = {};
export type RoleQuery$data = {
  readonly roles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly displayName: string;
      };
    } | null> | null;
  };
};
export type RoleQuery = {
  variables: RoleQuery$variables;
  response: RoleQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1000
      }
    ],
    "concreteType": "RoleConnection",
    "kind": "LinkedField",
    "name": "roles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RoleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "roles(first:1000)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoleQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b280222164ee5dba4f4b840d76275b9c",
    "id": null,
    "metadata": {},
    "name": "RoleQuery",
    "operationKind": "query",
    "text": "query RoleQuery {\n  roles(first: 1000) {\n    edges {\n      node {\n        id\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72ae7d6f6443601069a19d1cd11089c9";

export default node;
