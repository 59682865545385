/* eslint-disable */
import StripSharedWorker from "worker-loader?worker=SharedWorker!./strip-comments.worker.ts";
import StripWebWorker from "worker-loader!./strip-comments.worker.ts";
import LegacyWorker from "./legacy.worker";
import { Subject, SubscriptionToken } from "services/subject";
import { ACTIONS } from "../workerActions";

enum WorkerType {
  SharedWorker,
  WebWorker,
  LegacyWorker,
}

export default class Worker {
  private worker: any;

  private FAST_CACHE_TTL: number;

  private workingQueue: Map<string, any>;

  private workerSubject = new Subject();

  static resolveWorker() {
    switch (Worker.resolveType()) {
      case WorkerType.SharedWorker:
        return new StripSharedWorker();
      case WorkerType.WebWorker:
        return new StripWebWorker();
      default:
        return new LegacyWorker();
    }
  }

  private static resolveType() {
    if (!!window.SharedWorker) {
      return WorkerType.SharedWorker;
    } else if (!!window.Worker) {
      return WorkerType.WebWorker;
    }
    return WorkerType.LegacyWorker;
  }

  constructor() {
    this.worker = Worker.resolveWorker();
    this.FAST_CACHE_TTL = 20000;
    this.workerSubject = new Subject();
    this.workingQueue = new Map();
    this.handleMessage = this.handleMessage.bind(this);
  }

  private getWorker() {
    return this.worker.port ?? this.worker;
  }

  handleMessage(event) {
    const { type = null, result = "", input = "" } = event?.data;
    if (type === ACTIONS.STRIP_FINISHED && !!input) {
      this.workerSubject.notifyAsync({
        type,
        result,
        input,
      });
    }
  }

  start() {
    if (this.worker.port) {
      this.worker.port.start();
    }

    this.getWorker().onmessage = this.handleMessage;
  }

  async strip(query) {
    const input = query.trim();
    if (input === "") {
      return Promise.resolve("");
    }

    const job = () => {
      let token: SubscriptionToken | null = null;
      return new Promise(resolve => {
        token = this.workerSubject.subscribe(payload => {
          if (input === payload.input) {
            resolve(payload.result);
          }
        });
        setTimeout(() => {
          this.getWorker().postMessage({
            input,
            type: ACTIONS.STRIP_START,
          });
        }, 0);
      }).finally(() => {
        setTimeout(() => {
          if (this.workingQueue.has(input)) {
            this.workingQueue.delete(input);
          }
          token && token.unsubscribe();
        }, this.FAST_CACHE_TTL);
      });
    };

    if (this.workingQueue.has(input)) {
      return this.workingQueue.get(input);
    } else {
      this.workingQueue.set(input, job());
    }

    return this.workingQueue.get(input);
  }
}
