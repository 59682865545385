import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AuthResetPasswordFinishInput } from "./__generated__/ResetPasswordFinishMutation.graphql";

const mutation = graphql`
  mutation ResetPasswordFinishMutation($input: AuthResetPasswordFinishInput!) {
    authResetPasswordFinish(input: $input) {
      success
    }
  }
`;

function commit(
  environment,
  input: AuthResetPasswordFinishInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
