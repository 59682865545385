import React, { ReactElement } from "react";
import cn from "classnames";
import styles from "../ContactSupport.module.scss";

interface Props {
  cols: ReactElement[];
  className?: string;
}

const SplitRow: React.FunctionComponent<Props> = (props: Props) => {
  const { cols, className } = props;
  return (
    <div className={cn(styles.row, className)}>
      <div className={styles.col}>{cols[0]}</div>
      <div className={styles.col}>{cols[1]}</div>
    </div>
  );
};

export default SplitRow;
