import { fetchQuery } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";

export const query = graphql`
  query UserQuery {
    me {
      ... on User {
        id
        lastUpdateTime
        phoneNumber
        createTime
        companyName
        displayName
        jobTitle
        emailAddress
        firstName
        fullName
        lastName
        status
        uiState {
          data
          lastUpdateTime
        }
        accountsMemberships(first: 100) {
          edges {
            node {
              id
              joinFlow
              accountId
              status
              accountId
              account {
                title
              }
              assignedRole {
                accountId
                id
                description
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

export const fetchUserUiState = (environment, params): Promise<any> => {
  return fetchQuery(
    environment,
    graphql`
      query UserUiStateQuery {
        me {
          ... on User {
            id
            uiState {
              data
              lastUpdateTime
            }
          }
        }
      }
    `,
    params
  ).toPromise();
};

const fetch = (environment): Promise<any> => {
  return fetchQuery(environment, query, {}).toPromise();
};

export default { fetch };
