import React, { useContext, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { ReactComponent as StopIcon } from "assets/icons/StopIcon.svg";
import MaterialButton, {
  ButtonSize,
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import {
  canStopEngine,
  engineCanBeStopped,
} from "components/EnginesList/helpers";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";
import styles from "../index.module.scss";
import { AccessManagerContext } from "../../App/App";
import { GQLRbacaction } from "../../../graphql.types";

type Props = {
  engine: any;
  accountAllowedActions: string[];
  onClick: (() => any) | undefined;
  hovered?: boolean;
};

function StopEngineButton({
  engine,
  onClick,
  accountAllowedActions,
  hovered,
}: Props) {
  const { t } = useTranslation();
  const { isAllowedTo } = useContext(AccessManagerContext);
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    engine.computeRegion
  );

  const render =
    engineCanBeStopped(engine) &&
    canStopEngine(accountAllowedActions) &&
    isAllowedTo(engine.id, GQLRbacaction.ENGINE_OPERATE);
  const [isStopDisabled, setIsStopDisabled] = useState(false);

  if (!render) {
    return null;
  }

  return (
    <div
      className={cn(styles.actionBtn, {
        [styles.visible]: hovered,
      })}
    >
      <MaterialButton
        template={ButtonTemplate.Secondary}
        startIcon={<StopIcon />}
        size={ButtonSize.Small}
        tooltip={readOnlyMode ? readOnlyTooltip : undefined}
        text={t("engines.stop")}
        disabled={readOnlyMode || isStopDisabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          Promise.resolve(true)
            .then(() => setIsStopDisabled(true))
            .then(() => onClick?.())
            .finally(() => setIsStopDisabled(false));
        }}
        dataTestid="stop-engine"
      />
    </div>
  );
}

export default StopEngineButton;
