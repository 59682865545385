import React, { Component, ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Snackbar, Portal } from "@material-ui/core";
import cn from "classnames";
import { ReactComponent as Exclamation } from "assets/icons/Exclamation.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/SucessIcon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import styles from "./styles.module.scss";
import Spinner from "components/common/Spinner";

interface SnackBarViewProps extends WithTranslation {
  vertical?: any;
  horizontal?: any;
  openSnackBar?: boolean;
  closeSnackBar?: () => void;
  type?: "success" | "error" | "loading";
  message: string | ReactElement;
  closeIcon?: boolean;
  displayInline?: boolean;
  classes?: any;
  snackBarKey?: string;
  autoHideDuration: number | null;
  dimmed: boolean;
}

class SnackBarView extends Component<SnackBarViewProps, any> {
  static defaultProps = {
    autoHideDuration: null,
    dimmed: false,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.snackBarKey !== this.props.snackBarKey ||
      nextProps.message !== this.props.message
    );
  }

  renderMessageType() {
    const { type } = this.props;
    switch (type) {
      case "error":
        return <Exclamation className={styles.messageIcon} />;
      case "success":
        return <SuccessIcon className={styles.messageIcon} />;
      case "loading":
        return <Spinner size={22} />;
      default:
        return <Exclamation className={styles.messageIcon} />;
    }
  }

  renderMessage = () => {
    const { message, t } = this.props;
    if (typeof message === "string") {
      return <span className={styles.messageText}>{t(message as string)}</span>;
    }

    return <div className={styles.messageText}>{message}</div>;
  };

  render() {
    const {
      openSnackBar,
      vertical,
      horizontal,
      closeSnackBar,
      type,
      closeIcon,
      snackBarKey,
      autoHideDuration,
      displayInline,
      classes,
      dimmed,
    } = this.props;

    const component = (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={snackBarKey}
        open={openSnackBar}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackBar}
        ContentProps={{
          "aria-describedby": "message-id",
          classes: {
            root: cn(styles.snackBarContent, classes?.snackbar, {
              [styles.errorSnackBar]: type === "error",
              [styles.successSnackBar]: type === "success",
              [styles.loadingSnackBar]: type === "loading",
            }),
          },
          onClick: e => {
            e.stopPropagation();
          },
        }}
        classes={{
          root: cn(styles.root, classes?.root),
          anchorOriginTopCenter: cn(styles.anchorOriginTopCenter, {
            [styles.dimmed]: dimmed,
          }),
        }}
        onClick={closeSnackBar}
        message={
          <div className={styles.messageWrap}>
            {this.renderMessageType()}
            {this.renderMessage()}
          </div>
        }
        action={
          closeIcon
            ? [
                <CloseIcon
                  key="close-icon"
                  onClick={closeSnackBar}
                  className={styles.closeImg}
                />,
              ]
            : undefined
        }
      />
    );

    return displayInline ? component : <Portal>{component}</Portal>;
  }
}

export default withTranslation()(SnackBarView);
