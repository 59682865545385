import { useEffect } from "react";
import { Navigate, Route, useLocation, useMatch } from "react-router-dom";
import { authService } from "services/auth";
import ChooseAccount from "pages/ChooseAccount/ChooseAccount";
import Accounts from "pages/Accounts/Accounts";
import { FeatureFlag } from "featureFlags/constants";
import { withTitle } from "components/DocumentTitle/withTitle";
import { InvitationFlow } from "pages/Users/InvitationTokenVerification";
import {
  RESERVED_URL_SEARCH_PARAMS,
  ROUTES,
} from "businessLogic/routes.constants";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import {
  AsyncInvitationTokenVerification,
  AsyncAccountInfo,
  AsyncDatabasesNew,
  AsyncEngines,
  AsyncQueryEditor,
  AsyncUsers,
  AsyncRoles,
} from "./routes";

import { checkEnginesRoute } from "./checkEnginesRoute";

const UnauthorizedRoute = props => {
  const { query } = props;
  const loginType = authService.getLoginType();

  useEffect(() => {
    if (loginType === "auth0") {
      authService.redirectToLogout(false, query);
    }
  }, [loginType]);

  return loginType === "auth0" ? null : (
    <Navigate
      to={`${ROUTES.LOGIN}${query}`}
      replace
    />
  );
};

const Protected = props => {
  const { Component } = props;
  const location = useLocation();

  const { isLoggedIn } = useLoggedInUser();

  if (isLoggedIn) {
    return <Component {...props} />;
  }

  const redirect = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(RESERVED_URL_SEARCH_PARAMS.REDIRECT, redirect);

  const query = `?${searchParams}`;
  return <UnauthorizedRoute query={query} />;
};

const TokenExtractor = () => {
  const match = useMatch("/accept-member-invitation/:accountId/:token");

  return (
    <AsyncInvitationTokenVerification
      invitationFlow={InvitationFlow.ExistingExternalUser}
      token={match?.params.token as string}
      accountId={match?.params.accountId}
    />
  );
};

const protectedRoute = Component => {
  return props => (
    <Protected
      Component={Component}
      {...props}
    />
  );
};

export const getProtectedRoutes = flags => {
  const isEnginesPageEnabled = !!flags?.[FeatureFlag.FireboltAppEnginesPage];

  const ProtectedAsyncAccountInfo = protectedRoute(AsyncAccountInfo);
  const ProtectedAsyncUsers = protectedRoute(
    withTitle("title.user", AsyncUsers)
  );
  const ProtectedAsyncRoles = protectedRoute(
    withTitle("title.role", AsyncRoles)
  );
  const ProtectedAsyncEngines = checkEnginesRoute(
    protectedRoute(
      isEnginesPageEnabled
        ? withTitle("title.engines", AsyncEngines)
        : withTitle("title.database", AsyncDatabasesNew)
    )
  );
  const ProtectedAsyncQueryEditor = protectedRoute(
    withTitle("title.workspace", AsyncQueryEditor)
  );

  const ProtectedAsyncDatabasesNew = protectedRoute(
    withTitle("title.database", AsyncDatabasesNew)
  );
  const ProtectedAccount = protectedRoute(ChooseAccount);
  const ProtectedAccounts = protectedRoute(Accounts);
  const ProtectedAsyncInvitationTokenVerification =
    protectedRoute(TokenExtractor);
  const ProtectedDatabasesRedirect = protectedRoute(() => {
    return (
      <Navigate
        to={ROUTES.DATABASES}
        replace
      />
    );
  });

  return [
    <Route
      key={ROUTES.ACCOUNT_INFO}
      path={ROUTES.ACCOUNT_INFO}
      element={<ProtectedAsyncAccountInfo />}
    />,
    <Route
      key="/users"
      path="/users"
      element={<ProtectedAsyncUsers />}
    />,
    <Route
      key={ROUTES.ROLES}
      path={ROUTES.ROLES}
      element={<ProtectedAsyncRoles />}
    />,
    <Route
      key={ROUTES.ENGINES}
      path={ROUTES.ENGINES}
      element={<ProtectedAsyncEngines />}
    />,
    <Route
      key={ROUTES.SQLWORKSPACE}
      path={`${ROUTES.SQLWORKSPACE}`}
      element={<ProtectedAsyncQueryEditor />}
    />,
    <Route
      key={ROUTES.SQLWORKSPACE}
      path={`${ROUTES.SQLWORKSPACE}/:databaseName`}
      element={<ProtectedAsyncQueryEditor />}
    />,
    <Route
      key={ROUTES.DATABASES}
      path={ROUTES.DATABASES}
      element={<ProtectedAsyncDatabasesNew />}
    />,
    <Route
      key={ROUTES.CHOOSE_ACCOUNT}
      path={ROUTES.CHOOSE_ACCOUNT}
      element={<ProtectedAccount />}
    />,
    <Route
      key={ROUTES.ACCOUNTS}
      path={ROUTES.ACCOUNTS}
      element={<ProtectedAccounts />}
    />,
    <Route
      key="/accept-member-invitation/:accountId/:token"
      path="/accept-member-invitation/:accountId/:token"
      element={<ProtectedAsyncInvitationTokenVerification />}
    />,
    <Route
      key="/"
      path="/"
      element={<ProtectedDatabasesRedirect />}
    />,
  ];
};
