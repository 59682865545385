import { fetchQuery } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";
import {
  AccountQuery,
  AccountQuery$variables,
} from "./__generated__/AccountQuery.graphql";

export const query = graphql`
  query AccountQuery($id: ID!, $desiredActions: [String!]!) {
    account(id: $id) {
      id
      title
      businessDomainNames
      status
      allowedActions(desiredActions: $desiredActions)
      createTime
      tenantStatus
      rbacPolicies
    }
  }
`;

export const AccountTenant = graphql`
  query AccountTenantQuery($id: ID!) {
    account(id: $id) {
      tenant {
        id
        status
      }
    }
  }
`;

export const AccountsQuery = graphql`
  query AccountsQuery {
    accounts(first: 1000) {
      edges {
        node {
          id
          title
          businessDomainNames
          status
          createTime
        }
      }
    }
  }
`;

const fetch = (environment, params = {}): Promise<any> => {
  return fetchQuery<AccountQuery>(
    environment,
    query,
    params as AccountQuery$variables
  ).toPromise();
};

export const fetchAwsMonthToDateCost = (environment, params = {}) => {
  return fetchQuery(
    environment,
    graphql`
      query AccountAwsQuery($id: ID!) {
        account(id: $id) {
          awsMonthToDateCosts {
            ec2Costs
            s3Costs
            storageInTb
            additionalUsageFees
          }
        }
      }
    `,
    params
  ).toPromise();
};

export const fetchAwsSubscriptions = (environment, params = {}) => {
  return fetchQuery(
    environment,
    graphql`
      query AccountSubscriptionQuery($id: ID!) {
        account(id: $id) {
          awsMarketplaceSubscription {
            accountId
            customerId
            expiresTime: expirationTime
            id
            status
            tier
            type
          }
        }
      }
    `,
    params
  ).toPromise();
};

export default { fetch };
