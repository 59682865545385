import environment from "relay/environment";
import {
  fetchDatabaseDetails,
  fetchDatabases,
} from "relay/queries/database/Database";
import {
  getUiEngineStatus,
  getUiEngineStatusText,
} from "components/EngineStatusIcon/helpers";
import { SYSTEM_ACTOR } from "businessLogic/constants";
import {
  EngineFormField,
  SettingsPreset,
} from "components/EngineForm/constants";

const listDatabases = (accountId: string) => {
  return fetchDatabases(environment, { id: accountId }).then(response => {
    return response?.account.databases.edges?.map(({ node }: any) => {
      return {
        ...node,
        engines: [],
      };
    });
  });
};

const getDatabaseDetails = (
  databaseId: string,
  accountId: string,
  systemEngine?: any
) => {
  return fetchDatabaseDetails(environment, { id: databaseId, accountId }).then(
    response => {
      if (!response) {
        return {};
      }
      const { database } = response;

      const engines = database.bindings.edges?.map(({ node }: any) => {
        const engine = node.engine;

        return {
          ...engine,
          isDefault: node.engineIsDefault,
          id: engine.id,
          name: engine.name,
          emoji: engine.emoji,
          description: engine.description,
          endpoint: engine.endpoint,
          status: getUiEngineStatus(engine.currentStatusSummary),
          statusText: getUiEngineStatusText(engine.currentStatusSummary),
          latestRevision: engine.latestRevision,
          [EngineFormField.InstanceTypeId]:
            engine.latestRevision.specification.dbComputeInstancesType.id,
          [EngineFormField.RegionId]: engine.computeRegionId,
          engineCost:
            (engine.latestRevision.specification.dbComputeInstancesType
              .pricePerHourCents *
              engine.latestRevision.specification.dbComputeInstancesCount) /
            100,
          engineScale:
            engine.latestRevision.specification.dbComputeInstancesCount,
          autoStopAfter: engine.settings.autoStopDelayDurationSeconds,
          warmup: engine.settings.warmUpLevel,
          settingsPreset: engine.settingsPreset as SettingsPreset,
          isSpot: engine.latestRevision.specification.dbComputeInstancesUseSpot,
          tooltipLists: [
            [
              {
                name: "Created by:",
                value: (
                  <span data-heap-redact-text={true}>
                    {engine.createActor
                      ? (engine.createActor as any).fullName ||
                        (engine.createActor as any).displayName
                      : SYSTEM_ACTOR}
                  </span>
                ),
              },
            ],
          ],
        };
      });

      if (systemEngine) {
        engines?.push(systemEngine);
      }

      return {
        id: database.id,
        name: database.name,
        emoji: database.emoji,
        computeRegion: database.computeRegion,
        description: database.description,
        engines,
      };
    }
  );
};

export const DatabaseService = {
  listDatabases,
  getDatabaseDetails,
};
