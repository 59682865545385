import { all, put, takeEvery, delay } from "redux-saga/effects";
import {
  removeStatusMessageByTimeoutId,
  putStatusMessageSuccess,
  StatusMessageActions,
  StatusMessageType
} from "./actions";

export function* wrapAutoClearMessage(action) {
  const { message, type, options } = action.payload;

  const timeoutId = options?.timeoutId || Date.now();
  const newOptions = {
    ...options,
    timeoutId
  };

  yield put(putStatusMessageSuccess({ message, type, options: newOptions }));

  if (
    options.preventAutoClear ||
    ![StatusMessageType.Success, StatusMessageType.Error].includes(type)
  ) {
    return;
  }
  yield delay(options.autoClearDelay || 5000);
  yield put(removeStatusMessageByTimeoutId(timeoutId));
}

export function* StatusMessageSagas() {
  return yield all([
    takeEvery(StatusMessageActions.PUT_MESSAGE, wrapAutoClearMessage)
  ]);
}
