import { EngineFormField } from "components/EngineForm/constants";

export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";

export enum DatabaseFormField {
  Emoji = "emoji",
  Name = "name",
  Description = "description",
  RegionId = "regionId",
  AttachedEngineIds = "attachedEngineIds",
  DefaultEngineIdx = "defaultEngineIdx",
  EngineTemplateIdxs = "engineTemplateIdxs",
  EngineTemplateDefaultIdx = "engineTemplateDefaultIdx",
  AddEngines = "addEnginesBtn"
}

export interface Template {
  isDefault?: boolean;
  initialInstanceTypeName?: string;
  allowedActions?: any[];
  [EngineFormField.Name]: string;
  [EngineFormField.Scale]: number;
  [EngineFormField.IsSpot]: boolean;
  [EngineFormField.InstanceTypeId]: string;
  [EngineFormField.SettingsPreset]: string;
  [EngineFormField.AutoStopAfter]: number;
  [EngineFormField.WarmUp]: string;
}

export const INGEST_DEFAULT_INSTANCE_TYPE_NAME = "i3.4xlarge";
export const INGEST_DEFAULT_INSTANCE_TYPE_NAME_WITH_USE_CPU = "S4";
export const ANALYTICS_DEFAULT_INSTANCE_TYPE_NAME = "m5d.4xlarge";
export const ANALYTICS_DEFAULT_INSTANCE_TYPE_NAME_WITH_USE_CPU = "B4";
export const GENERAL_PURPOSE_DEFAULT_INSTANCE_TYPE_NAME = "r5d.4xlarge";
export const GENERAL_PURPOSE_DEFAULT_INSTANCE_TYPE_NAME_WITH_CPU = "M4";

export const enginesAllAllowedActions = [
  "core.engines.start",
  "core.engines.stop",
  "core.engines.update",
  "core.engines.delete"
];

export enum DatabasePermission {
  CREATE = "core.databases.create",
  DELETE = "core.databases.delete",
  UPDATE = "core.databases.update"
}
