import _, { find, mapValues } from "lodash";
import {
  hasRunningInsertQueries,
  hasRunningQueries,
  queriesByIdToSortedArray,
} from "./queries";

export function getAllScripts(state, databaseName) {
  const scripts = state?.query?.userScripts?.scripts || [];
  const globalScripts = state?.query?.userScripts?.globalScripts || [];
  const queries =
    state?.query?.queries.databasesQueries?.[databaseName]?.queriesById || {};

  return [...scripts, ...globalScripts, ...Object.values(queries)];
}

export const getAllScriptsMemoized = _.memoize((query, databaseName) => {
  const scripts = query?.userScripts?.scripts || [];
  const globalScripts = query?.userScripts?.globalScripts || [];
  const queries =
    query?.queries.databasesQueries?.[databaseName]?.queriesById || {};

  return [...scripts, ...globalScripts, ...Object.values(queries)];
});

export function isScriptNameDuplicate(scripts, scriptName) {
  return find(scripts, { name: scriptName });
}

export const getNonEmptyUnsavedScripts = (queries, skipId = null) => {
  const { queriesById } = queries;

  let scripts: any[] = [];

  mapValues(queriesById, q => {
    if (q.id === skipId) {
      return;
    }

    let isUnsaved = false;
    let isEmpty = false;

    if (!q.userScriptId) {
      isUnsaved = true;
    }
    if (q.query === "") {
      isEmpty = true;
    }

    scripts.push({
      id: q.id,
      isUnsaved,
      isEmpty,
      hasRunningQueries: hasRunningQueries(q),
      hasRunningInsertQueries: hasRunningInsertQueries(q),
    });
  });

  return scripts;
};

export const canCloseAll = queries => {
  const scripts = getNonEmptyUnsavedScripts(queries);

  if (scripts.length === 1) {
    return !(scripts[0].isUnsaved && scripts[0].isEmpty);
  }
  return true;
};

export const getOpenTabs = queries => {
  const { queriesById } = queries;
  return queriesByIdToSortedArray(queriesById);
};

export const canCloseOthersAll = (queries, script) => {
  const scripts = getNonEmptyUnsavedScripts(queries, script?.id);

  return !!scripts.length;
};

/**
 * @TODO crete an helper to generate unique script name
 * right now we have pretty much same functionality in 3 different places
 * 1) query-browser
 * 2) reduces/query
 * 3) saga/userScriptsSaga
 *
 * @param scriptName
 * @returns {boolean}
 */
export const checkExistScriptName = (scripts, scriptName) => {
  return isScriptNameDuplicate(scripts, scriptName);
};

export const canSaveScriptName = ({ scripts, scriptName, currentQuery }) => {
  const existScriptName = checkExistScriptName(scripts, scriptName);
  const isSameScript =
    existScriptName &&
    (currentQuery.userScriptId
      ? existScriptName.id === currentQuery.userScriptId
      : existScriptName.id === currentQuery.id);

  return {
    scriptNameExists: !!existScriptName,
    foundCurrentScriptName: isSameScript,
  };
};

export const exportScript = item => {
  const { query, name } = item;
  const blob = new Blob([query], { type: "text/plain" });

  downloadFile(blob, `${name}.sql`);
};

export const downloadFile = (file, fileName) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const hasUnsavedScripts = scripts =>
  _.some(scripts, s => s.isUnsaved && !s.isEmpty);
