import * as _ from "lodash";
import { parseBool } from "businessLogic/helpers/Format";
import { FeatureFlag } from "./constants";

export function isHybridEnabled(accountFlags) {
  return parseBool(
    _.get(
      accountFlags,
      FeatureFlag.FireboltAppDefaultHybridQueryExecution,
      false
    )
  );
}

export function isSystemEnginedEnabled(accountFlags) {
  return (
    parseBool(
      _.get(accountFlags, FeatureFlag.FireboltEnableSystemEngine, false)
    ) ||
    parseBool(_.get(accountFlags, FeatureFlag.FireboltFirenzeEnabled, false))
  );
}
