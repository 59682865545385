import { getBrowserName, BrowserNames } from "./browser";

let browserIsReloading = false;

window.addEventListener("beforeunload", () => {
  browserIsReloading = true;
});

const BrowsersToIgnoreUnloadError = {
  [BrowserNames.firefox]: true,
  [BrowserNames.safari]: true,
};

const ignoreBrowserUnloadingError = () => {
  const browserName = getBrowserName();
  return BrowsersToIgnoreUnloadError[browserName] && browserIsReloading;
};

export const FireboltGlobals = {
  ignoreBrowserUnloadingError,
};
