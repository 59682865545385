import React from "react";
import { requestSubscription } from "react-relay";
import environment, { subscriptionClient } from "relay/environment";
import { ConnectedComponent } from "react-redux";
import { fetchQuery } from "relay-runtime";

export interface FallbackPollingOptions {
  query: any;
  variables: any;
  intervalMs: number;
  onResponse: (response: any) => void;
  onError: (error: any) => void;
}

export interface SubscriptionProps {
  getVariables: (props: any) => any;
  subscription: any;
  onNext?: (data: any) => void;
  fallbackPollingOptions?: FallbackPollingOptions;
}

interface withGraphqlSubscriptionProps {
  forceSubscribe?: () => void;
}

export default function withGraphqlSubscriptions<T, P>(
  Component: T | ConnectedComponent<any, any>,
  options: ((props: P) => SubscriptionProps[]) | SubscriptionProps[],
  shouldUpdateSubscriptions?: (prevProps: P, currentProps: P) => boolean
): React.ComponentType<P & withGraphqlSubscriptionProps> {
  class HOC extends React.Component<P, any> {
    _subs: any[] = [];

    _pollingIntervals: NodeJS.Timer[] = [];

    unsubscribe() {
      this._subs.forEach(s => s.dispose());
      this._subs = [];

      this._pollingIntervals.forEach(intervalID => {
        clearInterval(intervalID);
      });
      this._pollingIntervals = [];
    }

    subscribe() {
      const optionsData =
        typeof options === "function" ? options(this.props) : options;

      optionsData.forEach(
        ({ subscription, getVariables, onNext, fallbackPollingOptions }) => {
          const getSubscription = () => {
            return requestSubscription(environment, {
              subscription,
              variables: { ...getVariables(this.props) },
              onError: () => {
                subscriptionClient.dispose();
                setTimeout(() => {
                  this._subs.push(getSubscription());
                }, 5000);
              },
              onNext: data => {
                if (onNext) {
                  onNext(data);
                }
              },
            });
          };

          this._subs.push(getSubscription());

          if (fallbackPollingOptions) {
            const { query, variables, intervalMs, onResponse, onError } =
              fallbackPollingOptions;

            const intervalID = setInterval(() => {
              fetchQuery<any>(environment, query, variables)
                .toPromise()
                .then(onResponse)
                .catch(onError);
            }, intervalMs);
            this._pollingIntervals.push(intervalID);
          }
        }
      );
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    componentDidMount() {
      this.subscribe();
    }

    componentDidUpdate(prevProps) {
      if (shouldUpdateSubscriptions?.(prevProps, this.props)) {
        this.unsubscribe();
        this.subscribe();
      }
    }

    render() {
      const HOCComponent = Component as any;
      return (
        <HOCComponent
          {...this.props}
          forceSubscribe={() => {
            this.unsubscribe();
            this.subscribe();
          }}
        />
      );
    }
  }
  return HOC;
}
