import { ORDER_ASC, ORDER_DESC } from "pages/Database/constants";
import React from "react";
import { ReactComponent as ArrowUpIcon } from "assets/icons/SortAsc.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/SortDesc.svg";
import styles from "./styles.module.scss";
import { SortDirection } from "@material-ui/core";
import Spinner from "components/common/Spinner";

interface IIconSort {
  rowId: string;
  order: SortDirection;
  orderBy: string | null;
  isLoading?: boolean;
}

export default function IconSortComponent(props: IIconSort) {
  const { rowId, order, orderBy, isLoading } = props;

  return (
    <div className={styles.sortIconWrap}>
      {isLoading ? (
        orderBy === rowId && <Spinner size={14} />
      ) : (
        <>
          <ArrowUpIcon
            id="sortAsc"
            className={
              orderBy === rowId &&
              `${order}`.toUpperCase() === ORDER_ASC.toUpperCase()
                ? styles.sortIconActive
                : styles.sortIcon
            }
          />
          <ArrowDownIcon
            id="sortDesc"
            className={
              orderBy === rowId &&
              `${order}`.toUpperCase() === ORDER_DESC.toUpperCase()
                ? styles.sortIconActive
                : styles.sortIcon
            }
          />
        </>
      )}
    </div>
  );
}
