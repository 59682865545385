import React, { useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clx from "classnames";
import _ from "lodash";
import { ReactComponent as LogoutIcon } from "assets/icons/Logout.svg";
import { ReactComponent as SwitchAccountIcon } from "assets/icons/SwitchAccountIcon.svg";
import { ReactComponent as BillingIcon } from "assets/icons/BillingIcon.svg";
import ActionMenuItem from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import ActionMenu from "components/ActionMenu/ActionMenu";
import { useMenu } from "components/ActionMenu/useMenu";
import { UI_USER_ROLE_NAMES_MAP } from "pages/Users/User.constant";
import { Member, UserRole } from "pages/Users/User.interface";
import { logout } from "pages/Auth/redux/actions";
import { ROUTES } from "businessLogic/routes.constants";
import Tooltip from "../../Tooltip/Tooltip";
import {
  AQA_ACCOUNT_BILLING_LINK,
  AQA_AVATAR_NAME,
  AQA_LOGOUT_LINK,
  AQA_SWITCH_ACCOUNT_LINK,
} from "../AqaIds";
import { clickOnSpaceOrEnterPress } from "../../common/keyboardUtils";
import sidebarStyles from "../MainSidebar.module.scss";
import styles from "../sidebarItemWithDropdown.module.scss";

interface Props {
  logout(preventRedirect: boolean): void;
  name: string;
  userRole: UserRole | null;
  accountName: string | null;
  members: Member[] | null;
  accountIsReadyForUse: boolean;
}

const getAvatarAbbr = name => {
  return (name || "")
    .split(" ")
    .filter(Boolean)
    .map(c => c[0])
    .slice(0, 2)
    .join("");
};

const UserBar: React.FunctionComponent<Props> = (props: Props) => {
  const { name, userRole, logout, accountName, members, accountIsReadyForUse } =
    props;
  const navigate = useNavigate();
  const { menuElement, openMenu, closeMenu } = useMenu();

  const handleLogout = useCallback(() => {
    closeMenu();
    logout(false);
  }, [closeMenu, logout]);

  const header = [
    <div
      className={styles.header}
      key={name}
      data-heap-redact-text={true}
    >
      <div
        className={styles.fullName}
        title={userRole ? UI_USER_ROLE_NAMES_MAP[userRole] : ""}
      >
        {name}
      </div>
      <div className={styles.accountName}>{accountName}</div>
    </div>,
  ];

  const mainItems = [
    ...(userRole && userRole === UserRole.AccountAdmin && accountIsReadyForUse
      ? [
          <Link
            to={ROUTES.ACCOUNT_INFO}
            key="account"
          >
            <ActionMenuItem
              text="Account Billing"
              testId={AQA_ACCOUNT_BILLING_LINK}
              onClick={closeMenu}
              icon={<BillingIcon />}
            />
          </Link>,
        ]
      : []),
  ];

  const secondaryItems = [
    members && members.length > 1 ? (
      <ActionMenuItem
        text="Switch Account"
        testId={AQA_SWITCH_ACCOUNT_LINK}
        key="switch_account"
        onClick={e => {
          e.stopPropagation();
          closeMenu();
          navigate(ROUTES.CHOOSE_ACCOUNT, { replace: true });
        }}
        icon={<SwitchAccountIcon />}
      />
    ) : undefined,
    <ActionMenuItem
      text="Logout"
      testId={AQA_LOGOUT_LINK}
      key="log_out"
      onClick={handleLogout}
      icon={<LogoutIcon />}
    />,
  ];

  const menuItems = [header, mainItems, secondaryItems];

  return (
    <ClickAwayListener onClickAway={() => closeMenu()}>
      <div>
        <Tooltip
          title={name}
          placement="right"
          classes={{
            popper: clx(sidebarStyles.menuItemTooltip, sidebarStyles.forced),
          }}
        >
          <div
            className={styles.root}
            onClick={openMenu}
            tabIndex={0}
            onKeyPress={e => clickOnSpaceOrEnterPress(e)}
          >
            <div className={styles.container}>
              <span
                className={styles.name}
                data-heap-redact-text={true}
                data-testid={AQA_AVATAR_NAME}
              >
                {getAvatarAbbr(name)}
              </span>
            </div>
          </div>
        </Tooltip>
        {!!menuElement && (
          <ActionMenu
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            lists={menuItems.filter(list => list.length)}
            open={true}
            anchorEl={menuElement}
            onClose={closeMenu}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default connect(
  state => {
    return {
      accountName: _.get(state, "account.accountDetails.name"),
      members: _.get(state, "user.userData.members"),
    };
  },
  { logout }
)(UserBar);
