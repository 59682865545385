import { parseDomain, fromUrl, ParseResultType } from "parse-domain";
import { Validations } from "businessLogic/helpers/Validation";
import { ContactFormField } from "components/MainSidebar/ContactSupport/constants";
import { NOT_PARSED_PROPERLY_DOMAIN_ZONES } from "./constants";

export const getDomainNameFromEmail = (email: string): string => {
  if (Validations.isValidEmail(email) && Validations.isValidWorkEmail(email)) {
    const url = email.replace(/.+@/gi, "");
    const parseResult = parseDomain(fromUrl(url));

    switch (parseResult.type) {
      case ParseResultType.Listed: {
        const { icann, topLevelDomains } = parseResult;
        const { domain = "" } = icann;

        // some rarely used domains have both domain name and zone listed as top level domain as of parse-domain 7.0.0
        const isException = !!NOT_PARSED_PROPERLY_DOMAIN_ZONES.filter(e =>
          topLevelDomains.includes(e)
        ).length;
        if (isException) {
          return topLevelDomains[topLevelDomains.length - 2];
        }

        return domain;
      }
      // passes check but is reserved or doesn't match public domain suffix list
      case ParseResultType.Reserved:
      case ParseResultType.NotListed: {
        const { labels } = parseResult;
        return labels[labels.length - 2];
      }
    }
  }

  return "";
};

export const formatContactFields = fields => {
  return {
    [ContactFormField.FirstName]: fields.firstName,
    [ContactFormField.LastName]: fields.lastName,
    [ContactFormField.Company]: fields.accountName || fields.company,
    [ContactFormField.Email]: fields.email,
    [ContactFormField.Subject]: fields.subject,
  };
};
