import { all, put, takeEvery, call } from "redux-saga/effects";
import { Action } from "redux-actions";
import { executeQuery } from "businessLogic/services/query/execute";
import { getEngineProxyVersion } from "businessLogic/services/engine";
import {
  ProxyActions,
  setProxyVersionSuccess,
  setLoadingProxyVersion,
  setPreserveQueryExecutionLoading,
  setPreserveQueryExecutionSuccess,
} from "../actions/proxy";
import { QUERY_SETTINGS } from "./constants";

export function* setProxyVersionSaga(action: Action<{ engine: any }>) {
  const { engine } = action.payload;

  try {
    yield put(setLoadingProxyVersion(engine.id));
    const version = yield call(
      getEngineProxyVersion,
      `https://${engine.endpoint}`
    );
    yield put(
      setProxyVersionSuccess({ engineId: engine.id, version: version || null })
    );
  } catch (error) {
    yield put(setProxyVersionSuccess({ engineId: engine.id, version: null }));
    console.error(`Failed to fetch ${engine.endpoint} version:`, error);
  }
}

export function* setPreserveQueryExecutionSaga(
  action: Action<{ engine: any; databaseName: string }>
) {
  const { engine, databaseName } = action.payload;

  try {
    yield put(setPreserveQueryExecutionLoading(engine.id));

    yield call(executeQuery, {
      query: `
--Validating SET ${QUERY_SETTINGS.preserveQueryExecution} = 1
SELECT 1
`,
      database: databaseName,
      queryId: null,
      engineEndpoint: `https://${engine.endpoint}`,
      querySettings: {
        [QUERY_SETTINGS.preserveQueryExecution]: 1,
        [QUERY_SETTINGS.noProgress]: 1,
      },
      engine,
    });
    yield put(setPreserveQueryExecutionSuccess({ engineId: engine.id }));
  } catch (error) {
    console.info(
      `Preserve query execution is not supported on engine endpoint ${engine.endpoint}: `,
      error
    );
  }
}

export function* ProxySagas() {
  return yield all([
    takeEvery(ProxyActions.SET_VERSION, setProxyVersionSaga),
    takeEvery(
      ProxyActions.SET_PRESERVE_QUERY_EXECUTION,
      setPreserveQueryExecutionSaga
    ),
  ]);
}
