import { createAction } from "redux-actions";
import { Member, MyUser } from "pages/Users/User.interface";
import { AccessManager } from "../../services/access/accessManager";

export interface UserData {
  user: MyUser;
  members: Member[];
}

export const UserActions = {
  LOAD_USER_DATA: "USER/LOAD_USER_DATA",
  LOAD_USER_DATA_FAILURE: "USER/LOAD_USER_DATA_FAILURE",
  INCOMPLETE_SETUP: "USER/INCOMPLETE_SETUP",
  LOAD_USER_DATA_SUCCESS: "USER/LOAD_USER_DATA_SUCCESS",
  PUT_USER_DATA: "USER/PUT_USER_DATA",
  PUT_SELECTED_ACCOUNT_ID: "USER/PUT_SELECTED_ACCOUNT_ID",
  REMOVE_SELECTED_ACCOUNT_ID: "USER/REMOVE_SELECTED_ACCOUNT_ID",
};
export const incompleteSetup = createAction(UserActions.INCOMPLETE_SETUP);

export const loadUserData = createAction(
  UserActions.LOAD_USER_DATA,
  (login: boolean, accessManager: AccessManager) => ({ login, accessManager })
);

export const loadUserDataFailure = createAction(
  UserActions.LOAD_USER_DATA_FAILURE,
  (errorMessage: string) => errorMessage
);

export const loadUserDataSuccess = createAction(
  UserActions.LOAD_USER_DATA_SUCCESS,
  (userData: UserData) => userData
);

export const putUserData = createAction(
  UserActions.PUT_USER_DATA,
  (userData: UserData) => userData
);

export const putSelectedAccountId = createAction(
  UserActions.PUT_SELECTED_ACCOUNT_ID,
  (
    accountId: string | null,
    resolve?: () => void,
    accessManager?: AccessManager
  ) => ({ accountId, resolve, accessManager })
);

export const removeSelectedAccountId = createAction(
  UserActions.REMOVE_SELECTED_ACCOUNT_ID
);
