import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import {
  AuthVerifyTokenInput,
  VerifyTokenMutation$data,
} from "relay/mutations/auth/__generated__/VerifyTokenMutation.graphql";

const mutation = graphql`
  mutation VerifyTokenMutation($input: AuthVerifyTokenInput!) {
    authVerifyToken(input: $input) {
      success
      attachedEmail
      token {
        expiresAt
        used
      }
    }
  }
`;

function commit(
  environment,
  input: AuthVerifyTokenInput,
  onCompleted: (response: VerifyTokenMutation$data) => void,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
  });
}

export default { commit };
