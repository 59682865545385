/**
 * @generated SignedSource<<ca3ac79bf02105bd357aa1e8259b3141>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TenantStatus = "UNSPECIFIED" | "CREATED" | "CREATING" | "FAILED" | "ACTIVE" | "%future added value";
export type AccountTenantQuery$variables = {
  id: string;
};
export type AccountTenantQuery$data = {
  readonly account: {
    readonly tenant: {
      readonly id: string;
      readonly status: TenantStatus;
    };
  };
};
export type AccountTenantQuery = {
  variables: AccountTenantQuery$variables;
  response: AccountTenantQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountTenantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountTenantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a435ca92c96f877986e081a2db6b3f96",
    "id": null,
    "metadata": {},
    "name": "AccountTenantQuery",
    "operationKind": "query",
    "text": "query AccountTenantQuery(\n  $id: ID!\n) {\n  account(id: $id) {\n    tenant {\n      id\n      status\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc43665681676c212a09e1592923f584";

export default node;
