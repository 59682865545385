import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { TooltipAutoOpenInterface } from "./types";
import styles from "./styles.module.scss";
import { Divider, List } from "@material-ui/core";
import uniqueid from "lodash/uniqueId";
import TooltipItem, { TooltipItemProps } from "./TooltipItem/TooltipItem";

interface RenderProps {
  isOpen?: boolean;
}

interface PropsTooltip extends TooltipProps {
  lists?: TooltipItemProps[][];
  classes?: any;
  autoOpen?: TooltipAutoOpenInterface;
  render?: (renderProps: RenderProps) => React.ReactElement;
  title: any;
}

const renderTooltipContent = lists => {
  return (
    <List
      component="nav"
      classes={{ root: styles.list }}
    >
      {lists.flatMap((item, index) => {
        if (index > 0) {
          return [
            <Divider
              key={uniqueid()}
              classes={{ root: styles.divider }}
            />,
            item.flatMap((tooltipItem, index) => {
              return (
                <TooltipItem
                  key={uniqueid()}
                  noMargin={!index}
                  name={tooltipItem.name}
                  value={tooltipItem.value}
                />
              );
            }),
          ];
        }
        return [
          item.flatMap((tooltipItem, index) => {
            return (
              <TooltipItem
                key={uniqueid()}
                noMargin={!index}
                name={tooltipItem.name}
                value={tooltipItem.value}
              />
            );
          }),
        ];
      })}
    </List>
  );
};

const CustomTooltip = React.forwardRef((props: PropsTooltip, ref) => {
  const {
    lists,
    title,
    classes = {},
    autoOpen,
    render,
    children,
    ...restProps
  } = props;

  const [open, setOpen] = useState(autoOpen ? true : false);

  useEffect(() => {
    let timer;

    if (autoOpen && open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, autoOpen.hideDelay);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [autoOpen, open]);

  useEffect(() => {
    if (autoOpen?.forceClose) {
      setOpen(false);
    }
  }, [autoOpen?.forceClose]);

  if (!title && !lists?.length) {
    return <>{children}</>;
  }

  const tooltipProps = {
    PopperProps: {
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: true,
            boundariesElement: document.body,
          },
        },
      },
    },
    ...restProps,
  };

  if (open) {
    tooltipProps.open = open;
  }

  return (
    <Tooltip
      ref={ref}
      arrow
      key={`${open}`}
      classes={{
        popper: cn(styles.popper, classes.popper),
        tooltip: cn(styles.tooltip, classes.tooltip),
        tooltipPlacementLeft: styles.tooltipPlacementLeft,
        tooltipPlacementRight: styles.tooltipPlacementRight,
        tooltipPlacementTop: cn(
          styles.tooltipPlacementTop,
          classes.tooltipPlacementTop
        ),
        tooltipPlacementBottom: styles.tooltipPlacementBottom,
        arrow: styles.arrow,
      }}
      title={
        lists ? (
          renderTooltipContent(lists)
        ) : (
          <span className={styles.tooltipTitle}>{title}</span>
        )
      }
      children={render ? render({ isOpen: open }) : children}
      {...tooltipProps}
    />
  );
});

export default CustomTooltip;
