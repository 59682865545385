import environment from "relay/environment";
import AccountQuery from "relay/queries/account/Account";
import { EnginePermission } from "components/EnginesList/constants";
import { DatabasePermission } from "pages/Database/constants";
import ReSendVerificationEmailMutation from "relay/mutations/auth/ReSendVerificationEmailMutation";

const allowedPermissions = [
  ...Object.values(EnginePermission),
  ...Object.values(DatabasePermission),
];

const getAccountDetails = accountId => {
  return AccountQuery.fetch(environment, {
    id: accountId,
    desiredActions: allowedPermissions,
  })
    .then(response => {
      const {
        businessDomainNames: DomainNames,
        id,
        Status,
        title,
        status,
        allowedActions,
        createTime,
        tenantStatus,
        rbacPolicies,
      } = response?.account;

      return {
        id,
        DomainNames,
        Status,
        name: title,
        status,
        users: null,
        allowedActions,
        createTime,
        tenantStatus,
        rbacPolicies,
      };
    })

    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
};

const resendEmailAccount = (emailAccount: string) => {
  return new Promise((resolve, reject) => {
    const input = {
      email: emailAccount,
    };
    ReSendVerificationEmailMutation.commit(environment, input, resolve, reject);
  });
};

export const AccountService = {
  resendEmailAccount,
  getAccountDetails,
};
