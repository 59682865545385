export const Validations = {
  isValidEmail,
  isValidPass,
  isValidName,
  isLettersAndNumbers,
  isValidWorkEmail,
  isValidDatabaseName,
  isValidNameStart,
  isDatabaseNameReserved,
};

// Copied from https://github.com/manishsaraan/email-validator/blob/master/index.js
/* eslint-disable */
const tester =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
/* eslint-enable */

function isValidEmail(email: string) {
  if (!email) return false;

  var emailParts = email.split("@");

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  var domainParts = address.split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  if (!tester.test(email)) return false;

  return true;
}
function isValidPass(pass: string) {
  const passRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return passRegex.test(pass);
}

function isValidName(name: string) {
  const letters = /^[\p{L}',.-][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/gmu;
  return letters.test(name);
}

function isLettersAndNumbers(name: string) {
  const letters = /^[\p{L}',.-][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/gmu;
  return letters.test(name);
}

function isValidWorkEmail(email: string) {
  const emailRegex =
    /^([\w-.+]+@)(?!((me|mac|icloud|gmail|googlemail|live|msn|outlook|yahoo|aol|163|wp|sky|btinternet|hatmail|email|hotmail|gnail|protonmail|gmx|seznam|inbox|freemail|qq|citromail|abc|xyz|pqr|rediffmail|ymail|gamil|yopmail|yandex|mail|office|mailinator)\.))(([\w-]+\.)+[\w-]{2,100})?$/i;
  return emailRegex.test(email);
}
function isValidDatabaseName(database: string) {
  const nameRegex = /^[0-9a-zA-Z_]+$/;
  const invalidStartRegex = /^(([0-9]+)|(xml))/i;
  if (nameRegex.test(database) && !invalidStartRegex.test(database)) {
    return true;
  }
  return false;
}

function isDatabaseNameReserved(name: string) {
  if (name.toLowerCase() === "system") {
    return true;
  }
  return false;
}

function isValidNameStart(str: string): boolean {
  const invalidStartRegex = /^(([0-9_]+)|(xml))/i;
  return !invalidStartRegex.test(str);
}
