/**
 * @generated SignedSource<<980b48e7ee967de5e37491d62138a6ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountMemberDeleteInput = {
  id: string;
};
export type DeleteMemberMutation$variables = {
  input: AccountMemberDeleteInput;
};
export type DeleteMemberMutation$data = {
  readonly accountMemberDelete: {
    readonly success: boolean;
  };
};
export type DeleteMemberMutation = {
  variables: DeleteMemberMutation$variables;
  response: DeleteMemberMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountMemberDeletePayload",
    "kind": "LinkedField",
    "name": "accountMemberDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMemberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMemberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "daad8ce38e7884c958e8db95ea8b318f",
    "id": null,
    "metadata": {},
    "name": "DeleteMemberMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMemberMutation(\n  $input: AccountMemberDeleteInput!\n) {\n  accountMemberDelete(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4c62fd4ba0a00eee6f3dbd25ad59e5a";

export default node;
