/**
 * @generated SignedSource<<d8456cdb505f3fb551b20e0600f5e21d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthResetPasswordStartInput = {
  username: string;
  recaptchaChallenge: string;
};
export type ResetPasswordStartMutation$variables = {
  input: AuthResetPasswordStartInput;
};
export type ResetPasswordStartMutation$data = {
  readonly authResetPasswordStart: {
    readonly success: boolean;
  };
};
export type ResetPasswordStartMutation = {
  variables: ResetPasswordStartMutation$variables;
  response: ResetPasswordStartMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthResetPasswordStartPayload",
    "kind": "LinkedField",
    "name": "authResetPasswordStart",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordStartMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordStartMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01fa5a1023c8c9f92e21e9498aad71b9",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordStartMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordStartMutation(\n  $input: AuthResetPasswordStartInput!\n) {\n  authResetPasswordStart(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef7ab9bceae26ce796744b2cea7a19c2";

export default node;
