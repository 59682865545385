import { Action, handleActions } from "redux-actions";
import { AccountQueryFlagsAction } from "../actions/accountFlags";
import { AccountFlagsState, AccountFlags } from "../types";

export const INITIAL_STATE: AccountFlagsState = {
  flags: {},
};

const reducerMap = {};

reducerMap[AccountQueryFlagsAction.SET_QUERY_FLAGS] = (
  state: AccountFlagsState,
  action: Action<{ accountFlags: AccountFlags }>
): AccountFlagsState => {
  const { accountFlags } = action.payload;
  return {
    ...state,
    flags: {
      ...state.flags,
      ...accountFlags,
    },
  };
};

export default handleActions<AccountFlagsState, any>(reducerMap, INITIAL_STATE);
