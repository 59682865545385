import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AuthRefreshTokenInput } from "./__generated__/RefreshTokenMutation.graphql";

const mutation = graphql`
  mutation RefreshTokenMutation($input: AuthRefreshTokenInput!) {
    authRefreshToken(input: $input) {
      success
      accessToken
      expiresAt
      scopes
    }
  }
`;

function commit(
  environment,
  input: AuthRefreshTokenInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
  });
}

export default { commit };
