import { createAction } from "redux-actions";
import { UserScript, UserScriptPayload } from "../../constants/interfaces";

export const UserScriptsAction = {
  USER_SCRIPTS_LOAD: "USER_SCRIPTS/LOAD",
  USER_SCRIPTS_LOAD_SUCCESS: "USER_SCRIPTS/LOAD_SUCCESS",
  USER_SCRIPTS_LOAD_FAIL: "USER_SCRIPTS/LOAD_FAIL",
  USER_SCRIPTS_SAVE: "USER_SCRIPTS/SAVE",
  USER_SCRIPTS_SAVE_SUCCESS: "USER_SCRIPTS/SAVE_SUCCESS",
  USER_SCRIPTS_UPDATE: "USER_SCRIPTS/UPDATE",
  USER_SCRIPTS_UPDATE_SUCCESS: "USER_SCRIPTS/UPDATE_SUCCESS",
  USER_GLOBAL_SCRIPTS_UPDATE_SUCCESS:
    "USER_SCRIPTS/GLOBAL_SCRIPTS_UPDATE_SUCCESS",
  USER_SCRIPTS_DELETE: "USER_SCRIPTS/DELETE",
  USER_SCRIPTS_DELETE_SUCCESS: "USER_SCRIPTS/DELETE_SUCCESS",
  USER_GLOBAL_SCRIPTS_DELETE_SUCCESS:
    "USER_SCRIPTS/USER_GLOBAL_SCRIPTS_DELETE_SUCCESS",
  USER_SCRIPTS_FAILURE: "USER_SCRIPTS/FAILURE",
  USER_SCRIPTS_RELEASE_ERROR: "USER_SCRIPTS/RELEASE_ERROR",
  USER_SCRIPTS_ADD_NEW: "USER_SCRIPTS/ADD_NEW",
  USER_GLOBAL_SCRIPTS_ADD_NEW: "USER_SCRIPTS/USER_GLOBAL_SCRIPTS_ADD_NEW",
  USER_SCRIPTS_DUPLICATE: "USER_SCRIPTS/DUPLICATE",
  USER_SCRIPTS_SELECT: "USER_SCRIPTS/SELECT",
  GLOBALIZE_SCRIPT: "USER_SCRIPTS/GLOBALIZE_SCRIPT",
  GLOBALIZE_SCRIPT_SUCCESS: "USER_SCRIPTS/GLOBALIZE_SCRIPT_SUCCESS",
  SCRIPT_SECTION_TOGGLE: "USER_SCRIPTS/SCRIPT_SECTION_TOGGLE",
  USER_SCRIPT_DELETE_WITH_CANCEL: "USER_SCRIPTS/USER_SCRIPT_DELETE_WITH_CANCEL",
  USER_MULTIPLE_SCRIPTS_DELETE_WITH_CANCEL:
    "USER_SCRIPTS/USER_MULTIPLE_SCRIPTS_DELETE_WITH_CANCEL",
};

export const LoadUserScripts = createAction(
  UserScriptsAction.USER_SCRIPTS_LOAD,
  (database: any) => ({ database })
);

export const LoadUserScriptsSuccess = createAction(
  UserScriptsAction.USER_SCRIPTS_LOAD_SUCCESS,
  (scripts): any => scripts
);

export const UserScriptsFailure = createAction(
  UserScriptsAction.USER_SCRIPTS_FAILURE,
  (error: string) => error
);

export const UserScriptsReleaseError = createAction(
  UserScriptsAction.USER_SCRIPTS_RELEASE_ERROR
);

export const SaveUserScripts = createAction(
  UserScriptsAction.USER_SCRIPTS_SAVE,
  ({
    query,
    database,
    afterSaveCallback,
  }: {
    query: any;
    database: any;
    afterSaveCallback?: () => void;
  }) => ({ query, database, afterSaveCallback })
);

export const SaveUserScriptsSuccess = createAction(
  UserScriptsAction.USER_SCRIPTS_SAVE_SUCCESS,
  (userScript: UserScript) => ({ userScript })
);

export const DuplicateUserScripts = createAction(
  UserScriptsAction.USER_SCRIPTS_DUPLICATE,
  (payload: UserScriptPayload) => ({
    userScript: payload.userScript,
    database: payload.database,
    globalScript: payload.globalScript,
  })
);

export const UserScriptsAddNew = createAction(
  UserScriptsAction.USER_SCRIPTS_ADD_NEW,
  (userScript: UserScript) => ({ userScript })
);

export const UserGlobalScriptsAddNew = createAction(
  UserScriptsAction.USER_GLOBAL_SCRIPTS_ADD_NEW,
  (userScript: UserScript) => ({ userScript })
);

export const DeleteUserScripts = createAction(
  UserScriptsAction.USER_SCRIPTS_DELETE,
  (payload: UserScriptPayload) => ({
    userScript: payload.userScript,
    database: payload.database,
    globalScript: payload.globalScript,
  })
);

export const DeleteUserScriptWithCancelQuery = createAction(
  UserScriptsAction.USER_SCRIPT_DELETE_WITH_CANCEL,
  (payload: UserScriptPayload) => ({
    userScript: payload.userScript,
    database: payload.database,
    globalScript: payload.globalScript,
    scriptOpenTab: payload?.scriptOpenTab,
  })
);

export const DeleteMultipleUserScriptsWithCancelQuery = createAction(
  UserScriptsAction.USER_MULTIPLE_SCRIPTS_DELETE_WITH_CANCEL,
  (payload: any) => ({
    database: payload.database,
    queryIdsToCancel: payload.queryIdsToCancel,
    scriptsToDelete: payload.scriptsToDelete,
  })
);

export const DeleteUserScriptsSuccess = createAction(
  UserScriptsAction.USER_SCRIPTS_DELETE_SUCCESS,
  (userScript: UserScript) => userScript
);

export const DeleteGlobalUserScriptsSuccess = createAction(
  UserScriptsAction.USER_GLOBAL_SCRIPTS_DELETE_SUCCESS,
  (userScript: UserScript) => userScript
);

export const UpdateUserScripts = createAction(
  UserScriptsAction.USER_SCRIPTS_UPDATE,
  (payload: UserScriptPayload) => ({
    userScript: payload.userScript,
    database: payload.database,
    globalScript: payload.globalScript,
    afterSaveCallback: payload.afterSaveCallback,
  })
);

export const UpdateUserScriptsSuccess = createAction(
  UserScriptsAction.USER_SCRIPTS_UPDATE_SUCCESS,
  (userScript: UserScript) => userScript
);
export const UpdateGlobalUserScriptsSuccess = createAction(
  UserScriptsAction.USER_GLOBAL_SCRIPTS_UPDATE_SUCCESS,
  (userScript: UserScript) => userScript
);

export const GlobalizeUserScript = createAction(
  UserScriptsAction.GLOBALIZE_SCRIPT,
  (userScript: UserScript) => ({ userScript })
);

export const GlobalizeUserScriptSuccess = createAction(
  UserScriptsAction.GLOBALIZE_SCRIPT_SUCCESS,
  (userScript: UserScript) => userScript
);

export const UpdateUserScriptsSelect = createAction(
  UserScriptsAction.USER_SCRIPTS_SELECT,
  ({ userScript, database }) => ({
    userScript,
    database,
  })
);

export const ToggleActiveScriptSection = createAction(
  UserScriptsAction.SCRIPT_SECTION_TOGGLE,
  activeSection => activeSection
);
