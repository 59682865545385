import environment from "relay/environment";
import CreateAccountMembersMutation from "relay/mutations/iam/CreateAccountMembersMutation";
import UserQuery from "relay/queries/iam/User";
import DeleteMemberMutation from "relay/mutations/iam/DeleteMemberMutation";
import AssignRoleMutation from "relay/mutations/iam/AssignRoleMutation";
import CreateUserMutation from "relay/mutations/iam/CreateUserMutation";
import AcceptInvitationMutation from "relay/mutations/iam/AcceptInvitationMutation";

import { UserCreateInput } from "relay/mutations/iam/__generated__/CreateUserMutation.graphql";
import { UserUpdateInput } from "relay/mutations/iam/__generated__/UpdateUserMutation.graphql";
import SetupUserMutation from "relay/mutations/iam/SetupUserMutation";
import VerifyTokenMutation from "relay/mutations/auth/VerifyTokenMutation";
import UpdateUserMutation from "relay/mutations/iam/UpdateUserMutation";
import SetIpAllowDenyListsMutation from "relay/mutations/iam/SetIpAllowDenyListsMutation";
import { SetIPAllowDenyListsInput } from "relay/mutations/iam/__generated__/SetIpAllowDenyListsMutation.graphql";
import { AccountMemberAcceptInvitationInput } from "relay/mutations/iam/__generated__/AcceptInvitationMutation.graphql";
import { AccountDetails } from "businessLogic/model";
import { BasicUser } from "pages/Users/User.interface";

export interface SubmitUserData {
  token: string;
  firstName: string;
  lastName: string;
  password: string;
}

export const IAMService = {
  getMyMemberships: (): any => {
    return UserQuery.fetch(environment).then(res => {
      const user = Object.assign({}, res?.me);
      const accountsMemberships = user.accountsMemberships?.edges.map(edge => {
        return Object.assign({}, edge?.node);
      });

      const mappedUser = {
        deleted: user.deleted,
        displayName: user.displayName,
        email: user.emailAddress,
        firstName: user.firstName,
        id: user.id,
        lastName: user.lastName,
        password: null,
        status: user.status,
      };

      return {
        user: mappedUser,
        accountMembers: accountsMemberships.map(accountsMembership => {
          return {
            assignedRole: accountsMembership.assignedRole,
            accountId: accountsMembership.accountId,
            accountTitle: accountsMembership.account.title,
            joinFlow: accountsMembership.joinFlow,
            reviewerUserId: accountsMembership.reviewerUserId,
            id: accountsMembership.id,
            user: mappedUser,
            status: accountsMembership.status,
          };
        }),
      };
    });
  },
  deleteMember: (accountId: string, memberId: string): any => {
    return new Promise((resolve, reject) => {
      DeleteMemberMutation.commit(
        environment,
        {
          id: memberId,
        },
        resolve,
        reject
      );
    });
  },
  updateMember: (accountId: string, memberId: string, roleId: string): any => {
    return new Promise((resolve, reject) => {
      AssignRoleMutation.commit(
        environment,
        {
          accountMemberId: memberId,
          resourceId: accountId,
          roleId: roleId,
        },
        resolve,
        reject
      );
    });
  },
  createAccountMember: (
    account_id: string,
    email: string,
    roleId: string,
    accessRoles: string[] = [],
    invitationType: string | null = null
  ): any => {
    return new Promise((resolve, reject) => {
      const input = {
        accountId: account_id,
        inviteeEmail: email,
        roleId: roleId,
        useAwsEmailTemplate: invitationType === "aws",
        accessRoles,
      };
      CreateAccountMembersMutation.commit(environment, input, resolve, reject);
    });
  },
  // mutation userCreate
  createUser: (accountDetails: AccountDetails, recaptcha): any => {
    const userInput: UserCreateInput = {
      email: accountDetails.email,
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      recaptchaChallenge: recaptcha,
      companyName: "", // TODO fill all the fields
      displayName: "",
      jobTitle: "",
      password: (accountDetails as any).password,
      phoneNumber: "",
    };
    return new Promise((resolve, reject) => {
      CreateUserMutation.commit(environment, userInput, resolve, reject);
    });
  },
  /// mutation userUpdate
  updateUser: (user: BasicUser): any => {
    const userInput: UserUpdateInput = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
    };
    return new Promise((resolve, reject) => {
      UpdateUserMutation.commit(environment, userInput, resolve, reject);
    });
  },
  updateUserNetwork: user => {
    const userInput: SetIPAllowDenyListsInput = {
      accountId: user.accountId,
      userId: user.id,
      ipAllowList: user.ipAllowList,
      ipDenyList: user.ipDenyList,
    };
    const updater = (store, response) => {
      if (!response.setIpAllowDenyLists?.success) {
        return;
      }
      const userRecord = store.get(userInput.userId);
      userRecord.setValue(user.ipAllowList, "ipAllowList");
      userRecord.setValue(user.ipDenyList, "ipDenyList");
    };
    return new Promise((resolve, reject) => {
      SetIpAllowDenyListsMutation.commit(
        environment,
        userInput,
        resolve,
        reject,
        updater
      );
    });
  },
  createUserFromAws: (
    accountDetails: AccountDetails,
    token,
    recaptcha
  ): any => {
    const userInput: UserCreateInput = {
      email: accountDetails.email,
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      recaptchaChallenge: recaptcha,
      awsMarketplaceToken: token,
      companyName: "",
      displayName: "",
      jobTitle: "",
      password: (accountDetails as any).password,
      phoneNumber: "",
    };
    return new Promise((resolve, reject) => {
      CreateUserMutation.commit(environment, userInput, resolve, reject);
    });
  },
  validateEmail: (email: string): any => {
    return new Promise((resolve, reject) => {
      SetupUserMutation.commit(environment, { email }, resolve, e => {
        reject(e);
      });
    });
  },

  validateInvitationToken: (token: string): any => {
    return new Promise((resolve, reject) => {
      const input = {
        token,
      };
      VerifyTokenMutation.commit(environment, input, resolve, reject);
    }).then(response => (response as any).authVerifyToken);
  },

  acceptInvitation: (token: string, accountId: string): any => {
    return new Promise((resolve, reject) => {
      AcceptInvitationMutation.commit(
        environment,
        {
          accountId,
          token,
        } as AccountMemberAcceptInvitationInput,
        resolve,
        reject
      );
    });
  },
  // will be remove, done within userSetup
  submitUser: (
    accountDetails: AccountDetails,
    invitationToken,
    recaptcha: string
  ): any => {
    const userInput: UserCreateInput = {
      email: accountDetails.email,
      firstName: accountDetails.firstName,
      lastName: accountDetails.lastName,
      recaptchaChallenge: recaptcha,
      companyName: "",
      displayName: accountDetails.firstName + " " + accountDetails.lastName,
      jobTitle: "",
      password: (accountDetails as any).password,
      phoneNumber: "",
      invitationToken: invitationToken,
    };
    return new Promise((resolve, reject) => {
      CreateUserMutation.commit(environment, userInput, resolve, reject);
    });
  },
};
