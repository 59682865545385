export const attachTestIdBreadcrumb = (breadcrumb, hint) => {
  const testId = hint?.event?.target?.dataset?.testid;
  const info = !testId
    ? {}
    : {
        data: { testId }
      };

  return { ...breadcrumb, ...info };
};

const graphqlRequest = /^(query|subscription|mutation)\s*(\w+)\s*[(|{]/;

export const attachGraphqlBreadcrumb = (breadcrumb, hint) => {
  const [url = "", params = {}] = hint?.input;
  if (url.includes("graphql")) {
    const body = JSON.parse(params?.body ?? "{}");
    const graphqlInfo = (body?.query ?? "").match(graphqlRequest);
    if (graphqlInfo && graphqlInfo.length === 3) {
      return {
        ...breadcrumb,
        ...{
          data: {
            ...breadcrumb.data,
            NAME: graphqlInfo[2]
          }
        }
      };
    }
  }

  return breadcrumb;
};
