import { useState, useRef, useEffect, useCallback } from "react";

const handleNestedContext = event => {
  event?.preventDefault();
};

export const useMenu = () => {
  const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
  const iconElement = useRef(null);

  const openMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuElement(event.currentTarget as HTMLElement);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuElement(null);
    document.removeEventListener("contextmenu", handleNestedContext);
  }, []);

  const openContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuElement(iconElement.current);
    document.addEventListener("contextmenu", handleNestedContext);
  }, []);

  useEffect(() => {
    document.removeEventListener("contextmenu", handleNestedContext);
  }, []);

  return {
    menuElement,
    iconElement,
    openMenu,
    openContextMenu,
    closeMenu,
  };
};
