import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAccountId } from "../common/hooks";
import DialogWithFetch from "./components/DialogWithFetch";
import { stateSelectorHandle } from "./redux/selectors";
import Dialog from "./components/Dialog";

const ConfirmationDialog = () => {
  const accountId = useAccountId();
  const stateSelector = useCallback(stateSelectorHandle, []);
  const { confirmationModal } = useSelector(stateSelector);
  const { actionType, entity, entityId } = confirmationModal;

  if (!actionType) return null;

  return entityId ? (
    <DialogWithFetch
      entityId={entityId}
      accountId={accountId}
    />
  ) : (
    <Dialog
      entity={entity}
      accountId={accountId}
    />
  );
};

export default ConfirmationDialog;
