import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { deletingEngineIds } from "index";

const mutation = graphql`
  mutation RemoveEngineMutation($input: EngineDeleteInput!) {
    engineDelete(input: $input) {
      success
      engine {
        id
        currentStatusSummary
        ...EngineRow_engine
        ...EngineModal_engine
      }
    }
  }
`;

function commit(environment, engineId, accountId, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError: err => {
      deletingEngineIds?.delete(engineId);
      onError(err);
    },
    variables: {
      input: { id: engineId },
    },
    optimisticUpdater: store => {
      const engine = store.get(engineId);

      if (engine) {
        const currentStatusSummary = engine.getValue("currentStatusSummary");

        const newStatus =
          currentStatusSummary === "RUNNING" ? "STOPPING" : "DELETING";

        deletingEngineIds?.set(engineId, newStatus);

        engine.setValue(newStatus, "currentStatusSummary");
      }
    },
    updater: (store, data: any) => {
      if (data?.engineDelete.success === false) {
        deletingEngineIds?.delete(engineId);
      }
    },
  });
}

export default { commit };
