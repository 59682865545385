import * as SystemEngineActions from "../actions/systemEngine";

const INITIAL_STATE = {
  systemEngine: null,
  systemEngineLoading: true,
  systemEngineLoadingError: null,
};

const systemEngineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SystemEngineActions.FETCH_SYSTEM_ENGINE: {
      return {
        ...state,
        systemEngine: null,
        systemEngineLoading: true,
      };
    }
    case SystemEngineActions.FETCH_SYSTEM_ENGINE_SUCCESS: {
      return {
        ...state,
        systemEngine: action.payload,
        systemEngineLoading: false,
      };
    }

    case SystemEngineActions.FETCH_SYSTEM_ENGINE_FAILURE: {
      return {
        ...state,
        systemEngine: null,
        systemEngineLoadingError: "This is system engine error",
        systemEngineLoading: false,
      };
    }

    case SystemEngineActions.CLEAR_SYSTEM_ENGINE: {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};

export default systemEngineReducer;
