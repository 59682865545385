import React, { useCallback, useContext, useState } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { TooltipProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as GeneralPurpose } from "assets/icons/instanceCategories/GeneralPurpose.svg";
import { ReactComponent as DataAnalytics } from "assets/icons/instanceCategories/DataAnalytics.svg";
import { ReactComponent as SystemEngine } from "assets/icons/instanceCategories/SystemEngine.svg";
import { ReactComponent as CheckIcon } from "assets/icons/CheckIcon.svg";
import { ReactComponent as RamIcon } from "assets/icons/instanceTypes/Ram.svg";
import { ReactComponent as StorageIcon } from "assets/icons/instanceTypes/SSD.svg";
import { ReactComponent as CPUIcon } from "assets/icons/instanceTypes/Cpu.svg";
import { Formatter } from "businessLogic/helpers/Format";
import Label from "components/Label/Label";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";
import ListView, { WrapOptions } from "components/Table/ListView/ListView";
import { SettingsPreset } from "components/EngineForm/constants";
import CustomTooltip from "components/Tooltip";
import { highlightText } from "components/common/helpers";
import withGraphqlSubscriptions, {
  SubscriptionProps,
} from "components/common/withGraphqlSubscriptions";
import EngineStatusSummaryChangedSubscription from "relay/subscriptions/engine/EngineStatusSummaryChangedSubscription";
import {
  getUiEngineStatus,
  getUiEngineStatusText,
} from "components/EngineStatusIcon/helpers";
import { selectUserAccountAllowedActions } from "redux/user/selectors";
import { EngineActions } from "../constants";
import { EngineStatusChanged } from "../EnginesList";
import EngineActionsPromptPanel, {
  PromptConfiguration,
} from "components/EngineActionsPromptPanel/EngineActionsPromptPanel";
import {
  CopyButton,
  DeleteEngineButton,
  EditEngineButton,
  MakeDefaultEngineButton,
  RestartEngineButton,
  StartEngineButton,
  StopEngineButton,
} from "components/EngineActionsPromptPanel/Actions";
import EngineStatus from "./EngineStatus";
import engineActionsStyles from "../../EngineActionsPromptPanel/index.module.scss";
import styles from "./EngineItem.module.scss";
import { AccessManagerContext } from "../../App/App";
import { GQLRbacaction } from "../../../graphql.types";

export interface EngineItemType {
  isSpot: any;
  scale: any;
  instanceTypeIcon: JSX.Element;
  settingsPreset: any;
  endpoint?: any;
  id: string;
  status?: UIEngineStatus;
  name: string;
  engineScale: number;
  engineCost: number;
  description: string;
  settingPreset: string;
  isSpotInstance: boolean;
  latestRevision?: any;
  isDefault?: boolean;
}

export interface EngineItemClassNames {
  engineItem?: string;
  engineSpecItem?: string;
  totalItem?: string;
  ram?: string;
  cpu?: string;
  storage?: string;
  price?: string;
}

export interface IEngineListItem {
  engines?: EngineItemType[];
  dbName?: string;
  dbId?: string;
  classes?: EngineItemClassNames;
  hiddenActions?: EngineActions[];
  engineItem: any;
  statusTooltipProps?: any;
  selected?: boolean;
  onSelect?: () => void;
  isDefault: boolean;
  active?: boolean;
  skipDeleteInlineConfirmation?: boolean;
  readOnly?: boolean;
  searchWord?: string;
  sqlwMode?: boolean;
  tooltipLists?: any[];
  placement?: TooltipProps["placement"];
  onClickDefault?: () => any;
  onClickStop?: () => any;
  onClickStart?: () => any;
  onClickDelete?: () => void;
  onClickRestart?: () => void;
  onClickEdit?: () => void;
  onEngineStatusChanged?: EngineStatusChanged;
  disabled?: boolean;
  accountId?: string;
  closeDropdown?: () => void;
}

interface ReduxProps {
  accountAllowedActions: string[];
}

const presetIconMap = {
  [SettingsPreset.GeneralPurpose]: <GeneralPurpose />,
  [SettingsPreset.DataAnalytics]: <DataAnalytics />,
};

const EngineListItem = (props: IEngineListItem & ReduxProps) => {
  const {
    dbName,
    dbId,
    classes,
    hiddenActions,
    active,
    selected,
    onSelect,
    engineItem,
    tooltipLists,
    placement,
    onClickDefault,
    onClickStop,
    onClickStart,
    onClickDelete,
    onClickRestart,
    onClickEdit,
    isDefault,
    skipDeleteInlineConfirmation,
    readOnly,
    accountAllowedActions,
    searchWord,
    sqlwMode = false,
    statusTooltipProps,
    engines,
    disabled,
    closeDropdown,
  } = props;

  const { t } = useTranslation();
  const { isAllowedTo } = useContext(AccessManagerContext);
  const [hovered, setHoverState] = useState(false);

  const [promptConfiguration, setPromptConfiguration] =
    useState<PromptConfiguration | null>(null);

  const isSystemEngine = engineItem.isSystem;

  const engineItemClassNames = cn(styles.engineItem, classes?.engineItem, {
    [styles.active]: active,
    [styles.readOnly]: readOnly,
    [styles.clickable]: !!onSelect,
    [styles.sqlwMode]: sqlwMode,
    [styles.disabled]: disabled,
    [styles.systemEngine]: isSystemEngine,
  });

  const actionWrapper = React.useCallback(
    action =>
      (...params) => {
        setHoverState(false);
        closeDropdown && closeDropdown();
        return action?.(...params);
      },
    [setHoverState]
  );

  const isAllowedToDropEngine = useCallback(() => {
    const dropEngineAllowed = isAllowedTo(
      engineItem.id,
      GQLRbacaction.ENGINE_DROP
    );
    if (isDefault) {
      return dbId
        ? isAllowedTo(dbId, GQLRbacaction.DATABASE_MODIFY) && dropEngineAllowed
        : false;
    }

    return dropEngineAllowed;
  }, [engineItem.id, isDefault, dbId, isAllowedTo]);

  const component = (
    <div
      className={engineItemClassNames}
      onClick={() => {
        return onSelect && !disabled && onSelect();
      }}
      onMouseOver={() =>
        !readOnly && !hovered && !disabled && setHoverState(true)
      }
      onMouseLeave={() => !readOnly && !disabled && setHoverState(false)}
      data-testid={`engine-item-${engineItem.id ?? engineItem.name}`}
    >
      <ListView
        direction="vertical"
        spacingPx={0}
      >
        <div className={styles.nameAndActions}>
          {isSystemEngine && (
            <div className={styles.specCol}>
              <div
                className={cn(styles.iconContainer, styles.systemEngineIcon)}
                data-testid="engine-item-systemEngineIconWrap"
              >
                <SystemEngine />
              </div>
            </div>
          )}
          <div className={styles.nameContainer}>
            <div
              className={cn(styles.engineName, {
                [styles.default]: isDefault,
                [styles.disabled]: disabled,
              })}
            >
              {!engineItem.id && dbName && (
                <div className={styles.namePrefix}>{dbName}_</div>
              )}
              <div
                data-testid="engine-item-name"
                className={styles.name}
              >
                {highlightText({
                  searchWord,
                  textToHighlight: engineItem.name,
                })}
              </div>
            </div>
            {isDefault && (
              <Label
                data-testid="engine-item-default"
                bordered={true}
                className={styles.defaultLabel}
              >
                {t("engines.default")}
              </Label>
            )}
          </div>
          {!isSystemEngine && (
            <EngineActionsPromptPanel
              configuration={promptConfiguration}
              promptTestId="engine-item-prompt-delete"
              hovered={hovered}
            >
              <>
                <div className={styles.actionsCol}>
                  {!hiddenActions?.includes(EngineActions.Edit) &&
                    isAllowedTo(engineItem.id, GQLRbacaction.ENGINE_MODIFY) && (
                      <EditEngineButton
                        hovered={hovered}
                        engine={engineItem}
                        onClick={actionWrapper(onClickEdit)}
                        accountAllowedActions={accountAllowedActions}
                      />
                    )}

                  {!hiddenActions?.includes(EngineActions.Restart) &&
                    isAllowedTo(
                      engineItem.id,
                      GQLRbacaction.ENGINE_OPERATE
                    ) && (
                      <RestartEngineButton
                        engines={engines || []}
                        hovered={hovered}
                        engine={engineItem}
                        onClick={actionWrapper(onClickRestart)}
                        accountAllowedActions={accountAllowedActions}
                      />
                    )}

                  {!hiddenActions?.includes(EngineActions.CopyEndpoint) && (
                    <CopyButton
                      hovered={hovered}
                      text={engineItem.endpoint}
                      dataTestId="engine-item-copy-endpoint"
                    />
                  )}
                </div>

                <div
                  className={cn(
                    styles.actionsCol,
                    engineActionsStyles.actionBtn
                  )}
                >
                  {!hiddenActions?.includes(EngineActions.Delete) &&
                    (engineItem.id ? isAllowedToDropEngine() : true) && (
                      <DeleteEngineButton
                        hovered={hovered}
                        engine={engineItem}
                        onClick={e => {
                          e.stopPropagation();
                          if (skipDeleteInlineConfirmation) {
                            return actionWrapper(onClickDelete)();
                          } else {
                            setPromptConfiguration({
                              action: actionWrapper(
                                onClickDelete || (() => null)
                              ),
                              confirmProps: {
                                dataTestid: "confirm-delete-engine",
                              },
                              rejectProps: {
                                dataTestid: "cancel-delete-engine",
                              },
                            });
                          }
                        }}
                        accountAllowedActions={accountAllowedActions}
                      />
                    )}

                  {!hiddenActions?.includes(EngineActions.MakeDefault) &&
                    (dbId
                      ? isAllowedTo(dbId, GQLRbacaction.DATABASE_MODIFY)
                      : true) &&
                    engineItem.status !== UIEngineStatus.STATUS_DROPPING && (
                      <MakeDefaultEngineButton
                        hovered={hovered}
                        engine={engineItem}
                        onClick={actionWrapper(onClickDefault)}
                        accountAllowedActions={accountAllowedActions}
                      />
                    )}
                </div>

                <div
                  className={cn(
                    styles.actionsCol,
                    engineActionsStyles.actionBtn
                  )}
                >
                  {!hiddenActions?.includes(EngineActions.Start) &&
                    isAllowedTo(
                      engineItem.id,
                      GQLRbacaction.ENGINE_OPERATE
                    ) && (
                      <StartEngineButton
                        engines={engines || []}
                        hovered={hovered}
                        engine={engineItem}
                        onClick={actionWrapper(onClickStart)}
                        accountAllowedActions={accountAllowedActions}
                      />
                    )}
                  {!hiddenActions?.includes(EngineActions.Stop) &&
                    isAllowedTo(
                      engineItem.id,
                      GQLRbacaction.ENGINE_OPERATE
                    ) && (
                      <StopEngineButton
                        hovered={hovered}
                        engine={engineItem}
                        onClick={actionWrapper(onClickStop)}
                        accountAllowedActions={accountAllowedActions}
                      />
                    )}
                </div>
              </>
            </EngineActionsPromptPanel>
          )}
        </div>
        {!isSystemEngine && (
          <ListView
            direction="horizontal"
            alignCenter
            wrap={WrapOptions.wrap}
          >
            <div
              className={cn(styles.engineSpecs, {
                [styles.disabled]: disabled,
              })}
            >
              <div className={styles.specCol}>
                <div
                  className={cn(
                    styles.iconContainer,
                    styles.specItem,
                    classes?.engineSpecItem
                  )}
                  data-testid="engine-item-settingsPreset"
                >
                  {presetIconMap[engineItem.settingsPreset]}
                </div>

                <div
                  className={cn(
                    styles.specItem,
                    styles.text,
                    styles.cpu,
                    classes?.engineSpecItem,
                    classes?.cpu
                  )}
                  data-testid="engine-item-cpu"
                >
                  <div className={styles.iconContainer}>
                    <CPUIcon />
                  </div>
                  {Formatter.formatNumberToLocale(
                    String(engineItem.instanceType.cores),
                    0
                  )}
                </div>
              </div>
              <div className={styles.specCol}>
                <div
                  className={cn(
                    styles.specItem,
                    styles.text,
                    styles.ram,
                    classes?.engineSpecItem,
                    classes?.ram
                  )}
                  data-testid="engine-item-ram"
                >
                  <div className={styles.iconContainer}>
                    <RamIcon />
                  </div>
                  {
                    Formatter.bytesFormatter(engineItem.instanceType.ram)
                      .formattedString
                  }
                </div>
                <div
                  className={cn(
                    styles.specItem,
                    styles.text,
                    styles.storage,
                    classes?.engineSpecItem,
                    classes?.storage
                  )}
                  data-testid="engine-item-storage"
                >
                  <div className={styles.iconContainer}>
                    <StorageIcon />
                  </div>
                  {
                    Formatter.bytesFormatter(engineItem.instanceType.storage)
                      .formattedString
                  }
                </div>
              </div>
            </div>
            <div className={cn(styles.totals, { [styles.disabled]: disabled })}>
              <EngineStatus
                status={engineItem.status}
                statusText={engineItem.statusText}
                tooltipProps={statusTooltipProps}
              />
              <div
                className={cn(
                  styles.text,
                  styles.totalItem,
                  styles.price,
                  classes?.totalItem,
                  classes?.price
                )}
              >
                $
                {Formatter.formatNumberToLocale(
                  engineItem.engineCost.toFixed(2),
                  2,
                  2
                )}
                /{t("engines.hour")}
              </div>
              {engineItem.isSpot && (
                <div
                  className={cn(
                    styles.text,
                    styles.totalItem,
                    classes?.totalItem
                  )}
                  data-testid="engine-item-spot"
                >
                  {t("engines.spot")}
                </div>
              )}
            </div>
          </ListView>
        )}
        {isSystemEngine && (
          <div className={styles.systemEngineDescription}></div>
        )}
        {!!selected && (
          <CheckIcon
            data-testid="engine-item-selected-icon"
            className={cn(styles.checkIcon)}
          />
        )}
      </ListView>
    </div>
  );

  if (tooltipLists) {
    return (
      <CustomTooltip
        placement={placement ?? "right"}
        title=""
        lists={tooltipLists}
        enterDelay={300}
        TransitionProps={{ timeout: { exit: 350 } }}
        open={hovered}
      >
        <div>{component}</div>
      </CustomTooltip>
    );
  }

  return component;
};

const mapStateToProps = state => ({
  accountAllowedActions: selectUserAccountAllowedActions(state),
});

export default withGraphqlSubscriptions<typeof EngineListItem, IEngineListItem>(
  connect(mapStateToProps)(EngineListItem),
  ({ engineItem, onEngineStatusChanged }): SubscriptionProps[] => {
    const subscriptions: SubscriptionProps[] = [];
    if (!engineItem || !engineItem.id || engineItem?.isSystem) {
      return subscriptions;
    }

    subscriptions.push({
      subscription: EngineStatusSummaryChangedSubscription,
      getVariables: props => {
        return { id: engineItem.id, accountId: props.accountId };
      },
      onNext: response => {
        onEngineStatusChanged?.(
          engineItem.id,
          getUiEngineStatus(
            response.engineStatusSummaryChanged.currentStatusSummary
          ),
          getUiEngineStatusText(
            response.engineStatusSummaryChanged.currentStatusSummary
          )
        );
      },
    });

    return subscriptions;
  }
);
