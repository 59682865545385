import { all, put, select, call, takeEvery, delay } from "redux-saga/effects";
import { MembersActions } from "./actions";
import {
  putStatusMessage,
  removeStatusMessage,
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import { IAMService } from "businessLogic/services/iam";
import { selectUserAccountId } from "redux/user/selectors";
import environment from "relay/environment";
import Role from "relay/queries/role/Role";
import { USER_ROLE_NAMES_MAP } from "pages/Users/User.constant";

function* createMemberSaga(invitation, accountId, type) {
  const roleName = USER_ROLE_NAMES_MAP[invitation.role];

  const roleId = yield call(() => {
    return Role.fetch(environment).then(response => {
      return response.roles.edges.find(edge => {
        return edge.node.displayName === roleName;
      }).node.id;
    });
  });

  yield IAMService.createAccountMember(
    accountId,
    invitation.email,
    roleId,
    invitation.roles,
    type
  );
}

function* createMembersSaga(action) {
  const { invitations, type, resolve } = action.payload;

  const progressMessagesId = "user-invitation-progress";
  const accountId = yield select(selectUserAccountId);

  let successCounter = 0;
  let failuresCounter = 0;
  let requestsSent = 0;

  for (let invitation of invitations) {
    try {
      requestsSent++;

      yield put(
        putStatusMessage(
          `Inviting ${requestsSent}/${invitations.length} users`,
          StatusMessageType.Loading,
          {
            id: progressMessagesId,
            insertToPosition: StatusMessagePosition.Top,
            autoRemove: false,
            preventDeletionOnReset: true,
          }
        )
      );
      yield call(createMemberSaga, invitation, accountId, type);
      successCounter++;
    } catch (err) {
      failuresCounter++;
    }
  }

  yield delay(5000); // Allow backend update permissions cache

  yield put(removeStatusMessage(progressMessagesId));

  if (successCounter) {
    yield put(
      putStatusMessage(
        `${successCounter} users invited`,
        StatusMessageType.Success,
        {
          id: null,
          insertToPosition: StatusMessagePosition.Bottom,
          autoRemove: false,
        }
      )
    );
  }

  if (failuresCounter) {
    yield put(
      putStatusMessage(
        `Failed to invite ${failuresCounter} users`,
        StatusMessageType.Error,
        {
          id: null,
          insertToPosition: StatusMessagePosition.Bottom,
          autoRemove: false,
        }
      )
    );
  }

  resolve();
}

function* verifyInvitationTokenSaga(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const data = yield IAMService.validateInvitationToken(token);

    if (!data.success) {
      throw new Error("Token is not valid");
    }

    if (data.token.used) {
      throw new Error("Invitation link has already been used");
    }

    if (new Date(data.token.expiresAt) < new Date()) {
      throw new Error("Invitation link has expired");
    }

    resolve(data);
  } catch (err) {
    reject(typeof err === "string" ? err : err.message);
  }
}

export function* MembersSagas() {
  return yield all([
    takeEvery(MembersActions.CREATE_MEMBERS, createMembersSaga),
    takeEvery(
      MembersActions.VERIFY_INVITATION_TOKEN,
      verifyInvitationTokenSaga
    ),
  ]);
}
