/**
 * @generated SignedSource<<847540113c0b4718c43a913ba48e4ed7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Emoji = "UNSPECIFIED" | "KICK_SCOOTER" | "BULLET_TRAIN" | "ROCKET" | "TRACTOR" | "AUTOMOBILE" | "SPEEDBOAT" | "SAILBOAT" | "CANOE" | "LOCOMOTIVE" | "BICYCLE" | "DELIVERY_TRUCK" | "PASSENGER_SHIP" | "RACING_CAR" | "HELICOPTER" | "MINIBUS" | "POLICE_CAR" | "AERIAL_TRAMWAY" | "MOTOR_SCOOTER" | "ONCOMING_AUTOMOBILE" | "SMALL_AIRPLANE" | "GEM_STONE" | "CREDIT_CARD" | "MONEY_BAG" | "MOBILE_PHONE" | "BRIEFCASE" | "CROWN" | "GRADUATION_CAP" | "SKULL" | "JACK_O_LANTERN" | "SMILING_FACE_WITH_HORNS" | "FACE_SCREAMING_IN_FEAR" | "FIRE" | "ALIEN" | "ALIEN_MONSTER" | "ROBOT" | "MOVIE_CAMERA" | "%future added value";
export type EngineSettingsPreset = "UNSPECIFIED" | "GENERAL_PURPOSE" | "DATA_ANALYTICS" | "%future added value";
export type EngineSettingsWarmUpLevel = "UNSPECIFIED" | "MINIMAL" | "INDEXES" | "ALL" | "SHARD_ON_READ" | "%future added value";
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineQuery$variables = {
  engineId: string;
  accountId: string;
};
export type EngineQuery$data = {
  readonly engine: {
    readonly id: string;
    readonly name: string;
    readonly emoji: Emoji;
    readonly computeRegion: {
      readonly id: string;
      readonly name: string;
    };
    readonly currentStatusSummary: EngineStatusSummary;
    readonly latestRevision: {
      readonly id: string;
      readonly specification: {
        readonly dbComputeInstancesCount: number;
        readonly dbComputeInstancesUseSpot: boolean;
        readonly dbComputeInstancesType: {
          readonly id: string;
          readonly pricePerHourCents: number;
          readonly name: string;
          readonly cpuVirtualCoresCount: number;
          readonly memorySizeBytes: number;
          readonly storageSizeBytes: number;
        };
      };
    };
    readonly settingsPreset: EngineSettingsPreset;
    readonly settings: {
      readonly autoStopDelayDurationSeconds: number;
      readonly warmUpLevel: EngineSettingsWarmUpLevel;
    };
    readonly bindings: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly engineIsDefault: boolean;
        };
      } | null> | null;
    };
  };
};
export type EngineQuery = {
  variables: EngineQuery$variables;
  response: EngineQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engineId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engineId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "computeRegion",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentStatusSummary",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EngineRevision",
  "kind": "LinkedField",
  "name": "latestRevision",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngineRevisionSpecification",
      "kind": "LinkedField",
      "name": "specification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbComputeInstancesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbComputeInstancesUseSpot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InstanceType",
          "kind": "LinkedField",
          "name": "dbComputeInstancesType",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "accountId",
                  "variableName": "accountId"
                }
              ],
              "kind": "ScalarField",
              "name": "pricePerHourCents",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cpuVirtualCoresCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "memorySizeBytes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "storageSizeBytes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingsPreset",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "EngineSettings",
  "kind": "LinkedField",
  "name": "settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoStopDelayDurationSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warmUpLevel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engineIsDefault",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EngineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Engine",
        "kind": "LinkedField",
        "name": "engine",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingConnection",
            "kind": "LinkedField",
            "name": "bindings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Binding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EngineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Engine",
        "kind": "LinkedField",
        "name": "engine",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingConnection",
            "kind": "LinkedField",
            "name": "bindings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Binding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6676612586a2a976cb01188f03a1cf73",
    "id": null,
    "metadata": {},
    "name": "EngineQuery",
    "operationKind": "query",
    "text": "query EngineQuery(\n  $engineId: ID!\n  $accountId: ID!\n) {\n  engine(id: $engineId) {\n    id\n    name\n    emoji\n    computeRegion {\n      id\n      name\n    }\n    currentStatusSummary\n    latestRevision {\n      id\n      specification {\n        dbComputeInstancesCount\n        dbComputeInstancesUseSpot\n        dbComputeInstancesType {\n          id\n          pricePerHourCents(accountId: $accountId)\n          name\n          cpuVirtualCoresCount\n          memorySizeBytes\n          storageSizeBytes\n        }\n      }\n    }\n    settingsPreset\n    settings {\n      autoStopDelayDurationSeconds\n      warmUpLevel\n    }\n    bindings {\n      edges {\n        node {\n          engineIsDefault\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79d2be2d91271d21e16e0e652938ad0b";

export default node;
