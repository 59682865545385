/**
 * @generated SignedSource<<2b4cc75b0ea03988b7293b4f507fcf27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountAwsQuery$variables = {
  id: string;
};
export type AccountAwsQuery$data = {
  readonly account: {
    readonly awsMonthToDateCosts: {
      readonly ec2Costs: number;
      readonly s3Costs: number;
      readonly storageInTb: number;
      readonly additionalUsageFees: number;
    };
  };
};
export type AccountAwsQuery = {
  variables: AccountAwsQuery$variables;
  response: AccountAwsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "MonthToDateCosts",
  "kind": "LinkedField",
  "name": "awsMonthToDateCosts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ec2Costs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "s3Costs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storageInTb",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalUsageFees",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountAwsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountAwsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98fcb509e84a777583b6defdd3b62f97",
    "id": null,
    "metadata": {},
    "name": "AccountAwsQuery",
    "operationKind": "query",
    "text": "query AccountAwsQuery(\n  $id: ID!\n) {\n  account(id: $id) {\n    awsMonthToDateCosts {\n      ec2Costs\n      s3Costs\n      storageInTb\n      additionalUsageFees\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "caf0253681d2ddd1e5d8d8eb4ce6dd7e";

export default node;
