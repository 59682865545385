import i18n from "i18next";
import * as QueryActions from "../actions/queries";
import * as _ from "lodash";
import axios from "axios";
import { Action } from "redux-actions";
import {
  all,
  delay,
  put,
  call,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  cancelQuery,
  getQueryProgress,
  getQueryStatus,
} from "businessLogic/services/query";
import {
  executeQuery,
  getAsyncQueryResult,
} from "businessLogic/services/query/execute";
import {
  canAffectEnginesList,
  getQueryList,
} from "businessLogic/helpers/Query";
import { parseBool } from "businessLogic/helpers/Format";
import {
  ClientSideQueryStatuses,
  QueryStatus,
  ServerQueryStatusesV1,
} from "../../constants/queries";
import {
  putStatusMessage,
  StatusMessageType,
} from "../../../StatusMessage/redux/actions";
import { ProgressInfoInput } from "../QueryEditor.interfaces";
import { DatabaseService } from "businessLogic/services/database";
import {
  canUseQueryHybridFlag,
  getStatusKeyByProxyVersion,
  Version,
} from "businessLogic/helpers/Version";
import { setEngines } from "pages/Database/redux/actions";
import { selectUserAccountId } from "redux/user/selectors";
import { FireboltGlobals } from "services/globals";
import { isHybridEnabled } from "featureFlags/helpers";
import {
  isQueryStatementRunning,
  parseQuerySetting,
} from "../../utils/queries";
import { getDBEndpoint } from "./getDBEndpoint";
import {
  getBrowserTabId,
  getDatabases,
  getQueries,
  getQuery,
  getEngineProxyVersion,
  getAccountFlags,
  getDatabaseQueries,
  getDatabaseQueriesResumedFlag,
  getCurrentQueryTabId,
  getDatabaseQueryStatementResumedFlag,
} from "redux/query/selectors";
import { QUERY_SETTINGS } from "./constants";
import {
  formatQueryStatusType,
  formatQueryStatusStatistics,
  withQuerySettingsForExecute,
  getServerQueryid,
  ansiRegex,
} from "./helpers";
import { UserScriptsAction } from "../actions/userScripts";
import { UserScript } from "../../constants/interfaces";
import {
  closeTabs,
  queryResumedInBackground,
  queryStatementResumedInBackground,
  resumeQueryInBackground,
} from "../actions/queries";
import { getAllScripts } from "../../utils/scripts";
import { QueryStatementInterface } from "../types";

export let runningQueries = {};
let closingScripts = {};
const hybridStatements = {};
/* fetchingResultsStatementIds is used to fix race conditions on calling the response endpoint
   when browsing different pages of the application */
const fetchingResultsStatementIds = {};

export const getQueryFromServerQueryId = (state, database, serverQueryId) => {
  const queries = _.get(
    state,
    `query.queries.databasesQueries[${database}].queriesById`
  );

  return _.find(queries, { serverQueryId });
};

export function isAsyncQuery({
  querySettings,
  serverQueryId,
  queryStatementState,
}: {
  querySettings: any;
  serverQueryId?: string;
  queryStatementState?: any;
}) {
  return (
    parseBool(_.get(querySettings, QUERY_SETTINGS.asyncExecution, false)) ||
    isHybridStatement(serverQueryId) ||
    queryStatementState?.isAsync
  );
}

function isHybridStatement(serverQueryId) {
  return !!_.get(hybridStatements, serverQueryId, false);
}

export function shouldFetchAsyncResults({
  query,
  accountFlags,
  engineProxyVersion,
}: {
  query: any;
  accountFlags?: any;
  engineProxyVersion: Version;
}): boolean {
  if (_.isEmpty(query?.multiQueryStatement)) return false;

  const queryStatement: QueryStatementInterface | undefined = _.last(
    query?.multiQueryStatement
  );
  return (
    queryStatement?.status !== QueryStatus.Canceled &&
    isHybridEnabled(accountFlags) &&
    canUseQueryHybridFlag(engineProxyVersion)
  );
}

export function getQueryToExecute(query) {
  return query.requestedQuery || query.query;
}

export function* startQuery(database, queryId, querySettings) {
  if (isAsyncQuery({ querySettings })) {
    yield endQuery(database, queryId);
    return;
  }

  const dbQueries = runningQueries?.[database] ?? {};
  runningQueries = {
    ...runningQueries,
    [database]: {
      ...dbQueries,
      [queryId]: true,
    },
  };
}

function getClientRunningQuery(databaseName, queryId) {
  return runningQueries?.[databaseName]?.[queryId];
}

export function endQuery(database, queryId) {
  const qIds = [queryId];

  runningQueries = {
    ...runningQueries,
    [database]: {
      ..._.omit(runningQueries?.[database], qIds),
    },
  };
}

export function isQueryRunning(database, queryId) {
  const qIds = [queryId];

  const running = _.pick(runningQueries[database], qIds);

  return !_.isEmpty(running);
}

export function* executeQuerySaga(
  action: Action<{
    database: string;
    query: string;
    queryId: string;
    resumeFromQueryStatementId: number;
  }>
) {
  const { database, query, resumeFromQueryStatementId } = action.payload;
  const queryId = action.payload.queryId;
  const querySqlStatements = yield getQueryList(query);

  if (querySqlStatements?.error) {
    yield put(
      putStatusMessage(
        i18n.t("errors.editor_range") as string,
        StatusMessageType.Error
      )
    );
    return;
  }

  const engineEndpoint = yield getDBEndpoint(database, queryId);
  let originalQueryState = yield select(getQuery, database, queryId);
  const browserTabId = yield select(getBrowserTabId);

  // There is no running engine to execute the query
  if (!engineEndpoint) return;

  let results;

  // used in case multi query is resumed
  let i = resumeFromQueryStatementId ?? 0;

  let execQueryAsync = false;

  const statementToExecute = yield getQueryStatement(database, queryId, i);

  if (
    // query was already resumed and the saga was called again due to a race condition
    statementToExecute
  )
    return;

  const dbList = yield select(getDatabases);

  const engineId = originalQueryState.engineId;
  const db = _.find(dbList, { name: database });
  const engine = _.find(db.engines, e => e.id === engineId);

  try {
    yield put({
      type: QueryActions.SAVE_QUERY_STATEMENTS,
      payload: {
        database,
        queryId,
        querySqlStatements,
      },
    });

    for (i; i < querySqlStatements.length; i++) {
      const sqlText = querySqlStatements[i];

      yield put({
        type: QueryActions.EXECUTE_QUERY_STATEMENT_REQUEST,
        payload: { query: sqlText, database, queryId, queryStatementId: i },
      });

      // super ugly `SET` support on client side (to revisit)
      if (sqlText.toLowerCase().startsWith("set") && sqlText.indexOf("=") > 0) {
        const settings = parseQuerySetting(sqlText);

        const validQuery = `
--Validating SET ${settings.key} = ${settings.value}
SELECT 1`;
        const queryId = action.payload.queryId;
        originalQueryState = yield select(getQuery, database, queryId);
        const engineProxyVersion = yield select(
          getEngineProxyVersion,
          originalQueryState?.engineId
        );
        const querySettingsState = originalQueryState.settings;
        const querySettings = yield withQuerySettingsForExecute({
          engineId: originalQueryState?.engineId,
          settings: {
            ..._.pick(querySettingsState, [QUERY_SETTINGS.advancedMode]),
            [settings.key]: settings.value,
            [QUERY_SETTINGS.noProgress]: true,
          },
        });

        execQueryAsync = false;
        yield startQuery(database, queryId, querySettings);
        results = yield executeQuery({
          query: validQuery,
          database,
          queryId,
          engineEndpoint,
          querySettings, // set queries should always be sync
          browserTabId,
          engineProxyVersion,
          engine,
        });

        // checking if script was closed while query was running
        const queryAfterCall = yield select(getQuery, database, queryId);
        if (!queryAfterCall) {
          yield cleanupAfterScriptClose(database, queryId);
          return;
        }

        yield queryExecutionSuccess({
          query: sqlText as string,
          database,
          queryId: action.payload.queryId,
          queryStatementId: i,
          results: { queryStatementId: i },
          isLastQueryStatement: i === querySqlStatements.length - 1,
        });

        yield put(QueryActions.addQuerySettings(database, queryId, settings));
      } else {
        originalQueryState = yield select(getQuery, database, queryId);
        const engineProxyVersion = yield select(
          getEngineProxyVersion,
          originalQueryState?.engineId
        );
        const accountFlags = yield select(getAccountFlags);
        const querySettings = yield withQuerySettingsForExecute({
          engineId: originalQueryState?.engineId,
          settings: originalQueryState.settings,
          queryText: sqlText,
        });

        execQueryAsync = isAsyncQuery({ querySettings });
        yield startQuery(database, queryId, querySettings);
        results = yield call(executeQuery, {
          query: sqlText,
          database,
          queryId,
          engineEndpoint,
          querySettings,
          browserTabId,
          engineProxyVersion,
          accountFlags,
          engine,
        });
        results = {
          ...results,
          queryStatementId: i,
        };

        // checking if script was closed while query was running
        const queryAfterCall = yield select(getQuery, database, queryId);
        // the script could be deleted, but a new one could get its id, while the query is running
        if (!queryAfterCall) {
          yield cleanupAfterScriptClose(database, queryId);
          return;
        }

        if (
          !execQueryAsync &&
          results.responseStatusCode === 201 &&
          results.query_id
        ) {
          hybridStatements[results.query_id] = true;
          execQueryAsync = isAsyncQuery({
            querySettings,
            serverQueryId: results.query_id,
          });
          yield endQuery(database, queryId);
        }
        yield queryExecutionSuccess({
          query: sqlText,
          database,
          queryId: action.payload.queryId,
          queryStatementId: i,
          results: results && typeof results === "object" ? results : undefined,
          querySettings,
          isLastQueryStatement: i === querySqlStatements.length - 1,
        });
      }
      if (execQueryAsync) return;
    }
  } catch (error) {
    // some browsers throw an error when we refresh the page and a request is in progress
    if (FireboltGlobals.ignoreBrowserUnloadingError()) return;
    if (axios.isCancel(error)) {
      if (!closingScripts[queryId]) {
        yield queryExecutionCanceled({ query, database, queryId });
      }
      return;
    } else {
      const queryAfterCall = yield select(getQuery, database, queryId);
      // the script could be deleted, but a new one could get its id, while the query is running
      if (
        !queryAfterCall ||
        queryAfterCall.query !== originalQueryState.query
      ) {
        yield cleanupAfterScriptClose(database, queryId);
      } else {
        yield handleQueryErrors({
          database,
          query: queryAfterCall,
          queryStatementId: i,
          originalError: error,
        });
      }
    }
  } finally {
    if (execQueryAsync) return;
    if (FireboltGlobals.ignoreBrowserUnloadingError()) return;

    if (!closingScripts[queryId]) {
      yield clearTempQueryInfo(database, queryId);
      delete closingScripts[queryId];
    }
    yield endQuery(database, queryId);
  }
}

export function* updateDbEnginesList(dbId) {
  const accountId = yield select(selectUserAccountId);
  const systemEngine = yield select(state =>
    _.get(state, "query.systemEngine.systemEngine")
  );

  try {
    const data = yield call(
      DatabaseService.getDatabaseDetails,
      dbId,
      accountId,
      systemEngine
    );

    yield put(setEngines(dbId, data.engines));
  } catch (err) {
    console.error("Failed to get engines details:", err);
    yield put(
      putStatusMessage("Failed to get engines details", StatusMessageType.Error)
    );
  }
}

function* queryExecutionSuccess({
  query,
  database,
  queryId,
  queryStatementId,
  results,
  querySettings = null,
  isLastQueryStatement,
}: {
  query: string;
  database: string;
  queryId: string;
  queryStatementId: number;
  results: any;
  querySettings?: any;
  isLastQueryStatement: boolean;
}) {
  if (isAsyncQuery({ querySettings, serverQueryId: results?.query_id })) {
    if (results.query_id) {
      yield put(
        QueryActions.setServerQueryID({
          database,
          queryId,
          serverQueryId: results.query_id,
          asyncStatementId: queryStatementId,
        })
      );
    }
  } else {
    const dbList = yield select(getDatabases);
    const db = _.find(dbList, { name: database });
    if (canAffectEnginesList(query) && !db.isVirtual) {
      yield updateDbEnginesList(db.id);
    }

    // query was executed synchronous and here we received the results
    yield put({
      type: QueryActions.EXECUTE_QUERY_SUCCESS,
      payload: {
        query,
        database,
        results,
        queryId,
        queryStatementId,
        isLastQueryStatement,
      },
    });
  }
}

function* asyncQueryExecutionSuccess({
  query,
  database,
  queryId,
  queryStatementId,
  results,
  isLastQueryStatement,
}: {
  query: string;
  database: string;
  queryId: string;
  queryStatementId: number;
  results: any;
  isLastQueryStatement: boolean;
}) {
  yield put({
    type: QueryActions.EXECUTE_QUERY_SUCCESS,
    payload: {
      query,
      database,
      results,
      queryId,
      queryStatementId,
      isLastQueryStatement,
    },
  });
}

export function* queryExecutionCanceled({
  query,
  database,
  queryId,
}: {
  query: string;
  database: string;
  queryId: number | string;
}) {
  yield put({
    type: QueryActions.CANCEL_QUERY_EXECUTION,
    payload: {
      query,
      database,
      queryId,
    },
  });
}

function* getResultsForAsyncQuery({
  database,
  serverQueryId,
  queryStatementId,
}: {
  database: string;
  serverQueryId: string;
  queryStatementId: number;
}) {
  const queryState = yield select(
    getQueryFromServerQueryId,
    database,
    serverQueryId
  );
  const queryId = queryState?.id;
  const sqlStatement =
    queryState?.multiQueryStatement?.[queryStatementId]?.query;
  const engineEndpoint = yield getDBEndpoint(database, queryId);
  const engineProxyVersion = yield select(
    getEngineProxyVersion,
    queryState?.engineId
  );
  const querySqlStatements = yield getQueryList(getQueryToExecute(queryState));
  const isLastQueryStatement =
    querySqlStatements?.length - 1 === queryStatementId;

  // There is no running engine to execute the query
  if (!engineEndpoint) return;

  let results;

  try {
    const querySettings = queryState?.settings;
    results = yield call(getAsyncQueryResult, {
      dbName: database,
      queryId: serverQueryId,
      engineEndpoint,
      querySettings,
      engineProxyVersion,
      sqlStatement,
    });

    results = {
      ...results,
      queryStatementId,
      fetchedAsyncDataOnce: true,
    };

    delete hybridStatements[serverQueryId];

    yield asyncQueryExecutionSuccess({
      query: sqlStatement,
      database,
      queryId,
      queryStatementId,
      results: results && typeof results === "object" ? results : undefined,
      isLastQueryStatement,
    });
    yield resumeQueryStatementSaga(queryState, database, queryStatementId);
  } catch (error) {
    if (axios.isCancel(error)) {
      yield queryExecutionCanceled({
        query: sqlStatement,
        database,
        queryId,
      });
      return;
    } else {
      if (error?.response?.status === 404) {
        const queryStatusResults = yield getQueryStatusInfo(
          serverQueryId,
          database,
          engineEndpoint,
          queryId
        );
        const statistics = formatQueryStatusStatistics(queryStatusResults);
        yield queryStatementExecutionFailedSaga({
          queryStatement: sqlStatement,
          database,
          queryId,
          queryStatementId,
          error,
          results: {
            fetchedAsyncDataOnce: true,
            expiredResults: true,
            statistics,
          },
        });
      } else {
        yield queryStatementExecutionFailedSaga({
          queryStatement: sqlStatement,
          database,
          queryId,
          queryStatementId,
          error,
          results: {
            fetchedAsyncDataOnce: true,
          },
        });
      }

      yield resumeQueryStatementSaga(queryState, database, queryStatementId);
    }
  } finally {
    yield endQuery(database, queryId);
  }
}

function* queryStatementExecutionFailedSaga({
  database,
  queryId,
  queryStatementId,
  error,
  queryStatement,
  results,
}: {
  database: string;
  queryId: number | string;
  queryStatementId: number | string;
  error: any;
  queryStatement: string;
  results?: any;
}) {
  const errMsg = _.get(error, "request.response") || "internal server error";
  console.error("Failed to execute query:", errMsg);
  const cleanErrMsg = errMsg.replace(ansiRegex(), ""); // Removing ANSI Styles (colors)

  yield put({
    type: QueryActions.EXECUTE_QUERY_FAILURE,
    payload: {
      query: queryStatement,
      database,
      results: { error: cleanErrMsg, queryStatementId, ...results },
      queryId,
      queryStatementId,
    },
  });
}

export function* setQueryStatementProgressSaga(
  action: Action<{
    database: string;
    queryId: string;
    queryStatementId: number;
  }>
) {
  const { database, queryStatementId, queryId } = action.payload;
  const engineEndpoint = yield getDBEndpoint(database, queryId);

  // There is no running engine to execute the query
  if (!engineEndpoint) return;

  let runQueryProgress = yield shouldRunQueryStatementProgressSaga(
    database,
    queryId,
    queryStatementId
  );

  while (runQueryProgress) {
    const serverQueryId = yield getServerQueryid(database, queryId);

    if (serverQueryId) {
      let progress: { data: ProgressInfoInput } | null = null;
      try {
        progress = yield getQueryProgress(serverQueryId, engineEndpoint);
      } catch (err) {
        console.error("Failed to get query progress:", err);
      }

      // we must read and check the state again after api call (possible race-condition)
      runQueryProgress = yield shouldRunQueryStatementProgressSaga(
        database,
        queryId,
        queryStatementId
      );
      if (!runQueryProgress) return;

      // checking if script was closed while query was running
      const queryAfterCall = yield select(getQuery, database, queryId);
      if (!queryAfterCall) {
        yield cleanupAfterScriptClose(database, queryId);
        return;
      }

      if (progress && !_.isEmpty(progress.data)) {
        const { read_bytes, read_rows, elapsed } = progress.data[0];
        const progressObj = {
          elapsed,
          read_rows: Number(read_rows),
          read_bytes: Number(read_bytes),
        };
        yield put(
          QueryActions.setQueryStatementProgress(
            database,
            queryId,
            progressObj,
            queryStatementId
          )
        );
      } else {
        const queryExecutionEnded = yield wasQueryExecutionEnded(
          database,
          queryId,
          queryStatementId
        );
        const queryState = yield select(getQuery, database, queryId);
        const accountFlags = yield select(getAccountFlags);
        const queryStatementState =
          queryState?.multiQueryStatement?.[queryStatementId];

        if (queryExecutionEnded) {
          const engineProxyVersion = yield select(
            getEngineProxyVersion,
            queryState.engineId
          );
          if (
            !queryStatementState?.fetchedAsyncDataOnce &&
            !fetchingResultsStatementIds[serverQueryId] &&
            shouldFetchAsyncResults({
              query: queryState,
              accountFlags,
              engineProxyVersion,
            })
          ) {
            fetchingResultsStatementIds[serverQueryId] = true;
            yield getResultsForAsyncQuery({
              database,
              serverQueryId,
              queryStatementId,
            });
            delete fetchingResultsStatementIds[serverQueryId];
          } else if (isQueryStatementRunning(queryStatementState.status)) {
            const querySqlStatements = yield getQueryList(
              getQueryToExecute(queryState)
            );
            yield asyncQueryExecutionSuccess({
              query: queryStatementState.query,
              database,
              queryId,
              queryStatementId,
              results: {
                fetchedAsyncDataOnce: false,
                queryStatementId,
              },
              isLastQueryStatement:
                querySqlStatements?.length - 1 === queryStatementId,
            });
            yield resumeQueryStatementSaga(
              queryState,
              database,
              queryStatementId
            );
          }
        }
      }
    }

    yield delay(200);

    runQueryProgress = yield shouldRunQueryStatementProgressSaga(
      database,
      queryId,
      queryStatementId
    );
  }
}

/**
 * Decide for multi query statement if progress should run for a subquery
 *
 * @param database
 * @param queryId
 * @param queryStatementId
 */
function* shouldRunQueryStatementProgressSaga(
  database,
  queryId,
  queryStatementId
) {
  let dbQueries = yield select(getQueries);
  const currentQueryTabId = _.get(
    dbQueries,
    `databasesQueries.${database}.currentQueryTabId`,
    false
  );

  if (queryId !== currentQueryTabId) return false;

  const multiQueryStatement = _.get(
    dbQueries,
    `databasesQueries.${database}.queriesById[${queryId}].multiQueryStatement`
  );

  const runningQuery = _.find(multiQueryStatement, { queryStatementId });
  return runningQuery
    ? isQueryStatementRunning(runningQuery.status) &&
        !runningQuery.fetchingAsyncData
    : false;
}

export function* clearTempQueryInfo(database, queryId) {
  // checking if script was closed while query was running
  const queryAfterCall = yield select(getQuery, database, queryId);
  if (!queryAfterCall) {
    yield cleanupAfterScriptClose(database, queryId);
    return;
  }
  yield put(
    QueryActions.setServerQueryID({
      database,
      queryId,
      serverQueryId: undefined,
    })
  );
}

export function* cancelQuerySaga(
  action: Action<{ database: string; queryId: string }>
) {
  const { queryId, database } = action.payload;
  const queryState = yield select(getQuery, database, queryId);
  const { serverQueryId, query } = queryState;

  const qIds = [queryId];

  try {
    const engineEndpoint = yield getDBEndpoint(database, queryId);
    // There is no running engine to execute the query
    if (!engineEndpoint) {
      return;
    }

    yield call(cancelQuery, qIds, engineEndpoint, database, serverQueryId);
    yield put({
      type: QueryActions.CANCEL_QUERY_SUCCESS,
      payload: { queryId, database },
    });
    const isQueryRunningOnClient = yield isQueryRunning(database, queryId);
    if (!isQueryRunningOnClient) {
      yield queryExecutionCanceled({ query, database, queryId });
    }
  } catch (error) {
    yield put({
      type: QueryActions.CANCEL_QUERY_FAILURE,
      payload: {
        queryId,
        database,
        error,
      },
    });
  } finally {
    yield clearTempQueryInfo(database, queryId);
  }
}

function* closeScriptWithCancelSaga(
  action: Action<{ database: string; queryId: string }>
) {
  const { queryId, database } = action.payload;
  const queryState = yield select(getQuery, database, queryId);
  const { serverQueryId } = queryState;

  const qIds = [queryId];

  try {
    const engineEndpoint = yield getDBEndpoint(database, queryId);
    // There is no running engine to execute the query
    if (!engineEndpoint) {
      return;
    }

    closingScripts[queryId] = true;
    yield call(cancelQuery, qIds, engineEndpoint, database, serverQueryId);
    const allScripts = yield select(getAllScripts, database);
    yield put({
      type: QueryActions.REMOVE_QUERY,
      payload: { database, queryId, allScripts },
    });
  } catch (error) {
    yield put({
      type: QueryActions.CANCEL_QUERY_FAILURE,
      payload: {
        queryId,
        database,
        error,
      },
    });
  } finally {
    yield clearTempQueryInfo(database, queryId);
  }
}

export function* closeAllScriptsWithCancel(
  action: Action<{
    databaseName: string;
    queryIdsToCancel: string[];
    scriptToKeepOpen: any;
  }>
) {
  const { databaseName, queryIdsToCancel, scriptToKeepOpen } = action.payload;
  let cancelQueriesSuccessfully = true;
  let currentQueryIdx = 0;
  while (currentQueryIdx < queryIdsToCancel.length) {
    const queryId = queryIdsToCancel[currentQueryIdx];
    const queryState = yield select(getQuery, databaseName, queryId);
    const { serverQueryId } = queryState;

    const qIds = [queryId];
    try {
      const engineEndpoint = yield getDBEndpoint(databaseName, queryId);
      // There is no running engine to execute the query
      if (!engineEndpoint) {
        return;
      }

      closingScripts[queryId] = true;
      yield call(
        cancelQuery,
        qIds,
        engineEndpoint,
        databaseName,
        serverQueryId
      );
    } catch (error) {
      cancelQueriesSuccessfully = false;
      yield put({
        type: QueryActions.CANCEL_QUERY_FAILURE,
        payload: {
          queryId,
          database: databaseName,
          error,
        },
      });
    } finally {
      yield clearTempQueryInfo(databaseName, queryId);
    }
    currentQueryIdx++;
  }

  if (cancelQueriesSuccessfully) {
    yield put(closeTabs(databaseName, scriptToKeepOpen));
  }
}

export function* deleteScriptsWithCancel(
  action: Action<{
    database: any;
    queryIdsToCancel: string[];
    scriptsToDelete: any[];
  }>
) {
  const { database, queryIdsToCancel, scriptsToDelete } = action.payload;
  let cancelQueriesSuccessfully = true;
  let currentQueryIdx = 0;
  while (currentQueryIdx < queryIdsToCancel.length) {
    const queryId = queryIdsToCancel[currentQueryIdx];
    const queryState = yield select(getQuery, database.name, queryId);
    const { serverQueryId } = queryState;

    const qIds = [queryId];
    try {
      const engineEndpoint = yield getDBEndpoint(database.name, queryId);
      // There is no running engine to execute the query
      if (!engineEndpoint) {
        return;
      }

      closingScripts[queryId] = true;
      yield call(
        cancelQuery,
        qIds,
        engineEndpoint,
        database.name,
        serverQueryId
      );
    } catch (error) {
      cancelQueriesSuccessfully = false;
      yield put({
        type: QueryActions.CANCEL_QUERY_FAILURE,
        payload: {
          queryId,
          database: database.name,
          error,
        },
      });
    } finally {
      yield clearTempQueryInfo(database.name, queryId);
    }
    currentQueryIdx++;
  }

  if (cancelQueriesSuccessfully) {
    let deleteIndex = 0;

    while (deleteIndex < scriptsToDelete.length) {
      const currentScript = scriptsToDelete[deleteIndex];
      yield put({
        type: UserScriptsAction.USER_SCRIPTS_DELETE,
        payload: {
          userScript: _.omit(currentScript, "globalScript"),
          database: database,
          globalScript: !!currentScript?.globalScript,
        },
      });
      deleteIndex++;
    }
  }
}

export function* removeScriptWithCancelSaga(
  action: Action<{
    userScript: UserScript;
    database: any;
    globalScript?: boolean;
    scriptOpenTab?: any;
  }>
) {
  const { userScript, database, globalScript, scriptOpenTab } = action.payload;

  const qIds = [scriptOpenTab.id];

  try {
    const engineEndpoint = yield getDBEndpoint(database.name, scriptOpenTab.id);
    // There is no running engine to execute the query
    if (!engineEndpoint) {
      return;
    }

    closingScripts[scriptOpenTab.id] = true;
    yield call(
      cancelQuery,
      qIds,
      engineEndpoint,
      database.name,
      scriptOpenTab.serverQueryId
    );
    yield put({
      type: UserScriptsAction.USER_SCRIPTS_DELETE,
      payload: { userScript, globalScript, database },
    });
  } catch (error) {
    yield put({
      type: QueryActions.CANCEL_QUERY_FAILURE,
      payload: {
        queryId: scriptOpenTab.id,
        database,
        error,
      },
    });
  } finally {
    yield clearTempQueryInfo(database, scriptOpenTab.id);
  }
}

function isQueryInRunningState(query, statusToCheck) {
  return !!_.find(
    query.multiQueryStatement,
    ({ status }) => status === statusToCheck
  );
}

export function* checkQueryStatusSaga(
  action: Action<{ query: any; database: string }>
) {
  const { query, database } = action.payload;
  const isQueryRunning = isQueryInRunningState(query, QueryStatus.Running);
  const isExplainRunning = isQueryInRunningState(
    query,
    QueryStatus.ExplainRunning
  );

  if (isExplainRunning) {
    yield put({
      type: QueryActions.EXECUTE_QUERY_EXPLAIN_RESET,
      payload: { database, query },
    });
  }

  if (!isQueryRunning) {
    if (query.isQueryRunning) {
      yield put({
        type: QueryActions.MARK_QUERY_AS_STOPPED,
        payload: { database, query },
      });
    }
    return;
  }

  yield runQueryStatementStatusCheckSaga(query, database);
}

export function* checkServerQueryStatementStatus(
  database,
  query,
  queryStatementId
) {
  const engineEndpoint = yield getDBEndpoint(database, query.id);
  const queryToExecute = query?.multiQueryStatement?.[queryStatementId]?.query;
  // //There is no running engine to execute the query
  if (!engineEndpoint) {
    yield queryStatementExecutionFailedSaga({
      database,
      queryId: query.id,
      queryStatementId,
      error: {
        request: {
          serverSideQueryExecuted: true,
          response:
            "We can not check for query status, the engine is not running anymore!",
        },
      },
      queryStatement: queryToExecute,
    });
    return false;
  }

  const { serverQueryId, engineId } = query;
  try {
    const isQueryRunningOnClient = yield isQueryRunning(database, query.id);
    if (isQueryRunningOnClient) {
      return true;
    }

    if (!serverQueryId) {
      yield queryStatementExecutionFailedSaga({
        database,
        queryId: query.id,
        queryStatementId,
        error: {
          request: {
            serverSideQueryExecuted: true,
            response:
              "Missing query identifier! Can not check for query status!",
          },
        },
        queryStatement: queryToExecute,
      });
      return false;
    }

    const queryStatusResults = yield getQueryStatusInfo(
      serverQueryId,
      database,
      engineEndpoint,
      query.id
    );

    // checking if script was closed while query was running
    const queryAfterCall = yield select(getQuery, database, query?.id);
    if (!queryAfterCall) {
      yield cleanupAfterScriptClose(database, query?.id);
      return;
    }

    switch (queryStatusResults.eventType) {
      case ClientSideQueryStatuses.RUNNING: {
        return true;
      }
      case ClientSideQueryStatuses.FINISHED: {
        yield put({
          type: QueryActions.FETCH_ASYNC_DATA,
          payload: {
            database,
            queryId: query.id,
            queryStatementId,
            results: {
              queryStatementId: queryStatementId,
            },
          },
        });
        return false;
      }
      case ClientSideQueryStatuses.CANCELED: {
        yield put({
          type: QueryActions.CANCEL_QUERY_SUCCESS,
          payload: {
            database,
            queryId: query.id,
          },
        });
        return false;
      }
      default: {
        const engineProxyVersion = yield select(
          getEngineProxyVersion,
          engineId
        );
        const accountFlags = yield select(getAccountFlags);
        if (
          canUseQueryHybridFlag(engineProxyVersion) &&
          isHybridEnabled(accountFlags)
        ) {
          return false;
        }
        yield queryStatementExecutionFailedSaga({
          database,
          queryId: query.id,
          queryStatementId,
          error: {
            request: {
              serverSideQueryExecuted: true,
              response: "Query Execution failed!",
            },
          },
          queryStatement: queryToExecute,
        });
        return false;
      }
    }
  } catch (error) {
    yield queryStatementExecutionFailedSaga({
      database,
      queryId: query.id,
      queryStatementId,
      error,
      queryStatement: queryToExecute,
    });
    return false;
  }
}

/**
 * Try to get query status for `MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS` min
 * no matter if the http call fails of we receive blank response from server
 */
function* getQueryStatusInfo(serverQueryId, database, engineEndpoint, queryId) {
  let getStatusCallFailed = false;
  let queryStatusResults: any = {
    eventType: "",
  };
  try {
    queryStatusResults = yield call(
      getQueryStatus,
      serverQueryId,
      database,
      engineEndpoint
    );
  } catch (err) {
    getStatusCallFailed = true;
  } finally {
    /**
     * @HACK in some cases the status response may have blank values
     * for `MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS` minutes we will allow blank responses (fake running status)
     * after this we will fail the query
     */
    const queryState = yield select(getQuery, database, queryId);
    const engineProxyVersion = yield select(
      getEngineProxyVersion,
      queryState?.engineId
    );
    const queryServerStatusKey = getStatusKeyByProxyVersion(engineProxyVersion);
    queryStatusResults.eventType = normalizeQueryStatusType(
      queryId,
      queryStatusResults?.[queryServerStatusKey]
    );

    if (!queryStatusResults.eventType && getStatusCallFailed) {
      /**
       * in case the query status call fail we still retry to receive info about query
       * for `MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS` min
       */
      /*eslint-disable */
      throw {
        request: {
          serverSideQueryExecuted: true,
          response: "Can not retrieve any information about your query status!",
        },
      };
      /* eslint-enable */
    }
  }

  return queryStatusResults;
}

/**
 * normalizeQueryStatusType will normalize `eventType` in case of empty status received
 *
 * this is a hack so we do not mark an running query on server side as failed in ui
 * we need to stopped it after `MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS` minutes
 * in cases the engine was stooped or proxy service is down
 */
let emptyQueryStatusTimer = {};
const MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS = 10;
export function normalizeQueryStatusType(queryId, eventType) {
  if (!_.isEmpty(eventType)) {
    const status = formatQueryStatusType(eventType);
    if (status) {
      delete emptyQueryStatusTimer?.[queryId];
      return status;
    }
  }

  const startCounter = _.get(emptyQueryStatusTimer, queryId, performance.now());
  const runningMin = (performance.now() - startCounter) / 60000;

  if (runningMin > MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS) {
    delete emptyQueryStatusTimer?.[queryId];
    console.error(
      `Failed to receive an valid query status after ${MAX_MIN_RETRY_FOR_EMPTY_QUERY_STATUS} minutes of retries`
    );
    return ClientSideQueryStatuses.FAILED;
  }

  emptyQueryStatusTimer = {
    ...emptyQueryStatusTimer,
    [queryId]: startCounter,
  };
  return formatQueryStatusType(ServerQueryStatusesV1.RUNNING);
}

function* runQueryStatementStatusCheckSaga(query, database) {
  if (_.isEmpty(query.multiQueryStatement)) return;

  const queryStatement: any = _.last(query.multiQueryStatement);
  const { queryStatementId, fetchedAsyncDataOnce } = queryStatement;
  const isQueryStatementServerRunning = yield checkServerQueryStatementStatus(
    database,
    query,
    queryStatementId
  );

  if (isQueryStatementServerRunning) {
    yield put(
      QueryActions.startQueryStatementProgress(
        query.id,
        database,
        queryStatementId
      )
    );
  } else {
    const accountFlags = yield select(getAccountFlags);
    const engineProxyVersion = yield select(
      getEngineProxyVersion,
      query.engineId
    );
    if (
      !fetchedAsyncDataOnce &&
      !fetchingResultsStatementIds[query.serverQueryId] &&
      shouldFetchAsyncResults({
        query,
        accountFlags,
        engineProxyVersion,
      })
    ) {
      fetchingResultsStatementIds[query.serverQueryId] = true;
      yield getResultsForAsyncQuery({
        database,
        serverQueryId: query.serverQueryId,
        queryStatementId,
      });
      delete fetchingResultsStatementIds[query.serverQueryId];
      return;
    }

    const querySqlStatements = yield getQueryList(getQueryToExecute(query));
    yield asyncQueryExecutionSuccess({
      query: querySqlStatements?.[queryStatementId],
      database,
      queryId: query.id,
      queryStatementId,
      results: {
        fetchedAsyncDataOnce: false,
        queryStatementId,
      },
      isLastQueryStatement: querySqlStatements?.length - 1 === queryStatementId,
    });
    yield resumeQueryStatementSaga(query, database, queryStatementId);
  }
}

function* wasQueryExecutionEnded(
  database,
  queryId,
  queryStatementId: number | null = null
) {
  const isQueryRunningOnClient = yield isQueryRunning(database, queryId);

  if (isQueryRunningOnClient) return false;

  const query = yield select(getQuery, database, queryId);
  const queryStatementState =
    query?.multiQueryStatement?.[queryStatementId as number];
  const isQuerySeverRunning = yield checkServerQueryStatementStatus(
    database,
    query,
    queryStatementId
  );

  if (
    !isQuerySeverRunning &&
    !isAsyncQuery({
      querySettings: query?.settings,
      serverQueryId: query?.serverQueryId,
      queryStatementState,
    })
  ) {
    yield resumeQueryStatementSaga(query, database, queryStatementId);
  }

  return !isQuerySeverRunning;
}

function* resumeQueryStatementSaga(query, database, queryStatementId) {
  const queryStatementStatus = yield getQueryStatementStatus(
    database,
    query.id,
    queryStatementId
  );
  if (queryStatementStatus !== QueryStatus.Success) return;
  const queryToExecute = getQueryToExecute(query);

  const querySqlStatements = yield getQueryList(queryToExecute);

  if (querySqlStatements?.error) {
    yield put(
      putStatusMessage(
        i18n.t("errors.editor_range") as string,
        StatusMessageType.Error
      )
    );
    return;
  }

  const nextQueryStatement = yield getQueryStatement(
    database,
    query.id,
    queryStatementId + 1
  );

  if (
    querySqlStatements.length - 1 <= queryStatementId ||
    // query was already resumed and the saga was called again due to a race condition
    nextQueryStatement
  )
    return;

  yield put(
    QueryActions.resumeMultiQuery(
      queryToExecute,
      database,
      query.id,
      queryStatementId + 1
    )
  );
}

function* cleanupAfterScriptClose(database, queryId) {
  yield endQuery(database, queryId);
}

function* getQueryStatementStatus(database, queryId, queryStatementId) {
  const query = yield select(getQuery, database, queryId);
  return _.get(
    query?.multiQueryStatement,
    `${queryStatementId}.status`,
    QueryStatus.Failed
  );
}

function* getQueryStatement(database, queryId, queryStatementId) {
  const query = yield select(getQuery, database, queryId);
  return _.get(query?.multiQueryStatement, `${queryStatementId}`, null);
}

export function* handleQueryErrors({
  database,
  query,
  queryStatementId,
  originalError,
}: {
  database: string;
  query: any;
  queryStatementId: number;
  originalError?: Error;
}) {
  const queryStatementState = query?.multiQueryStatement?.[queryStatementId];
  const queryToExecute = getQueryToExecute(queryStatementState);

  yield queryStatementExecutionFailedSaga({
    database,
    queryId: query.id,
    queryStatementId,
    error: originalError,
    queryStatement: queryToExecute,
  });
}

function* runAsyncQueryInBackground(
  action: Action<{
    database: string;
    serverQueryId: string;
    queryStatementId: number;
  }>
) {
  const { database, serverQueryId, queryStatementId } = action.payload;
  const currentQueryTabId = yield select(getCurrentQueryTabId, database);
  const queryState = yield select(
    getQueryFromServerQueryId,
    database,
    serverQueryId
  );

  if (!queryState) return;

  const attemptedToResumeQueryStatement = yield select(
    getDatabaseQueryStatementResumedFlag,
    database,
    serverQueryId
  );
  const queryStatementState =
    queryState?.multiQueryStatement?.[queryStatementId];
  const isQueryAsync = isAsyncQuery({
    querySettings: queryState?.settings,
    serverQueryId,
    queryStatementState,
  });
  const queryIsRunningOnClient = getClientRunningQuery(
    database,
    queryState?.id
  );
  if (
    currentQueryTabId === queryState?.id ||
    attemptedToResumeQueryStatement ||
    (!isQueryAsync && queryIsRunningOnClient)
  ) {
    return;
  }
  const accountFlags = yield select(getAccountFlags);
  const engineProxyVersion = yield select(
    getEngineProxyVersion,
    queryState?.engineId
  );

  yield put(queryStatementResumedInBackground(database, serverQueryId));

  if (
    !queryStatementState?.fetchedAsyncDataOnce &&
    !fetchingResultsStatementIds[serverQueryId] &&
    shouldFetchAsyncResults({
      query: queryState,
      accountFlags,
      engineProxyVersion,
    })
  ) {
    fetchingResultsStatementIds[serverQueryId] = true;
    yield getResultsForAsyncQuery({
      database,
      serverQueryId,
      queryStatementId,
    });
    delete fetchingResultsStatementIds[serverQueryId];
    return;
  }

  const querySqlStatements = yield getQueryList(getQueryToExecute(queryState));
  yield asyncQueryExecutionSuccess({
    query: queryStatementState?.query,
    database,
    queryId: queryState?.id,
    queryStatementId,
    results: {
      fetchedAsyncDataOnce: false,
      queryStatementId,
    },
    isLastQueryStatement: querySqlStatements?.length - 1 === queryStatementId,
  });
  yield resumeQueryStatementSaga(queryState, database, queryStatementId);
}

function* resumeQueriesInBackground(
  action: Action<{
    database: string;
  }>
) {
  const { database } = action.payload;
  const queries = yield select(getDatabaseQueries, database);
  const attemptedToResume = yield select(
    getDatabaseQueriesResumedFlag,
    database
  );
  if (!queries || attemptedToResume) return;

  const { currentQueryTabId, queriesById } = queries;
  const backgroundQueries = _.omit(queriesById, currentQueryTabId);

  let idx = 0;
  const backgroundQueriesIds = Object.keys(backgroundQueries);
  while (idx < backgroundQueriesIds.length) {
    const query = backgroundQueries[backgroundQueriesIds[idx]];

    yield put(queryResumedInBackground(database));
    yield put(resumeQueryInBackground(query, database));

    idx++;
  }
}

export function* QueriesSagas() {
  return yield all([
    takeEvery(QueryActions.EXECUTE_QUERY_REQUEST, executeQuerySaga),
    takeEvery(
      QueryActions.EXECUTE_QUERY_STATEMENT_REQUEST,
      setQueryStatementProgressSaga
    ),
    takeEvery(QueryActions.CANCEL_QUERY_REQUEST, cancelQuerySaga),
    takeEvery(QueryActions.CLOSE_SCRIPT_WITH_CANCEL, closeScriptWithCancelSaga),
    takeEvery(
      UserScriptsAction.USER_SCRIPT_DELETE_WITH_CANCEL,
      removeScriptWithCancelSaga
    ),
    takeEvery(
      QueryActions.CLOSE_ALL_SCRIPTS_WITH_CANCEL,
      closeAllScriptsWithCancel
    ),
    takeEvery(
      UserScriptsAction.USER_MULTIPLE_SCRIPTS_DELETE_WITH_CANCEL,
      deleteScriptsWithCancel
    ),
    takeEvery(
      QueryActions.START_QUERY_STATEMENT_PROGRESS,
      setQueryStatementProgressSaga
    ),
    takeEvery(QueryActions.RESUME_QUERY, executeQuerySaga),
    takeEvery(QueryActions.RUN_QUERY_IN_BACKGROUND, runAsyncQueryInBackground),
    takeEvery(QueryActions.RESUME_QUERY_IN_BACKGROUND, checkQueryStatusSaga),
    takeEvery(
      QueryActions.RESUME_QUERIES_IN_BACKGROUND,
      resumeQueriesInBackground
    ),
    takeLatest(QueryActions.QUERY_TAB_CHANGED, checkQueryStatusSaga),
  ]);
}
