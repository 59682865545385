import axios, { AxiosInstance } from "axios";
import { statusCodes } from "businessLogic/constants";
import { authService } from "services/auth";
import { refreshToken } from "relay/refreshToken";
import { store } from "store";

const initializeAllInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async config => {
      const token = await authService.getToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    error => Promise.reject(error)
  );

  instance.interceptors.response.use(
    config => {
      return config;
    },
    async error => {
      const { response } = error;
      const originalRequest = error.config;

      if (
        response &&
        response.status === statusCodes.UNAUTHORIZED &&
        !originalRequest._retry
      ) {
        const loginType = authService.getLoginType();
        if (loginType === "auth0") {
          authService.redirectToLogout();
          return Promise.reject(error);
        } else {
          originalRequest._retry = true;
          const token = await refreshToken(store);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          return instance.request(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );
};

export const EngineClient = (engineEndpoint: string) => {
  const axiosInstance = axios.create({
    baseURL: engineEndpoint,
  });
  initializeAllInterceptors(axiosInstance);
  return axiosInstance;
};
