import React from "react";
import styles from "./TooltipItem.module.scss";
import cn from "classnames";

export interface TooltipItemProps {
  name: string;
  value: string;
  noMargin?: boolean;
}

const TooltipItem = (props: TooltipItemProps) => {
  const { name, value, noMargin } = props;
  return (
    <div className={cn(styles.itemWrap, { [styles.noMargin]: noMargin })}>
      {name && <span className={styles.itemLabel}>{name} </span>}
      <span className={styles.itemValue}>{value}</span>
    </div>
  );
};

export default TooltipItem;
