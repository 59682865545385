import { useState, useCallback, useRef, useEffect } from "react";
import copy from "copy-to-clipboard";

const COPY_TIMEOUT = 3000;

export const useCopy = (timeout = COPY_TIMEOUT) => {
  const [copied, setIsCopied] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cleanUp = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };

  const handleCopy = useCallback(
    (text: string) => {
      cleanUp();
      copy(text);
      setIsCopied(true);
      timeoutRef.current = setTimeout(() => {
        setIsCopied(false);
      }, timeout);
    },
    [timeout]
  );

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  return {
    copied,
    handleCopy
  };
};
