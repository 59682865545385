import { AuthSagas } from "pages/Auth/redux/sagas";
import { AccountSagas } from "pages/Account/redux/sagas";
import { DatabaseSagas } from "pages/Database/redux/sagas";
import { MembersSagas } from "pages/Users/redux/sagas";
import { UserSagas } from "./user/sagas";
import { QuerySagas } from "components/QueryEditor/redux/sagas";
import { all } from "redux-saga/effects";
import { SubscriptionSagas } from "./subscription/sagas";
import { CostsSagas } from "./costs/sagas";
import { maintainanceSagas } from "./maintainance/sagas";
import { AppSagas } from "components/App/redux/sagas";
import { StatusMessageSagas } from "components/StatusMessage/redux/sagas";

export default function* rootSaga(): any {
  yield all([
    AccountSagas(),
    AuthSagas(),
    DatabaseSagas(),
    QuerySagas(),
    MembersSagas(),
    UserSagas(),
    SubscriptionSagas(),
    CostsSagas(),
    maintainanceSagas(),
    AppSagas(),
    StatusMessageSagas(),
  ]);
}
