import React from "react";
import styles from "./ListView.module.scss";
import cn from "classnames";

export enum WrapOptions {
  wrap = "wrap",
  noWrap = "noWrap",
  wrapReverse = "wrapReverse",
}

interface Props {
  children: React.ReactNode[];
  direction: "vertical" | "horizontal";
  spacingPx?: number;
  className?: string;
  childClassNames?: string;
  justifyContent?: boolean;
  alignCenter?: boolean;
  dataTestId?: string;
  equalElemWidth?: boolean;
  wrap?: WrapOptions;
}

const ListView = ({
  children,
  spacingPx,
  direction,
  justifyContent,
  alignCenter,
  className,
  childClassNames,
  dataTestId,
  equalElemWidth,
  wrap,
}: Props) => {
  let style: any = {};
  let firstElemStyle: any = {};

  if (direction === "vertical") {
    style.marginTop = `${spacingPx}px`;
  }

  if (direction === "horizontal") {
    style.marginLeft = `${spacingPx}px`;

    if (equalElemWidth) {
      firstElemStyle.width =
        style.width = `calc( 100% / ${children.length} - ${spacingPx}px)`;
    }
  }

  return (
    <div
      data-testid={dataTestId}
      className={cn(
        styles.root,
        styles[direction],
        {
          [styles.justifyContent]: justifyContent,
          [styles.alignCenter]: alignCenter,
          [styles.wrap]: wrap === WrapOptions.wrap,
          [styles.noWrap]: wrap === WrapOptions.noWrap,
          [styles.wrapReverse]: wrap === WrapOptions.wrapReverse,
        },
        className
      )}
    >
      {children
        .filter(child => !!child)
        .map((child, index) => {
          return (
            <div
              key={index}
              className={cn(styles.child, childClassNames)}
              style={index ? style : firstElemStyle}
            >
              {child}
            </div>
          );
        })}
    </div>
  );
};

ListView.defaultProps = {
  direction: "vertical",
};

export default ListView;
