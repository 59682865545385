import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AuthUnsubscribeMailingsInput } from "relay/mutations/auth/__generated__/UnsubscribeMailingsMutation.graphql";

const mutation = graphql`
  mutation UnsubscribeMailingsMutation($input: AuthUnsubscribeMailingsInput!) {
    authUnsubscribeMailings(input: $input) {
      success
    }
  }
`;

function commit(
  environment,
  input: AuthUnsubscribeMailingsInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
