import { ResetPass, UserDetails } from "businessLogic/model";
import environment from "relay/environment";
import ResetPasswordStartMutation from "relay/mutations/auth/ResetPasswordStartMutation";
import { AuthResetPasswordStartInput } from "relay/mutations/auth/__generated__/ResetPasswordStartMutation.graphql";
import { AuthResetPasswordFinishInput } from "relay/mutations/auth/__generated__/ResetPasswordFinishMutation.graphql";
import ResetPasswordFinishMutation from "relay/mutations/auth/ResetPasswordFinishMutation";
import VerifyTokenMutation from "relay/mutations/auth/VerifyTokenMutation";
import UnsubscribeMailingsMutation from "relay/mutations/auth/UnsubscribeMailingsMutation";

/* remove later old login flow */
import { getAccessToken } from "./getAccessToken";
import { getRefreshToken, refreshToken } from "./refreshToken";
import LoginMutation from "relay/mutations/auth/LoginMutation";
import {
  AuthLoginInput,
  LoginMutation$data,
} from "relay/mutations/auth/__generated__/LoginMutation.graphql";
import LogoutMutation from "relay/mutations/auth/LogoutMutation";
import { AuthLogoutInput } from "relay/mutations/auth/__generated__/LogoutMutation.graphql";
import { VerifyTokenMutation$data } from "relay/mutations/auth/__generated__/VerifyTokenMutation.graphql";

const sendResetPassEmail = (email: string, recaptchaChallenge: string) => {
  return new Promise((resolve, reject) => {
    const input: AuthResetPasswordStartInput = {
      username: email,
      recaptchaChallenge,
    };
    ResetPasswordStartMutation.commit(environment, input, resolve, reject);
  });
};

const setNewPass = (passwordInfo: ResetPass) => {
  return new Promise((resolve, reject) => {
    const input: AuthResetPasswordFinishInput = {
      newPassword: passwordInfo.password,
      token: passwordInfo.tokenId,
    };
    ResetPasswordFinishMutation.commit(environment, input, resolve, reject);
  });
};

const verifyPassword = (token: string) => {
  return new Promise<VerifyTokenMutation$data>((resolve, reject) => {
    const input = {
      token,
    };
    VerifyTokenMutation.commit(environment, input, resolve, reject);
  });
};

const unsubscribeEmail = (email: string) => {
  return new Promise((resolve, reject) => {
    const input = {
      email,
    };
    UnsubscribeMailingsMutation.commit(environment, input, resolve, reject);
  });
};

/* remove later old login flow */
const login = (
  userInfo: UserDetails,
  recaptcha: string
): Promise<LoginMutation$data> => {
  return new Promise((resolve, reject) => {
    const input: AuthLoginInput = {
      password: userInfo.password as string,
      username: userInfo.username,
      recaptchaChallenge: recaptcha,
    };
    LoginMutation.commit(environment, input, resolve, reject);
  });
};

const logout = refreshToken => {
  return new Promise((resolve, reject) => {
    const input: AuthLogoutInput = {
      refreshToken,
    };
    LogoutMutation.commit(environment, input, resolve, reject);
  });
};

export const AuthService = {
  login,
  logout,
  getAccessToken,
  getRefreshToken,
  refreshToken,

  sendResetPassEmail,
  setNewPass,
  verifyPassword,
  unsubscribeEmail,
};
