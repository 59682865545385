import { combineReducers } from "redux";
import queries from "./queries";
import tables from "./tables";
import views from "./views";
import sidebar from "./sidebar";
import sidebarSearch from "./sidebarSearch";
import userScripts from "./userScripts";
import tableDetails from "./tableDetails";
import scriptActions from "./scriptActions";
import proxy from "./proxy";
import accountFlags from "./accountFlags";
import systemEngine from "./systemEngine";

const rootReducer = combineReducers({
  queries,
  tables,
  views,
  sidebar,
  sidebarSearch,
  userScripts,
  tableDetails,
  scriptActions,
  proxy,
  accountFlags,
  systemEngine,
});

export default rootReducer;
