import { FIREBOLT_RELEASE_NOTES_URL } from "businessLogic/constants";

export const REFETCH_TIMEOUT = 60 * 1000;
export const DEFAULT_TITLE = "Firebolt got updated";
export const DEFAULT_ACTION = {
  type: "primary",
  content: "Learn about the details",
  url: FIREBOLT_RELEASE_NOTES_URL
};

export const RELEASE_NOTES_URL = "/release-notes.json";
