import React from "react";
import cn from "classnames";
import { ReactComponent as DeleteIcon } from "assets/icons/selectMenuIcons/DeleteIcon.svg";
import {
  canDeleteEngine,
  engineCanBeDeleted,
  engineCanBeRestarted,
  isEngineOff,
} from "components/EnginesList/helpers";
import CustomTooltip from "components/Tooltip";
import styles from "../index.module.scss";
import { useTranslation } from "react-i18next";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";

type Props = {
  engine: any;
  hovered?: boolean;
  onClick: ((e) => void) | undefined;
  accountAllowedActions: string[];
};

function DeleteEngineButton({
  engine,
  onClick,
  accountAllowedActions,
  hovered,
}: Props) {
  const { t } = useTranslation();
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    engine.computeRegion
  );
  const render =
    canDeleteEngine(accountAllowedActions) && engineCanBeDeleted(engine.status);

  if (!render) {
    return null;
  }

  const deleteDisabledText = t("engines.stop_engine_to_delete");

  const canShowDeleteBtn =
    isEngineOff(engine.status) || engineCanBeRestarted(engine.status);

  return (
    <CustomTooltip
      title={
        readOnlyMode
          ? readOnlyTooltip.title
          : !canShowDeleteBtn
          ? deleteDisabledText
          : t("engines.delete")
      }
      placement="top"
    >
      <DeleteIcon
        data-testid="engine-item-delete-icon"
        className={cn(styles.actionBtn, {
          [styles.disabled]: !canShowDeleteBtn || readOnlyMode,
          [styles.visible]: hovered,
        })}
        onClick={e => {
          if (readOnlyMode) {
            return;
          }
          if (!canShowDeleteBtn) {
            e.stopPropagation();
            return;
          }
          return onClick?.(e);
        }}
      />
    </CustomTooltip>
  );
}

export default DeleteEngineButton;
