import { createAction } from "redux-actions";
import { DialogAction } from "../interfaces";

export const DialogActions = {
  OPEN_DIALOG: "DIALOG_ACTIONS/OPEN_DIALOG",
  CLOSE_DIALOG: "DIALOG_ACTIONS/CLOSE_DIALOG"
};

export const openDialog = createAction(
  DialogActions.OPEN_DIALOG,
  (payload: DialogAction) => payload
);

export const closeDialog = createAction(DialogActions.CLOSE_DIALOG, () => {});
