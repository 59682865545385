import { EngineClient } from "businessLogic/api";
import environment from "../../../relay/environment";
import { fetchEngineById } from "../../../relay/queries/engine/Engine";

const formatEngineProxyVersion = (version: string) => {
  const [major, minor, patch] = version.substr(1).split(".");

  return {
    major: parseInt(major),
    minor: parseInt(minor),
    patch: parseInt(patch),
  };
};

export const getEngineProxyVersion = async (engineEndpoint: string) => {
  const engineClient = EngineClient(`${engineEndpoint}/version`);
  const { data: response, status } = await engineClient.get("");

  if (typeof response !== "string" || !response?.length || status === 404) {
    return;
  }

  return formatEngineProxyVersion(response);
};

export const getEngineById = (engineId: string, accountId: string) => {
  return fetchEngineById(environment, { id: engineId, accountId }).then(
    response => {
      if (!response) {
        return {};
      }
      const { engine } = response;
      return engine;
    }
  );
};

export const EngineService = {
  getEngineProxyVersion,
  getEngineById,
};
