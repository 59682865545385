import * as React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlag } from "../constants";

export interface FlagsInterface {
  [FeatureFlag.FireboltAppReadOnlyMaintenanceMode]: boolean | undefined;
  [FeatureFlag.FireboltAppRunMultipleReadWriteEngines]: boolean | undefined;
  [FeatureFlag.FireboltAppFullMaintenanceMode]: boolean | undefined;
  [FeatureFlag.FireboltAppSpotInstances]: boolean | undefined;
  [FeatureFlag.FireboltAppShowViews]: boolean | undefined;
  [FeatureFlag.FireboltAppEnginesPage]: boolean | undefined;
  [FeatureFlag.FireboltAppQueryExplain]: boolean | undefined;
  [FeatureFlag.UseSingleGpEnginePerDb]: boolean | undefined;
  [FeatureFlag.UseFireboltDisplayNameForEngineSpec]: boolean | undefined;
  [FeatureFlag.FireboltAppBypassQueryQueue]: boolean | undefined;
  [FeatureFlag.FireboltAppUseSpotInstancesByDefault]: boolean | undefined;
  [FeatureFlag.FireboltAppLinkToNewReleaseNotesSite]: boolean | undefined;
  [FeatureFlag.FireboltAppEnableBillingEngineChart]: boolean | undefined;
  [FeatureFlag.FireboltFirenzeEnabled]: boolean | undefined;
  [FeatureFlag.FireboltEnableSystemEngine]: boolean | undefined;
}

export const useAllFlags = () => {
  return {
    ...useFlags(),
  };
};

export const withFlags = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return (props: P) => {
    const flags = useAllFlags();
    return (
      <Component
        {...props}
        flags={flags}
      />
    );
  };
};
