import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import { EngineBasicQuery } from "relay/queries/engine/__generated__/EngineBasicQuery.graphql";
import {
  EngineDetailsQuery,
  EngineDetailsQuery$variables,
} from "./__generated__/EngineDetailsQuery.graphql";

export const enginesBasicQuery = graphql`
  query EngineBasicQuery($id: ID!) {
    account(id: $id) {
      id
      engines(first: 1000) {
        edges {
          node {
            id
            computeRegionId
            currentStatusSummary
            currentStatus
          }
        }
      }
    }
  }
`;

export const engineDetailsQuery = graphql`
  query EngineDetailsQuery($id: ID!) {
    engine(id: $id) {
      id
      name
      emoji
      endpoint
      computeRegion {
        id
        name
      }
      currentStatusSummary
      allowedActions(
        desiredActions: [
          "core.engines.start"
          "core.engines.stop"
          "core.engines.update"
          "core.engines.delete"
        ]
      )
      settingsPreset
      settings {
        autoStopDelayDurationSeconds
        warmUpLevel
      }
    }
  }
`;

export const fetchEnginesBasic = (environment, params) => {
  return fetchQuery<EngineBasicQuery>(
    environment,
    enginesBasicQuery,
    params
  ).toPromise();
};

export const fetchEngineById = (environment, params) => {
  return fetchQuery<EngineDetailsQuery>(
    environment,
    engineDetailsQuery,
    params as EngineDetailsQuery$variables
  ).toPromise();
};
