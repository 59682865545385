import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AuthResetPasswordStartInput } from "./__generated__/ResetPasswordStartMutation.graphql";

const mutation = graphql`
  mutation ResetPasswordStartMutation($input: AuthResetPasswordStartInput!) {
    authResetPasswordStart(input: $input) {
      success
    }
  }
`;

function commit(
  environment,
  input: AuthResetPasswordStartInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
