import { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { ReactComponent as BrokenLinkIllustration } from "assets/images/errorScreen/brokenLinkIllustartion.svg";
import { IAMService } from "businessLogic/services/iam";
import LoadingOverlap from "components/LoadingOverlap";
import ErrorScreen, {
  ErrorScreenImagePosition,
} from "components/ErrorScreen/ErrorScreen";
import CreateAccount from "../Account/CreateAccount";
import { verifyInvitationToken } from "./redux/actions";

export enum InvitationFlow {
  NewUser = "NEW_USER",
  ExistingExternalUser = "EXISTING_EXTERNAL_USER",
}

interface Props {
  verifyInvitationToken: (token: string, resolve, reject) => void;
  token: string;
  invitationFlow: InvitationFlow;
  accountId?: string;
}

interface State {
  isLoading: boolean;
  errorMessage: string | null;
  email?: string;
}

class InvitationTokenVerification extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: null,
    };
  }

  componentDidMount() {
    const { token, accountId, invitationFlow } = this.props;

    new Promise((resolve, reject) => {
      this.props.verifyInvitationToken(token, resolve, reject);
    })
      .then((data: { success: boolean; attachedEmail: string | null }) => {
        if (
          invitationFlow === InvitationFlow.ExistingExternalUser &&
          accountId
        ) {
          IAMService.acceptInvitation(token, accountId)
            .then(() => {
              this.setState({
                isLoading: false,
              });
            })
            .catch(err => {
              this.setState({ isLoading: false, errorMessage: err.message });
            });
        } else {
          this.setState({ isLoading: false, email: data.attachedEmail || "" });
        }
      })
      .catch(e => {
        this.setState({ isLoading: false, errorMessage: e });
      });
  }

  render() {
    const { isLoading, errorMessage, email } = this.state;
    const { token, invitationFlow } = this.props;

    if (isLoading) {
      return <LoadingOverlap isLoading />;
    }

    if (errorMessage) {
      return (
        <ErrorScreen
          imagePosition={ErrorScreenImagePosition.Top}
          title={errorMessage}
          description="Contact your account admin to resend the invitation link."
          image={<BrokenLinkIllustration />}
        />
      );
    }

    if (invitationFlow === InvitationFlow.NewUser) {
      return (
        <CreateAccount
          email={email}
          invitationToken={token}
        />
      );
    }

    if (invitationFlow === InvitationFlow.ExistingExternalUser) {
      return (
        <Navigate
          to="/"
          replace
        />
      );
    }
  }
}

export { InvitationTokenVerification };

export default connect(null, { verifyInvitationToken })(
  InvitationTokenVerification
);
