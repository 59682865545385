import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigateFunction } from "react-router/lib/hooks";
import { useParams } from "react-router";

export interface WithRouterProps {
  location: Location;
  navigate: NavigateFunction;
  params: { [key: string]: string };
}

const withRouter = WrappedComponent => {
  return props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <WrappedComponent
        location={location}
        params={params}
        navigate={navigate}
        {...props}
      />
    );
  };
};

export default withRouter;
