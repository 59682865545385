import { useContext } from "react";
import { ThemeContext } from "themes/themeContext";
import Toggle from "../Toggle";

import { ReactComponent as LightThemeIcon } from "assets/icons/LightTheme.svg";
import { ReactComponent as DarkThemeIcon } from "assets/icons/DarkTheme.svg";

import styles from "./styles.module.scss";

interface Props {
  isLoggedIn: boolean;
  theme?: string;
  toggle?: (string) => void;
}

const ThemeSwitcher = (props: Props) => {
  const { isLoggedIn } = props;
  const { theme, toggle } = useContext(ThemeContext);

  const onThemeChange = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("firebolt_theme", newTheme);
    toggle(newTheme);
  };

  if (isLoggedIn) {
    return (
      <div className={styles.toggleWrap}>
        <Toggle
          checked={theme === "dark"}
          onChange={onThemeChange}
          vertical
          dataTestId={`theme-switch-${theme}`}
          classes={{ ...styles }}
          icon={<LightThemeIcon />}
          iconChecked={<DarkThemeIcon />}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default ThemeSwitcher;
