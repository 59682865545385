import React from "react";
import cn from "classnames";
import { Divider, List, Popover } from "@material-ui/core";
import styles from "./ActionMenu.module.scss";
import uniqueid from "lodash/uniqueId";
import {
  PopoverOrigin,
  PopoverPosition,
  PopoverReference,
} from "@material-ui/core/Popover/Popover";

interface Props {
  lists: React.ReactNode[][];
  open: boolean;
  anchorEl?: HTMLElement | null;
  anchorReference?: PopoverReference;
  anchorPosition?: PopoverPosition;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  testId?: string;
  classes?: any;
}

const ActionMenu = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    lists,
    open,
    anchorEl,
    anchorPosition,
    onClose,
    anchorOrigin,
    anchorReference,
    transformOrigin,
    testId,
    classes = {},
  } = props;

  return (
    <Popover
      open={open}
      data-testid={testId}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
        ...transformOrigin,
      }}
      classes={{ paper: cn(styles.paper, classes?.paper) }}
      anchorEl={anchorEl}
      anchorReference={anchorReference}
      anchorPosition={anchorPosition}
      onClose={onClose}
      ref={ref}
    >
      <List
        component="nav"
        classes={{ root: styles.list }}
      >
        {lists.flatMap((item, index) => {
          if (index > 0) {
            return [
              <Divider
                key={uniqueid()}
                classes={{ root: styles.divider }}
              />,
              item,
            ];
          }

          return item;
        })}
      </List>
    </Popover>
  );
});

export default ActionMenu;
