import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { ScriptUpdateInput } from "relay/mutations/userScript/__generated__/ScriptUpdateMutation.graphql";

const mutation = graphql`
  mutation ScriptUpdateMutation($input: ScriptUpdateInput!) {
    scriptUpdate(input: $input) {
      success
      script {
        id
        data
        databaseId
        isStarred
        displayName
        lastUpdateTime
      }
    }
  }
`;

function commit(environment, input: ScriptUpdateInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
