import * as _ from "lodash";
import { Action } from "redux-actions";
import { all, put, select, takeEvery } from "redux-saga/effects";
import {
  deleteUserQuery,
  getUserQueries,
  saveUserQueryItem,
  updateUserQueryItem,
} from "businessLogic/services/query";

import * as QueryActions from "../actions/queries";
import {
  UserScriptsFailure,
  LoadUserScriptsSuccess,
  UserScriptsAction,
  SaveUserScriptsSuccess,
  UserScriptsAddNew,
  DeleteUserScriptsSuccess,
  UpdateUserScriptsSuccess,
  GlobalizeUserScriptSuccess,
  UpdateGlobalUserScriptsSuccess,
  UserGlobalScriptsAddNew,
  DeleteGlobalUserScriptsSuccess,
} from "../actions/userScripts";
import { UserScript, UserScriptPayload } from "../../constants/interfaces";
import {
  putStatusMessage,
  StatusMessagePosition,
  StatusMessageType,
} from "../../../StatusMessage/redux/actions";
import { getAllScripts } from "../../utils/scripts";

export const getUserScripts = state =>
  _.get(state, "query.userScripts.scripts");
export const getGlobalScripts = state =>
  _.get(state, "query.userScripts.globalScripts");
export const getQueries = state => _.get(state, "query.queries");

export function* duplicateUserScript(action: Action<UserScriptPayload>) {
  const { userScript, database, globalScript } = action.payload;

  const userId = yield select(state => state.user.userData.user.id);

  if (!userScript) {
    return yield put(UserScriptsFailure("No script found!"));
  }

  try {
    if (globalScript) {
      yield duplicateGlobalScript({ script: userScript, userId });
    } else {
      yield duplicateRegularScript({ script: userScript, database, userId });
    }
  } catch (error) {
    yield put(UserScriptsFailure(error.message || "errors.general"));
  }
}

function* duplicateRegularScript({ script, database, userId }) {
  const userUserScript = yield saveUserQueryItem([script], database.id, userId);

  yield put(
    UserScriptsAddNew({
      ...userUserScript[0],
    })
  );
}
function* duplicateGlobalScript({ script, userId }) {
  const userUserScript = yield saveUserQueryItem([script], null, userId);
  yield put(
    UserGlobalScriptsAddNew({
      ...userUserScript[0],
    })
  );
}

export function* saveUserScriptFromQuery(
  action: Action<{
    query: any;
    database: any;
    afterSaveCallback?: (script?: UserScript) => void;
  }>
) {
  try {
    const { query, database, afterSaveCallback } = action.payload;

    const databaseId = database.isVirtual ? null : database.id;

    const userScript = {
      database_id: databaseId,
      name: query.name,
      query: query.query,
    };

    const userId = yield select(state => state.user.userData.user.id);

    // @TODO remove  array payload

    const userUserScript = yield saveUserQueryItem(
      [userScript],
      databaseId,
      userId
    );

    const savedUserScript = userUserScript[0];

    if (database.isVirtual) {
      yield put(
        UserGlobalScriptsAddNew({
          ...savedUserScript,
        })
      );
    } else {
      yield put(
        SaveUserScriptsSuccess({
          ...savedUserScript,
        })
      );
    }

    // query is open, so weed to update query tab title
    if (query?.id) {
      yield put(
        QueryActions.querySavedAsScript(
          query.id,
          database.name,
          savedUserScript.id
        )
      );
    }

    afterSaveCallback?.(savedUserScript);
  } catch (error) {
    const { query } = action.payload;
    if (query.isImport) {
      yield put(
        putStatusMessage(
          `Failed to import ${query.name}, corrupt file`,
          StatusMessageType.Error,
          {
            id: null,
            insertToPosition: StatusMessagePosition.Top,
            autoRemove: false,
          }
        )
      );
      return;
    }
    yield put(UserScriptsFailure(error.message || "errors.general"));
  }
}

export function* updateUserScript(
  action: Action<{
    userScript: UserScript;
    database: any;
    globalScript: boolean;
    afterSaveCallback?: (script?: UserScript) => void;
  }>
) {
  try {
    const { userScript, database, globalScript, afterSaveCallback } =
      action.payload;
    const userQueries = yield updateUserQueryItem([userScript]);
    // @todo backend should only return one element
    const updatedUserScript = userQueries[0];

    if (globalScript) {
      yield updateGlobalUserScript({ updatedUserScript, database, userScript });
    } else {
      yield updateRegularUserScript({
        updatedUserScript,
        database,
        userScript,
      });
    }

    afterSaveCallback?.(updatedUserScript);
  } catch (error) {
    yield put(UserScriptsFailure(error.message || "errors.general"));
  }
}

function* renameQueryTabIfNeeded({ updatedUserScript, database, userScript }) {
  const query = yield getQueryByScriptId(database.name, userScript.id);
  if (query && updatedUserScript.name !== query.name) {
    // query is open, ne weed to update query tab title
    yield put(
      QueryActions.renameQuery(updatedUserScript.name, database.name, query.id)
    );
  }
}

function* updateRegularUserScript({ updatedUserScript, database, userScript }) {
  yield put(UpdateUserScriptsSuccess(updatedUserScript));

  yield renameQueryTabIfNeeded({ updatedUserScript, database, userScript });
}

function* updateGlobalUserScript({ updatedUserScript, database, userScript }) {
  yield put(UpdateGlobalUserScriptsSuccess(updatedUserScript));

  yield renameQueryTabIfNeeded({ updatedUserScript, database, userScript });
}

export function* globalizeUserScript(
  action: Action<{ userScript: UserScript }>
) {
  try {
    const { userScript } = action.payload;
    const formattedScript = { ...userScript, databaseId: {} };

    yield updateUserQueryItem([formattedScript]);
    yield put(GlobalizeUserScriptSuccess(formattedScript));
  } catch (error) {
    yield put(UserScriptsFailure(error.message || "errors.general"));
  }
}

export function* removeUserScript(action: Action<UserScriptPayload>) {
  const { userScript, database, globalScript } = action.payload;
  try {
    yield deleteUserQuery(userScript.id);

    if (globalScript) {
      yield deleteGlobalScript({ database, userScript });
    } else {
      yield deleteRegularScript({ database, userScript });
    }
  } catch (error) {
    yield put(UserScriptsFailure(error.message || "errors.general"));
  }
}

function* deleteRegularScript({ database, userScript }) {
  yield put(DeleteUserScriptsSuccess(userScript));

  yield closeTabIfNeeded({ database, userScript });
}

function* deleteGlobalScript({ database, userScript }) {
  yield put(DeleteGlobalUserScriptsSuccess(userScript));

  yield closeTabIfNeeded({ database, userScript });
}

function* closeTabIfNeeded({ database, userScript }) {
  const query = yield getQueryByScriptId(database.name, userScript.id);
  const allScripts = yield select(getAllScripts, database.name);
  if (query) {
    // query is open, so weed to close the tab as well
    yield put(
      QueryActions.removeQuery({
        database: database.name,
        queryId: query.id,
        allScripts,
      })
    );
  }
}

export function* loadUserScripts(action: Action<{ database: any }>) {
  try {
    const { database } = action.payload;

    let scripts = [];

    if (!database.isVirtual) {
      scripts = yield getUserQueries(database.id, false);
    }

    const globalScripts = yield getUserQueries(null, true);
    const payload = { scripts, globalScripts };

    yield put(LoadUserScriptsSuccess(payload));
  } catch (error) {
    yield put(UserScriptsFailure(error.message || "Error loading scripts!"));
  }
}

function* getQueryByScriptId(database, userScriptId) {
  const dbQueriesState = yield select(getQueries);
  return _.find(dbQueriesState.databasesQueries?.[database]?.queriesById, {
    userScriptId,
  });
}

export function* openQueryFromUserScript(
  action: Action<{
    userScript: UserScript;
    database: any;
  }>
) {
  const { userScript, database } = action.payload;
  const query = yield getQueryByScriptId(database.name, userScript.id);

  if (query) {
    // query is already open, just need to make it active
    yield put({
      type: QueryActions.SELECT_QUERY,
      payload: {
        queryId: query.id,
        database: database.name,
      },
    });
  } else {
    // create new query from script
    yield put(
      QueryActions.newQuery({
        database: database.name,
        savedQuery: userScript,
      })
    );
  }
}

const autoSaveUserScripts = {};

export function* autoSaveQuery(
  action: Action<{ sqlQuery: any; database: any; query: any }>
) {
  const { sqlQuery, query } = action.payload;

  if (!query.userScriptId) return;

  const userScriptsList = yield select(getUserScripts);
  const globalScriptsList = yield select(getGlobalScripts);

  const userScript = _.find([...userScriptsList, ...globalScriptsList], {
    id: query.userScriptId,
  });

  if (!userScript) return;

  if (autoSaveUserScripts?.[userScript.id]) {
    clearTimeout(autoSaveUserScripts?.[userScript.id]);
  }

  userScript.query = sqlQuery;
  autoSaveUserScripts[userScript.id] = setTimeout(() => {
    updateUserQueryItem([userScript]).catch(e => {
      console.error("Failed to auto save script: ", e?.message);
    });
  }, 1000);
}

export function* UserScriptsSagas() {
  return yield all([
    takeEvery(UserScriptsAction.USER_SCRIPTS_LOAD, loadUserScripts),
    takeEvery(UserScriptsAction.USER_SCRIPTS_SAVE, saveUserScriptFromQuery),
    takeEvery(UserScriptsAction.USER_SCRIPTS_DUPLICATE, duplicateUserScript),
    takeEvery(UserScriptsAction.USER_SCRIPTS_DELETE, removeUserScript),
    takeEvery(UserScriptsAction.USER_SCRIPTS_UPDATE, updateUserScript),
    takeEvery(UserScriptsAction.USER_SCRIPTS_SELECT, openQueryFromUserScript),
    takeEvery(UserScriptsAction.GLOBALIZE_SCRIPT, globalizeUserScript),
    takeEvery(QueryActions.UPDATE_QUERY, autoSaveQuery),
  ]);
}
