import graphql from "babel-plugin-relay/macro";

export const engineQuery = graphql`
  query EngineQuery($engineId: ID!, $accountId: ID!) {
    engine(id: $engineId) {
      id
      name
      emoji
      computeRegion {
        id
        name
      }
      currentStatusSummary
      latestRevision {
        id
        specification {
          dbComputeInstancesCount
          dbComputeInstancesUseSpot
          dbComputeInstancesType {
            id
            pricePerHourCents(accountId: $accountId)
            name
            cpuVirtualCoresCount
            memorySizeBytes
            storageSizeBytes
          }
        }
      }
      settingsPreset
      settings {
        autoStopDelayDurationSeconds
        warmUpLevel
      }
      bindings {
        edges {
          node {
            engineIsDefault
          }
        }
      }
    }
  }
`;
