import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

type RefCallback = (node?: React.ReactNode) => null;

export function useHookWithRefCallback(action: RefCallback = () => null) {
  const ref = useRef(null);
  const setRef = useCallback(
    node => {
      if (ref.current) {
        return;
      }

      if (node) {
        action(node);
      }

      ref.current = node;
    },
    [action]
  );

  return [setRef];
}

const selectUserAccountId = state => state.account?.accountDetails?.id;
const accountIdSelector = createSelector(selectUserAccountId, id => id);

export function useAccountId() {
  const stateSelector = useCallback(accountIdSelector, []);
  return useSelector(stateSelector);
}

export function usePopover() {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const openPopover = () => setOpen(true);
  const closePopover = () => setOpen(false);

  const api = {
    open,
    ref,
    openPopover,
    closePopover,
  };

  return api;
}

type Distance = {
  toTop: number;
  toBottom: number;
};

export function useDistance(el: HTMLDivElement | null): Distance {
  if (!el) {
    return {
      toBottom: 0,
      toTop: 0,
    };
  }

  const rect = (el as HTMLElement).getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    toTop: rect.top + scrollTop,
    toBottom: window.innerHeight - rect.bottom,
  };
}

export function useKeyPress(key: string, handler: Function) {
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === key) {
        handler(event);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [key, handler]);
}
