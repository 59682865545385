import * as _ from "lodash";
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { Action } from "redux-actions";
import * as queriesApi from "businessLogic/services/query/execute";
import * as MetadataService from "businessLogic/services/metadata";
import {
  EmptyTableDetails,
  LoadTableDetailsFailed,
  LoadTableDetailsSuccess,
  TableDetailsAction,
} from "../actions/tableDetails";
import {
  LoadTables,
  LoadTablesFailed,
  LoadTablesSuccess,
  startLoading,
  stopLoading,
  TablesAction,
  UnselectTable,
  UnfreezeFetchTables,
} from "../actions/tables";
import {
  putStatusMessage,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import {
  getCurrentQueryTabId,
  getDatabaseQueries,
  getDatabases,
  getQuery,
} from "redux/query/selectors";
import {
  NEW_QUERY,
  SELECT_QUERY,
  UPDATE_QUERY_ENGINE_ID,
  EXECUTE_QUERY_SUCCESS,
} from "../actions/queries";
import { DatabaseActions } from "pages/Database/redux/actions";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";
import { getUsedTablesFromExplain } from "components/QueryEditor/utils/views";
import { QUERY_SETTINGS } from "./constants";
import { TableTypes } from "../../constants";
import {
  LoadViews,
  LoadViewsFailed,
  LoadViewsSuccess,
  UnfreezeFetchViews,
  ViewsAction,
} from "../actions/views";
import * as SidebarActions from "../actions/sidebar";
import { withBypassQueueSettings } from "./helpers";
import {
  getCurrentQuery,
  getEngine,
  getSelectedEngine,
} from "../../utils/database";
import { FeatureFlag } from "featureFlags/constants";
import { isRefreshTablesCommand, isRefreshViewsCommand } from "../helpers";

type executeMetadataParams = {
  query: string;
  engineEndpoint: EngineEndpointResult;
  formatter?: Function;
};

const METADATA_QUERY_SETTINGS = {
  [QUERY_SETTINGS.noProgress]: true,
  [QUERY_SETTINGS.advancedMode]: 1,
  [QUERY_SETTINGS.hiddenQuery]: 1,
};

export type FindBy = { name?: string; id?: string };

export type EngineEndpointResult = {
  id: string;
  databaseName: string;
  endpoint: string;
  engine: any;
};

const fetchingResourceByDatabase = {
  tables: {},
};

function* findDatabase(findByDatabase: FindBy) {
  const databases = yield select(getDatabases);
  return _.find(
    databases,
    findByDatabase.id
      ? ["id", findByDatabase.id]
      : ["name", findByDatabase.name]
  );
}

export function* findEngineEndpoint(findByDatabase: FindBy): any {
  const database = yield findDatabase(findByDatabase);

  if (!database) {
    return null;
  }

  const state = yield select();
  if (!state.query.queries.databasesQueries?.[database.name]) {
    yield delay(0);
  }

  const currentQueryTabId = yield select(getCurrentQueryTabId, database.name);
  const selectedQuery = yield select(
    getQuery,
    database.name,
    currentQueryTabId
  );

  const { engineId = null } = selectedQuery ?? {};

  const EngineOnPredicate = {
    status: UIEngineStatus.STATUS_ON,
  };

  if (engineId) {
    const selectedEngine = _.find(database.engines ?? [], {
      id: engineId,
      ...EngineOnPredicate,
    });
    if (selectedEngine) {
      return {
        id: selectedEngine.id,
        endpoint: `https://${selectedEngine.endpoint}`,
        databaseName: database.name,
        engine: selectedEngine,
      };
    }
  }

  const runningEngine = _.find(database.engines ?? [], EngineOnPredicate);

  if (!runningEngine) {
    return null;
  }

  return {
    endpoint: `https://${runningEngine.endpoint}`,
    databaseName: database.name,
    engine: runningEngine,
  };
}

export function* executeMetadataQuery({
  query,
  engineEndpoint,
  formatter = _.identity,
}: executeMetadataParams) {
  const querySettings = yield withBypassQueueSettings({
    settings: METADATA_QUERY_SETTINGS,
    engineId: engineEndpoint.id,
  });

  const results = yield queriesApi.executeQuery({
    query,
    database: engineEndpoint.databaseName,
    queryId: null,
    engineEndpoint: engineEndpoint.endpoint,
    querySettings,
    engine: engineEndpoint.engine,
  });

  if (!results || _.isEmpty(results?.data)) return [];

  return formatter(results);
}

export function* getTableColumns(
  engineEndpoint: EngineEndpointResult,
  table: string
) {
  const results = yield call(executeMetadataQuery, {
    query: `DESCRIBE ${table}`,
    engineEndpoint,
  });

  if (!results || _.isEmpty(results.data)) return null;

  return _.map(results.data, ({ column_name, data_type }) => ({
    name: column_name,
    type: data_type,
  }));
}

export function* getTableIndexes(
  engineEndpoint: EngineEndpointResult,
  table: any
) {
  // external tables does not support indexes
  if (table?.type === "EXTERNAL") return;

  const results = yield call(executeMetadataQuery, {
    query: `select * from information_schema.indexes where table_name = '${table.name}'`,
    engineEndpoint,
  });

  if (!results || _.isEmpty(results.data)) return null;

  return _.chain(results.data)
    .filter({ index_type: "aggregating" }) // we what to display only indexes from type aggregating  (not primary)
    .map(({ index_name }) => ({ name: index_name }))
    .value();
}

export const formatTableData = results =>
  _.map(results.data, ({ table_name, table_type, primary_index, state }) => ({
    name: table_name,
    type: table_type,
    primaryIndexColumns: primary_index
      ? primary_index.split(",").map(col => col.trim())
      : [],
    state,
  }));

const formatViewData = results =>
  _.map(results.data, ({ view_name, schema }) => ({
    name: view_name,
    type: TableTypes.VIEW,
    schema,
  }));

export function* fetchViewsSaga(action: Action<{ database: string }>) {
  const { database } = action.payload;

  try {
    const engineEndpoint = yield findEngineEndpoint({
      name: database,
    });
    // There is no running engine to execute the query
    if (!engineEndpoint || engineEndpoint.engine.isSystem) {
      return;
    }

    const views = yield call(executeMetadataQuery, {
      query: "SHOW VIEWS",
      engineEndpoint,
      formatter: formatViewData,
    });

    yield put(LoadViewsSuccess(database, views));
    yield delay(1000);
    yield put(UnfreezeFetchViews(database));
  } catch (error) {
    console.error(`Failed to fetch ${database} views:`, error);
    yield put(LoadViewsFailed(database, error));
  }
}

export function* fetchTablesSaga(action: Action<{ database: string }>) {
  const { database } = action.payload;

  try {
    const engineEndpoint = yield findEngineEndpoint({
      name: database,
    });

    // There is no running engine to execute the query or tables are already being fetched
    if (
      !engineEndpoint ||
      fetchingResourceByDatabase.tables[database] ||
      engineEndpoint.engine.isSystem
    ) {
      return;
    }

    fetchingResourceByDatabase.tables[database] = true;
    const fetchedTables = yield call(executeMetadataQuery, {
      query: "SHOW TABLES",
      engineEndpoint,
      formatter: formatTableData,
    });

    const savedTables = yield select(
      state => state.query.tables.tablesByDatabase?.[database]
    );

    if (!_.isEqual(savedTables, fetchedTables)) {
      yield put(startLoading(database));
      yield delay(500);
      yield put(LoadTablesSuccess(database, fetchedTables));
    }

    yield delay(1000);
    yield put(UnfreezeFetchTables(database));
  } catch (error) {
    console.error(`Failed to fetch ${database} tables:`, error);
    yield put(LoadTablesFailed(database, error));
  } finally {
    fetchingResourceByDatabase.tables[database] = false;
    yield put(stopLoading(database));
  }
}

export function* fetchColumnsSaga(
  action: Action<{ database: string; table: { name: string; type: string } }>
) {
  const { database, table } = action.payload;
  const tableName = table.name;

  try {
    const engineEndpoint = yield findEngineEndpoint({
      name: database,
    });

    // There is no running engine to execute the query
    if (!engineEndpoint || engineEndpoint.engine.isSystem) {
      yield put(LoadTableDetailsSuccess(database, tableName, null, null));
      return;
    }
    const columns = yield getTableColumns(engineEndpoint, tableName);
    const indexes = yield getTableIndexes(engineEndpoint, table);
    if (!columns && !indexes) {
      yield put(UnselectTable(database));
      yield put(EmptyTableDetails(database, tableName));
    } else {
      yield put(LoadTableDetailsSuccess(database, tableName, columns, indexes));
    }
  } catch (error) {
    console.error(`Failed to fetch ${database}.${tableName} columns:`, error);
    yield put(LoadTableDetailsFailed(database, tableName, error));
  }
}

function* fetchViewExplainSaga(
  action: Action<{ database: string; name: string; schema: string }>
) {
  const { name, schema, database } = action.payload;

  try {
    const engineEndpoint = yield findEngineEndpoint({ name: database });
    if (!engineEndpoint || engineEndpoint.engine.isSystem) {
      yield put({
        type: ViewsAction.VIEW_EXPLAIN_FAILED,
        payload: { name, database, error: "No engine running" },
      });
      return;
    }

    const tableSchema = schema.replace(
      /create\s+view(\s+if\s+not\s+exists)?\s+.+?\s+as/i,
      ""
    );

    const query = `explain using json ${tableSchema}`;

    const results = yield call(executeMetadataQuery, {
      query,
      engineEndpoint,
    });

    const explain = _.get(results, ["data", 0, "output_lqp"]);
    const explainObject = JSON.parse(explain);
    const tables = getUsedTablesFromExplain(explainObject);

    yield put({
      type: ViewsAction.VIEW_EXPLAIN_SUCCESS,
      payload: { name, database, tables },
    });
  } catch (error) {
    console.error("Failed to explain view", error);
    yield put({
      type: ViewsAction.VIEW_EXPLAIN_FAILED,
      payload: { name, database, error },
    });
  }
}

export function* updateMetadataFlow(
  action: Action<{
    database?: string;
    databaseId?: string;
    engine?: any;
    query?: string;
  }>
) {
  const {
    database,
    databaseId,
    engine: engineWithLatestUpdates,
    query,
  } = action.payload;

  let foundDb: { name: string; engines: [] };
  if (databaseId) {
    foundDb = yield findDatabase({ id: databaseId });
  } else {
    foundDb = yield findDatabase({ name: database });
  }

  if (!foundDb) {
    return;
  }

  const engineIsOn = engine => engine?.status === UIEngineStatus.STATUS_ON;

  const engineIsRun = engine =>
    engine?.status === UIEngineStatus.STATUS_REPAIRING ||
    engine?.status === UIEngineStatus.STATUS_STARTING ||
    engine?.status === UIEngineStatus.STATUS_ON;

  const flags = yield select(state => state.flags.flags);
  const engineWithLatestUpdatesIsOn = engineWithLatestUpdates
    ? engineIsOn(engineWithLatestUpdates)
    : false;
  const engineWithLatestUpdatesIsRunning = engineWithLatestUpdates
    ? engineIsRun(engineWithLatestUpdates)
    : false;
  const engineWithCurrentSettings = getEngine(
    foundDb,
    engineWithLatestUpdates?.id
  );
  const engineWithCurrentSettingsIsOn = engineWithCurrentSettings
    ? engineIsOn(engineWithCurrentSettings)
    : false;
  const dbQueries = yield select(getDatabaseQueries, foundDb.name);
  const currentQuery = getCurrentQuery(dbQueries);
  const previouslySelectedEngineId = dbQueries.previouslySelectedEngineId;
  const selectedEngine = getSelectedEngine(foundDb, currentQuery);
  const selectedEngineIsOn = engineIsOn(selectedEngine);

  const forceFetch =
    action.type === UPDATE_QUERY_ENGINE_ID ||
    (!engineWithCurrentSettingsIsOn &&
      engineWithLatestUpdatesIsOn &&
      selectedEngine?.id === engineWithLatestUpdates?.id) ||
    ([SELECT_QUERY, NEW_QUERY].includes(action.type) &&
      selectedEngine?.id !== previouslySelectedEngineId &&
      selectedEngineIsOn);

  const forceFetchTables = !!query && isRefreshTablesCommand(query);
  const forceFetchViews = !!query && isRefreshViewsCommand(query);

  if (
    !_.some(
      _.filter(
        foundDb?.engines,
        (en: any) => en.id !== engineWithLatestUpdates?.id
      ) ?? [],
      engineIsRun
    ) &&
    !engineWithLatestUpdatesIsRunning
  ) {
    yield put(LoadViewsSuccess(foundDb.name, []));
    yield put(LoadTablesSuccess(foundDb.name, []));
    return;
  }

  try {
    const shouldFetchTables = yield select(
      MetadataService.shouldFetchTables,
      foundDb.name,
      forceFetch || forceFetchTables
    );

    const shouldFetchViews = yield select(
      MetadataService.shouldFetchViews,
      foundDb.name,
      {
        isDatabaseViewsEnabled: flags?.[FeatureFlag.FireboltAppShowViews],
        force: forceFetch || forceFetchViews,
      }
    );

    if (shouldFetchTables) {
      yield put(LoadTables(foundDb.name, false));
    }

    if (shouldFetchViews) {
      yield put(LoadViews(foundDb.name));
    }
  } catch (e) {
    console.error("failed to fetch the metadata when engine status is changed");
  }
  return;
}

function* searchColumnsSaga(
  action: Action<{ database: string; search: string }>
) {
  const { search, database } = action.payload;
  const searchEscaped = search
    .replace(/_/g, "\\\\_")
    .replace(/%/g, "\\\\%")
    .replace(/'/g, "\\'");

  try {
    const engineEndpoint = yield findEngineEndpoint({ name: database });
    // There is no running engine to execute the query
    if (!engineEndpoint || engineEndpoint.engine.isSystem) {
      yield put(LoadTablesSuccess(database, []));
      return;
    }

    const query = `
SELECT
  table_name,
  column_name
FROM
  information_schema.columns
WHERE
  column_name ILIKE '%${searchEscaped}%'
`;

    const columns = yield executeMetadataQuery({
      query,
      engineEndpoint,
      formatter: a => a.data,
    });

    const columnsByTable = columns.reduce((acc, item) => {
      const { column_name, table_name } = item;
      acc[table_name] = (acc[table_name] || []).concat([column_name]);
      return acc;
    }, {});

    yield put({
      type: SidebarActions.SET_COLUMNS,
      payload: { columnsByTable, search },
    });
  } catch (error) {
    console.error(`Failed to search ${database} columns:`, error);
    yield put({
      type: SidebarActions.SET_COLUMNS,
      payload: { columnsByTable: {}, search },
    });
    const errMessage = error.message || "Error: can not find columns!";
    yield put(putStatusMessage(errMessage, StatusMessageType.Error));
  }
}

export function* DatabaseMetadataSagas() {
  return yield all([
    takeEvery(TableDetailsAction.TABLE_DETAILS_LOAD, fetchColumnsSaga),
    takeEvery(TablesAction.TABLES_LOAD, fetchTablesSaga),
    takeEvery(SidebarActions.SEARCH_COLUMNS, searchColumnsSaga),
    takeEvery(ViewsAction.VIEWS_LOAD, fetchViewsSaga),
    takeEvery(ViewsAction.VIEW_EXPLAIN, fetchViewExplainSaga),
    takeLatest(
      [
        SELECT_QUERY,
        NEW_QUERY,
        UPDATE_QUERY_ENGINE_ID,
        DatabaseActions.SET_ENGINES,
        DatabaseActions.UPDATE_ENGINE,
        EXECUTE_QUERY_SUCCESS,
      ],
      updateMetadataFlow
    ),
  ]);
}
