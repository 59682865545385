import { FeatureFlag } from "featureFlags/constants";
import ErrorScreen, {
  ErrorScreenImagePosition,
} from "components/ErrorScreen/ErrorScreen";
import { ReactComponent as MaintenanceIllustration } from "assets/images/MaintenanceIllustration.svg";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";

export const withMaitenance = Component => props => {
  const flags = useAllFlags();
  const fullMaintenanceMode =
    flags?.[FeatureFlag.FireboltAppFullMaintenanceMode];

  if (fullMaintenanceMode) {
    return (
      <ErrorScreen
        title="Firebolt is under maintenance!"
        description={
          <>
            We are currently undergoing maintenance.
            <br />
            This won’t take long.
          </>
        }
        image={<MaintenanceIllustration />}
        imagePosition={ErrorScreenImagePosition.Top}
      />
    );
  }
  return <Component {...props} />;
};
