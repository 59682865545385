import { SET_COLUMNS } from "../actions/sidebar";

const INITIAL_STATE = {
  search: "",
  columnsByTable: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COLUMNS: {
      const { columnsByTable, search } = action.payload;
      return { columnsByTable, search };
    }
    default: {
      return state;
    }
  }
};
