/**
 * @generated SignedSource<<127f5a81324835661a30351f65877f35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AwsmarketplaceSubscriptionStatus = "UNSPECIFIED" | "ACTIVE" | "UNSUBSCRIBE_PENDING" | "STOPPED" | "FAILED" | "%future added value";
export type AwsmarketplaceSubscriptionTier = "UNSPECIFIED" | "BASIC" | "TRIAL" | "PRO" | "%future added value";
export type AwsmarketplaceSubscriptionType = "UNSPECIFIED" | "ANNUAL" | "PAY_PER_USE" | "%future added value";
export type AccountSubscriptionQuery$variables = {
  id: string;
};
export type AccountSubscriptionQuery$data = {
  readonly account: {
    readonly awsMarketplaceSubscription: {
      readonly accountId: string;
      readonly customerId: string;
      readonly expiresTime: any;
      readonly id: string;
      readonly status: AwsmarketplaceSubscriptionStatus;
      readonly tier: AwsmarketplaceSubscriptionTier;
      readonly type: AwsmarketplaceSubscriptionType;
    };
  };
};
export type AccountSubscriptionQuery = {
  variables: AccountSubscriptionQuery$variables;
  response: AccountSubscriptionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AwsmarketplaceSubscription",
  "kind": "LinkedField",
  "name": "awsMarketplaceSubscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    },
    {
      "alias": "expiresTime",
      "args": null,
      "kind": "ScalarField",
      "name": "expirationTime",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSubscriptionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountSubscriptionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17bcf10196f33b3f6b0e352ab9b4c319",
    "id": null,
    "metadata": {},
    "name": "AccountSubscriptionQuery",
    "operationKind": "query",
    "text": "query AccountSubscriptionQuery(\n  $id: ID!\n) {\n  account(id: $id) {\n    awsMarketplaceSubscription {\n      accountId\n      customerId\n      expiresTime: expirationTime\n      id\n      status\n      tier\n      type\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd10584803dc1fcba155acaec841e8c9";

export default node;
