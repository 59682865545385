import React from "react";
import classNames from "classnames";
import { ReactComponent as IncompleteSignupImg } from "assets/images/errorScreen/incompleteSignup.svg";
import ErrorScreen, {
  ErrorScreenImagePosition
} from "../../ErrorScreen/ErrorScreen";
import styles from "./styles.module.scss";

export const PendingInviteError = () => {
  return (
    <ErrorScreen
      title="Your user setup process was not completed"
      description={
        <div>
          You have a pending invitation, get back to your email and accept the
          invitation.
        </div>
      }
      imagePosition={ErrorScreenImagePosition.Top}
      image={<IncompleteSignupImg />}
      classes={{ description: classNames(styles.description, styles.forced) }}
    />
  );
};
