import React from "react";
import SnackBarView from "../../SnackBar";

const OfflineIndicator = () => {
  return (
    <SnackBarView
      message="Not connected. Check your network."
      snackBarKey="offline"
      vertical="top"
      horizontal="center"
      openSnackBar={true}
      type="error"
      displayInline={true}
      dimmed={true}
    />
  );
};

export default OfflineIndicator;
