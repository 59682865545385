export const TABLES_COMMANDS_THAT_TRIGGER_REFRESH = [
  /create\s+table/i,
  /create\s+fact\s+table/i,
  /create\s+dimension\s+table/i,
  /create\s+external\s+table/i,
  /drop\s+table/i,
  /drop\s+database/i,
  /alter\s+table/i,
  /rename\s+table/i,
  /create\s+and\s+generate\s+aggregating\s+index/i,
  /create\s+join\s+index/i,
  /drop\s+aggregating\s+index/i,
  /drop\s+view.+cascade/i,
];

export const VIEWS_COMMANDS_THAT_TRIGGER_REFRESH = [
  /create\s+view/i,
  /create\s+or\s+replace\s+view/i,
  /drop\s+view/i,
  /drop\s+database/i,
  /alter\s+view/i,
  /rename\s+view/i,
  /drop\s+table.+cascade/i,
];
