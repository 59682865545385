export const ROUTES = {
  ROLES: "/roles",
  USERS_LIST: "/users",
  DATABASE: "/database/",
  ACCOUNT_INFO: "/account-info",
  LOGOUT: "/logout",
  QUERY: "/query",
  ENGINES: "/engines",
  DATABASES: "/",
  SQLWORKSPACE: "/sql-workspace",
  CHOOSE_ACCOUNT: "/choose-account",
  ACCOUNTS: "/accounts",
  LOGIN: "/login",
  LINK_FAILURE: "/aws-marketplace-linking-failure",
  LINK_ALREADY_LINED: "/aws-marketplace-linking-already-linked",
  UNSUBSCRIBE: "/unsubscribe/:emailBase64",
  NO_ACCESS: "/access-error/no-access",
};

export const GUEST_ROUTES = {
  LOGIN_ERROR: "/login/error",
  LOGIN: "/login",
  CREATE_ACCOUNT: "/create-account",
  GETTING_STARTED: "/getting-started",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_TOKEN: "/password/reset/:tokenId",
  ACCEPT_INVITATION: "/accept-account-invitation/:accountId/:token",
};

export const GENERAL_ROUTES = {
  LINK_FAILURE: "/aws-marketplace-linking-failure",
  LINK_ALREADY_LINED: "/aws-marketplace-linking-already-linked",
  UNSUBSCRIBE: "/unsubscribe/:emailBase64",
  NO_ACCESS: "/access-error/no-access",
  LOGOUT: "/logout",
};

export const RESERVED_URL_SEARCH_PARAMS = {
  REDIRECT: "redirect",
};
