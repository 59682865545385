import React, { useState } from "react";
import AccountSelector from "components/AccountSelector/AccountSelector";
import { connect } from "react-redux";
import { putSelectedAccountId, UserData } from "redux/user/actions";
import _ from "lodash";
import withUserLoading from "components/common/withUserLoading";
import withRouter, { WithRouterProps } from "components/common/withRouter";
import { AccessManager } from "../../services/access/accessManager";
import withAccessManager from "../../components/common/withAccessManager";

interface Props extends WithRouterProps {
  userData: UserData | null;
  putSelectedAccountId: (
    accountId: string,
    resolve?: () => void,
    accessManager?: AccessManager
  ) => void;
  accessManager?: AccessManager;
}

const ChooseAccount = ({
  userData,
  putSelectedAccountId,
  navigate,
  accessManager,
}: Props) => {
  const [loadingAccountId, setLoadingAccountId] = useState<string | null>(null);

  if (!userData) {
    return null;
  }

  return (
    <AccountSelector
      onSelect={accountId => {
        setLoadingAccountId(accountId);
        new Promise<void>(resolve => {
          putSelectedAccountId(accountId, resolve, accessManager);
        }).then(() => {
          navigate("/", { replace: true });
        });
      }}
      accounts={userData.members.map(member => {
        return {
          id: member.accountId,
          title: member.accountTitle,
          isLoading: loadingAccountId === member.accountId,
        };
      })}
    />
  );
};

export { ChooseAccount };

export default withAccessManager(
  withUserLoading(
    withRouter(
      connect(
        state => {
          return {
            userData: _.get(state, "user.userData"),
          };
        },
        { putSelectedAccountId }
      )(ChooseAccount)
    ),
    { allowNotAcceptedUser: true, allowWithInactiveAccount: true }
  )
);
