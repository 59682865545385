export const TableTypes = {
  FACT: "FACT",
  DIMENSION: "DIMENSION",
  EXTERNAL: "EXTERNAL",
  VIEW: "VIEW",
};

export const DbObjectType = {
  TABLES: "Tables",
  SCRIPTS: "SQL Scripts",
  EXTERNAL_TABLES: "External Tables",
  COLUMNS: "Columns",
  INDEXES: "Indexes",
  VIEWS: "Views",
};

// All sizes in px
export const MAX_SCRIPT_LENGTH = 255;
export const RIGHT_DEFAULT_WIDTH_SIDEBAR = 267;
export const RIGHT_MAX_WIDTH_SIDEBAR = 500;
export const COLLAPSED_RIGHT_SIDEBAR_WIDTH = 57;
export const COLLAPSED_LEFT_SIDEBAR_WIDTH = 57;
export const MAIN_SIDEBAR_WIDTH = 48;
export const QUERY_BROWSER_PADDING = 33;
export const EXAPNDED_MODE_MARGIN_BOTTOM = 16;

export const SCRIPTS_TABS_HEIGHT = 46;
export const MIN_EDITOR_HEIGHT = 140;
export const RESULT_TABS_TOP_MARGIN = 28;
export const RESULT_TABS_ACTUAL_HEIGHT = 216;
export const RESULT_TABS_HEIGHT =
  RESULT_TABS_TOP_MARGIN + RESULT_TABS_ACTUAL_HEIGHT;

export const LEFT_DEFAULT_SIDEBAR = 258;
export const LEFT_MIN_SIDEBAR = 227;
export const LEFT_MAX_SIDEBAR = 500;
export const LEFT_COLLAPSED_SIDEBAR = 57;

// tab width should match the tab width plus its horizontal margins
export const DEFAULT_TAB_WIDTH = 60;
export const ARROW_BTN_WIDTH = 24;
export const QUERY_EDITOR_HEIGHT = 200;
export const MIN_CONSTRAIN_MAX = 193;

const withNullableTypes = types => {
  return types.reduce(
    (acc, type) => [...acc, type, `${type} NULL`, `${type} NOT NULL`],
    []
  );
};

export const DATE_TYPES = withNullableTypes([
  "DATE",
  "PGDATE",
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMESTAMPNTZ",
  "DATETIME",
]);

export const FLOAT_TYPES = withNullableTypes(["FLOAT", "DOUBLE", "DECIMAL"]);

export const BOOLEAN_TYPES = withNullableTypes(["BOOLEAN"]);

export const INTEGER_TYPES = withNullableTypes(["INT", "INTEGER", "LONG"]);

export const JSON_TYPES = withNullableTypes(["JSON"]);

export const STRING_TYPES = withNullableTypes(["STRING", "TEXT"]);

export const BYTEA_TYPES = withNullableTypes(["BYTEA"]);

export const NUMBER_TYPES = [...INTEGER_TYPES, ...FLOAT_TYPES];

export const isDateType = type => {
  return DATE_TYPES.includes(type);
};

export const isDecimalType = type => {
  return type.match(/^decimal(.+)?/i);
};

export const isFloatType = type => {
  return FLOAT_TYPES.includes(type);
};

export const isIntegerType = type => {
  return INTEGER_TYPES.includes(type);
};

export const isNumberType = type => {
  return NUMBER_TYPES.includes(type) || isDecimalType(type);
};

export const isStringType = type => {
  return STRING_TYPES.includes(type);
};

export const SQL_FORMAT_MAX_TEXT_LENGTH = 20000;
