import { Action, handleActions } from "redux-actions";
import _ from "lodash";
import { UserScriptsAction } from "../actions/userScripts";
import { UserScript, UserScriptsState } from "../../constants/interfaces";
import { ScriptsSections } from "components/common/types";

export const INITIAL_STATE: UserScriptsState = {
  scripts: [],
  globalScripts: [],
  isLoadingUserScripts: false,
  error: null,
  activeSections: {
    [ScriptsSections.Regular]: true,
  },
};

const reducerMap = {};

reducerMap[UserScriptsAction.USER_SCRIPTS_LOAD] = (
  state: UserScriptsState
): UserScriptsState => {
  return {
    ...state,
    isLoadingUserScripts: true,
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_LOAD_SUCCESS] = (
  state: UserScriptsState,
  action: Action<any>
): UserScriptsState => {
  return {
    ...state,
    scripts: action.payload.scripts,
    globalScripts: action.payload.globalScripts,
    isLoadingUserScripts: false,
    error: null,
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_LOAD_FAIL] = (
  state: UserScriptsState,
  action: Action<string>
): UserScriptsState => {
  return {
    ...state,
    error: action.payload,
    isLoadingUserScripts: false,
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_SAVE_SUCCESS] = (
  state: UserScriptsState,
  action: Action<{ userScript: UserScript }>
): UserScriptsState => {
  return {
    ...state,
    scripts: [...state.scripts, action.payload.userScript],
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_ADD_NEW] = (
  state: UserScriptsState,
  action: Action<{ userScript: UserScript }>
): UserScriptsState => {
  return {
    ...state,
    scripts: [...state.scripts, action.payload.userScript],
  };
};

reducerMap[UserScriptsAction.USER_GLOBAL_SCRIPTS_ADD_NEW] = (
  state: UserScriptsState,
  action: Action<{ userScript: UserScript }>
): UserScriptsState => {
  return {
    ...state,
    globalScripts: [...state.globalScripts, action.payload.userScript],
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_DELETE_SUCCESS] = (
  state: UserScriptsState,
  action: Action<UserScript>
): UserScriptsState => {
  return {
    ...state,
    scripts: _.reject(state.scripts, { id: action.payload.id }),
  };
};

reducerMap[UserScriptsAction.USER_GLOBAL_SCRIPTS_DELETE_SUCCESS] = (
  state: UserScriptsState,
  action: Action<UserScript>
): UserScriptsState => {
  return {
    ...state,
    globalScripts: _.reject(state.globalScripts, { id: action.payload.id }),
  };
};

reducerMap[UserScriptsAction.GLOBALIZE_SCRIPT_SUCCESS] = (
  state: UserScriptsState,
  action: Action<UserScript>
): UserScriptsState => {
  const [globalScripts, scripts] = _.partition(state.scripts, {
    id: action.payload.id,
  });

  return {
    ...state,
    scripts,
    globalScripts: [...state.globalScripts, ...globalScripts],
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_UPDATE_SUCCESS] = (
  state: UserScriptsState,
  action: Action<UserScript>
): UserScriptsState => {
  return {
    ...state,
    scripts: updateScriptInList(action.payload, state.scripts),
  };
};

reducerMap[UserScriptsAction.USER_GLOBAL_SCRIPTS_UPDATE_SUCCESS] = (
  state: UserScriptsState,
  action: Action<UserScript>
): UserScriptsState => {
  return {
    ...state,
    globalScripts: updateScriptInList(action.payload, state.globalScripts),
  };
};

reducerMap[UserScriptsAction.SCRIPT_SECTION_TOGGLE] = (
  state: UserScriptsState,
  action: Action<any>
): UserScriptsState => {
  const currentSectionState = state.activeSections[action.payload];
  return {
    ...state,
    activeSections: {
      ...state.activeSections,
      [action.payload]: !currentSectionState,
    },
  };
};
reducerMap[UserScriptsAction.USER_SCRIPTS_FAILURE] = (
  state: UserScriptsState,
  action: Action<string>
): UserScriptsState => {
  return {
    ...state,
    error: action.payload,
  };
};

reducerMap[UserScriptsAction.USER_SCRIPTS_RELEASE_ERROR] = (
  state: UserScriptsState
): UserScriptsState => {
  return {
    ...state,
    error: null,
  };
};

function updateScriptInList(script, scriptList) {
  const { id } = script;
  const index = _.findIndex(scriptList, { id });
  const newScriptList = [...scriptList];
  newScriptList[index] = { ...newScriptList[index], ...script };
  return newScriptList;
}

export default handleActions<UserScriptsState, any>(reducerMap, INITIAL_STATE);
