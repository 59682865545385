import { createAction } from "redux-actions";

export enum SubscriptionStatus {
  SUBSCRIPTION_STATUS_UNSPECIFIED = "UNSPECIFIED",
  SUBSCRIPTION_STATUS_ACTIVE = "ACTIVE",
  SUBSCRIPTION_STATUS_UNSUBSCRIBE_PENDING = "UNSUBSCRIBE_PENDING",
  SUBSCRIPTION_STATUS_STOPPED = "STOPPED",
  SUBSCRIPTION_STATUS_FAILED = "FAILED",
}

export enum SubscriptionTier {
  SUBSCRIPTION_TIER_UNSPECIFIED = "UNSPECIFIED",
  SUBSCRIPTION_TIER_BASIC = "BASIC",
  SUBSCRIPTION_TIER_TRIAL = "TRIAL",
  SUBSCRIPTION_TIER_PRO = "PRO",
}

export enum AwsmarketplaceSubscriptionType {
  UNSPECIFIED = "UNSPECIFIED",
  ANNUAL = "ANNUAL",
  PAY_PER_USE = "PAY_PER_USE",
}

export interface Subscription {
  accountId: string;
  customerId: string;
  expiresTime: string;
  id: string;
  status: SubscriptionStatus;
  tier: SubscriptionTier;
  type: AwsmarketplaceSubscriptionType;
}

export const SubscriptionActions = {
  LOAD_SUBSCRIPTION: "USER/LOAD_SUBSCRIPTION",
  LOAD_SUBSCRIPTION_FAILURE: "USER/LOAD_SUBSCRIPTION_FAILURE",
  LOAD_SUBSCRIPTION_SUCCESS: "USER/LOAD_SUBSCRIPTION_SUCCESS",
  LINK_AWS: "LINK_AWS",
};

export const loadSubscription = createAction(
  SubscriptionActions.LOAD_SUBSCRIPTION,
  (accountId: string, isSilent: boolean) => ({
    accountId,
    isSilent,
  })
);

export const loadSubscriptionFailure = createAction(
  SubscriptionActions.LOAD_SUBSCRIPTION_FAILURE,
  (errorMessage: string) => errorMessage
);

export const loadSubscriptionSuccess = createAction(
  SubscriptionActions.LOAD_SUBSCRIPTION_SUCCESS,
  (subscription: Subscription) => subscription
);

export const linkAws = createAction(
  SubscriptionActions.LINK_AWS,
  (token: string, resolve, reject) => ({ token, resolve, reject })
);
