import { Invite } from "components/WelcomeScreen/SubscriptionError/Invite";
import styles from "components/WelcomeScreen/SubscriptionError/styles.module.scss";

export const AdminError = props => {
  const { icon, message, title } = props;
  return (
    <div className={styles.admin} data-testid="admin-aws-subscription-error">
      {icon}
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.message}>{message}</div>
      <Invite />
    </div>
  );
};
