import _ from "lodash";
import { Formatter } from "businessLogic/helpers/Format";
import { ROUTES } from "businessLogic/routes.constants";
import {
  ContactFormField,
  ERROR_TYPES,
  ErrorObject,
  REQUIRED_FIELDS,
  SeverityValues,
  VALIDATION_BY_FIELD,
} from "./constants";
import { Translations } from "./translations";
import { getDatabaseQueriesFromQueries } from "../../QueryEditor/redux/selectors";
import {
  getCurrentQuery,
  getSelectedEngine,
} from "../../QueryEditor/utils/database";

export function generateDisabledFields(user, accountNameDisabled) {
  return {
    [ContactFormField.FirstName]: !!user?.firstName,
    [ContactFormField.LastName]: !!user?.lastName,
    [ContactFormField.Company]: accountNameDisabled,
    [ContactFormField.Email]: !!user?.email,
  };
}

const getValidationByLength = inputName =>
  VALIDATION_BY_FIELD[inputName]?.maxLength;

const generateInitialInput = (input = "", inputName) => {
  const maxLength = getValidationByLength(inputName) || 0;

  return input.length > maxLength ? input.substr(0, maxLength) : input;
};

export function generateInitialState({
  preCompletedFields,
  user,
  accountName,
  defaultEngineName,
}) {
  return {
    [ContactFormField.FirstName]: generateInitialInput(
      preCompletedFields?.[ContactFormField.FirstName] || user?.firstName,
      ContactFormField.FirstName
    ),
    [ContactFormField.LastName]: generateInitialInput(
      preCompletedFields?.[ContactFormField.LastName] || user?.lastName,
      ContactFormField.LastName
    ),
    [ContactFormField.Company]: generateInitialInput(
      preCompletedFields?.[ContactFormField.Company] || accountName,
      ContactFormField.Company
    ),
    [ContactFormField.Email]: generateInitialInput(
      preCompletedFields?.[ContactFormField.Email] || user?.email,
      ContactFormField.Email
    ),
    [ContactFormField.Severity]: SeverityValues.S3,
    [ContactFormField.EngineName]: defaultEngineName,
    [ContactFormField.Subject]:
      preCompletedFields?.[ContactFormField.Subject] || "",
    [ContactFormField.Description]: "",
  };
}

export function prepareDataForRequest(data) {
  const formData = {
    [ContactFormField.Origin]: "Web",
    [ContactFormField.Name]: `${data[ContactFormField.FirstName]} ${
      data[ContactFormField.LastName]
    }`,
    [ContactFormField.Company]: data[ContactFormField.Company],
    [ContactFormField.Email]: data[ContactFormField.Email],
    [ContactFormField.Subject]: data[ContactFormField.Subject],
    [ContactFormField.Description]: data[ContactFormField.Description],
    [ContactFormField.Severity]: data[ContactFormField.Severity],
    [ContactFormField.EngineName]: data[ContactFormField.EngineName],
  };
  return JSON.stringify(formData);
}

export function canSubmit(fields) {
  // check if all required fields have values
  const emptyRequiredFields = REQUIRED_FIELDS.find(field => !fields[field]);
  if (emptyRequiredFields) return false;

  // validate if the form is valid
  const isValid = _.filter(
    VALIDATION_BY_FIELD,
    (fieldRules: any, fieldName: string) => {
      const fieldValue = fields[fieldName];
      if (fieldRules.validate && !fieldRules.validate(fieldValue)) {
        return true;
      }
      return fieldRules.maxLength && fieldValue.length > fieldRules.maxLength;
    }
  );
  return _.isEmpty(isValid);
}

export function getErrorFromField(fieldName, fieldValue) {
  if (REQUIRED_FIELDS.includes(fieldName) && !fieldValue) {
    return {
      fieldName,
      errorType: ERROR_TYPES.Empty,
    };
  }

  const fieldRules = VALIDATION_BY_FIELD[fieldName];

  if (fieldRules) {
    if (fieldRules.validate && !fieldRules.validate(fieldValue)) {
      return {
        fieldName,
        errorType: ERROR_TYPES.InvalidField,
        inputForErrorMessage: Translations[fieldName],
      };
    }

    if (fieldRules.maxLength && fieldValue.length > fieldRules.maxLength) {
      return {
        fieldName,
        errorType: ERROR_TYPES.MaxLength,
        inputForErrorMessage: Formatter.formatNumberToLocale(
          fieldRules.maxLength
        ),
      };
    }
  }

  return null;
}

export function getAllErrors(fields) {
  const keys = Object.keys(fields);
  const errors: ErrorObject[] = [];

  keys.forEach(key => {
    const error = getErrorFromField(key, fields[key]);
    if (error) {
      errors.push(error);
    }
  });

  return errors;
}

export function getContactSupportURL() {
  const CONTACT_SUPPORT_URI = "services/data/v53.0/sobjects/Case";
  return process.env.REACT_APP_SALESFORCE_ENDPOINT_URL + CONTACT_SUPPORT_URI;
}

export function getDefaultEngineName({ pathName, queries, databasesList }) {
  const paths = pathName.substr(1).split("/");

  if (paths.length < 2) return "";

  const [pageName, databaseName] = paths;

  if (`/${pageName}` !== ROUTES.SQLWORKSPACE || !databasesList.length)
    return "";

  const databaseQueries = getDatabaseQueriesFromQueries(queries, databaseName);
  const currentQuery = getCurrentQuery(databaseQueries);
  const selectedDatabase = databasesList.find(db => db.name === databaseName);
  const selectedEngine = getSelectedEngine(selectedDatabase, currentQuery);

  return selectedEngine?.name || "";
}

export function getBackendErrorMessage(data, t) {
  const errorCode = data?.[0]?.errorCode;
  const field = data?.[0]?.fields?.[0];

  if (!Translations[errorCode]) {
    return;
  }

  return t(Translations[errorCode], { field: t(Translations[field]) });
}
