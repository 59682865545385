import * as React from "react";
import {
  loadUserData,
  putSelectedAccountId,
  UserData,
} from "redux/user/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import * as _ from "lodash";
import { Navigate } from "react-router-dom";
import ErrorScreen, {
  ErrorScreenImagePosition,
} from "../ErrorScreen/ErrorScreen";
import LoadingOverlap from "../LoadingOverlap";
import {
  selectUserIsAccepted,
  selectPendingInvite,
} from "redux/user/selectors";
import { logout } from "pages/Auth/redux/actions";
import { SubscriptionState } from "redux/subscription/reducer";
import {
  AccountDetails,
  AccountStatus,
  TenantStatus,
} from "businessLogic/model";
import AccountSelector from "components/AccountSelector/AccountSelector";
import WelcomeScreenContainer from "components/WelcomeScreen/WelcomeScreenContainer";
import { AWSProvisioningError } from "components/WelcomeScreen/AWSProvisioningError/AWSPovisioningError";
import AwsLinking from "components/AwsLinking/AwsLinking";
import { ReactComponent as WaitingForApprovalImg } from "assets/images/errorScreen/waitingForApproval.svg";
import { ReactComponent as IncompleteSignupImg } from "assets/images/errorScreen/incompleteSignup.svg";
import { FireboltGlobals } from "services/globals";
import { PendingInviteError } from "./PendingInviteError/PendingInviteError";
import buttonStyles from "styles/buttons.module.scss";
import { ROUTES } from "businessLogic/routes.constants";
import withRouter, { WithRouterProps } from "components/common/withRouter";
import { history } from "index";
import { AccessManager } from "../../services/access/accessManager";
import withAccessManager from "./withAccessManager";

const GeneralError = () => {
  return (
    <ErrorScreen
      title="Your user setup process was not completed"
      description={
        <div>
          There was a problem setting up your account. <br />
          Try getting back to your email and verify your account again. <br />
          If the problem persists contact{" "}
          <a
            className={buttonStyles.btn_link}
            href="mailto:support@firebolt.io"
            data-testid="support-email"
          >
            support@firebolt.io
          </a>
          .
        </div>
      }
      imagePosition={ErrorScreenImagePosition.Top}
      image={<IncompleteSignupImg />}
    />
  );
};

interface Props extends WithRouterProps {
  loadUserData: (login: boolean, accessManager?: AccessManager) => void;
  userData: UserData | null;
  isUserLoading: boolean;
  userLoadingErrorMessage: string | null;
  isUserAccepted: boolean;
  pendingInvite: boolean;
  logout: (preventRedirect: boolean) => void;
  subscriptionState: SubscriptionState;
  account: AccountDetails;
  setupError?: boolean;
  selectedAccountId: number | null;
  putSelectedAccountId: (
    accountId: string,
    resolve?: () => void,
    accessManager?: AccessManager
  ) => void;
  accessManager?: AccessManager;
}

interface WithUserLoadingOptions {
  allowNotAcceptedUser: boolean;
  allowWithInactiveAccount: boolean;
}

const withUserLoading = <P, S>(
  WrappedComponent,
  options: WithUserLoadingOptions = {
    allowNotAcceptedUser: false,
    allowWithInactiveAccount: false,
  }
) => {
  const { allowNotAcceptedUser, allowWithInactiveAccount } = options;

  return class extends React.Component<WithRouterProps & Props & P, S> {
    componentDidMount() {
      const { userData, isUserLoading, location, accessManager } = this.props;
      const login = !!location.search.match(/(login=true)|(redirect=%2F)/);

      if (!userData && !isUserLoading) {
        this.props.loadUserData(login, accessManager);
      }

      if (login) {
        history.push(location.pathname);
      }
    }

    render() {
      const {
        isUserLoading,
        userLoadingErrorMessage,
        userData,
        isUserAccepted,
        pendingInvite,
        subscriptionState,
        account,
        selectedAccountId,
        location,
        setupError,
        navigate,
        accessManager,
      } = this.props;

      if (FireboltGlobals.ignoreBrowserUnloadingError()) {
        return null;
      }

      if (setupError) {
        return <GeneralError />;
      }
      if (userLoadingErrorMessage || subscriptionState.errorMessage) {
        return (
          <ErrorScreen
            title={
              (userLoadingErrorMessage as string) ||
              subscriptionState.errorMessage ||
              ""
            }
          />
        );
      }

      if (isUserLoading || subscriptionState.isLoading) {
        if (
          !selectedAccountId &&
          userData &&
          userData.members &&
          userData.members.length > 1
        ) {
          return (
            <AccountSelector
              accounts={userData.members.map(member => {
                return {
                  id: member.accountId,
                  title: member.accountTitle,
                  isLoading: false,
                };
              })}
              onSelect={accountId => {
                new Promise<void>(resolve => {
                  this.props.putSelectedAccountId(
                    accountId,
                    resolve,
                    accessManager
                  );
                }).then(() => {
                  if (location.pathname === ROUTES.CHOOSE_ACCOUNT) {
                    navigate("/", { replace: true });
                  }
                });
              }}
            />
          );
        }

        return <LoadingOverlap isLoading />;
      }

      if (!userData) {
        return null;
      }

      const params = location && new URLSearchParams(location.search);
      const awsLinkingToken = params && params.get("awsLinkingToken");
      if (awsLinkingToken) {
        return <AwsLinking token={awsLinkingToken} />;
      }

      if (userData && !userData.members.length) {
        this.props.logout(true);
        return (
          <Navigate
            to="/access-error/no-access"
            replace
          />
        );
      }
      if (pendingInvite && !allowNotAcceptedUser) {
        return <PendingInviteError />;
      }

      if (!isUserAccepted && !allowNotAcceptedUser) {
        const accountName =
          account && account?.DomainNames && account?.DomainNames?.[0];
        return (
          <ErrorScreen
            title="We are waiting for an approval"
            description={
              <div>
                The account admin {accountName ? `of ${accountName}` : ""} has
                not yet approved your account
                <br />
                Maybe a small ping will do the trick...
              </div>
            }
            imagePosition={ErrorScreenImagePosition.Top}
            image={<WaitingForApprovalImg />}
          />
        );
      }

      if (
        !allowWithInactiveAccount &&
        account.status === AccountStatus.Active &&
        account.tenantStatus === TenantStatus.Failed
      ) {
        return <AWSProvisioningError />;
      }

      if (
        !allowWithInactiveAccount &&
        account &&
        subscriptionState.subscription &&
        account.status !== AccountStatus.Active
      ) {
        return <WelcomeScreenContainer />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

const mapStateToProps = (state: any) => {
  return {
    userData: _.get(state, "user.userData"),
    selectedAccountId: _.get(state, "user.selectedAccountId"),
    isUserLoading: _.get(state, "user.isLoading"),
    setupError: _.get(state, "user.setupError"),
    userLoadingErrorMessage: _.get(state, "user.errorMessage"),
    isUserAccepted: selectUserIsAccepted(state),
    pendingInvite: selectPendingInvite(state),
    subscriptionState: state.subscription,
    account: _.get(state, "account.accountDetails"),
  };
};

export default compose(
  connect(mapStateToProps, { loadUserData, logout, putSelectedAccountId }),
  withRouter,
  withAccessManager,
  withUserLoading
);
