import React, { useRef } from "react";
import classNames from "classnames";
import IconSortComponent from "components/IconSortComponent";
import styles from "./styles.module.scss";

export const HeaderCell = props => {
  const {
    column,
    fillAvailableWidth,
    isDragging,
    style,
    onResize,
    onResizeFinish,
    onResizeStart,
    dragOffset,
    resizeLineHeight,
    order,
    orderBy,
    disableResize,
    toggleSorting
  } = props;
  const { id, name, sortable } = column;

  const resizeFinish = useRef(onResizeFinish);
  const resizeStart = useRef(onResizeStart);

  resizeFinish.current = onResizeFinish;
  resizeStart.current = onResizeStart;

  const onPointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
    if (event.pointerType === "mouse" && event.buttons !== 1) {
      return;
    }

    const { currentTarget, pointerId } = event;
    const { right } = currentTarget.getBoundingClientRect();
    const startOffset = right - event.clientX;

    function onPointerMove(event: PointerEvent) {
      if (event.pointerId !== pointerId) {
        return;
      }
      if (event.pointerType === "mouse" && event.buttons !== 1) {
        onPointerUp();
        return;
      }
      onResize(event.clientX - right + startOffset);
    }

    function onPointerUp() {
      resizeFinish.current();
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", onPointerUp);
    }

    event.preventDefault();
    resizeStart.current();
    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("pointerup", onPointerUp);
  };

  return (
    <div
      style={style}
      className={classNames(styles.header__item, column.className)}
      onClick={toggleSorting && (() => toggleSorting(id))}
    >
      {name}
      {sortable && (
        <IconSortComponent order={order} orderBy={orderBy} rowId={id} />
      )}
      {!fillAvailableWidth && !disableResize && (
        <>
          <div
            style={{ right: -dragOffset }}
            className={classNames(styles.draggable, {
              [styles.draggable__active]: isDragging
            })}
            onPointerDown={onPointerDown}
          />
          <div
            style={{ right: -dragOffset, height: resizeLineHeight }}
            className={classNames(styles.line, {
              [styles.line__active]: isDragging
            })}
          />
        </>
      )}
    </div>
  );
};
