import React, { useCallback, useContext, useState } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { closeDialog } from "../redux/actions";
import { DialogInnerActions } from "../constants";
import ListView from "components/Table/ListView/ListView";
import TableLabel from "components/Table/TableLabel/TableLabel";
import EmojiIcon, { Emoji } from "components/EmojiIcon/EmojiIcon";
import SnackMessage, {
  SnackMessageType,
} from "components/SnackMessage/SnackMessage";
import RemoveDatabaseMutation from "relay/mutations/database/RemoveDatabaseMutation";
import {
  putStatusMessage,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import EngineListItem, {
  EngineItemType,
} from "components/EnginesList/EngineItem/EngineItem";
import DialogComponent from "components/DialogComponent";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";
import { useDisabledStart } from "components/EngineActionsPromptPanel/Actions/useDisabledStart";
import environment from "relay/environment";
import EngineStartMutation from "relay/mutations/engine/EngineStartMutation";
import EngineStopMutation from "relay/mutations/engine/EngineStopMutation";
import EngineRestartMutation from "relay/mutations/engine/EngineRestartMutation";
import RemoveEngineMutation from "relay/mutations/engine/RemoveEngineMutation";
import { stateSelectorHandle } from "../redux/selectors";
import styles from "../ConfirmationDialog.module.scss";
import {
  intermediaryStatuses,
  UIEngineStatus,
} from "components/EngineStatusIcon/EngineStatusIcon";
import { updateEngineSuccess } from "../../../pages/Database/redux/actions";
import { EngineStatusSummary } from "../queries/__generated__/EngineQuery.graphql";
import { getNormalizedStatusEngine } from "../../../pages/Database/helpers";
import { AccessManagerContext } from "../../App/App";
import { GQLRbacaction } from "../../../graphql.types";

export interface DBEntity {
  id: string;
  name: string;
  emoji: Emoji;
  engines: EngineItemType[];
}

interface Props {
  entity?: DBEntity | EngineItemType;
  accountId?: string;
  dialogIsLoading?: boolean;
}

const PaperHeightMap = {
  [DialogInnerActions.REMOVE_DATABASE]: styles.paperBig,
  [DialogInnerActions.ENGINE_INTERMEDIARY_STATUS]:
    styles.engineIntermediaryPaper,
};

const DialogTitleClassNamesMap = {
  [DialogInnerActions.REMOVE_DATABASE]: styles.dialogTitle,
};

const Dialog = (props: Props) => {
  const { t } = useTranslation();
  const stateSelector = useCallback(stateSelectorHandle, []);
  const { confirmationModal } = useSelector(stateSelector);
  const { isAllowedTo } = useContext(AccessManagerContext);
  const {
    actionType,
    skipAction,
    actionDescription,
    onConfirmComplete,
    onConfirmFail,
    onCancelComplete,
    beforeSubmit,
    engines,
  } = confirmationModal;
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    (props.entity as any)?.computeRegion
  );

  const [confirmIsLoading, setConfirmIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { entity, accountId, dialogIsLoading } = props;

  const handleActionConfirmation = () => {
    if (confirmIsLoading || dialogIsLoading) {
      return;
    }
    setConfirmIsLoading(true);
    dialogInnerActions[actionType].doAction().then(data => {
      setConfirmIsLoading(false);
      dispatch(closeDialog());
      onConfirmComplete && onConfirmComplete(data);
    });
  };
  const { disabledStart, disabledStartTooltip } = useDisabledStart(
    engines,
    entity
  );
  const entityHasEngines =
    !!(entity && "engines" in entity) && entity?.engines?.length > 0;

  const isAllowedToStartEngine = entity?.id
    ? isAllowedTo(entity?.id, GQLRbacaction.ENGINE_OPERATE)
    : false;

  const dialogInnerActions = {
    [DialogInnerActions.REMOVE_DATABASE]: {
      name: t("dialog.drop_database_title"),
      renderName: () => {},
      renderConfirmationMessage: () => {
        return (
          <ListView
            direction="vertical"
            spacingPx={24}
            dataTestId="remove-database-dialog"
          >
            <TableLabel
              icon={
                <EmojiIcon
                  emoji={
                    entity && "emoji" in entity ? entity?.emoji : Emoji.MINIBUS
                  }
                />
              }
              children={entity?.name}
            />
            <div
              className={styles.text}
              data-testid="remove-database-text"
            >
              {entityHasEngines
                ? t("dialog.drop_database_with_engines_subtitle")
                : t("dialog.drop_database_subtitle")}
            </div>
            {entityHasEngines && (
              <div
                className={styles.enginesList}
                data-testid="remove-database-engines-list"
              >
                {entity &&
                  "engines" in entity &&
                  entity?.engines.map(en => renderEngineItem(en))}
              </div>
            )}
            <SnackMessage
              type={SnackMessageType.Error}
              message={t("dialog.drop_database_warning")}
              testId="remove-database-snackbar"
            />
          </ListView>
        );
      },
      confirmationButtonTitle: t("dialog.drop_database_confirmation"),
      doAction: (): Promise<any> => {
        return new Promise(resolve => {
          RemoveDatabaseMutation.commit(
            environment,
            entity?.id,
            accountId,
            () => {},
            e => {
              dispatch(
                putStatusMessage(
                  t("dialog.drop_database_error_message", {
                    databaseName: entity?.name,
                    reason: e.message,
                  }),
                  StatusMessageType.Error
                )
              );
              onConfirmFail && onConfirmFail(e);
            }
          );

          resolve(true);
        });
      },
    },
    [DialogInnerActions.REMOVE_ENGINE]: {
      name: t("dialog.delete_engine_title"),
      renderName: () => {},
      renderConfirmationMessage: () => {
        return renderEngineConfirmationMessage(
          t("dialog.delete_engine_confirmation_message")
        );
      },
      confirmationButtonTitle: t("dialog.delete_engine_confirmation"),
      doAction: (): Promise<any> => {
        return new Promise((resolve, reject) => {
          if (skipAction) {
            resolve(null);
            return;
          }
          if (beforeSubmit) {
            beforeSubmitWrapper({
              handler: removeEngine,
              resolve,
              reject,
            });
            return;
          }
          removeEngine(resolve);
        });
      },
    },
    [DialogInnerActions.START_ENGINE]: {
      name: t("dialog.start_engine_title"),
      renderName: () => {},
      renderConfirmationMessage: () => {
        return renderEngineConfirmationMessage(
          t("dialog.start_engine_confirmation_message")
        );
      },
      confirmationButtonTitle: t("dialog.start_engine_confirmation"),
      isConfirmationButtonHidden: () => !isAllowedToStartEngine,
      doAction: (): Promise<any> => {
        return new Promise(resolve => {
          const originEngine = { ...entity };

          EngineStartMutation.commit(environment, entity?.id, resolve, e => {
            // Rollback optimistic changes
            dispatch(
              updateEngineSuccess({
                engine: { ...originEngine },
              })
            );

            dispatch(
              putStatusMessage(
                t("dialog.start_engine_error_message", {
                  engineName: entity?.name,
                  reason: e.message,
                }),
                StatusMessageType.Error
              )
            );
            onConfirmFail && onConfirmFail(e);
          });

          const optimisticallyUpdatedEngine = {
            ...entity,
            currentStatusSummary: "STARTING" as EngineStatusSummary,
          };

          dispatch(
            updateEngineSuccess({
              engine: getNormalizedStatusEngine(optimisticallyUpdatedEngine),
            })
          );

          resolve(true);
        });
      },
    },
    [DialogInnerActions.STOP_ENGINE]: {
      name: t("dialog.stop_engine_title"),
      renderName: () => {},
      renderConfirmationMessage: () => {
        return renderEngineConfirmationMessage(
          t("dialog.stop_engine_confirmation_message")
        );
      },
      confirmationButtonTitle: t("dialog.stop_engine_confirmation"),
      doAction: (): Promise<any> => {
        const originEngine = { ...entity };

        return new Promise(resolve => {
          EngineStopMutation.commit(
            environment,
            entity?.id,
            () => {},
            e => {
              // Rollback optimistic changes
              dispatch(
                updateEngineSuccess({
                  engine: { ...originEngine },
                })
              );

              dispatch(
                putStatusMessage(
                  t("dialog.stop_engine_error_message", {
                    engineName: entity?.name,
                    reason: e.message,
                  }),
                  StatusMessageType.Error
                )
              );
              onConfirmFail && onConfirmFail(e);
            }
          );

          const optimisticallyUpdatedEngine = {
            ...entity,
            currentStatusSummary: "STOPPING" as EngineStatusSummary,
          };

          dispatch(
            updateEngineSuccess({
              engine: getNormalizedStatusEngine(optimisticallyUpdatedEngine),
            })
          );

          resolve(true);
        });
      },
    },
    [DialogInnerActions.RESTART_ENGINE]: {
      name: t("dialog.restart_engine_title"),
      renderName: () => {},
      renderConfirmationMessage: () => {
        return renderEngineConfirmationMessage(
          t("dialog.restart_engine_confirmation_message")
        );
      },
      confirmationButtonTitle: t("dialog.restart_engine_confirmation"),
      doAction: (): Promise<any> => {
        const originEngine = { ...entity };

        return new Promise(resolve => {
          EngineRestartMutation.commit(
            environment,
            entity?.id,
            () => {},
            e => {
              // Rollback optimistic changes
              dispatch(
                updateEngineSuccess({
                  engine: { ...originEngine },
                })
              );

              dispatch(
                putStatusMessage(
                  t("dialog.restart_engine_error_message", {
                    engineName: entity?.name,
                    reason: e.message,
                  }),
                  StatusMessageType.Error
                )
              );
              onConfirmFail && onConfirmFail(e);
            }
          );

          const optimisticallyUpdatedEngine = {
            ...entity,
            currentStatusSummary: "RESTARTING" as EngineStatusSummary,
          };

          dispatch(
            updateEngineSuccess({
              engine: getNormalizedStatusEngine(optimisticallyUpdatedEngine),
            })
          );

          resolve(true);
        });
      },
    },
    [DialogInnerActions.ENGINE_INTERMEDIARY_STATUS]: {
      name: "",
      renderName: () => {
        if (!entity) {
          return "";
        }

        const status = "status" in entity && (entity.status || "");
        const formattedStatus = (status as string).toLowerCase();
        const ellipsis = intermediaryStatuses.includes(status as UIEngineStatus)
          ? "..."
          : "";

        return t("dialog.engine_intermediary_status", {
          status: formattedStatus,
          ellipsis,
        });
      },
      renderConfirmationMessage: () => {
        if (!entity) {
          return "";
        }

        const status = ("status" in entity &&
          (entity.status || "")) as UIEngineStatus;

        return renderEngineConfirmationMessage(
          t(`dialog.engine_message_${status}`) || ""
        );
      },
      doAction: (): Promise<any> => Promise.resolve(),
    },
  };
  const removeEngine = resolve => {
    RemoveEngineMutation.commit(
      environment,
      entity?.id,
      accountId,
      () => {},
      e => {
        dispatch(
          putStatusMessage(
            t("dialog.delete_engine_error_message", {
              engineName: entity?.name,
              reason: e.message,
            }),
            StatusMessageType.Error
          )
        );
        onConfirmFail && onConfirmFail(e);
      }
    );

    resolve(true);
  };
  const beforeSubmitWrapper = ({ handler, resolve, reject }) => {
    beforeSubmit()
      .then(() => {
        return handler(resolve, reject);
      })
      .catch(errMessage => {
        dispatch(putStatusMessage(errMessage, StatusMessageType.Error));
      });
  };

  const renderEngineItem = (engine?: any) => {
    const item = engine || entity;
    return (
      <EngineListItem
        accountId={accountId}
        classes={{
          engineItem: cn({ [styles.engineItem]: !!engine }),
        }}
        engineItem={item}
        hiddenActions={[]}
        isDefault={!!item.isDefault}
        key={item.name}
        readOnly
        statusTooltipProps={{
          classes: {
            popper: cn(styles.statusTooltip, styles.forced),
          },
        }}
      />
    );
  };
  const renderEngineConfirmationMessage = description => {
    return (
      <ListView
        direction="vertical"
        spacingPx={28}
      >
        {renderEngineItem()}
        {isAllowedToStartEngine ? (
          <div
            className={cn(styles.description, {
              [styles.marginBottom]:
                !dialogInnerActions[actionType].confirmationButtonTitle,
            })}
            data-testid="item-description"
          >
            {description || actionDescription || ""}
          </div>
        ) : (
          <SnackMessage
            type={SnackMessageType.Error}
            message={t("dialog.start_engine_no_permission_warning")}
            testId="start-engine-warning-snackbar"
          />
        )}
      </ListView>
    );
  };
  const renderedTitle = dialogInnerActions[actionType].renderName();
  const title = dialogInnerActions[actionType].name || renderedTitle || "";
  const heightClassNames = PaperHeightMap[actionType] || styles.paper;
  const paperClassNames = cn(heightClassNames, styles.forced, styles.forced2);
  const confirmTooltip = disabledStartTooltip || readOnlyTooltip || undefined;

  return (
    <DialogComponent
      openModal={true}
      confirmationText={
        !dialogIsLoading
          ? dialogInnerActions[actionType].confirmationButtonTitle
          : undefined
      }
      confirmIsDisabled={readOnlyMode || disabledStart}
      confirmIsHidden={
        !!dialogInnerActions[actionType].isConfirmationButtonHidden?.()
      }
      confirmTooltip={confirmTooltip}
      cancelText={
        !dialogIsLoading &&
        !confirmIsLoading &&
        dialogInnerActions[actionType].confirmationButtonTitle
          ? t("confirmation.cancel")
          : undefined
      }
      confirmIsLoading={confirmIsLoading}
      onConfirm={handleActionConfirmation}
      closeModal={() => {
        dispatch(closeDialog());
        onCancelComplete && onCancelComplete();
      }}
      title={title}
      type="info"
      dialogIsLoading={dialogIsLoading}
      classes={{
        root: styles.root,
        paper: cn(paperClassNames, {
          [styles.hasEngines]: entityHasEngines,
        }),
        dialogTitleCn: entityHasEngines && DialogTitleClassNamesMap[actionType],
      }}
      showCloseIcon={
        actionType === DialogInnerActions.ENGINE_INTERMEDIARY_STATUS
      }
    >
      {!dialogIsLoading &&
        dialogInnerActions[actionType].renderConfirmationMessage()}
    </DialogComponent>
  );
};

export default Dialog;
