import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { UserUpdateInput } from "./__generated__/UpdateUserMutation.graphql";

const mutation = graphql`
  mutation UpdateUserMutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      success
      user {
        id
        ipAllowList
        ipDenyList
      }
    }
  }
`;

function commit(
  environment,
  input: UserUpdateInput,
  onCompleted,
  onError,
  updater?: any,
  optimisticUpdater?: any
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
    updater,
    optimisticUpdater,
  });
}

export default { commit };
