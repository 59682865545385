import { createAction } from "redux-actions";
import { shouldFetchExplain } from "businessLogic/services/metadata";

export const ViewsAction = {
  VIEWS_LOAD: "VIEWS/LOAD_REQUEST",
  VIEWS_LOAD_SUCCESS: "VIEWS/LOAD_SUCCESS",
  VIEWS_LOAD_FAILED: "VIEWS/LOAD_FAILED",
  VIEWS_REMOVE: "VIEWS/REMOVE",
  VIEW_EXPLAIN: "VIEWS/EXPLAIN",
  VIEW_EXPLAIN_SUCCESS: "VIEWS/EXPLAIN_SUCCESS",
  VIEW_EXPLAIN_FAILED: "VIEWS/EXPLAIN_FAILED",
  VIEWS_UNFREEZE_FETCH: "VIEWS/VIEWS_UNFREEZE_FETCH",
};

export const fetchExplainIfNeeded = ({ database, schema, name }) => {
  return (dispatch, getState) => {
    const state = getState();
    if (shouldFetchExplain({ state, database, name })) {
      dispatch({
        type: ViewsAction.VIEW_EXPLAIN,
        payload: { name, schema, database },
      });
    }
  };
};

export const LoadViews = createAction(
  ViewsAction.VIEWS_LOAD,
  (database: string) => ({ database })
);

export const UnfreezeFetchViews = createAction(
  ViewsAction.VIEWS_UNFREEZE_FETCH,
  (database: string) => ({ database })
);

export const LoadViewsFailed = createAction(
  ViewsAction.VIEWS_LOAD_FAILED,
  (database: string, error) => ({ database, error })
);

export const LoadViewsSuccess = createAction(
  ViewsAction.VIEWS_LOAD_SUCCESS,
  (database: string, views: any) => ({ database, views })
);

export const removeViews = createAction(
  ViewsAction.VIEWS_REMOVE,
  (database: string) => ({ database })
);
