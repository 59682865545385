import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AccountMemberAssignRoleInput } from "./__generated__/AssignRoleMutation.graphql";

const mutation = graphql`
  mutation AssignRoleMutation($input: AccountMemberAssignRoleInput!) {
    accountMemberAssignRole(input: $input) {
      success
    }
  }
`;

function commit(
  environment,
  input: AccountMemberAssignRoleInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
    updater: store => {
      const member = store.get(input.accountMemberId);

      const role = store.get(input.roleId);

      if (role) {
        member?.setLinkedRecord(role, "assignedRole");
      }
    },
  });
}

export default { commit };
