export const statusCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const AWS_MARKETPLACE_URL = process.env
  .REACT_APP_AWS_MARKETPLACE_URL as string;

export const SUPPORT_EMAIL = "support@firebolt.io";
export const SUPPORT_USER_EMAIL_DOMAIN = "firebolt.io";

export const PASSWORD_VALIDATION_RULE_MESSAGE =
  "Password must be at least 8 chars long, and must contain an uppercase letter, a lowercase letter, and a number.";

export const SYSTEM_ACTOR = "Firebolt System";
export const DEFAULT_REGION_NAME = "us-east-1";
export const FIREBOLT_DOCS_URL = "https://docs.firebolt.io";

// @deprecated old release notes will be retired soon
export const FIREBOLT_RELEASE_NOTES_URL =
  "https://www.notion.so/Firebolt-release-notes-5669618c83904f67949c481be383f784";
// @TODO release notes moved to new url see https://packboard.atlassian.net/browse/FIR-11544
export const FIREBOLT_RELEASE_NOTES_URL_NEW =
  "http://docs.firebolt.io/general-reference/release-notes";

export const FIREBOLT_STATUS_PAGE_URL = "http://firebolt.statuspage.io/";
