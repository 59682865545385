import {
  fetchAwsMonthToDateCost,
  fetchAwsSubscriptions
} from "relay/queries/account/Account";
import environment from "relay/environment";
import SubscriptionLinkToAccount from "relay/mutations/iam/SubscriptionLinkToAccount";

export const AWSService = {
  getActiveSubscription: (accountId: string) => {
    return fetchAwsSubscriptions(environment, { id: accountId });
  },

  getCosts: accountId => {
    return fetchAwsMonthToDateCost(environment, { id: accountId });
  },

  linkAws: (accountId, token) => {
    return new Promise((resolve, reject) => {
      SubscriptionLinkToAccount.commit(
        environment,
        {
          accountId: accountId,
          token: token
        },
        resolve,
        reject
      );
    });
  }
};
