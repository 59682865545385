/**
 * @generated SignedSource<<a700fb1da724483d9849f34ff6409e4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DatabaseStatus = "UNSPECIFIED" | "CREATED" | "PROVISIONING_PENDING" | "PROVISIONING_STARTED" | "PROVISIONING_FINISHED" | "PROVISIONING_FAILED" | "RUNNING" | "TERMINATION_PENDING" | "TERMINATION_STARTED" | "TERMINATION_FINISHED" | "TERMINATION_FAILED" | "DELETED" | "%future added value";
export type DatabaseDeleteInput = {
  id: string;
};
export type RemoveDatabaseMutation$variables = {
  input: DatabaseDeleteInput;
};
export type RemoveDatabaseMutation$data = {
  readonly databaseDelete: {
    readonly success: boolean;
    readonly database: {
      readonly id: string;
      readonly currentStatus: DatabaseStatus;
    };
  };
};
export type RemoveDatabaseMutation = {
  variables: RemoveDatabaseMutation$variables;
  response: RemoveDatabaseMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DatabaseDeletePayload",
    "kind": "LinkedField",
    "name": "databaseDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Database",
        "kind": "LinkedField",
        "name": "database",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveDatabaseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveDatabaseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5e8cd2aac20ddfe60d619d6cbdf09d14",
    "id": null,
    "metadata": {},
    "name": "RemoveDatabaseMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveDatabaseMutation(\n  $input: DatabaseDeleteInput!\n) {\n  databaseDelete(input: $input) {\n    success\n    database {\n      id\n      currentStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "687aed322b257df15cb3584f70f579e5";

export default node;
