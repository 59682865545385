import _ from "lodash";
import { select } from "redux-saga/effects";
import { canUseBypassQueueFlag } from "businessLogic/helpers/Version";
import { queryShouldBeSync } from "businessLogic/helpers/Query";
import {
  getProxyPreserveExecution,
  getEngineProxyVersion,
  getQueries,
} from "redux/query/selectors";
import { ServerQueryStatusesFormatted } from "../../constants/queries";
import { QUERY_SETTINGS } from "./constants";
import { Statistics } from "../../constants/interfaces";

export function* withQuerySettingsForExecute({
  settings,
  engineId,
  queryText = "",
}: {
  settings: any;
  engineId: string;
  queryText?: string;
}) {
  const settingsWithByPass = yield withBypassQueueSettings({
    settings,
    engineId,
  });
  const settingsWithPreserveQueryExecution =
    yield withPreserveQueryExecutionSettings({
      settings: settingsWithByPass,
      engineId,
    });
  return withSyncExecution(settingsWithPreserveQueryExecution, queryText);
}

export function* withBypassQueueSettings({
  settings,
  engineId,
}: {
  settings: any;
  engineId: string;
}) {
  const proxyVersion = yield select(getEngineProxyVersion, engineId);
  const bypassQueue = canUseBypassQueueFlag(proxyVersion);

  return withQueryFlag({
    settings,
    queryFlag: QUERY_SETTINGS.bypassQueue,
    flagEnabled: bypassQueue,
  });
}

export function formatQueryStatusType(status) {
  return ServerQueryStatusesFormatted[status];
}

export function formatQueryStatusStatistics(queryResults): Statistics {
  return {
    elapsed: queryResults.elapsed / 1000,
    bytes_read: queryResults.bytes_read,
    rows_read: queryResults.rows_read,
    scanned_bytes_cache: queryResults.scanned_bytes_cache,
    scanned_bytes_storage: queryResults.scanned_bytes_storage,
  };
}

export function* getServerQueryid(database, queryId) {
  const dbQueries = yield select(getQueries);
  return _.get(
    dbQueries,
    `databasesQueries.${database}.queriesById[${queryId}].serverQueryId`,
    false
  );
}

function* withPreserveQueryExecutionSettings({
  settings,
  engineId,
}: {
  settings: any;
  engineId: string;
}) {
  const proxyQueueById = yield select(getProxyPreserveExecution);
  const preserveExecution = proxyQueueById?.[engineId]?.preserveExecution;

  return withQueryFlag({
    settings,
    queryFlag: QUERY_SETTINGS.preserveQueryExecution,
    flagEnabled: preserveExecution,
  });
}

function withQueryFlag({ settings, queryFlag, flagEnabled }) {
  const querySettings = { ...settings };
  if (flagEnabled && !Number.isInteger(parseInt(querySettings[queryFlag]))) {
    querySettings[queryFlag] = "1";
  }

  if (!flagEnabled && Number.isInteger(parseInt(querySettings[queryFlag]))) {
    delete querySettings[queryFlag];
  }

  return querySettings;
}

function withSyncExecution(querySettings, queryText) {
  const shouldRunSync = queryShouldBeSync(queryText);

  return shouldRunSync
    ? {
        ...querySettings,
        [QUERY_SETTINGS.asyncExecution]: "0",
      }
    : querySettings;
}

// Copied from https://github.com/chalk/ansi-regex/blob/main/index.js#L3
export function ansiRegex({ onlyFirst = false } = {}) {
  const pattern = [
    "[\\u001B\\u009B][[\\]()#;?]*(?:(?:(?:(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]+)*|[a-zA-Z\\d]+(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]*)*)?\\u0007)",
    "(?:(?:\\d{1,4}(?:;\\d{0,4})*)?[\\dA-PR-TZcf-nq-uy=><~]))",
  ].join("|");

  return new RegExp(pattern, onlyFirst ? undefined : "g");
}
