import { useLocation, Link } from "react-router-dom";
import MaterialButton, {
  ButtonSize,
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import buttonStyles from "styles/buttons.module.scss";
import { ReactComponent as ErrorImage } from "./image.svg";
import styles from "./styles.module.scss";

export const LoginError = props => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const message =
    searchParams.get("message") ||
    searchParams.get("error_description") ||
    props.message;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ErrorImage />
        <h2 className={styles.title}>There was a problem with your login</h2>
        <div className={styles.message}>{message}</div>
        <Link to="/logout">
          <MaterialButton
            template={ButtonTemplate.Primary}
            size={ButtonSize.Large}
            text="Retry"
          />
        </Link>
      </div>
      <div className={styles.footer}>
        If the problem persists contact{" "}
        <a
          className={buttonStyles.btn_link}
          href="mailto:support@firebolt.io"
        >
          support@firebolt.io
        </a>
        .
      </div>
    </div>
  );
};
