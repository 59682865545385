import * as _ from "lodash";

export const getCurrentQueryTabId = (state, databaseName: string) => {
  return (
    state.query.queries?.databasesQueries?.[databaseName]?.currentQueryTabId ??
    null
  );
};

export const getCurrentQueryBy = (queryState, databaseName: string) => {
  const dbQuery = queryState.databasesQueries?.[databaseName];
  return dbQuery?.queriesById?.[dbQuery.currentQueryTabId];
};

export const getDatabases = state => _.get(state, "database.databasesList");
export const getQueries = (state): any => _.get(state, "query.queries");
export const getQuery = (state, database, queryId) =>
  _.get(
    state,
    `query.queries.databasesQueries[${database}].queriesById[${queryId}]`
  );
export const getBrowserTabId = state => _.get(state, "database.browserTabId");
export const getLoadingEnginesVersion = (state, engineId) =>
  _.get(state, `query.proxy.proxyVersionsById.${engineId}.isLoading`);
export const getEngineProxyVersion = (state, engineId) =>
  _.get(state, `query.proxy.proxyVersionsById.${engineId}.version`);
export const getAccountFlags = state =>
  _.get(state, `query.accountFlags.flags`);

export const getProxyPreserveExecution = state =>
  _.get(state, "query.proxy.proxyPreserveExecutionById");

export const getDatabaseQueries = (state, databaseName) => {
  const queries = getQueries(state);

  return (
    queries?.databasesQueries?.[databaseName] ??
    queries?.databasesQueries.initialQueryState
  );
};

export const getDatabaseQueriesResumedFlag = (state, databaseName) => {
  return state?.query?.queries?.databasesResumedQueries?.[databaseName]
    ?.attemptToResume;
};

export const getDatabaseQueryStatementResumedFlag = (
  state,
  databaseName,
  serverQueryId
) => {
  return state?.query?.queries?.databasesResumedQueries?.[databaseName]?.[
    serverQueryId
  ];
};
