import * as _ from "lodash";
import { INITIAL_STATE as DB_INITIAL_STATE } from "pages/Database/redux/reducer";
/**
 * this define to which version to migrate the state
 */
export const MIGRATION_VERSION = 6;

export const stateMigrations = {
  1: state => {
    /**
     * State Migration
     * Clear query state
     */
    return {
      ...state,
      query: {},
    };
  },
  2: state => {
    /**
     * State Migration
     * Clear query state
     */
    return {
      ...state,
      query: {},
    };
  },
  3: state => {
    /**
     * State Migration
     * Clear query state
     */
    return {
      ...state,
      query: {},
    };
  },
  4: state => {
    /**
     * State Migration
     * Clear query state
     */
    return {
      ...state,
      query: {},
    };
  },
  5: state => {
    /**
     * State Migration
     */
    return {
      ...state,
      query: {
        ...state.query,
      },
    };
  },
  6: state => {
    /**
     * Remove unused values from saved store
     * INITIAL STATE + migration(previous persisted store)
     */
    return {
      ...state,
      database: {
        ...DB_INITIAL_STATE,
        ..._.omit(state.database, ["hasTriedToLoadDatabases"]),
      },
    };
  },
  7: state => {
    const activeSection = state.query?.userScripts?.activeSection;
    return {
      ...state,
      query: {
        ...state.query,
        userScripts: {
          ...state.query.userScripts,
          activeSections: {
            [activeSection]: true,
          },
        },
      },
    };
  },
};
