import React, { useEffect, useState, createContext } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createTheme from "./index";
import { PaletteType } from "@material-ui/core";
import darkTheme from "./dark.module.scss";
import lightTheme from "./light.module.scss";

export type ExtendedPalette = PaletteType | "custom";

export const ThemeContext = createContext<{
  theme: string;
  toggle?: any;
}>({
  theme: localStorage.getItem("firebolt_theme") || "light",
  toggle: () => {},
});

export const ThemeProvider = props => {
  const { children, forcedTheme } = props;
  const [theme, toggle] = useState(
    forcedTheme || localStorage.getItem("firebolt_theme") || "light"
  );

  const themeMemo = React.useMemo(() => {
    return createTheme(theme);
  }, [theme]);

  useEffect(() => {
    const darkClass = darkTheme.dark;
    const lightClass = lightTheme.light;

    // changing <body> class to not obstruct app wrappers and accomodate changes in body text color and scroll color
    document.body.classList.add(
      (forcedTheme || theme) === "dark" ? darkClass : lightClass
    );
    document.body.classList.remove(
      (forcedTheme || theme) === "dark" ? lightClass : darkClass
    );

    return () => {
      document.body.classList.remove(darkTheme.dark, lightTheme.light);
    };
  }, [theme, forcedTheme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggle: newTheme => {
          toggle(newTheme);
        },
      }}
    >
      <MuiThemeProvider theme={themeMemo}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const LightThemeWrap = ({ children }) => (
  <ThemeProvider forcedTheme="light">{children}</ThemeProvider>
);
