/**
 * @generated SignedSource<<f4626912574dcdae87e9d98dfebedb4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EngineStatus = "UNSPECIFIED" | "CREATED" | "PROVISIONING_PENDING" | "PROVISIONING_STARTED" | "PROVISIONING_FINISHED" | "PROVISIONING_FAILED" | "RUNNING_IDLE" | "RUNNING_REVISION_STARTING" | "RUNNING_REVISION_STARTUP_FAILED" | "RUNNING_REVISION_SERVING" | "RUNNING_REVISION_CHANGING" | "RUNNING_REVISION_CHANGE_FAILED" | "RUNNING_REVISION_RESTARTING" | "RUNNING_REVISION_RESTART_FAILED" | "RUNNING_REVISIONS_TERMINATING" | "TERMINATION_PENDING" | "TERMINATION_STARTED" | "TERMINATION_FINISHED" | "TERMINATION_FAILED" | "DELETED" | "%future added value";
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineBasicQuery$variables = {
  id: string;
};
export type EngineBasicQuery$data = {
  readonly account: {
    readonly id: string;
    readonly engines: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly computeRegionId: string;
          readonly currentStatusSummary: EngineStatusSummary;
          readonly currentStatus: EngineStatus;
        };
      } | null> | null;
    };
  };
};
export type EngineBasicQuery = {
  variables: EngineBasicQuery$variables;
  response: EngineBasicQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "account",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          }
        ],
        "concreteType": "EngineConnection",
        "kind": "LinkedField",
        "name": "engines",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EngineEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Engine",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "computeRegionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentStatusSummary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "engines(first:1000)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngineBasicQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngineBasicQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4b66c065c2b602b3d65695a7d09e12b4",
    "id": null,
    "metadata": {},
    "name": "EngineBasicQuery",
    "operationKind": "query",
    "text": "query EngineBasicQuery(\n  $id: ID!\n) {\n  account(id: $id) {\n    id\n    engines(first: 1000) {\n      edges {\n        node {\n          id\n          computeRegionId\n          currentStatusSummary\n          currentStatus\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d639880a53b3bf5c55bee1909d908d73";

export default node;
