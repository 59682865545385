import { useLazyLoadQuery } from "react-relay";
import _ from "lodash";
import { useSelector } from "react-redux";
import graphql from "babel-plugin-relay/macro";
import { authService } from "services/auth";
import type { useLoggedInUserQuery } from "./__generated__/useLoggedInUserQuery.graphql";

export const useLoggedInUser = () => {
  const loginType = authService.getLoginType();
  const loggedByToken = useSelector(state => {
    return _.get(state, "auth.session") && _.get(state, "auth.userDetails");
  });

  const data = useLazyLoadQuery<useLoggedInUserQuery>(
    graphql`
      query useLoggedInUserQuery {
        me {
          ... on User {
            id
          }
        }
      }
    `,
    {},
    { fetchPolicy: "network-only" }
  );

  const isLoggedIn =
    (loginType === "classic" ? loggedByToken : !!data.me) || !!data.me;

  return {
    isLoggedIn,
  };
};
