/**
 * @generated SignedSource<<147bd026fad50dfa292d4c78bcdb1ce4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserUpdateInput = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  displayName?: string | null;
  companyName?: string | null;
  jobTitle?: string | null;
  phoneNumber?: string | null;
  ipAllowList?: ReadonlyArray<string> | null;
  ipDenyList?: ReadonlyArray<string> | null;
};
export type UpdateUserMutation$variables = {
  input: UserUpdateInput;
};
export type UpdateUserMutation$data = {
  readonly userUpdate: {
    readonly success: boolean;
    readonly user: {
      readonly id: string;
      readonly ipAllowList: ReadonlyArray<string>;
      readonly ipDenyList: ReadonlyArray<string>;
    };
  };
};
export type UpdateUserMutation = {
  variables: UpdateUserMutation$variables;
  response: UpdateUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserUpdatePayload",
    "kind": "LinkedField",
    "name": "userUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ipAllowList",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ipDenyList",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "05c03423678ab34c5b3230595fdefb41",
    "id": null,
    "metadata": {},
    "name": "UpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserMutation(\n  $input: UserUpdateInput!\n) {\n  userUpdate(input: $input) {\n    success\n    user {\n      id\n      ipAllowList\n      ipDenyList\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33516f5668b5931b1a7f577344be20a5";

export default node;
