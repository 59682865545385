import React, { Component, ReactElement, useMemo } from "react";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import * as _ from "lodash";
import {
  registerAccount,
  registerAccountFromAws,
  setAccountError,
} from "../../../redux/actions";
import COUNTRY_CODES from "businessLogic/sortedCountryCodes.json";
import { AccountDetails } from "businessLogic/model";
import { Validations } from "businessLogic/helpers/Validation";
import { ROUTES } from "businessLogic/routes.constants";
import {
  STEP_CHECK_EMAIL,
  PRIVACY_POLICY_URL,
  CreateAccountFieldName,
} from "../../constants";
import styles from "../../../styles.module.scss";
import InputField from "components/Input/InputFiled";
import { FormControl, InputLabel } from "@material-ui/core";
import SnackBarView from "components/SnackBar/SnackBarView";
import LoadingOverlap from "components/LoadingOverlap";
import { Link } from "react-router-dom";
import MaterialButton from "components/MaterialButton/MaterialButton";
import { ReactComponent as ChevronRight } from "assets/icons/ChevronRight.svg";
import SelectMenuItem from "components/Select/SelectMenuItem/SelectMenuItem";
import CustomSelect from "components/Select/Select";
import registerStyles from "../../CreateAccount.module.scss";
import cn from "classnames";
import { ReactComponent as EyeShutIcon } from "assets/icons/EyeShutIcon.svg";
import { ReactComponent as EyeOpenIcon } from "assets/icons/EyeOpenIcon.svg";
import { ReactComponent as WarningIcon } from "assets/icons/Warning.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/Arrow-Left.svg";
import withRecaptcha, {
  WithRecaptchaOuterProps,
} from "components/common/withRecaptcha";
import withRouter, { WithRouterProps } from "components/common/withRouter";
import ContactSupport from "components/MainSidebar/ContactSupport/ContactSupport";
import { formatContactFields, getDomainNameFromEmail } from "../../utils";
import { AppCrash } from "components/ErrorBoundary";

const NOT_FOUND_ERROR_MESSAGE_PREFIX = "Record not found";

const numbersAndLettersOnlyText = "Use letters and numbers.";
const lettersOnlyText = "Only alphabetical characters and spaces are allowed.";

interface RegisterProps extends WithRouterProps, WithRecaptchaOuterProps {
  goToStep(step: string);
  registerAccount(accountDetails: AccountDetails, recaptcha: string);
  registerAccountFromAws(
    accountDetails: AccountDetails,
    awsLinkingToken: string,
    recaptcha: string
  );
  errorMessage?: string;
  isRegistered?: boolean;
  setAccountError: (message) => void;
  email?: string;
  invitationToken?: string;
  isLoading: boolean;
  showExtraFields: boolean;
  t: (str: string) => string;
}

interface RegisterState {
  registered: boolean;
  openAlert: boolean;
  fields: any;
  errors: any;
  alertInfo?: any;
  showPassword: boolean;
  loadingCaptchaToken: boolean;
  showContactSupport: boolean;
}

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.footerText, styles.privacy)}>
      {t("register.privacy_description")}
      <a
        className={styles.footerLink}
        target="_blank"
        rel="noreferrer"
        href={PRIVACY_POLICY_URL}
      >
        {t("register.privacy")}
      </a>
    </div>
  );
};

const ContactSupportLink = ({ email, onClick }) => {
  const { t } = useTranslation();
  const isEmailValid = useMemo(() => {
    return (
      Validations.isValidEmail(email) && Validations.isValidWorkEmail(email)
    );
  }, [email]);

  return isEmailValid ? (
    <Link
      onClick={onClick}
      className={styles.contactSupportLink}
      to="#"
    >
      {t("contact.title")}
    </Link>
  ) : null;
};

export class Register extends Component<RegisterProps, RegisterState> {
  static defaultProps = {
    showExtraFields: false,
  };

  private phoneNumberRef;

  constructor(props: RegisterProps) {
    super(props);

    this.state = {
      registered: false,
      openAlert: false,
      fields: {
        firstName: "",
        lastName: "",
        email: props.email || "",
        company: "",
        jobTitle: "",
        country: "United States",
        phoneNumber: "",
        password: "",
        accountName: "",
      },
      errors: {},
      showPassword: false,
      loadingCaptchaToken: false,
      showContactSupport: false,
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  componentDidMount() {
    if (this.props.email) {
      const { fields } = this.state;

      this.setState({
        ...this.state,
        fields: {
          ...fields,
          [CreateAccountFieldName.accountName]: getDomainNameFromEmail(
            this.props.email
          ),
        },
      });
    }
  }

  componentDidUpdate(prevProps: RegisterProps) {
    if (
      this.props.errorMessage &&
      this.props.errorMessage !== prevProps.errorMessage
    ) {
      this.setState({
        openAlert: true,
      });
    } else {
      // registered
      if (
        this.props.isRegistered &&
        this.props.isRegistered !== prevProps.isRegistered
      ) {
        this.props.goToStep(STEP_CHECK_EMAIL);
      }
    }
  }

  closeAlert() {
    this.setState({ openAlert: false });
    this.props.setAccountError(undefined);
  }

  handleChange = (name: string, value: string) => {
    this.setState(
      state => {
        const { fields } = state;
        const newFields = { ...fields, [name]: value };

        if (name === CreateAccountFieldName.email) {
          newFields.accountName = getDomainNameFromEmail(value);
        }

        return {
          ...state,
          fields: newFields,
        };
      },
      () => this.runFieldValidation(name)
    );
  };

  handleFocus = (name: string) => {
    this.setState({
      ...this.state,
      errors: { ...this.state.errors, [name]: null },
    });
  };

  handleRegister() {
    const {
      fields: {
        firstName,
        lastName,
        email,
        password,
        jobTitle,
        company,
        phoneNumber,
        country,
      },
    } = this.state;
    const { location, showExtraFields } = this.props;

    if (!this.isFormValid()) return;

    this.setState({ loadingCaptchaToken: true });

    new Promise<string>(resolve => {
      this.props.getToken(resolve);
    })
      .then(token => {
        const accountInfo: AccountDetails = {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email,
          password,
          DomainNames: [],
          invitationToken: this.props.invitationToken,
          additionalInfo: showExtraFields
            ? {
                company,
                jobTitle,
                phone:
                  COUNTRY_CODES.find(c => c.name === country)?.dial_code +
                  phoneNumber,
              }
            : null,
        };

        const params = location && new URLSearchParams(location.search);
        const awsLinkingToken = params && params.get("awsLinkingToken");
        if (awsLinkingToken) {
          this.props.registerAccountFromAws(
            accountInfo,
            awsLinkingToken,
            token
          );
        } else {
          this.props.registerAccount(accountInfo, token);
        }
      })
      .finally(() => {
        this.setState({ loadingCaptchaToken: false });
      });
  }

  runFieldValidation = (name: string) => {
    const { errors } = this.state;
    this.setState({
      errors: {
        ...errors,
        [name]: this.getErrorMessage(name),
      },
    } as RegisterState);
  };

  getErrorMessage(field) {
    const {
      errors,
      fields: {
        firstName,
        lastName,
        email,
        password,
        jobTitle,
        company,
        phoneNumber,
      },
    } = this.state;
    const { showExtraFields } = this.props;
    let errorField = false;
    switch (field) {
      case "firstName":
        if (_.isEmpty(firstName)) return "First Name is required.";
        else {
          const isValidName = Validations.isValidName(firstName);
          if (!isValidName) return lettersOnlyText;
        }
        break;
      case "lastName":
        if (_.isEmpty(lastName)) return "Last Name is required.";
        else {
          const isValidName = Validations.isValidName(lastName);
          if (!isValidName) return lettersOnlyText;
        }
        break;
      case "password":
        if (_.isEmpty(password)) return "Password is required.";
        else {
          const isValidPass = Validations.isValidPass(password);
          if (!isValidPass) return "Provide a valid password";
        }
        break;
      case "email":
        if (_.isEmpty(email)) return "Work Email is required.";
        else {
          const isValidEmail =
            Validations.isValidEmail(email) &&
            Validations.isValidWorkEmail(email);
          if (!isValidEmail) return "Provide a valid work email.";
        }
        break;

      case "company":
        if (_.isEmpty(company)) return "Company is required.";
        else {
          const isValidName = Validations.isLettersAndNumbers(company);
          if (!isValidName) return numbersAndLettersOnlyText;
        }
        break;
    }

    if (showExtraFields) {
      switch (field) {
        case "jobTitle":
          if (_.isEmpty(jobTitle)) return "Job Title is required.";
          else {
            const isValidName = Validations.isLettersAndNumbers(company);
            if (!isValidName) return numbersAndLettersOnlyText;
          }
          break;
        case "phoneNumber":
          if (_.isEmpty(phoneNumber)) return "Phone Number is required.";
          else {
            const isValidPhoneNumber = !isNaN(phoneNumber);
            if (!isValidPhoneNumber) return "Use digits only.";
          }
          break;
      }
    }

    this.setState({
      errors: {
        ...errors,
        [field]: !errorField,
      },
    });
  }

  runFormValidation() {
    const { showExtraFields } = this.props;

    if (this.isFormValid()) {
      return this.setState({
        errors: {},
      });
    }

    const errors = {
      firstName: this.getErrorMessage("firstName"),
      lastName: this.getErrorMessage("lastName"),
      email: this.getErrorMessage("email"),
      password: this.getErrorMessage("password"),
      company: this.getErrorMessage("company"),
      ...(showExtraFields
        ? {
            jobTitle: this.getErrorMessage("jobTitle"),
            phoneNumber: this.getErrorMessage("phoneNumber"),
          }
        : {}),
    };

    this.setState({
      errors,
    });
  }

  isFormValid() {
    const {
      fields: {
        firstName,
        lastName,
        email,
        password,
        jobTitle,
        company,
        phoneNumber,
      },
    } = this.state;
    const { showExtraFields } = this.props;

    const errors = {
      firstName: !_.isEmpty(firstName) && Validations.isValidName(firstName),
      lastName: !_.isEmpty(lastName) && Validations.isValidName(lastName),
      email:
        !_.isEmpty(email) &&
        Validations.isValidEmail(email) &&
        Validations.isValidWorkEmail(email),
      password: !_.isEmpty(password) && Validations.isValidPass(password),
      company: !_.isEmpty(company) && Validations.isLettersAndNumbers(company),
      ...(showExtraFields
        ? {
            jobTitle: !_.isEmpty(jobTitle) && Validations.isValidName(jobTitle),
            phoneNumber: !_.isEmpty(phoneNumber) && !isNaN(phoneNumber),
          }
        : {}),
    };
    return !_.some(errors, val => val === false);
  }

  onSupportLinkClick = () => {
    this.setState({ showContactSupport: true });
  };

  getSnackBarMessage = () => {
    const { errorMessage } = this.props;

    let message: string | ReactElement = errorMessage || "";

    return message;
  };

  getCommonInputViewProperties = () => ({
    classes: { root: styles.input },
    fullwidth: true,
  });

  getCommonInputProperties = () => ({
    onChange: this.handleChange,
    onFocus: this.handleFocus,
    onBlur: this.runFieldValidation,
  });

  closeContactForm = () => {
    this.setState({ showContactSupport: false });
  };

  render() {
    const {
      fields: {
        firstName,
        lastName,
        company,
        email,
        password,
        phoneNumber,
        jobTitle,
        country,
        accountName,
      },
      errors,
      openAlert,
      showPassword,
      loadingCaptchaToken,
      showContactSupport,
    } = this.state;
    const { errorMessage, isLoading, showExtraFields, t } = this.props;
    const validForm = this.isFormValid();

    if (
      errorMessage &&
      errorMessage.startsWith(NOT_FOUND_ERROR_MESSAGE_PREFIX)
    ) {
      return <AppCrash />;
    }
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.backToLogin}>
            <Link
              to={ROUTES.LOGIN}
              className={styles.backToLoginLink}
            >
              <ArrowLeftIcon className={styles.backToLoginIcon} />{" "}
              {this.props.t("register.back_to_login")}
            </Link>
          </div>
          <div className={styles.accountContainer}>
            <div className={styles.titleSection}>
              <h1 className={cn(styles.title, registerStyles.header)}>
                {this.props.t("register.create_account_title")}
              </h1>
            </div>
            <form
              onSubmit={e => {
                e.preventDefault();
                if (validForm) {
                  this.handleRegister();
                  return;
                }

                this.runFormValidation();
              }}
              className={styles.form}
              name="loginForm"
              noValidate
              autoComplete="off"
            >
              <div className={styles.inlineFormControl}>
                <div className={styles.formControl}>
                  <InputField
                    {...this.getCommonInputProperties()}
                    {...this.getCommonInputViewProperties()}
                    testId="first-name-field"
                    type="text"
                    name={CreateAccountFieldName.firstName}
                    label={this.props.t(
                      `register.form_labels.${CreateAccountFieldName.firstName}`
                    )}
                    value={firstName}
                    required={false}
                    autoFocus={true}
                    errorText={
                      !validForm && errors.firstName ? errors.firstName : null
                    }
                  />
                </div>
                <div className={styles.formControl}>
                  <InputField
                    {...this.getCommonInputProperties()}
                    {...this.getCommonInputViewProperties()}
                    type="text"
                    name={CreateAccountFieldName.lastName}
                    label={this.props.t(
                      `register.form_labels.${CreateAccountFieldName.lastName}`
                    )}
                    value={lastName}
                    testId="last-name-field"
                    required={false}
                    errorText={
                      !validForm && errors.lastName ? errors.lastName : null
                    }
                  />
                </div>
              </div>

              <div className={styles.inlineFormControl}>
                <div className={styles.formControl}>
                  <InputField
                    {...this.getCommonInputProperties()}
                    {...this.getCommonInputViewProperties()}
                    type="text"
                    name={CreateAccountFieldName.company}
                    label={this.props.t(
                      `register.form_labels.${CreateAccountFieldName.company}`
                    )}
                    value={company}
                    testId="company-field"
                    required={false}
                    errorText={
                      !validForm && errors.company ? errors.company : null
                    }
                  />
                </div>

                <div className={styles.formControl}>
                  <InputField
                    {...this.getCommonInputProperties()}
                    {...this.getCommonInputViewProperties()}
                    type="text"
                    name={CreateAccountFieldName.email}
                    disabled={!!this.props.email}
                    label={this.props.t(
                      `register.form_labels.${CreateAccountFieldName.email}`
                    )}
                    testId="business-email-field"
                    value={email}
                    required={false}
                    errorText={!validForm && errors.email ? errors.email : null}
                    InputProps={{ readOnly: !!this.props.email }}
                  />
                </div>
              </div>

              {showExtraFields && (
                <div className={styles.inlineFormControl}>
                  <div className={styles.formControl}>
                    <InputField
                      {...this.getCommonInputProperties()}
                      {...this.getCommonInputViewProperties()}
                      type="text"
                      name={CreateAccountFieldName.jobTitle}
                      label={this.props.t(
                        `register.form_labels.${CreateAccountFieldName.jobTitle}`
                      )}
                      value={jobTitle}
                      testId="job-title-field"
                      required={false}
                      errorText={
                        !validForm && errors.jobTitle ? errors.jobTitle : null
                      }
                    />
                  </div>

                  <div className={styles.formControl}>
                    <div className={styles.phoneFieldsContainer}>
                      <div className={styles.countryCodeContainer}>
                        <FormControl fullWidth>
                          <InputLabel>
                            {this.props.t(
                              `register.form_labels.${CreateAccountFieldName.country}`
                            )}
                          </InputLabel>
                          <CustomSelect
                            testId="register-select-country"
                            error={!!errors.country}
                            value={country}
                            fullWidth
                            onChange={e => {
                              const { value } = e.target as HTMLInputElement;
                              this.handleChange(
                                CreateAccountFieldName.country,
                                value
                              );
                              if (this.phoneNumberRef) {
                                setTimeout(() => {
                                  this.phoneNumberRef.focus();
                                }, 0);
                              }
                            }}
                            renderValue={country => {
                              return COUNTRY_CODES.find(c => c.name === country)
                                ?.dial_code;
                            }}
                          >
                            {COUNTRY_CODES.map(country => (
                              <SelectMenuItem
                                key={country.name}
                                value={country.name}
                                testId={`country-${country?.dial_code}`}
                              >
                                {country.name} {country.dial_code}
                              </SelectMenuItem>
                            ))}
                          </CustomSelect>
                        </FormControl>
                      </div>

                      <div>
                        <InputField
                          {...this.getCommonInputProperties()}
                          {...this.getCommonInputViewProperties()}
                          type="tel"
                          name={CreateAccountFieldName.phoneNumber}
                          label={this.props.t(
                            `register.form_labels.${CreateAccountFieldName.phoneNumber}`
                          )}
                          value={phoneNumber}
                          testId="phone-number-field"
                          inputRef={ref => (this.phoneNumberRef = ref)}
                          onChange={(name, value) => {
                            if (!isNaN(value)) {
                              this.handleChange(name, value.trim());
                            }
                          }}
                          required={false}
                          errorText={
                            !validForm && errors.phoneNumber
                              ? errors.phoneNumber
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.inlineFormControl}>
                <div className={styles.formControl}>
                  <InputField
                    {...this.getCommonInputViewProperties()}
                    type="text"
                    name={CreateAccountFieldName.accountName}
                    value={accountName}
                    label={this.props.t(
                      `register.form_labels.${CreateAccountFieldName.accountName}`
                    )}
                    testId="account-name-field"
                    readOnly
                    required={false}
                    disabled
                  />
                </div>

                <div className={styles.formControl}>
                  <p className={styles.accountNameWarning}>
                    <WarningIcon className={styles.warningIcon} />
                    {this.props.t(
                      email
                        ? "register.account_name_warning"
                        : "register.provide_email"
                    )}
                    <ContactSupportLink
                      email={email}
                      onClick={this.onSupportLinkClick}
                    />
                  </p>
                </div>
              </div>
              <div className={styles.inlineFormControl}>
                <div className={styles.formControl}>
                  <InputField
                    {...this.getCommonInputProperties()}
                    {...this.getCommonInputViewProperties()}
                    type={showPassword ? "text" : "password"}
                    name={CreateAccountFieldName.password}
                    value={password}
                    label={this.props.t(
                      `register.form_labels.${CreateAccountFieldName.password}`
                    )}
                    testId="password-field"
                    InputProps={{
                      endAdornment: (
                        <div
                          className={styles.eyeIcon}
                          aria-label="toggle password visibility"
                          onClick={() => {
                            this.setState({ showPassword: !showPassword });
                          }}
                        >
                          {showPassword ? <EyeShutIcon /> : <EyeOpenIcon />}
                        </div>
                      ),
                    }}
                    required={false}
                    errorText={errors.password ? errors.password : null}
                  />
                </div>

                <div className={styles.formControl}>
                  <p className={styles.passwordHint}>
                    {this.props.t("register.password_validation_rule")}
                  </p>
                </div>
              </div>
              <div className={styles.inlineFormControl}>
                <div className={styles.formControl}>
                  <MaterialButton
                    endIcon={<ChevronRight />}
                    text="Create Account"
                    id="btnSubmit"
                    dataTestid="register-create-account"
                    className={styles.submitButton}
                    disabled={!this.isFormValid()}
                  />
                </div>
                <PrivacyPolicy />
              </div>
            </form>
            <div className={styles.footer}>
              <div className={cn(styles.footerText, styles.copyright)}>
                {`${this.props.t(
                  "register.copyright"
                )}, ${new Date().getFullYear()}. ${this.props.t(
                  "register.all_rights_reserved"
                )}`}
              </div>
            </div>
          </div>
        </div>
        {(isLoading || loadingCaptchaToken) && (
          <LoadingOverlap
            isLoading
            dimmed
          />
        )}
        {openAlert && (
          <SnackBarView
            message={this.getSnackBarMessage()}
            vertical="top"
            horizontal="center"
            openSnackBar={!!errorMessage}
            type="error"
            closeSnackBar={this.closeAlert}
          />
        )}
        {showContactSupport && (
          <ContactSupport
            preCompletedFields={formatContactFields({
              ...this.state.fields,
              subject: t("register.contact_default_subject"),
            })}
            onClose={this.closeContactForm}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isRegistered: _.get(state, "account.isRegistered"),
    errorMessage: _.get(state, "account.errorMessage"),
    isLoading: _.get(state, "account.isLoading"),
  };
};

export default connect(mapStateToProps, {
  registerAccount,
  setAccountError,
  registerAccountFromAws,
})(withRouter(withRecaptcha(withTranslation()(Register), "registration")));
