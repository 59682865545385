import { Subscription, SubscriptionActions } from "./actions";
import { Action, handleActions } from "redux-actions";

export interface SubscriptionState {
  subscription: Subscription | null;
  errorMessage: string | null;
  isLoading: boolean;
}

export const INITIAL_STATE: SubscriptionState = {
  subscription: null,
  errorMessage: null,
  isLoading: false,
};

const reducerMap = {};

reducerMap[SubscriptionActions.LOAD_SUBSCRIPTION] = (
  state: SubscriptionState,
  action: Action<{ isSilent: boolean; accountId: string }>
): SubscriptionState => {
  return { ...state, errorMessage: null, isLoading: !action.payload.isSilent };
};

reducerMap[SubscriptionActions.LOAD_SUBSCRIPTION_SUCCESS] = (
  state: SubscriptionState,
  action: Action<Subscription>
): SubscriptionState => {
  return { subscription: action.payload, errorMessage: null, isLoading: false };
};

reducerMap[SubscriptionActions.LOAD_SUBSCRIPTION_FAILURE] = (
  state: SubscriptionState,
  action: Action<string>
): SubscriptionState => {
  return { ...state, errorMessage: action.payload, isLoading: false };
};

export default handleActions<SubscriptionState>(reducerMap, INITIAL_STATE);
