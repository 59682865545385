import "flat-map-polyfill";
import { Provider } from "react-redux";
import App from "components/App";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import "styles/reset.scss";
import "styles/fonts.scss";
import * as Sentry from "@sentry/browser";
import {
  attachTestIdBreadcrumb,
  attachGraphqlBreadcrumb,
} from "./sentry-utils";
import ErrorBoundary from "components/ErrorBoundary";
import LoadingOverlap from "components/LoadingOverlap";
import { store, persistor } from "./store";
import "./heap.io";
import "i18n/config";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { FunctionComponent } from "react";
import { putUserData } from "redux/user/actions";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

export const deletingEngineIds = new Map();
export const history = createBrowserHistory();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    environment: new URL(process.env.REACT_APP_GRAPHQL_ENDPOINT_URL as string)
      .host,
    beforeBreadcrumb(breadcrumb, hint) {
      switch (breadcrumb.category) {
        case "ui.click":
          return attachTestIdBreadcrumb(breadcrumb, hint);
        case "fetch":
          return attachGraphqlBreadcrumb(breadcrumb, hint);
        default:
          return breadcrumb;
      }
    },
    ignoreErrors: [
      "top.GLOBALS",
      "LaunchDarklyFlagFetchError",
      "ChunkLoadError",
      "Loading CSS chunk",
    ],
    denyUrls: [
      /\/(gtm|ga|analytics)\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}

const initApp = async () => {
  let LDProvider: FunctionComponent<{ children: React.ReactNode }> = ({
    children,
  }) => <>{children}</>;

  if (process.env.REACT_APP_LD_CLIENT_SIDE_ID) {
    LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID as string,
      user: {
        key: process.env.REACT_APP_LD_GLOBAL_KEY as string,
        name: process.env.REACT_APP_LD_GLOBAL_NAME as string,
      },
    });
  }

  root.render(
    <ErrorBoundary>
      <HistoryRouter history={history}>
        <Provider store={store}>
          <PersistGate
            loading={<LoadingOverlap isLoading />}
            persistor={persistor}
          >
            <LDProvider>
              <App />
            </LDProvider>
          </PersistGate>
        </Provider>
      </HistoryRouter>
    </ErrorBoundary>
  );
};

initApp();

(window as any).reloadApp = () => {
  store.dispatch(putUserData(null as any));
  initApp();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export { store };
