export const QUERY_SETTINGS = {
  advancedMode: "advanced_mode",
  asyncExecution: "async_execution",
  noProgress: "no_progress",
  hiddenQuery: "hidden_query",
  bypassQueue: "bypass_queue",
  preserveQueryExecution: "preserve_query_execution",
  hybridMode: "hybrid_mode",
  preserveResponseData: "preserve_response_data",
};
