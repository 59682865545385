import { EngineStatusSummary } from "pages/Engines/EnginesTable/__generated__/EnginesTable_account.graphql";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";

const ENGINE_STATUS_SUMMARY_MAP = {
  DELETED: UIEngineStatus.STATUS_DELETED,
  DELETING: UIEngineStatus.STATUS_DROPPING,
  REPAIRING: UIEngineStatus.STATUS_REPAIRING,
  RESTARTING: UIEngineStatus.STATUS_RESTARTING,
  RUNNING: UIEngineStatus.STATUS_ON,
  STARTING: UIEngineStatus.STATUS_STARTING,
  STOPPED: UIEngineStatus.STATUS_STOPPED,
  STOPPING: UIEngineStatus.STATUS_STOPPING,
  STARTING_INITIALIZING: UIEngineStatus.STATUS_STARTING,
  RESTARTING_INITIALIZING: UIEngineStatus.STATUS_RESTARTING,
};

const ENGINE_STATUS_TEXT_MAP = {
  STARTING: {
    shortText: "engines.setting_up_short",
    longText: "engines.setting_up_long",
  },
  RESTARTING: {
    shortText: "engines.setting_up_short",
    longText: "engines.setting_up_long",
  },
  STARTING_INITIALIZING: {
    shortText: "engines.starting_up_short",
    longText: "engines.starting_up_long",
  },
  RESTARTING_INITIALIZING: {
    shortText: "engines.starting_up_short",
    longText: "engines.starting_up_long",
  },
  REPAIRING: {
    shortText: "engines.repairing_short",
    longText: "engines.repairing_long",
  },
};

export const getUiEngineStatus = (
  engineStatusSummary: EngineStatusSummary
): UIEngineStatus => {
  const uIStatus = ENGINE_STATUS_SUMMARY_MAP[engineStatusSummary];

  if (!uIStatus) {
    return UIEngineStatus.STATUS_FAILED;
  }

  return uIStatus;
};

export const getUiEngineStatusText = (
  engineStatusSummary: EngineStatusSummary
): any => ENGINE_STATUS_TEXT_MAP[engineStatusSummary];
