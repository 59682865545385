import _ from "lodash";
import { Store } from "redux";
import RefreshTokenMutation from "relay/mutations/auth/RefreshTokenMutation";
import {
  AuthRefreshTokenInput,
  RefreshTokenMutation$data,
} from "relay/mutations/auth/__generated__/RefreshTokenMutation.graphql";

export const getRefreshToken = (store: Store) => {
  const state = store.getState();
  return _.get(state, "auth.session.refresh_token");
};

export const refreshToken = (
  store: Store,
  environment
): Promise<RefreshTokenMutation$data> => {
  const refreshToken = getRefreshToken(store);
  if (!refreshToken) {
    return Promise.reject(new Error("refresh token not found."));
  }
  const input: AuthRefreshTokenInput = {
    refreshToken,
  };
  return new Promise((resolve, reject) => {
    RefreshTokenMutation.commit(environment, input, resolve, reject);
  });
};
