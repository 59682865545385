/**
 * @generated SignedSource<<1f772736dd0f5cfc5bc21902c3bedb49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineDeleteInput = {
  id: string;
};
export type RemoveEngineMutation$variables = {
  input: EngineDeleteInput;
};
export type RemoveEngineMutation$data = {
  readonly engineDelete: {
    readonly success: boolean;
    readonly engine: {
      readonly id: string;
      readonly currentStatusSummary: EngineStatusSummary;
      readonly " $fragmentSpreads": FragmentRefs<"EngineRow_engine" | "EngineModal_engine">;
    };
  };
};
export type RemoveEngineMutation = {
  variables: RemoveEngineMutation$variables;
  response: RemoveEngineMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentStatusSummary",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveEngineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EngineDeletePayload",
        "kind": "LinkedField",
        "name": "engineDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Engine",
            "kind": "LinkedField",
            "name": "engine",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EngineRow_engine"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EngineModal_engine"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveEngineMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EngineDeletePayload",
        "kind": "LinkedField",
        "name": "engineDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Engine",
            "kind": "LinkedField",
            "name": "engine",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "desiredActions",
                    "value": [
                      "core.engines.start",
                      "core.engines.stop",
                      "core.engines.update",
                      "core.engines.delete"
                    ]
                  }
                ],
                "kind": "ScalarField",
                "name": "allowedActions",
                "storageKey": "allowedActions(desiredActions:[\"core.engines.start\",\"core.engines.stop\",\"core.engines.update\",\"core.engines.delete\"])"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "computeRegion",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settingsPreset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endpoint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngineSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autoStopDelayDurationSeconds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "warmUpLevel",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EngineRevision",
                "kind": "LinkedField",
                "name": "latestRevision",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EngineRevisionSpecification",
                    "kind": "LinkedField",
                    "name": "specification",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dbComputeInstancesCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dbComputeInstancesUseSpot",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstanceType",
                        "kind": "LinkedField",
                        "name": "dbComputeInstancesType",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingConnection",
                "kind": "LinkedField",
                "name": "bindings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BindingEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Binding",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Database",
                            "kind": "LinkedField",
                            "name": "database",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dataSizeFull",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "computeRegionId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cdafbbcc94f4ae084128698dc2e0895d",
    "id": null,
    "metadata": {},
    "name": "RemoveEngineMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveEngineMutation(\n  $input: EngineDeleteInput!\n) {\n  engineDelete(input: $input) {\n    success\n    engine {\n      id\n      currentStatusSummary\n      ...EngineRow_engine\n      ...EngineModal_engine\n    }\n  }\n}\n\nfragment EngineModal_engine on Engine {\n  id\n  name\n  description\n  emoji\n  computeRegionId\n  settingsPreset\n  endpoint\n  currentStatusSummary\n  settings {\n    autoStopDelayDurationSeconds\n    warmUpLevel\n  }\n  latestRevision {\n    id\n    specification {\n      dbComputeInstancesCount\n      dbComputeInstancesUseSpot\n      dbComputeInstancesType {\n        id\n        name\n      }\n    }\n  }\n  bindings {\n    edges {\n      node {\n        id\n        database {\n          id\n          name\n          emoji\n          dataSizeFull\n        }\n      }\n    }\n  }\n}\n\nfragment EngineRow_engine on Engine {\n  id\n  name\n  description\n  emoji\n  allowedActions(desiredActions: [\"core.engines.start\", \"core.engines.stop\", \"core.engines.update\", \"core.engines.delete\"])\n  computeRegion {\n    id\n    name\n  }\n  settingsPreset\n  endpoint\n  currentStatusSummary\n  settings {\n    autoStopDelayDurationSeconds\n  }\n  latestRevision {\n    id\n    specification {\n      dbComputeInstancesCount\n      dbComputeInstancesUseSpot\n      dbComputeInstancesType {\n        id\n        name\n      }\n    }\n  }\n  bindings {\n    edges {\n      node {\n        id\n        database {\n          id\n          name\n          emoji\n          dataSizeFull\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f08b170665c1f36570b87b96b5f2c89";

export default node;
