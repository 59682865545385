import _ from "lodash";
import { DEFAULT_REGION_NAME } from "businessLogic/constants";
import { Emoji } from "components/EmojiIcon/EmojiIcon";
import { Validations } from "businessLogic/helpers/Validation";
import { Formatter } from "businessLogic/helpers/Format";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";
import {
  getUiEngineStatus,
  getUiEngineStatusText,
} from "components/EngineStatusIcon/helpers";
import { EngineSettingsLoggingLevel } from "relay/mutations/engine/__generated__/EngineCreateMutation.graphql";
import {
  AutoStopValues,
  EngineFormField,
  WarmupValues,
  SETTING_PRESET_DESCRIPTION,
  AutoStopTooltipMap,
  WARMUP_OPTIONS,
  InstanceType,
  SettingsPreset,
} from "./constants";
import {
  GENERAL_PURPOSE_DEFAULT_INSTANCE_TYPE_NAME_WITH_CPU,
  GENERAL_PURPOSE_DEFAULT_INSTANCE_TYPE_NAME,
  INGEST_DEFAULT_INSTANCE_TYPE_NAME_WITH_USE_CPU,
  INGEST_DEFAULT_INSTANCE_TYPE_NAME,
  ANALYTICS_DEFAULT_INSTANCE_TYPE_NAME_WITH_USE_CPU,
  ANALYTICS_DEFAULT_INSTANCE_TYPE_NAME,
  enginesAllAllowedActions,
  Template,
} from "pages/Database/constants";

export const resolveDefaultInstanceTypeName = (
  fireboltNameForEngine: boolean,
  singleGpEnginePerDb: boolean
) => {
  if (singleGpEnginePerDb) {
    return resolveGeneralPurposeDefaultInstanceTypeName(fireboltNameForEngine);
  }

  return resolveIngestDefaultInstanceTypeName(fireboltNameForEngine);
};

export const resolveGeneralPurposeDefaultInstanceTypeName = (
  fireboltNameForEngine: boolean
) => {
  return fireboltNameForEngine
    ? GENERAL_PURPOSE_DEFAULT_INSTANCE_TYPE_NAME_WITH_CPU
    : GENERAL_PURPOSE_DEFAULT_INSTANCE_TYPE_NAME;
};

export const resolveIngestDefaultInstanceTypeName = (
  fireboltNameForEngine: boolean
): string => {
  return fireboltNameForEngine
    ? INGEST_DEFAULT_INSTANCE_TYPE_NAME_WITH_USE_CPU
    : INGEST_DEFAULT_INSTANCE_TYPE_NAME;
};

export const resolveAnalyticsDefaultInstanceTypeName = (
  fireboltNameForEngine: boolean
): string => {
  return fireboltNameForEngine
    ? ANALYTICS_DEFAULT_INSTANCE_TYPE_NAME_WITH_USE_CPU
    : ANALYTICS_DEFAULT_INSTANCE_TYPE_NAME;
};

export const generalDefaultTemplate = (
  fireboltNameForEngine: boolean,
  spotEnabledByDefault: boolean
) => {
  return {
    isDefault: true,
    initialInstanceTypeName: resolveGeneralPurposeDefaultInstanceTypeName(
      fireboltNameForEngine
    ),
    allowedActions: enginesAllAllowedActions,
    [EngineFormField.Name]: "general_purpose",
    [EngineFormField.Scale]: 2,
    [EngineFormField.IsSpot]: spotEnabledByDefault,
    [EngineFormField.InstanceTypeId]:
      resolveGeneralPurposeDefaultInstanceTypeName(fireboltNameForEngine),
    [EngineFormField.SettingsPreset]: SettingsPreset.GeneralPurpose,
    [EngineFormField.AutoStopAfter]: AutoStopValues.TwentyMinutes,
    [EngineFormField.WarmUp]: WarmupValues.Indexes,
  };
};

export const ingestDefaultTemplate = (
  fireboltNameForEngine: boolean,
  spotEnabledByDefault: boolean
) => {
  return {
    isDefault: true,
    initialInstanceTypeName: resolveIngestDefaultInstanceTypeName(
      fireboltNameForEngine
    ),
    allowedActions: enginesAllAllowedActions,
    [EngineFormField.Name]: "Ingest",
    [EngineFormField.Scale]: 2,
    [EngineFormField.IsSpot]: spotEnabledByDefault,
    [EngineFormField.InstanceTypeId]: resolveIngestDefaultInstanceTypeName(
      fireboltNameForEngine
    ),
    [EngineFormField.SettingsPreset]: SettingsPreset.GeneralPurpose,
    [EngineFormField.AutoStopAfter]: AutoStopValues.TwentyMinutes,
    [EngineFormField.WarmUp]: WarmupValues.Indexes,
  };
};

export const analyticDefaultTemplate = (
  fireboltNameForEngine: boolean,
  spotEnabledByDefault: boolean
) => {
  return {
    initialInstanceTypeName: resolveAnalyticsDefaultInstanceTypeName(
      fireboltNameForEngine
    ),
    allowedActions: enginesAllAllowedActions,
    [EngineFormField.Name]: "Analytics",
    [EngineFormField.Scale]: 1,
    [EngineFormField.IsSpot]: spotEnabledByDefault,
    [EngineFormField.InstanceTypeId]: resolveAnalyticsDefaultInstanceTypeName(
      fireboltNameForEngine
    ),
    [EngineFormField.SettingsPreset]: SettingsPreset.DataAnalytics,
    [EngineFormField.AutoStopAfter]: AutoStopValues.TwentyMinutes,
    [EngineFormField.WarmUp]: WarmupValues.Indexes,
  };
};

export const engineNameByType = {
  [SettingsPreset.DataAnalytics]: "Analytics",
  [SettingsPreset.GeneralPurpose]: "Ingest",
};

export const getEngineTemplates = ({
  spotEnabledByDefault,
  fireboltNameForEngine,
  singleGpEnginePerDb,
}: {
  spotEnabledByDefault: boolean;
  fireboltNameForEngine: boolean;
  singleGpEnginePerDb: boolean;
}): Template[] => {
  const templates: Template[] = [];
  if (singleGpEnginePerDb) {
    templates.push(
      generalDefaultTemplate(fireboltNameForEngine, spotEnabledByDefault)
    );
  } else {
    templates.push(
      ingestDefaultTemplate(fireboltNameForEngine, spotEnabledByDefault)
    );
    templates.push(
      analyticDefaultTemplate(fireboltNameForEngine, spotEnabledByDefault)
    );
  }
  return templates;
};

export const getDefaultRegion = regions => {
  const defaultRegion = regions.find(r => r.name === DEFAULT_REGION_NAME);

  return defaultRegion || regions[0];
};

export const getDefaultInstanceType = ({
  instanceTypes,
  regionId,
  instanceName,
}) => {
  const defaultInstanceType = instanceTypes
    .filter(it => it.regionId === regionId)
    .find(it => it.name === instanceName);

  return defaultInstanceType || instanceTypes[0];
};

export const getDefaultEngineFormState = ({
  regionId,
  instanceTypes,
  fireboltNameForEngine,
  singleGpEnginePerDb,
  instanceSpotDefaultEnabled,
}) => {
  const instanceType = getDefaultInstanceType({
    instanceTypes,
    regionId,
    instanceName: resolveDefaultInstanceTypeName(
      fireboltNameForEngine,
      singleGpEnginePerDb
    ),
  });
  return {
    emoji: Emoji.MOVIE_CAMERA,
    name: "",
    regionId,
    scale: 2,
    isSpot: instanceSpotDefaultEnabled,
    instanceTypeId: instanceType.id,
    settingsPreset: instanceType.availableSettingsPresets.includes(
      "GENERAL_PURPOSE"
    )
      ? "GENERAL_PURPOSE"
      : instanceType.availableSettingsPresets[0],
    autoStopAfter: AutoStopValues.TwentyMinutes,
    warmup: WarmupValues.Indexes,
  };
};

export const engineGqlToUi = (engine, engineIsDefault?: boolean) => {
  return {
    id: engine.id,
    currentStatusSummary: engine.currentStatusSummary,
    status: getUiEngineStatus(engine.currentStatusSummary),
    statusText: getUiEngineStatusText(engine.currentStatusSummary),
    latestRevision: engine.latestRevision,
    isDefault: engineIsDefault,
    endpoint: engine.endpoint,
    computeRegion: engine.computeRegion,
    emoji: engine.emoji,
    allowedActions: engine.allowedActions,
    [EngineFormField.Name]: engine.name,
    [EngineFormField.Scale]:
      engine?.latestRevision?.specification?.dbComputeInstancesCount,
    [EngineFormField.IsSpot]:
      engine?.latestRevision?.specification?.dbComputeInstancesUseSpot,
    [EngineFormField.InstanceTypeId]:
      engine?.latestRevision?.specification?.dbComputeInstancesType?.id,
    [EngineFormField.SettingsPreset]: engine.settingsPreset,
    [EngineFormField.AutoStopAfter]:
      engine?.settings?.autoStopDelayDurationSeconds,
    [EngineFormField.WarmUp]: engine?.settings?.warmUpLevel,
  };
};

export const formatEngineForUI = node => {
  const { engine, engineIsDefault } = node;
  return engineGqlToUi(engine, engineIsDefault);
};

export const formatEngineUiToGraphQL = ({ engineFields, instanceTypes }) => {
  return {
    description: "",
    emoji: Emoji.KICK_SCOOTER,
    settingIsReadOnly:
      SETTING_PRESET_DESCRIPTION[engineFields.settingsPreset].readOnly,
    settingWarmUpLevel: engineFields.warmup,
    settingAutoStopDelayDurationSeconds: +engineFields.autoStopAfter,
    specDbComputeInstancesCount: engineFields.scale,
    specDbComputeInstancesUseSpot: engineFields.isSpot,
    specDbComputeInstancesTypeId: getCurrentInstanceType(
      instanceTypes,
      engineFields.instanceTypeId
    ).id,
  };
};

export const getEngineNameForNewEngine = (dbName, engineName) =>
  `${dbName}_${engineName}`;

export const formatEngineUiToGraphQLCreateMode = ({
  engineFields,
  dbFields,
  instanceTypes,
}) => {
  return {
    name: getEngineNameForNewEngine(dbFields.name, engineFields.name),
    settingMinimumLoggingLevel: "INFO",
    settingPreset: engineFields.settingsPreset,
    ...formatEngineUiToGraphQL({
      engineFields,
      instanceTypes,
    }),
  };
};

export const formatEngineUiToGraphQLEditMode = ({
  engineFields,
  instanceTypes,
}) => {
  return {
    id: engineFields.id,
    name: engineFields.name,
    settingsPreset: engineFields.settingsPreset,
    ...formatEngineUiToGraphQL({
      engineFields,
      instanceTypes,
    }),
  };
};

export const formatEngineUiToGraphQLCreateMutation = ({
  engineFields,
  dbFields,
  instanceTypes,
  accountId,
}) => {
  return {
    accountId,
    name: `${dbFields.name}_${engineFields.name}`,
    settingsPreset: engineFields.settingsPreset,
    computeRegionId: dbFields.regionId,
    ...formatEngineUiToGraphQL({
      engineFields,
      instanceTypes,
    }),
    emoji: dbFields.emoji,
    settingMinimumLoggingLevel: "INFO" as EngineSettingsLoggingLevel,
  };
};

export const validateEngineForm = name => {
  return (
    !_.isEmpty(name) &&
    Validations.isValidDatabaseName(name) &&
    !Validations.isDatabaseNameReserved(name)
  );
};

export const getAvailableInstanceTypes = ({
  instanceTypes,
  regionId,
  settingsPreset,
}) => {
  return instanceTypes.filter(
    it =>
      it.regionId === regionId &&
      it.availableSettingsPresets.includes(settingsPreset)
  );
};

export const getCurrentInstanceType = (instanceTypes, instanceTypeId) => {
  return instanceTypes.find(
    it => it.id === instanceTypeId || it.name === instanceTypeId
  );
};

export const engineCanBeEdited = status => {
  return (
    !status ||
    status === UIEngineStatus.STATUS_STOPPED ||
    status === UIEngineStatus.STATUS_FAILED
  );
};

export const generateEngineTooltip = ({
  instanceType,
  engine,
  instanceTypeInfo,
  t,
}): any => {
  const engineSpecs = getTotalEngineSpecs({
    instanceType,
    scale: engine.scale,
    options: {
      isSpot: engine.isSpot,
    },
  });

  const customAutoStop = Formatter.secondsToTimeString(
    +engine[EngineFormField.AutoStopAfter]
  ).replace(/minutes?/, "min");

  return [
    [
      {
        // @ts-ignore
        value: `${t(`engines.${engine.settingsPreset}`)} ${t(
          "engines.engine_capitalized"
        )}`,
        name: "",
      },
    ],
    [
      {
        name: `${t("engines.optimized_for")}: `,
        value: `${instanceTypeInfo ? instanceTypeInfo.info : ""}`,
      },
      {
        name: `${t("engines.ec2_type")}: `,
        value: `${instanceType.name}`,
      },
    ],
    [
      {
        value: `${t("engines.total_spec")} `,
        name: "",
      },
      {
        name: `${t("engines.cpu_cores")}: `,
        value: `${engineSpecs.cores.value}`,
      },
      {
        name: `${engineSpecs.ram.notation} ${t("engines.ram")}: `,
        value: `${engineSpecs.ram.value}`,
      },
      {
        name: `${engineSpecs.storage.notation} ${t("engines.ssd")}: `,
        value: `${engineSpecs.storage.value}`,
      },
      {
        name: `${t("engines.price")}: `,
        value: `${engineSpecs.cost.notation}${engineSpecs.cost.value}/${t(
          "engines.hour"
        )}`,
      },
      {
        name: `${t("engines.scale")} `,
        value: `x${engine.scale}`,
      },
    ].filter(t => {
      return t !== null;
    }),
    [
      {
        name: `${t("engines.auto_stop")} `,
        value:
          AutoStopTooltipMap[engine[EngineFormField.AutoStopAfter]] ||
          customAutoStop,
      },
      {
        name: `${t("engines.warmup")} `,
        value: _.find(
          WARMUP_OPTIONS,
          o => o.value === engine[EngineFormField.WarmUp]
        )?.text,
      },
    ],
  ];
};

export const getInstanceTypesFromRegionId = (regionId, instanceTypes) =>
  _.filter(instanceTypes, inst => inst.regionId === regionId);

interface SpecsInterface {
  instanceType: InstanceType;
  scale: number;
  options: any;
}

const DEFAULT_ENGINE_SPEC = {
  cores: {
    value: "",
    notation: "",
  },
  ram: {
    value: "",
    notation: "",
  },
  storage: {
    value: "",
    notation: "",
  },
  cost: {
    value: "",
    notation: "",
  },
};

export const getTotalEngineSpecs = ({
  instanceType,
  scale,
  options = {},
}: SpecsInterface) => {
  if (!instanceType || !scale) {
    return { ...DEFAULT_ENGINE_SPEC };
  }

  const totalCPUs = instanceType.cores * scale;
  const totalRam = instanceType.ram;
  const totalStorage = instanceType.storage * scale;
  const totalCost = instanceType.price * scale;

  const formattedCpus = Formatter.formatNumberToLocale(String(totalCPUs));
  const formattedRam = Formatter.bytesFormatter(totalRam);
  const formattedStorage = Formatter.bytesFormatter(totalStorage);
  const formattedCost = Formatter.formatNumberToLocale(
    String(totalCost.toFixed(2)),
    2,
    2
  );

  return {
    ...DEFAULT_ENGINE_SPEC,
    cores: {
      value: formattedCpus,
      notation: "",
    },
    ram: {
      value: formattedRam.size,
      notation: formattedRam.notation,
    },
    storage: {
      value: formattedStorage.size,
      notation: formattedStorage.notation,
    },
    cost: {
      value: formattedCost,
      notation: options.isSpot ? "~$" : "$",
    },
  };
};

export const getEngineInstanceType = (instanceType, engine) => ({
  cores: instanceType.cores * engine.scale,
  storage: instanceType.storage * engine.scale,
  ram: instanceType.ram,
});

export const formatEngineForEngineItem = ({
  engine,
  instanceType,
  instanceTypeInfo,
  t,
}) => {
  return {
    ...engine,
    instanceType: instanceType
      ? getEngineInstanceType(instanceType, engine)
      : null,
    engineCost: instanceType ? instanceType.price * engine.scale : 0,
    instanceTypeIcon: instanceTypeInfo && instanceTypeInfo.icon,
    tooltipLists: instanceType
      ? generateEngineTooltip({
          instanceTypeInfo,
          instanceType,
          engine,
          t,
        })
      : null,
  };
};
