import * as React from "react";
import { AccessManager } from "../../services/access/accessManager";
import { useContext } from "react";
import { AccessManagerContext } from "../App/App";

export interface WithRouterProps {
  accessManager: AccessManager;
}

const withAccessManager = WrappedComponent => {
  return props => {
    const accessManager = useContext(AccessManagerContext);

    return (
      <WrappedComponent
        accessManager={accessManager}
        {...props}
      />
    );
  };
};

export default withAccessManager;
