import { Suspense, useMemo } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { matchPath } from "react-router-dom";
import { GENERAL_ROUTES, GUEST_ROUTES } from "businessLogic/routes.constants";
import StatusMessageContainer from "components/StatusMessage/StatusMessage.container";
import LoadingOverlap from "components/LoadingOverlap";
import MainSidebar from "components/MainSidebar/MainSidebar";
import { MainSidebarSkeleton } from "components/MainSidebar/MainSidebarSkeleton";
import ConfirmationDialog from "components/ConfirmationDialog";
import ProductUpdatesBanner from "components/ProductUpdatesBanner";
import { withMaitenance } from "./withMaitenance";
import Router from "./Router";
import styles from "./styles.module.scss";
import { ThemeProvider } from "themes/themeContext";

export interface AppProps {
  appStateLoading: boolean;
  isLogoutLoading: boolean;
}

const mapStateToProps = state => {
  return {
    appStateLoading: _.get(state, "app.appStateLoading"),
    isLogoutLoading: _.get(state, "auth.isLogoutLoading"),
  };
};

const AppInner: React.FC<AppProps> = props => {
  const { appStateLoading, isLogoutLoading } = props;

  const isGuestRoute = useMemo(() => {
    const guestRoutes = [
      ...Object.values(GUEST_ROUTES),
      ...Object.values(GENERAL_ROUTES),
    ];

    for (let i = 0; i < guestRoutes.length; i++) {
      const match = matchPath(guestRoutes[i], location.pathname);
      if (match) {
        return true;
      }
    }

    return false;
  }, [location.pathname]);

  return (
    <ThemeProvider forcedTheme={isGuestRoute && "light"}>
      <div className={styles.app}>
        <Suspense fallback={<MainSidebarSkeleton />}>
          <MainSidebar />
        </Suspense>
        <div className={styles.content}>
          {appStateLoading ? <LoadingOverlap isLoading /> : <Router />}
          {isLogoutLoading && (
            <LoadingOverlap
              testId="logout-loading"
              isLoading
              dimmed
            />
          )}
        </div>
      </div>

      <Suspense fallback={null}>
        <ProductUpdatesBanner />
      </Suspense>
      <StatusMessageContainer />
      <ConfirmationDialog />
    </ThemeProvider>
  );
};

export default withMaitenance(connect(mapStateToProps)(AppInner));
