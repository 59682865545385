import * as React from "react";
import _ from "lodash";
import { FEATURE_FLAG_INFO_MESSAGE, FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "./useAllFlags";

const REGION_READ_ONLY_PREFIX = "firebolt-app-region-";
const REGION_READ_ONLY_SUFFIX = "-read-only-mode";

/*
Region param is optional. If region is provided It will also check for read-only flag for specific region.
 */
export const useReadOnlyMode = (region?: { id: string; name: string }) => {
  const flags = useAllFlags();
  const readOnlyMode = !!flags[FeatureFlag.FireboltAppReadOnlyMaintenanceMode];

  const isReadOnlyMode = (region?: {
    id: string;
    name: string;
  }): {
    readOnlyMode: boolean;
    readOnlyTooltip: {
      title: string;
    };
  } => {
    if (readOnlyMode) {
      const readOnlyTooltip = {
        title:
          FEATURE_FLAG_INFO_MESSAGE[
            FeatureFlag.FireboltAppReadOnlyMaintenanceMode
          ],
      };

      return {
        readOnlyMode,
        readOnlyTooltip,
      };
    }

    if (region) {
      const flagInCamelCase = _.camelCase(
        `${REGION_READ_ONLY_PREFIX}${region.name}${REGION_READ_ONLY_SUFFIX}`
      );
      if (flags[flagInCamelCase]) {
        return {
          readOnlyMode: true,
          readOnlyTooltip: {
            title: `Application region ${region.name} is in read-only mode`,
          },
        };
      }
    }

    return {
      readOnlyMode: false,
      readOnlyTooltip: {
        title: "",
      },
    };
  };

  // isReadOnlyMode - is a function. It may be called from event handlers or class components with HOC.
  // It useful in case if you need check read-only flag for specific region, but you haven't had the region object when component rendered.
  return { ...isReadOnlyMode(region), isReadOnlyMode };
};

export interface WithReadOnlyModeProps {
  isReadOnlyMode: (region: { id: string; name: string }) => {
    readOnlyMode: boolean;
    readOnlyTooltip: {
      title: string;
    };
  };
}

export const withReadOnlyMode = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithReadOnlyModeProps> => {
  return (props: P) => {
    const { isReadOnlyMode } = useReadOnlyMode();
    return (
      <Component
        {...props}
        isReadOnlyMode={isReadOnlyMode}
      />
    );
  };
};
