import {
  Observable,
  Environment,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";
import { createErrorMockInterceptor } from "relay/errorMockInterceptor";
import { createSubscriptionClient } from "relay/subscriptionClient";
import { createBeforeUnloadEventController } from "relay/beforeUnloadEventController";
import { authService } from "services/auth";

import { store } from "store";
import { getSession } from "businessLogic/services/auth/getSession";
import { getAccessToken } from "businessLogic/services/auth/getAccessToken";
import { logoutSuccess } from "pages/Auth/redux/actions";
import { refreshToken } from "./refreshToken";
import { UnauthorizedError } from "./UnauthorizedError";
import { history } from "index";

const MAX_REFRESH_TOKEN_ATTEMPTS = 3;
let refreshTokenTries = 0;
const UNKNOWN_GRPC_ERROR_REGEXP = /at line (\d+) column (\d+)/g;

window.addEventListener("storage", function (e) {
  const session = getSession(store);

  try {
    const data = JSON.parse(e.newValue as string);

    const auth = JSON.parse(data.auth);

    if (
      (session && !auth.session) ||
      (!auth.session.access_token &&
        auth.session.access_token !== session.access_token)
    ) {
      const loginType = authService.getLoginType();
      store.dispatch(logoutSuccess(loginType));
      store.dispatch(history.push("/"));
      return;
    }
  } catch (e) {
    return false;
  }
});

const storeInstance = new Store(new RecordSource(), {
  gcReleaseBufferSize: 1000,
  queryCacheExpirationTime: 5 * 60 * 1000,
});

const environment = new Environment({
  network: Network.create(fetchQuery as any, subscribe),
  store: storeInstance,
});

const { getErrorMockHeaders, setErrorMock } = createErrorMockInterceptor();

(window as any).setAPIErrorMock = setErrorMock;

const beforeUnloadEventController = createBeforeUnloadEventController();

function failedToRefreshTokenFlow(store) {
  const loginType = authService.getLoginType();
  store.dispatch(logoutSuccess(loginType));
  store.dispatch(history.push("/"));
  setTimeout(() => {
    refreshTokenTries = 0;
  }, 30000);
}

async function fetchQuery(
  operation,
  variables,
  cacheConfig,
  uploadables,
  logRequestInfo
) {
  const loginType = authService.getLoginType();

  const headers = {
    "Content-Type": "application/json",
    ...getErrorMockHeaders(operation.name),
  };

  const token = getAccessToken(store);

  if (token && loginType === "classic") {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (operation.operationKind === "mutation") {
    beforeUnloadEventController.addRequest();
  }

  try {
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    };

    if (loginType === "auth0") {
      Object.assign(options, {
        credentials: "include",
      });
    }

    const response = await fetch(
      (process.env.REACT_APP_GRAPHQL_ENDPOINT_URL as string) +
        `?${operation.name}`,
      options
    );

    if (loginType === "auth0" && response.status === 401) {
      authService.redirectToLogout();
      throw new UnauthorizedError("Unauthorized");
    }

    const json = await response.json();

    if (json?.errors?.length) {
      const error = json.errors[0];

      if (
        loginType === "classic" &&
        error?.extensions?.http_status === "Unauthorized"
      ) {
        if (refreshTokenTries >= MAX_REFRESH_TOKEN_ATTEMPTS) {
          failedToRefreshTokenFlow(store);
          return;
        }

        return refreshToken(store).then(() => {
          refreshTokenTries++;
          return fetchQuery(
            operation,
            variables,
            cacheConfig,
            uploadables,
            logRequestInfo
          );
        });
      }

      if (
        loginType === "auth0" &&
        error?.extensions?.http_status === "Unauthorized"
      ) {
        authService.redirectToLogout();
        throw new UnauthorizedError("Unauthorized");
      }

      const grpcDetails = error?.extensions?.grpc_status_details;

      if (grpcDetails?.length) {
        const error = grpcDetails[0];
        if (error?.data?.field_violations?.length) {
          const violation = error?.data?.field_violations[0];
          if (violation.description.trim().match(UNKNOWN_GRPC_ERROR_REGEXP)) {
            throw new Error("errors.general");
          }
          throw new Error(violation.description);
        }
      }

      throw new Error(error.message);
    }
    return json;
  } finally {
    if (operation.operationKind === "mutation") {
      beforeUnloadEventController.removeRequest();
    }
  }
}

const { subscriptionClient, addLogItem } = createSubscriptionClient();

function subscribe(operation, variables) {
  return Observable.create<any>(sink => {
    return subscriptionClient.subscribe(
      {
        query: operation.text,
        operationName: operation.name,
        variables,
      },
      sink
    );
  });
}

export default environment;
export { subscriptionClient, addLogItem };
