import { some } from "lodash";
import {
  TABLES_COMMANDS_THAT_TRIGGER_REFRESH,
  VIEWS_COMMANDS_THAT_TRIGGER_REFRESH,
} from "./constants";

export const SYSTEM_ENGINE_ID = "/engines/00000000-0000-0000-0000-000000000000";

export function isRefreshTablesCommand(query: string): boolean {
  return some(TABLES_COMMANDS_THAT_TRIGGER_REFRESH, command =>
    query.match(command)
  );
}

export function isRefreshViewsCommand(query: string): boolean {
  return some(VIEWS_COMMANDS_THAT_TRIGGER_REFRESH, command =>
    query.match(command)
  );
}

export function getEncodedSystemEngineId(accountId: string): string {
  return btoa(`Engine;/accounts/${accountId}${SYSTEM_ENGINE_ID}`);
}
