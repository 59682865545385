import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generalEngineRunning } from "components/EnginesList/helpers";
import { useOneEngineFlag } from "featureFlags/hooks/useOneEngineFlag";
import { SettingsPreset } from "components/EngineForm/constants";

const defaultState = {
  disabledStart: false,
  disabledStartTooltip: undefined,
};

export const useDisabledStart = (engines, engine) => {
  const { t } = useTranslation();
  const flag = useOneEngineFlag();

  const hasRunningEngine = useMemo(
    () =>
      engines?.length > 1 &&
      engines.find(item => {
        return engine?.id !== item.id && generalEngineRunning(item);
      }),
    [engines, engine]
  );

  const isGeneralEngine =
    engine?.settingsPreset === SettingsPreset.GeneralPurpose;

  if (flag) {
    return defaultState;
  }

  if (isGeneralEngine && hasRunningEngine) {
    return {
      disabledStart: hasRunningEngine,
      disabledStartTooltip: { title: t("engines.startup_disabled") },
    };
  }

  return defaultState;
};
