import React, { ReactElement } from "react";
import CustomTooltip from "components/Tooltip";
import cn from "classnames";
import { Link } from "react-router-dom";
import commonStyles from "styles/common.module.scss";
import sidebarStyles from "../MainSidebar.module.scss";
import styles from "./MenuItem.module.scss";

interface Props {
  icon?: ReactElement;
  selectedIcon?: ReactElement;
  tooltip?: string;
  onClick?: (e?: any) => void;
  selected?: boolean;
  disabled?: boolean;
  to?: string;
  testId?: string;
}

const MenuItem: React.FunctionComponent<Props> = props => {
  const { icon, tooltip, onClick, selected, disabled, to, testId } = props;

  const wrapByLink = (element, tooltip) => {
    if (!to || disabled) {
      return React.cloneElement(element, { "data-testid": testId });
    }

    return (
      <Link
        to={to}
        data-testid={testId}
        className={commonStyles.noFocus}
      >
        {tooltip && (
          <CustomTooltip
            title={tooltip}
            placement="right"
            classes={{
              popper: cn(sidebarStyles.menuItemTooltip, sidebarStyles.forced),
            }}
          >
            {element}
          </CustomTooltip>
        )}

        {!tooltip && { element }}
      </Link>
    );
  };

  return wrapByLink(
    <div
      className={cn(styles.listItem, commonStyles.noFocus, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      })}
      onClick={!disabled ? onClick : () => {}}
    >
      {icon}
    </div>,
    tooltip
  );
};

export default MenuItem;
