import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation RemoveDatabaseMutation($input: DatabaseDeleteInput!) {
    databaseDelete(input: $input) {
      success
      database {
        id
        currentStatus
      }
    }
  }
`;

function commit(environment, databaseId, accountId, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: { id: databaseId },
    },
    optimisticUpdater: store => {
      const database = store.get(databaseId);

      if (database) {
        database.setValue("TERMINATION_STARTED", "currentStatus");
      }
    },
  });
}

export default { commit };
