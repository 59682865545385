import { createAction } from "redux-actions";
import { Invitation } from "components/UserInvitationModal/UserInvitationModal.helper";

export const MembersActions = {
  REMOVE_DB_ACCESS_POLICY: "MEMBER/REMOVE_DB_ACCESS_POLICY",
  CREATE_MEMBERS: "MEMBER/CREATE_MEMBERS",
  VERIFY_INVITATION_TOKEN: "MEMBER/VERIFY_INVITATION_TOKEN",
  SUBMIT_USER: "MEMBER/SUBMIT_USER",
};

export const createMembers = createAction(
  MembersActions.CREATE_MEMBERS,
  (
    invitations: Invitation[],
    roleManagementEnabled,
    resolve,
    reject,
    type: string | null = null
  ) => ({
    roleManagementEnabled,
    invitations,
    resolve,
    reject,
    type,
  })
);

export const verifyInvitationToken = createAction(
  MembersActions.VERIFY_INVITATION_TOKEN,
  (token: string, resolve, reject) => ({
    token,
    resolve,
    reject,
  })
);
