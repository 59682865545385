import _ from "lodash";

export const getEngine = (database, engineId: string) => {
  return _.find(database.engines, engine => engine.id === engineId);
};

export const getSelectedEngine = (database, currentQuery) => {
  if (
    _.isEmpty(currentQuery) ||
    _.isEmpty(database) ||
    _.isEmpty(database.engines)
  ) {
    return null;
  }

  const { engineId } = currentQuery;
  return getEngine(database, engineId);
};

export const getCurrentQuery = queries => {
  const { queriesById, currentQueryTabId } = queries;
  const currentQuery = _.find(queriesById, {
    id: currentQueryTabId,
  });

  // @ts-ignore
  return currentQuery ? currentQuery : queriesById[_.findLastKey(queriesById)];
};
