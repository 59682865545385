import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import HelpBar from "./HelpBar/HelpBar";
import styles from "./MainSidebar.module.scss";

export const MainSidebarSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.sidebarHead}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.sidebarFooter}>
        <HelpBar isLoggedIn={false} />
      </div>
    </div>
  );
};
