import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { registerAccount } from "../../../redux/actions";
import * as _ from "lodash";
import { AccountService } from "businessLogic/services/account";
import SnackBarView from "components/SnackBar";
import CheckEmail from "components/CheckEmail";

interface CheckEmailProps {
  accountDetails?: any;
}

class CheckEmailPage extends Component<CheckEmailProps, any> {
  constructor(props: CheckEmailProps) {
    super(props);
    this.state = {
      openAlert: false,
      alertInfo: {},
      isSendingEmail: false,
    };
    this.resendEmail = this.resendEmail.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  resendEmail() {
    const {
      accountDetails: { email },
    } = this.props;
    this.setState({ isSendingEmail: true });
    AccountService.resendEmailAccount(email)
      .then(() => {
        const alertInfo = {
          open: true,
          title: "Success",
          text: "Email resent",
          icon: "success",
          isConfirm: true,
        };
        this.setState({ openAlert: true, alertInfo, isSendingEmail: false });
      })
      .catch(error => {
        const alertInfo = {
          open: true,
          title: "Error!",
          text: error.message,
          icon: "error",
          isConfirm: true,
        };
        this.setState({ openAlert: true, alertInfo, isSendingEmail: false });
      });
  }

  closeAlert() {
    this.setState({ openAlert: false });
  }

  renderAlert() {
    const { alertInfo } = this.state;
    return (
      <SnackBarView
        vertical="top"
        horizontal="center"
        openSnackBar={!!alertInfo.text}
        type={alertInfo.icon}
        closeSnackBar={this.closeAlert}
        message={alertInfo.text}
      />
    );
  }

  render() {
    const { openAlert, isSendingEmail } = this.state;
    const {
      accountDetails: { email },
    } = this.props;
    return (
      <Fragment>
        <CheckEmail
          resendEmail={this.resendEmail}
          email={email}
          isSendingEmail={isSendingEmail}
        />
        {openAlert && this.renderAlert()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    accountDetails: _.get(state, "account.accountDetails"),
  };
};
export default connect(mapStateToProps, { registerAccount })(CheckEmailPage);
