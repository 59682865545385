import React from "react";
import LoadingOverlap from "../LoadingOverlap";
import { linkAws } from "redux/subscription/actions";
import { connect } from "react-redux";
import ErrorScreen from "../ErrorScreen/ErrorScreen";
import withRouter, { WithRouterProps } from "components/common/withRouter";

interface Props extends WithRouterProps {
  token: string;
  linkAws: (token: string, resole: any, reject: any) => void;
}

interface State {
  errorMessage: string | null;
}

class AwsLinking extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount(): void {
    const { token, navigate } = this.props;

    new Promise((resolve, reject) => {
      this.props.linkAws(token, resolve, reject);
    })
      .then(() => {
        navigate("/", { replace: true });
      })
      .catch((errorMessage: string) => {
        this.setState({ errorMessage });
      });
  }

  render() {
    const { errorMessage } = this.state;

    if (errorMessage) {
      return <ErrorScreen title={errorMessage} />;
    }

    return <LoadingOverlap isLoading />;
  }
}

export default connect(undefined, { linkAws })(withRouter(AwsLinking));
