import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import EngineStatusIcon, {
  UIEngineStatus,
} from "components/EngineStatusIcon/EngineStatusIcon";
import CustomTooltip from "components/Tooltip";
import styles from "./EngineItem.module.scss";

const EngineStatus = ({
  status,
  statusText,
  tooltipProps,
}: {
  status: UIEngineStatus;
  statusText: { shortText: string; longText: string };
  tooltipProps?: any;
}) => {
  const { t } = useTranslation();
  const component = (
    <div
      className={styles.engineStatus}
      data-testid="engine-status-container"
    >
      {!!status && (
        <EngineStatusIcon
          status={status}
          hideTitle={!!statusText?.shortText || !!statusText?.longText}
        />
      )}
      {!!statusText?.shortText && (
        <div
          className={styles.engineStatusText}
          data-testid="engine-status-short-text"
        >
          {t(statusText?.shortText)}
        </div>
      )}
    </div>
  );

  if (statusText?.longText) {
    return (
      <CustomTooltip
        placement="top"
        title={t(statusText?.longText)}
        enterDelay={300}
        TransitionProps={{ timeout: { exit: 350 } }}
        {...tooltipProps}
        classes={{
          ...tooltipProps?.classes,
          tooltip: cn(styles.statusTooltip, styles.forced),
        }}
      >
        <div>{component}</div>
      </CustomTooltip>
    );
  }

  return component;
};

export default EngineStatus;
