import React, { useEffect } from "react";
import Loader from "components/EllipsisLoader";
import { fetchVersion } from "redux/maintainance/sagas";
import { authService } from "services/auth";
import AppCrash from "./AppCrash";
import NewAppVersion from "./NewAppVersion";
import * as Sentry from "@sentry/browser";
import { UnauthorizedError } from "relay/UnauthorizedError";

interface IState {
  hasError: boolean;
  chunkError: boolean;
  isNewVersion: boolean;
  unauthorizedError: boolean;
}

const RedirectLogin = () => {
  useEffect(() => {
    authService.redirectToLogout();
  }, []);
  return null;
};

interface Props {
  children?: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, IState> {
  captureError(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras({
        ...errorInfo,
      });
      Sentry.captureException(error);
    });
    this.setState(() => ({
      hasError: true,
      chunkError: false,
      isNewVersion: false,
    }));
  }

  public static getDerivedStateFromError(error) {
    return {
      hasError: true,
      chunkError: error.name === `ChunkLoadError`,
      unauthorizedError: error instanceof UnauthorizedError,
      isNewVersion: false,
    };
  }

  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
      chunkError: false,
      unauthorizedError: false,
      isNewVersion: false,
    };
  }

  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (this.state.unauthorizedError) {
      return;
    }
    if (this.state.chunkError) {
      const latestVersion = await fetchVersion();
      const currentMetaVersion = (
        document.getElementById("app-version") as HTMLMetaElement
      ).content;

      if (latestVersion !== currentMetaVersion) {
        this.setState(() => ({
          hasError: false,
          chunkError: false,
          isNewVersion: true,
        }));
      } else {
        this.captureError(error, errorInfo);
      }
    } else {
      this.captureError(error, errorInfo);
    }
  }

  public render() {
    if (this.state.unauthorizedError) {
      return <RedirectLogin />;
    }
    if (this.state.chunkError) {
      return <Loader />;
    }

    if (this.state.isNewVersion) {
      return <NewAppVersion />;
    }

    if (this.state.hasError) {
      return <AppCrash />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
