import * as React from "react";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export interface WithRecaptchaOuterProps {
  getToken: (onUpdated: (token: string) => void) => void;
}
// <
//   P extends WithRecaptchaOuterProps = WithRecaptchaOuterProps
// >
const withRecaptcha = <
  P extends WithRecaptchaOuterProps = WithRecaptchaOuterProps
>(
  WrappedComponent: React.ComponentType<P>,
  action: string
) => {
  class ComponentWithRecaptcha extends React.Component<
    Omit<P, keyof WithRecaptchaOuterProps>,
    WithRecaptchaOuterProps
  > {
    private recaptcha;

    private onUpdated: (token: string) => void | null;

    constructor(props) {
      super(props);

      this.recaptcha = React.createRef();
    }

    componentDidMount(): void {
      loadReCaptcha(SITE_KEY);
      const grecaptcha = document.getElementsByClassName("grecaptcha-badge");
      // @ts-ignore
      for (const item of grecaptcha) {
        item.style.visibility = "visible";
      }
    }

    componentWillUnmount(): void {
      /**
       * This is a hack because the api doesn't support unload recaptcha
       */
      const grecaptcha = document.getElementsByClassName("grecaptcha-badge");
      // @ts-ignore
      for (const item of grecaptcha) {
        item.style.visibility = "hidden";
      }
    }

    verifyCallback = recaptchaToken => {
      if (this.onUpdated) {
        this.onUpdated(recaptchaToken);
      }
    };

    updateToken = () => {
      // you will get a new token in verifyCallback
      this.recaptcha.execute();
    };

    getToken = (onUpdated: (token: string) => void) => {
      this.onUpdated = onUpdated;
      this.updateToken();
    };

    render() {
      const wrapperProps = { getToken: this.getToken };

      return (
        <>
          <WrappedComponent {...wrapperProps} {...(this.props as P)} />
          <ReCaptcha
            size="normal"
            ref={ref => (this.recaptcha = ref)}
            sitekey={SITE_KEY}
            action={action}
            verifyCallback={this.verifyCallback}
            onResolved={() => null}
          />
        </>
      );
    }
  }

  return ComponentWithRecaptcha;
};

export default withRecaptcha;
