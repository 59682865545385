import { createAction } from "redux-actions";

export const AccountQueryFlagsAction = {
  SET_QUERY_FLAGS: "ACCOUNT/SET_QUERY_FLAGS",
};

export const setAccountQueryFlags = createAction(
  AccountQueryFlagsAction.SET_QUERY_FLAGS,
  accountFlags => ({ accountFlags })
);
