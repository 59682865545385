/**
 * @generated SignedSource<<651b5f4caf8bc3e1d0498de4d67772f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserSetUIStateInput = {
  id: string;
  data: string;
};
export type UserUiStateMutation$variables = {
  input: UserSetUIStateInput;
};
export type UserUiStateMutation$data = {
  readonly userSetUiState: {
    readonly success: boolean;
  };
};
export type UserUiStateMutation = {
  variables: UserUiStateMutation$variables;
  response: UserUiStateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserSetUIStatePayload",
    "kind": "LinkedField",
    "name": "userSetUiState",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserUiStateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserUiStateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "da61af779c1b2d0b744805e8e1a23cc1",
    "id": null,
    "metadata": {},
    "name": "UserUiStateMutation",
    "operationKind": "mutation",
    "text": "mutation UserUiStateMutation(\n  $input: UserSetUIStateInput!\n) {\n  userSetUiState(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "84eae6f41e13fc542c67a2155056ba69";

export default node;
