import React, { useCallback } from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import CustomSelect from "components/Select/Select";
import SelectMenuItem from "components/Select/SelectMenuItem/SelectMenuItem";
import { ContactFormField, TEST_IDS } from "../constants";
import { Translations } from "../translations";

const Severity = props => {
  const { fields, disabledFields, handleChange, t, members, autoFocus } = props;

  const handleSelectChange = useCallback(
    event => {
      handleChange(ContactFormField.Company, event.target.value);
    },
    [handleChange]
  );

  const items =
    members && members.length
      ? members.map(item => (
          <SelectMenuItem
            key={`${item.accountTitle}`}
            value={`${item.accountTitle}`}
            testId={`${TEST_IDS.companyInput}${item.accountTitle}`}
          >
            {t(item.accountTitle)}
          </SelectMenuItem>
        ))
      : [];

  return (
    <FormControl fullWidth>
      <InputLabel>{t(Translations[ContactFormField.Company])}</InputLabel>
      <CustomSelect
        testId={TEST_IDS.companyInput}
        fullWidth
        value={fields[ContactFormField.Company]}
        disabled={disabledFields[ContactFormField.Company] || !items.length}
        onChange={handleSelectChange}
        autoFocus={autoFocus}
      >
        {items}
      </CustomSelect>
    </FormControl>
  );
};

export default Severity;
