/**
 * @generated SignedSource<<43d93c980fd828003e535e88121a35bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScriptDeleteInput = {
  id: string;
};
export type ScriptDeleteMutation$variables = {
  input: ScriptDeleteInput;
};
export type ScriptDeleteMutation$data = {
  readonly scriptDelete: {
    readonly success: boolean;
  };
};
export type ScriptDeleteMutation = {
  variables: ScriptDeleteMutation$variables;
  response: ScriptDeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScriptDeletePayload",
    "kind": "LinkedField",
    "name": "scriptDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScriptDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScriptDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "267877dfb5c0ef58b939eb6aeb1b08dd",
    "id": null,
    "metadata": {},
    "name": "ScriptDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ScriptDeleteMutation(\n  $input: ScriptDeleteInput!\n) {\n  scriptDelete(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "0280d7a24ed966349dd519f18c700f0b";

export default node;
