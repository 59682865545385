interface ErrorMockConfig {
  statusCode: number;
  body: string;
  mockedCallNumber: number;
}

export const STATUS_CODE_MOCK_HEADER = "X-Firebolt-Response-Mock-Status-Code";
export const BODY_MOCK_HEADER = "X-Firebolt-Response-Mock-Body";

const getHeadersWithErrorMock = (statusCode: number, body: string) => {
  return {
    [STATUS_CODE_MOCK_HEADER]: `${statusCode}`,
    [BODY_MOCK_HEADER]: body
  };
};

const createErrorMockInterceptor = () => {
  const mockedOperationsConfigs = new Map<string, ErrorMockConfig>();
  let globalMockConfig: ErrorMockConfig | null = null;

  const getGlobalMock = (): null | ErrorMockConfig => {
    if (!globalMockConfig) {
      return null;
    }

    const mock = globalMockConfig;

    globalMockConfig.mockedCallNumber -= 1;
    if (globalMockConfig.mockedCallNumber < 1) {
      globalMockConfig = null;
    }

    return mock;
  };

  const getMock = (operationName: string): null | ErrorMockConfig => {
    const globalMockConfig = getGlobalMock();
    if (globalMockConfig) {
      return globalMockConfig;
    }

    const foundMockedOperationConfig =
      mockedOperationsConfigs.get(operationName);

    if (!foundMockedOperationConfig) {
      return null;
    }

    foundMockedOperationConfig.mockedCallNumber -= 1;

    if (foundMockedOperationConfig.mockedCallNumber < 1) {
      mockedOperationsConfigs.delete(operationName);
    }

    return foundMockedOperationConfig;
  };

  const setErrorMock = (
    statusCode: number,
    body: string,
    operationName: string | null = null,
    mockedCallNumber: number = Number.MAX_SAFE_INTEGER
  ) => {
    if (!operationName) {
      globalMockConfig = {
        statusCode,
        body,
        mockedCallNumber
      };
      return;
    }

    mockedOperationsConfigs.set(operationName, {
      statusCode,
      body,
      mockedCallNumber
    });
  };

  const getErrorMockHeaders = (operationName: string): {} => {
    const mockConfig = getMock(operationName);

    if (!mockConfig) {
      return {};
    }

    return getHeadersWithErrorMock(mockConfig.statusCode, mockConfig.body);
  };

  return { getErrorMockHeaders, setErrorMock };
};

export { createErrorMockInterceptor };
