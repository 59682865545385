import { Action, handleActions } from "redux-actions";
import { ProxyActions } from "../actions/proxy";

export interface ProxyState {
  proxyVersionsById: any;
  proxyPreserveExecutionById: any;
}

export const INITIAL_STATE: ProxyState = {
  proxyVersionsById: {},
  proxyPreserveExecutionById: {},
};

const reducerMap = {};

reducerMap[ProxyActions.SET_VERSION_SUCCESS] = (
  state: ProxyState,
  action: Action<{ engineId; version }>
): ProxyState => {
  const { engineId, version } = action.payload;
  return {
    ...state,
    proxyVersionsById: {
      ...state.proxyVersionsById,
      [engineId]: { version, isLoading: false },
    },
  };
};

reducerMap[ProxyActions.SET_VERSION_LOADING] = (
  state: ProxyState,
  action: Action<{ engineId }>
): ProxyState => {
  const { engineId } = action.payload;
  return {
    ...state,
    proxyVersionsById: {
      ...state.proxyVersionsById,
      [engineId]: { isLoading: true },
    },
  };
};

reducerMap[ProxyActions.SET_PRESERVE_QUERY_EXECUTION_SUCCESS] = (
  state: ProxyState,
  action: Action<{ engineId }>
): ProxyState => {
  const { engineId } = action.payload;
  return {
    ...state,
    proxyPreserveExecutionById: {
      ...state.proxyPreserveExecutionById,
      [engineId]: {
        ...state.proxyPreserveExecutionById?.[engineId],
        preserveExecution: true,
        loading: false,
      },
    },
  };
};

reducerMap[ProxyActions.SET_PRESERVE_QUERY_EXECUTION_LOADING] = (
  state: ProxyState,
  action: Action<{ engineId }>
): ProxyState => {
  const { engineId } = action.payload;
  return {
    ...state,
    proxyPreserveExecutionById: {
      ...state.proxyPreserveExecutionById,
      [engineId]: {
        ...state.proxyPreserveExecutionById?.[engineId],
        loading: true,
      },
    },
  };
};

export default handleActions<ProxyState, any>(reducerMap, INITIAL_STATE);
