import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { stateSelectorHandle } from "../redux/selectors";
import EngineQueryRenderer from "./EngineQueryRenderer";
import DbQueryRenderer from "./DbQueryRenderer";

interface Props {
  entityId: string;
  accountId: string;
}

const DialogWithFetch = (props: Props) => {
  const { entityId, accountId } = props;
  const stateSelector = useCallback(stateSelectorHandle, []);
  const { confirmationModal } = useSelector(stateSelector);
  const { actionType } = confirmationModal;
  if (actionType.includes("ENGINE_ACTIONS")) {
    return <EngineQueryRenderer entityId={entityId} accountId={accountId} />;
  }

  return <DbQueryRenderer entityId={entityId} accountId={accountId} />;
};

export default DialogWithFetch;
