export const STEP_REGISTER = "Register";
export const STEP_CHECK_EMAIL = "Check email";
export const PRIVACY_POLICY_URL =
  "https://www.firebolt.io/firebolt-privacy-policy";

export const NOT_PARSED_PROPERLY_DOMAIN_ZONES = [
  "bd",
  "ck",
  "er",
  "fk",
  "jm",
  "kh",
  "mm",
  "np",
  "pg",
];

export enum CreateAccountFieldName {
  firstName = "firstName",
  lastName = "lastName",
  company = "company",
  email = "email",
  jobTitle = "jobTitle",
  country = "country",
  phoneNumber = "phoneNumber",
  accountName = "accountName",
  password = "password",
}
