import React, { Ref } from "react";
import cn from "classnames";
import * as _ from "lodash";
import styles from "./EmojiIcon.module.scss";

export enum Emoji {
  AERIAL_TRAMWAY = "AERIAL_TRAMWAY",
  ALIEN = "ALIEN",
  ALIEN_MONSTER = "ALIEN_MONSTER",
  AUTOMOBILE = "AUTOMOBILE",
  BICYCLE = "BICYCLE",
  BRIEFCASE = "BRIEFCASE",
  BULLET_TRAIN = "BULLET_TRAIN",
  CANOE = "CANOE",
  CREDIT_CARD = "CREDIT_CARD",
  CROWN = "CROWN",
  DELIVERY_TRUCK = "DELIVERY_TRUCK",
  FACE_SCREAMING_IN_FEAR = "FACE_SCREAMING_IN_FEAR",
  FIRE = "FIRE",
  GEM_STONE = "GEM_STONE",
  GRADUATION_CAP = "GRADUATION_CAP",
  HELICOPTER = "HELICOPTER",
  JACK_O_LANTERN = "JACK_O_LANTERN",
  KICK_SCOOTER = "KICK_SCOOTER",
  LOCOMOTIVE = "LOCOMOTIVE",
  MINIBUS = "MINIBUS",
  MOBILE_PHONE = "MOBILE_PHONE",
  MONEY_BAG = "MONEY_BAG",
  MOTOR_SCOOTER = "MOTOR_SCOOTER",
  MOVIE_CAMERA = "MOVIE_CAMERA",
  ONCOMING_AUTOMOBILE = "ONCOMING_AUTOMOBILE",
  PASSENGER_SHIP = "PASSENGER_SHIP",
  POLICE_CAR = "POLICE_CAR",
  RACING_CAR = "RACING_CAR",
  ROBOT = "ROBOT",
  ROCKET = "ROCKET",
  SAILBOAT = "SAILBOAT",
  SKULL = "SKULL",
  SMALL_AIRPLANE = "SMALL_AIRPLANE",
  SMILING_FACE_WITH_HORNS = "SMILING_FACE_WITH_HORNS",
  SPEEDBOAT = "SPEEDBOAT",
  TRACTOR = "TRACTOR",
}

export const emojiCodes = [
  { code: "1F6F4", char: "🛴", emoji: Emoji.KICK_SCOOTER },
  { code: "1F685", char: "🚅", emoji: Emoji.BULLET_TRAIN },
  { code: "1F680", char: "🚀", emoji: Emoji.ROCKET },
  { code: "1F69C", char: "🚜", emoji: Emoji.TRACTOR },
  { code: "1F697", char: "🚗", emoji: Emoji.AUTOMOBILE },
  { code: "1F6A4", char: "🚤", emoji: Emoji.SPEEDBOAT },
  { code: "26F5", char: "⛵", emoji: Emoji.SAILBOAT },
  { code: "1F6F6", char: "🛶", emoji: Emoji.CANOE },
  { code: "1F682", char: "🚂", emoji: Emoji.LOCOMOTIVE },
  { code: "1F6B2", char: "🚲", emoji: Emoji.BICYCLE },
  { code: "1F69A", char: "🚚", emoji: Emoji.DELIVERY_TRUCK },
  { code: "1F6F3 FE0F", char: "🛳️", emoji: Emoji.PASSENGER_SHIP },
  { code: "1F3CE FE0F", char: "🏎️", emoji: Emoji.RACING_CAR },
  { code: "1F681", char: "🚁", emoji: Emoji.HELICOPTER },
  { code: "1F690", char: "🚐", emoji: Emoji.MINIBUS },
  { code: "1F693", char: "🚓", emoji: Emoji.POLICE_CAR },
  { code: "1F6A1", char: "🚡", emoji: Emoji.AERIAL_TRAMWAY },
  { code: "1F6F5", char: "🛵", emoji: Emoji.MOTOR_SCOOTER },
  { code: "1F698", char: "🚘", emoji: Emoji.ONCOMING_AUTOMOBILE },
  { code: "1F6E9", char: "🛩", emoji: Emoji.SMALL_AIRPLANE },
  { code: "1F48E", char: "💎", emoji: Emoji.GEM_STONE },
  { code: "1F4B3", char: "💳", emoji: Emoji.CREDIT_CARD },
  { code: "1F4B0", char: "💰", emoji: Emoji.MONEY_BAG },
  { code: "1F4F1", char: "📱", emoji: Emoji.MOBILE_PHONE },
  { code: "1F4BC", char: "💼", emoji: Emoji.BRIEFCASE },
  { code: "1F451", char: "👑", emoji: Emoji.CROWN },
  { code: "1F393", char: "🎓", emoji: Emoji.GRADUATION_CAP },
  { code: "1F480", char: "💀", emoji: Emoji.SKULL },
  { code: "1F383", char: "🎃", emoji: Emoji.JACK_O_LANTERN },
  { code: "1F608", char: "😈", emoji: Emoji.SMILING_FACE_WITH_HORNS },
  { code: "1F631", char: "😱", emoji: Emoji.FACE_SCREAMING_IN_FEAR },
  { code: "1F525", char: "🔥", emoji: Emoji.FIRE },
  { code: "1F47D", char: "👽", emoji: Emoji.ALIEN },
  { code: "1F47E", char: "👾", emoji: Emoji.ALIEN_MONSTER },
  { code: "1F916", char: "🤖", emoji: Emoji.ROBOT },
  { code: "1F3A5", char: "🎥", emoji: Emoji.MOVIE_CAMERA },
];

interface EmojiProps {
  emoji: Emoji;
  className?: string;
  clickAction?: () => void;
}
const EmojiIcon = React.forwardRef(
  (props: EmojiProps, ref: Ref<HTMLDivElement>) => {
    const { emoji, className, clickAction = () => null } = props;
    const foundEmoji = emojiCodes.find(e => e.emoji === emoji);

    const emojiClassNames = cn(styles.emoji, className);
    return (
      <div
        className={emojiClassNames}
        {..._.omit(props, "clickAction")}
        ref={ref}
        onClick={clickAction}
      >
        {(foundEmoji && foundEmoji?.char) || emoji}
      </div>
    );
  }
);
export default EmojiIcon;
