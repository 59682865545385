import { find } from "lodash";
import { isDataQuery, isSetQuery } from "businessLogic/helpers/Query";
import { QueryStatus } from "../constants/queries";

export const isQueryStatementRunning = status => status === QueryStatus.Running;

const PARAMETER_SEPARATOR = "=";
const END_TOKEN = ";";

export const parseQuerySetting = (
  sql: string
): { key: string; value: string } => {
  const queryParts = sql.trim().substr(4).split(PARAMETER_SEPARATOR);

  let param = queryParts[1].trim();

  const endTokenPos = param.indexOf(END_TOKEN);
  if (endTokenPos !== -1 && endTokenPos !== param.length - 1) {
    param = param.substr(0, param.length - 1);
  } else {
    param = param.replace(END_TOKEN, "");
  }

  return {
    key: queryParts[0].trim().toLowerCase(),
    value: param.replace(/[",',`]/g, "").trim(),
  };
};

export const hasRunningQueries = query => {
  if (query.isQueryRunning) return true;

  if (query.multiQueryStatement) {
    const findRunningQuery = find(query.multiQueryStatement, {
      status: QueryStatus.Running,
    });
    return !!findRunningQuery;
  }

  return false;
};

export const getRunningQueryStatement = (
  query,
  statusToCheck = QueryStatus.Running
) => {
  return find(
    query.multiQueryStatement,
    ({ status }) => status === statusToCheck
  );
};

export const hasFailedQueries = query => {
  if (query.multiQueryStatement) {
    return find(query.multiQueryStatement, { status: QueryStatus.Failed });
  }
  return false;
};

export const hasRunningInsertQueries = query => {
  if (!query.multiQueryStatement) return false;

  const runningInsertQuery = find(
    query.multiQueryStatement,
    q =>
      q.status === QueryStatus.Running &&
      !isDataQuery(q.query) &&
      !isSetQuery(q.query)
  );
  return !!runningInsertQuery;
};

export const queriesByIdToSortedArray = queriesById => {
  return Object.values(queriesById).sort((a: any, b: any) => {
    return a.order - b.order;
  });
};
