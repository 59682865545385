import { createClient, Client, Event } from "graphql-ws";
import { authService } from "services/auth";

const EVENTS = ["connecting", "connected", "closed", "error"];

let addLogRecord;
if (process.env.REACT_APP_LOG_WS === "true") {
  const logger = require("services/localLoger");
  addLogRecord = logger.addLogRecord;
}

const createLogRecord = (eventName: string, payload: string) => {
  if (addLogRecord) {
    addLogRecord(
      eventName,
      JSON.stringify({
        payload: payload,
      })
    );
  }
};

function createSubscriptionClient(): {
  subscriptionClient: Client;
  addLogItem: (eventName: string, payload: string) => void;
} {
  const subscriptionClient = createClient({
    url: (process.env.REACT_APP_GRAPHQL_ENDPOINT_URL as string).replace(
      new RegExp("http|https"),
      "ws"
    ),
    lazy: true,
    on: {
      error: error => {
        console.log(error);
      },
    },
    connectionParams: async () => {
      const token = await authService.getToken();

      return {
        authorization: `Bearer ${token}`,
      };
    },
  });

  EVENTS.forEach(eventName => {
    subscriptionClient.on(eventName as Event, payload => {
      createLogRecord(eventName, payload);
    });
  });

  const addLogItem = (eventName: string, payload: string) => {
    createLogRecord(eventName, payload);
  };

  return { subscriptionClient, addLogItem };
}

export { createSubscriptionClient };
