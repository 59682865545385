import _ from "lodash";
import LoadingOverlap from "components/LoadingOverlap";
import { connect } from "react-redux";
import styles from "./AccountSelector.module.scss";
import { ReactComponent as CheckIcon } from "assets/icons/CheckIcon.svg";
import Spinner from "components/common/Spinner";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  accounts: Array<{ id: string; title: string; isLoading: boolean }>;
  onSelect: (accountId: string) => void;
  selectedAccountId: string | null;
}

const AccountSelector: React.FC<Props> = (props: Props) => {
  const { accounts, onSelect, selectedAccountId } = props;
  const { t } = useTranslation();

  if (!accounts) {
    return <LoadingOverlap isLoading />;
  }

  const sorted = accounts.sort((a, b) =>
    a.title?.toLocaleLowerCase() > b.title?.toLocaleLowerCase() ? 1 : -1
  );

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>{t("login.choose_account")}</h1>

      <div className={styles.list}>
        {sorted.map(account => {
          const name = account.title;
          return (
            <div
              className={classNames(styles.item, {
                [styles.selected]: account.id === selectedAccountId,
              })}
              key={account.id}
              onClick={() => onSelect(account.id)}
            >
              <div className={styles.name}>{name}</div>

              <div className={styles.icon}>
                {account.isLoading ? (
                  <Spinner size={20} />
                ) : (
                  selectedAccountId === account.id && <CheckIcon />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { AccountSelector };

export default connect(state => {
  return {
    selectedAccountId: _.get(state, "user.selectedAccountId"),
  };
})(AccountSelector);
