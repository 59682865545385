import React, { useState } from "react";
import cn from "classnames";
import { canEditDatabase } from "pages/Database/helpers";
import MaterialButton, {
  ButtonSize,
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import { useTranslation } from "react-i18next";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";
import styles from "../index.module.scss";

type Props = {
  accountAllowedActions: string[];
  engine: any;
  hovered?: boolean;
  onClick: (() => void) | undefined;
};

function MakeDefaultEngineButton({
  engine,
  onClick,
  accountAllowedActions,
  hovered,
}: Props) {
  const { t } = useTranslation();
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    engine.computeRegion
  );
  const [isMakeDefaultDisabled, setMakeDefaultDisabled] = useState(false);
  const render = !engine.isDefault && canEditDatabase(accountAllowedActions);

  if (!render) return null;

  return (
    <div
      className={cn(styles.makeDefaultBtn, styles.actionBtn, {
        [styles.visible]: hovered,
      })}
    >
      <MaterialButton
        template={ButtonTemplate.Secondary}
        size={ButtonSize.Small}
        text={t("engines.make_default")}
        tooltip={readOnlyMode ? readOnlyTooltip : undefined}
        disabled={readOnlyMode || isMakeDefaultDisabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          Promise.resolve(true)
            .then(() => setMakeDefaultDisabled(true))
            .then(() => {
              return onClick?.();
            })
            .finally(() => setMakeDefaultDisabled(false));
        }}
        dataTestid="make-default-engine"
      />
    </div>
  );
}

export default MakeDefaultEngineButton;
