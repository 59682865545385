import { Action, handleActions } from "redux-actions";
import { TableDetailsAction } from "../actions/tableDetails";

export interface TableDetails {
  columns: TableColumn[];
  indexes: TableIndex[];
}

export interface TableColumn {
  name: string;
  type: string;
}

export interface TableIndex {
  name: string;
}

export interface TableDetailsState {
  isLoadingTableDetails: any;
  detailsByTable: any;
  error: string | null;
}

export const INITIAL_STATE: TableDetailsState = {
  isLoadingTableDetails: {},
  detailsByTable: {},
  error: null
};

const reducerMap = {};

reducerMap[TableDetailsAction.TABLE_DETAILS_LOAD] = (
  state: TableDetailsState,
  action: Action<{ database: string; table: { name: string } }>
): TableDetailsState => {
  const { database, table } = action.payload;
  const hasDetails = !!state.detailsByTable?.[database]?.[table.name];

  return {
    ...state,
    isLoadingTableDetails: {
      ...state.isLoadingTableDetails,
      [database]: {
        ...state.isLoadingTableDetails?.[database],
        // set loading state if no details in cache (background fetch)
        [table.name]: hasDetails ? false : true
      }
    },
    error: null
  };
};

reducerMap[TableDetailsAction.TABLE_DETAILS_LOAD_FAILED] = (
  state: TableDetailsState,
  action: Action<{ database: string; table: string; error: string }>
): TableDetailsState => {
  const { database, table, error } = action.payload;
  return {
    ...state,
    isLoadingTableDetails: {
      ...state.isLoadingTableDetails,
      [database]: {
        ...state.isLoadingTableDetails?.[database],
        [table]: false
      }
    },
    error: error
  };
};

reducerMap[TableDetailsAction.TABLE_DETAILS_LOAD_SUCCESS] = (
  state: TableDetailsState,
  action: Action<{
    database: string;
    table: string;
    tableDetails: TableDetails;
  }>
): TableDetailsState => {
  const { database, table, tableDetails } = action.payload;
  return {
    ...state,
    isLoadingTableDetails: {
      ...state.isLoadingTableDetails,
      [database]: {
        ...state.isLoadingTableDetails?.[database],
        [table]: false
      }
    },
    detailsByTable: {
      ...state.detailsByTable,
      [database]: {
        ...state.detailsByTable?.[database],
        [table]: tableDetails
      }
    },
    error: null
  };
};

reducerMap[TableDetailsAction.EMPTY_TABLE_DETAILS] = (
  state: TableDetailsState,
  action: Action<{
    database: string;
    table: string;
  }>
): TableDetailsState => {
  const { database, table } = action.payload;
  return {
    ...state,
    isLoadingTableDetails: {
      ...state.isLoadingTableDetails,
      [database]: {
        ...state.isLoadingTableDetails?.[database],
        [table]: false
      }
    },
    detailsByTable: {
      ...state.detailsByTable,
      [database]: {
        ...state.detailsByTable?.[database],
        [table]: null
      }
    },
    error: null
  };
};

export default handleActions<TableDetailsState, any>(reducerMap, INITIAL_STATE);
