import { handleActions } from "redux-actions";
import { DialogActions } from "./actions";
import { DialogAction } from "../interfaces";

export interface DialogState extends DialogAction {}

export const INITIAL_STATE: DialogState = {
  actionType: null,
  entity: null,
  entityId: null
};

const reducerMap = {};

reducerMap[DialogActions.OPEN_DIALOG] = (
  state: DialogState,
  action: any
): DialogState => {
  const {
    actionType,
    entity,
    entityId,
    actionDescription,
    skipAction,
    onConfirmComplete,
    onConfirmFail,
    onCancelComplete,
    beforeSubmit,
    engines
  } = action.payload;
  return {
    ...state,
    actionType,
    entity,
    entityId,
    actionDescription,
    skipAction,
    onConfirmComplete,
    onConfirmFail,
    onCancelComplete,
    beforeSubmit,
    engines
  };
};

reducerMap[DialogActions.CLOSE_DIALOG] = (): DialogState => {
  return INITIAL_STATE;
};

export default handleActions<DialogState, any>(reducerMap, INITIAL_STATE);
