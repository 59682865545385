/**
 * @generated SignedSource<<46c6f11d76a4df958a49ff10552c124e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthVerifyTokenInput = {
  token: string;
};
export type VerifyTokenMutation$variables = {
  input: AuthVerifyTokenInput;
};
export type VerifyTokenMutation$data = {
  readonly authVerifyToken: {
    readonly success: boolean;
    readonly attachedEmail: string | null;
    readonly token: {
      readonly expiresAt: any;
      readonly used: boolean;
    } | null;
  };
};
export type VerifyTokenMutation = {
  variables: VerifyTokenMutation$variables;
  response: VerifyTokenMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthVerifyTokenPayload",
    "kind": "LinkedField",
    "name": "authVerifyToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "attachedEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Token",
        "kind": "LinkedField",
        "name": "token",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "used",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "786fa7d16d8f5242cc58a483aa38ac66",
    "id": null,
    "metadata": {},
    "name": "VerifyTokenMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyTokenMutation(\n  $input: AuthVerifyTokenInput!\n) {\n  authVerifyToken(input: $input) {\n    success\n    attachedEmail\n    token {\n      expiresAt\n      used\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11fed011eac8ed9f8ab7a9dd1145ff2d";

export default node;
