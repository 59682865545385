import React from "react";
import { ReactComponent as CompassBase } from "assets/icons/CompassBase.svg";
import { ReactComponent as CompassArrow } from "assets/icons/CompassArrow.svg";
import styles from "./styles.module.scss";

const Compass = () => {
  return (
    <div className={styles.compass}>
      <CompassBase className={styles.base} />
      <CompassArrow className={styles.arrow} />
    </div>
  );
};

export default Compass;
