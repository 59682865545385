import React, { useState } from "react";
import { QueryRenderer } from "react-relay";
import environment from "relay/environment";
import { AccountsQuery } from "relay/queries/account/Account";
import ErrorScreen, {
  ErrorScreenImagePosition,
} from "components/ErrorScreen/ErrorScreen";
import { ReactComponent as NoAccess } from "assets/images/errorScreen/noAccess.svg";
import withUserLoading from "components/common/withUserLoading";
import { Cell, Table } from "components/Table/v2";
import LoadingOverlap from "components/LoadingOverlap";
import classNames from "classnames";
import styles from "pages/Users/UserList/UserList.module.scss";
import commonStyles from "styles/common.module.scss";
import MaterialButton from "components/MaterialButton/MaterialButton";
import AccountsFilters from "pages/Accounts/AccountsFilters/AccountsFilters";
import { AccountsQuery as AccountsQueryType } from "relay/queries/account/__generated__/AccountsQuery.graphql";
import { AccountStatus } from "businessLogic/model";
import DialogComponent from "components/DialogComponent";
import { connect } from "react-redux";
import {
  putStatusMessage,
  StatusMessageOptions,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import ApproveAccountMutation from "relay/mutations/account/ApproveAccountMutation";

export const ACCOUNT_STATUS_NAME = {
  [AccountStatus.Deleted]: "Deleted",
  [AccountStatus.ApprovalPending]: "Approval Pending",
  [AccountStatus.ProvisioningFailed]: "Provisioning Failed",
  [AccountStatus.Active]: "Active",
  [AccountStatus.Blocked]: "Blocked",
  [AccountStatus.PendingProvisioning]: "Pending Provisioning",
};

export interface AccountsFiltersValues {
  name: string;
  domain: string;
  statuses: AccountStatus[];
}

export const DEFAULT_FILTERS: AccountsFiltersValues = {
  name: "",
  domain: "",
  statuses: [],
};

interface Props {
  putStatusMessage: (
    message: string,
    type: StatusMessageType,
    options?: StatusMessageOptions
  ) => void;
}

const Accounts = ({ putStatusMessage }: Props) => {
  const [filters, setFilters] =
    useState<AccountsFiltersValues>(DEFAULT_FILTERS);
  const [accountIdToActivate, setAccountIdToActivate] = useState<string | null>(
    null
  );
  const [activationIsLoading, setActivationIsLoading] =
    useState<boolean>(false);

  return (
    <QueryRenderer<AccountsQueryType>
      environment={environment}
      query={AccountsQuery}
      render={({ error, props, retry }) => {
        if (error) {
          return (
            <ErrorScreen
              title="Access Denied"
              description="It seems you don’t currently have the access to this page.
        Contact your administrator for advice."
              image={<NoAccess />}
              imagePosition={ErrorScreenImagePosition.Top}
            />
          );
        }

        if (!props) {
          return <LoadingOverlap isLoading={true} />;
        }

        const accounts =
          props.accounts.edges?.map(node => {
            const account = node?.node;
            return account;
          }) || [];

        const filteredAccounts = accounts.filter(account => {
          if (!account) {
            return false;
          }

          if (filters.name) {
            if (
              !account.title.toLowerCase().includes(filters.name.toLowerCase())
            ) {
              return false;
            }
          }

          if (filters.domain) {
            if (!account.businessDomainNames.length) {
              return false;
            }

            for (let domain of account.businessDomainNames) {
              if (
                !domain.toLowerCase().includes(filters.domain.toLowerCase())
              ) {
                return false;
              }
            }
          }

          if (filters.statuses.length) {
            if (!filters.statuses.includes(account.status as AccountStatus)) {
              return false;
            }
          }

          return true;
        });

        return (
          <div className={commonStyles.listContainer}>
            <div className={commonStyles.titleSection}>
              <h1 className={styles.title}>Accounts</h1>
            </div>

            <div>
              <AccountsFilters
                filters={filters}
                onFiltersChanged={setFilters}
              />
            </div>

            {filteredAccounts.length ? (
              <Table
                rowRenderer={({ row }) => {
                  const account: any = row;

                  return (
                    <div
                      className={classNames(styles.row)}
                      key={account.id}
                    >
                      <Cell
                        fillAvailableWidth
                        className={classNames(styles.cell)}
                        id="title"
                      >
                        {account.title}
                      </Cell>
                      <Cell
                        className={classNames(styles.cell)}
                        id="domain"
                      >
                        {account.businessDomainNames.join(", ")}
                      </Cell>
                      <Cell
                        className={classNames(styles.cell)}
                        id="status"
                      >
                        {ACCOUNT_STATUS_NAME[account.status]}
                      </Cell>
                      <Cell
                        className={classNames(styles.cell)}
                        id="action"
                      >
                        {account.status === AccountStatus.ApprovalPending && (
                          <MaterialButton
                            onClick={() => {
                              setAccountIdToActivate(account.id);
                            }}
                            text="Approve"
                          />
                        )}
                      </Cell>
                    </div>
                  );
                }}
                rows={filteredAccounts}
                columns={[
                  {
                    id: "title",
                    name: "Name",
                    width: 0,
                    fillAvailableWidth: true,
                  },
                  {
                    id: "domain",
                    name: "Domain",
                    width: 300,
                  },
                  {
                    id: "status",
                    name: "Status",
                    width: 300,
                  },
                  {
                    id: "action",
                    name: "Action",
                    width: 130,
                  },
                ]}
                disableResize
              />
            ) : (
              <div className={styles.noResultsMessageContainer}>
                <div>
                  {(filters.domain ||
                    filters.name ||
                    filters.statuses.length) && (
                    <div className={styles.text}>No results were found</div>
                  )}
                </div>
              </div>
            )}
            {accountIdToActivate && (
              <DialogComponent
                openModal={true}
                contentMaxWidth="630px"
                confirmationText="Approve"
                confirmationButtonProps={{ disabled: activationIsLoading }}
                cancelText={!activationIsLoading ? "Cancel" : undefined}
                confirmIsLoading={activationIsLoading}
                onConfirm={() => {
                  setActivationIsLoading(true);
                  ApproveAccountMutation.commit(
                    environment,
                    { id: accountIdToActivate },
                    () => {
                      putStatusMessage(
                        "Account has been approved",
                        StatusMessageType.Success
                      );
                      setActivationIsLoading(false);
                      setAccountIdToActivate(null);
                      retry?.();
                    },
                    () => {
                      putStatusMessage(
                        "Failed to approve the account",
                        StatusMessageType.Error
                      );
                      setActivationIsLoading(false);
                    }
                  );
                }}
                closeModal={() => {
                  if (activationIsLoading) {
                    return null;
                  }

                  setAccountIdToActivate(null);
                }}
                title="Account activation"
                type="info"
              >
                <span data-heap-redact-text={true}>
                  Are you sure you want to activate account{" "}
                  <strong>
                    {
                      accounts.find(acc => acc?.id === accountIdToActivate)
                        ?.title
                    }
                  </strong>
                  ?
                </span>
              </DialogComponent>
            )}
          </div>
        );
      }}
      variables={{}}
    />
  );
};

export { Accounts };
export default connect(undefined, {
  putStatusMessage,
})(withUserLoading(Accounts));
