import React, {
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import { ReactComponent as CrossIcon } from "assets/icons/Cross.svg";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import clx from "classnames";
import styles from "./SearchInput.module.scss";

interface Props {
  defaultValue: string;
  onChange: (value: string) => void;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
  placeholder: string;
  testId: string;
  disabled?: boolean;
  objectFilter?: string;
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  debounceMs?: number;
}

const stopPropagation = (event: React.KeyboardEvent) => event.stopPropagation();

const SearchInput: React.FC<Props> = props => {
  const {
    defaultValue,
    placeholder,
    disabled = false,
    className,
    inputClassName,
    autoFocus = false,
    testId,
    handleKeyDown,
    onChange,
    debounceMs,
  } = props;

  const inputElement = useRef<HTMLInputElement | null>(null);

  const dependencies = useRef({
    onChange,
  });

  useLayoutEffect(() => {
    dependencies.current = {
      onChange,
    };
  });

  const reset = useCallback(() => {
    const { onChange } = dependencies.current;
    if (inputElement.current) {
      inputElement.current.value = "";
    }
    onChange("");
  }, []);

  useEffect(() => {
    if (defaultValue === "") {
      reset();
    }
  }, [defaultValue]);

  const debouncedOnChange = useMemo(() => {
    const { onChange } = dependencies.current;
    return _.debounce(onChange, debounceMs);
  }, [debounceMs]);

  const handleInputChange = useCallback(
    event => {
      const { onChange } = dependencies.current;
      const value = event.target.value;
      debounceMs ? debouncedOnChange(value) : onChange(value);
    },
    [debounceMs, debouncedOnChange]
  );

  return (
    <TextField
      inputRef={inputElement}
      disabled={disabled}
      classes={{
        root: clx(styles.root, className),
      }}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      variant="outlined"
      size="small"
      placeholder={placeholder}
      onChange={handleInputChange}
      inputProps={{
        "data-testid": testId,
      }}
      onKeyDown={handleKeyDown || stopPropagation}
      InputProps={{
        classes: {
          root: clx(styles.inputContainer, inputClassName),
          focused: styles.inputFocused,
        },
        endAdornment: (
          <InputAdornment
            classes={{ root: styles.iconContainer }}
            position="end"
            disablePointerEvents={!inputElement.current?.value}
            onClick={reset}
          >
            {inputElement.current?.value ? <CrossIcon /> : <SearchIcon />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
