import { stringify } from "csv-stringify/sync";
import { rowsValuesToString } from "./convert";

export function stringifyResultToCSV(rows, delimiter) {
  if (!rows.length) {
    return "";
  }

  const header = Object.keys(rows[0]).reduce((_header, col) => {
    _header[col] = col;
    return _header;
  }, {});

  const data = [header, ...rowsValuesToString(rows)];

  return new Promise((resolve, reject) => {
    try {
      const res = stringify(data, { delimiter });

      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
}

export function downloadResults(data, type) {
  const blob = new Blob([data], { type: `text/${type.toLowerCase()}` });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `QueryResults.${type.toLowerCase()}`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
