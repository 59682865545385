import { ReactComponent as GeneralIcon } from "assets/images/errorScreen/general.svg";
import { SUPPORT_EMAIL } from "businessLogic/constants";
import buttonStyles from "styles/buttons.module.scss";
import styles from "components/WelcomeScreen/SubscriptionError/styles.module.scss";

export const GeneralError = props => {
  const { title } = props;
  return (
    <div
      className={styles.general}
      data-testid="general-aws-subscription-error"
    >
      <GeneralIcon />
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.support}>
        Contact our support team at&nbsp;
        <a href={`mailto:${SUPPORT_EMAIL}`} className={buttonStyles.btn_link}>
          {SUPPORT_EMAIL}
        </a>
      </div>
    </div>
  );
};
