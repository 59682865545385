import classNames from "classnames/bind";
import { omit } from "lodash";
import { ChangeEvent, FocusEvent, forwardRef, RefObject } from "react";
import styles from "./styles.module.scss";

interface Props {
  autoFocus?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: { [key: string]: string };
  testId?: string;
  value?: string;
}

const Textarea = forwardRef(
  (props: Props, ref: RefObject<HTMLTextAreaElement>) => {
    const { testId, className } = props;

    const textareaProps = {
      ...omit(props, ["className", "testId"]),
    };

    const textareaClassName = classNames(styles.root, className);

    return (
      <textarea
        {...textareaProps}
        ref={ref}
        className={textareaClassName}
        data-testid={testId}
      />
    );
  }
);

export default Textarea;
