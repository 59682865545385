/**
 * @generated SignedSource<<47cfa54598660be3c550fabee51a006b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Emoji = "UNSPECIFIED" | "KICK_SCOOTER" | "BULLET_TRAIN" | "ROCKET" | "TRACTOR" | "AUTOMOBILE" | "SPEEDBOAT" | "SAILBOAT" | "CANOE" | "LOCOMOTIVE" | "BICYCLE" | "DELIVERY_TRUCK" | "PASSENGER_SHIP" | "RACING_CAR" | "HELICOPTER" | "MINIBUS" | "POLICE_CAR" | "AERIAL_TRAMWAY" | "MOTOR_SCOOTER" | "ONCOMING_AUTOMOBILE" | "SMALL_AIRPLANE" | "GEM_STONE" | "CREDIT_CARD" | "MONEY_BAG" | "MOBILE_PHONE" | "BRIEFCASE" | "CROWN" | "GRADUATION_CAP" | "SKULL" | "JACK_O_LANTERN" | "SMILING_FACE_WITH_HORNS" | "FACE_SCREAMING_IN_FEAR" | "FIRE" | "ALIEN" | "ALIEN_MONSTER" | "ROBOT" | "MOVIE_CAMERA" | "%future added value";
export type EngineSettingsPreset = "UNSPECIFIED" | "GENERAL_PURPOSE" | "DATA_ANALYTICS" | "%future added value";
export type EngineSettingsWarmUpLevel = "UNSPECIFIED" | "MINIMAL" | "INDEXES" | "ALL" | "SHARD_ON_READ" | "%future added value";
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type DbQuery$variables = {
  dbId: string;
  accountId: string;
};
export type DbQuery$data = {
  readonly database: {
    readonly id: string;
    readonly name: string;
    readonly emoji: Emoji;
    readonly bindings: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly engineIsDefault: boolean;
          readonly engine: {
            readonly id: string;
            readonly name: string;
            readonly emoji: Emoji;
            readonly computeRegion: {
              readonly id: string;
              readonly name: string;
            };
            readonly endpoint: string | null;
            readonly currentStatusSummary: EngineStatusSummary;
            readonly allowedActions: ReadonlyArray<string>;
            readonly latestRevision: {
              readonly id: string;
              readonly specification: {
                readonly dbComputeInstancesCount: number;
                readonly dbComputeInstancesUseSpot: boolean;
                readonly dbComputeInstancesType: {
                  readonly id: string;
                  readonly pricePerHourCents: number;
                  readonly name: string;
                  readonly cpuVirtualCoresCount: number;
                  readonly memorySizeBytes: number;
                  readonly storageSizeBytes: number;
                };
              };
            };
            readonly settingsPreset: EngineSettingsPreset;
            readonly settings: {
              readonly autoStopDelayDurationSeconds: number;
              readonly warmUpLevel: EngineSettingsWarmUpLevel;
            };
            readonly createActor: {
              readonly __typename: "User";
              readonly fullName: string;
              readonly displayName: string;
            } | {
              readonly __typename: "ServiceAccount";
              readonly displayName: string;
            } | {
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              readonly __typename: "%other";
            } | null;
          };
        };
      } | null> | null;
    };
  };
};
export type DbQuery = {
  variables: DbQuery$variables;
  response: DbQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dbId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "dbId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engineIsDefault",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "computeRegion",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endpoint",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentStatusSummary",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "desiredActions",
      "value": [
        "core.engines.start",
        "core.engines.stop",
        "core.engines.update",
        "core.engines.delete"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "allowedActions",
  "storageKey": "allowedActions(desiredActions:[\"core.engines.start\",\"core.engines.stop\",\"core.engines.update\",\"core.engines.delete\"])"
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "EngineRevision",
  "kind": "LinkedField",
  "name": "latestRevision",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EngineRevisionSpecification",
      "kind": "LinkedField",
      "name": "specification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbComputeInstancesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dbComputeInstancesUseSpot",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InstanceType",
          "kind": "LinkedField",
          "name": "dbComputeInstancesType",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "accountId",
                  "variableName": "accountId"
                }
              ],
              "kind": "ScalarField",
              "name": "pricePerHourCents",
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cpuVirtualCoresCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "memorySizeBytes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "storageSizeBytes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingsPreset",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "EngineSettings",
  "kind": "LinkedField",
  "name": "settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoStopDelayDurationSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warmUpLevel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    (v15/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    (v15/*: any*/)
  ],
  "type": "ServiceAccount",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DbQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Database",
        "kind": "LinkedField",
        "name": "database",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingConnection",
            "kind": "LinkedField",
            "name": "bindings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Binding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engine",
                        "kind": "LinkedField",
                        "name": "engine",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "createActor",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DbQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Database",
        "kind": "LinkedField",
        "name": "database",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BindingConnection",
            "kind": "LinkedField",
            "name": "bindings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BindingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Binding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Engine",
                        "kind": "LinkedField",
                        "name": "engine",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "createActor",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "821d22a2a1f95b781974a91a1c2c5cdb",
    "id": null,
    "metadata": {},
    "name": "DbQuery",
    "operationKind": "query",
    "text": "query DbQuery(\n  $dbId: ID!\n  $accountId: ID!\n) {\n  database(id: $dbId) {\n    id\n    name\n    emoji\n    bindings {\n      edges {\n        node {\n          id\n          engineIsDefault\n          engine {\n            id\n            name\n            emoji\n            computeRegion {\n              id\n              name\n            }\n            endpoint\n            currentStatusSummary\n            allowedActions(desiredActions: [\"core.engines.start\", \"core.engines.stop\", \"core.engines.update\", \"core.engines.delete\"])\n            latestRevision {\n              id\n              specification {\n                dbComputeInstancesCount\n                dbComputeInstancesUseSpot\n                dbComputeInstancesType {\n                  id\n                  pricePerHourCents(accountId: $accountId)\n                  name\n                  cpuVirtualCoresCount\n                  memorySizeBytes\n                  storageSizeBytes\n                }\n              }\n            }\n            settingsPreset\n            settings {\n              autoStopDelayDurationSeconds\n              warmUpLevel\n            }\n            createActor {\n              __typename\n              ... on User {\n                fullName\n                displayName\n              }\n              ... on ServiceAccount {\n                displayName\n              }\n              ... on Node {\n                __isNode: __typename\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3910ee0f295118cfce30783195b6d847";

export default node;
