import { isEmpty } from "lodash";
import { createAction } from "redux-actions";
import {
  getEngineProxyVersion,
  getLoadingEnginesVersion,
  getProxyPreserveExecution,
} from "redux/query/selectors";
import { engineCanBeQueried } from "components/EnginesList/helpers";

export const ProxyActions = {
  SET_VERSION: "PROXY/SET_VERSION",
  SET_VERSION_SUCCESS: "PROXY/SET_VERSION_SUCCESS",
  SET_VERSION_LOADING: "PROXY/SET_VERSION_LOADING",
  SET_PRESERVE_QUERY_EXECUTION: "PROXY/SET_PRESERVE_QUERY_EXECUTION",
  SET_PRESERVE_QUERY_EXECUTION_SUCCESS:
    "PROXY/SET_PRESERVE_QUERY_EXECUTION_SUCCESS",
  SET_PRESERVE_QUERY_EXECUTION_LOADING:
    "PROXY/SET_PRESERVE_QUERY_EXECUTION_LOADING",
};

export const setProxyVersion = ({ engine }) => {
  return (dispatch, getState) => {
    const state = getState();
    const isVersionLoading = getLoadingEnginesVersion(state, engine?.id);
    const proxyCurrentVersion = getEngineProxyVersion(state, engine?.id);
    const shouldFetchProxyVersion =
      !isEmpty(engine) &&
      !engine.isSystem &&
      engineCanBeQueried(engine.status) &&
      !isVersionLoading &&
      isEmpty(proxyCurrentVersion);

    if (shouldFetchProxyVersion) {
      dispatch({
        type: ProxyActions.SET_VERSION,
        payload: { engine },
      });
    }
  };
};

export const setProxyVersionSuccess = createAction(
  ProxyActions.SET_VERSION_SUCCESS,
  ({ engineId, version }) => ({ engineId, version })
);

export const setLoadingProxyVersion = createAction(
  ProxyActions.SET_VERSION_LOADING,
  engineId => ({ engineId })
);

export const setPreserveQueryExecution = ({ database, engine }) => {
  return (dispatch, getState) => {
    const state = getState();
    const proxyPreserveExecutionById = getProxyPreserveExecution(state);

    const shouldFetchPreserveExecution =
      !isEmpty(engine) &&
      !engine.isSystem &&
      engineCanBeQueried(engine.status) &&
      !proxyPreserveExecutionById?.[engine.id]?.loading &&
      !proxyPreserveExecutionById?.[engine.id]?.preserveExecution;

    if (shouldFetchPreserveExecution) {
      dispatch({
        type: ProxyActions.SET_PRESERVE_QUERY_EXECUTION,
        payload: { engine, databaseName: database.name },
      });
    }
  };
};

export const setPreserveQueryExecutionSuccess = createAction(
  ProxyActions.SET_PRESERVE_QUERY_EXECUTION_SUCCESS,
  ({ engineId }) => ({ engineId })
);

export const setPreserveQueryExecutionLoading = createAction(
  ProxyActions.SET_PRESERVE_QUERY_EXECUTION_LOADING,
  engineId => ({ engineId })
);
