import { AuthActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { ResetPass, SessionData, UserDetails } from "businessLogic/model";

interface AuthState {
  errorLoginMessage?: string;
  userDetails?: UserDetails | null;
  session?: SessionData | null;
  resetPassEmail?: ResetPass;
  emailSent?: boolean;
  isLoading?: boolean;
  errorResetMessage?: string | null;
  isPasswordSet?: boolean;
  isLogoutLoading?: boolean;
  loginType?: string;
}

export const INITIAL_STATE: AuthState = {};

const reducerMap = {};

reducerMap[AuthActions.LOGIN] = (
  state: AuthState,
  action: Action<{ loginType: string }>
): AuthState => {
  const { loginType } = action.payload;
  return { ...state, errorLoginMessage: undefined, isLoading: true, loginType };
};

/* remove after new auth release */

reducerMap[AuthActions.LOGIN_SUCCESS] = (
  state: AuthState,
  action: Action<UserDetails>
): AuthState => {
  return {
    ...state,
    userDetails: { username: action.payload.username },
    errorLoginMessage: undefined,
    isLoading: false,
  };
};

reducerMap[AuthActions.SET_ERROR_LOGIN] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return {
    ...state,
    errorLoginMessage: action.payload,
    emailSent: false,
    isLoading: false,
  };
};

reducerMap[AuthActions.SET_SESSION] = (
  state: AuthState,
  action: Action<SessionData>
): AuthState => {
  const { session } = state;
  const newSession: any = {
    ...(session || {}),
    access_token: action.payload.access_token,
    refresh_token: action.payload.refresh_token,
  };
  return { ...state, session: newSession };
};

reducerMap[AuthActions.CLEAR_SESSION] = (state: AuthState): AuthState => {
  return { ...state, session: null };
};

reducerMap[AuthActions.SET_ERROR_RESET_PASS] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return {
    ...state,
    errorResetMessage: action.payload,
    emailSent: false,
    isLoading: false,
    isPasswordSet: false,
  };
};

/* end */

reducerMap[AuthActions.LOGOUT] = (state: AuthState): AuthState => {
  return { ...state, isLogoutLoading: true };
};

reducerMap[AuthActions.LOGOUT_SUCCESS] = (
  state: AuthState,
  action: Action<{ loginType: string }>
): AuthState => {
  const { loginType } = action.payload;
  const isLogoutLoading = loginType === "auth0";
  return {
    ...INITIAL_STATE,
    isLogoutLoading,
  };
};

reducerMap[AuthActions.LOGOUT_FAILURE] = (): AuthState => {
  return INITIAL_STATE;
};

reducerMap[AuthActions.CLEAR_USER_DETAILS] = (state: AuthState): AuthState => {
  return { ...state, userDetails: null };
};

reducerMap[AuthActions.RESET_PASSWORD] = (
  state: AuthState,
  action: Action<ResetPass>
): AuthState => {
  return {
    ...state,
    errorResetMessage: null,
    resetPassEmail: action.payload,
    emailSent: false,
    isLoading: true,
  };
};

reducerMap[AuthActions.EMAIL_SENT] = (state: AuthState): AuthState => {
  return {
    ...state,
    errorResetMessage: null,
    emailSent: true,
    isLoading: false,
  };
};

reducerMap[AuthActions.EMAIL_SENT_RESET] = (state: AuthState): AuthState => {
  return {
    ...state,
    emailSent: false,
  };
};

reducerMap[AuthActions.SET_NEW_PASSWORD] = (state: AuthState): AuthState => {
  return { ...state, resetPassEmail: undefined, isLoading: true };
};

reducerMap[AuthActions.SET_PASSWORD_SUCCESS] = (
  state: AuthState
): AuthState => {
  return {
    ...state,
    resetPassEmail: undefined,
    isLoading: false,
    isPasswordSet: true,
  };
};

export default handleActions<AuthState, any>(reducerMap, INITIAL_STATE);
