import { Suspense, useEffect, createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { RelayEnvironmentProvider } from "react-relay";
import { useDispatch, useSelector } from "react-redux";
import { isWindowsOS } from "services/browser";
import environment from "relay/environment";
import { useInitLD } from "./hooks/useInitLD";
import AppInner from "./AppInner";
import { setFlags } from "redux/flags/actions";
import styles from "./styles.module.scss";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { createAccessManager } from "../../services/access/accessManager";

const accessManager = createAccessManager();

export const AccessManagerContext = createContext(accessManager);

const queryClient = new QueryClient();

const helmetContext = {};

const accountSelector = state => {
  return state.account.accountDetails;
};

export const App = () => {
  const account = useSelector(accountSelector);
  const dispatch = useDispatch();

  useInitLD(account);
  const ldClient = useLDClient();

  const flags = useAllFlags();

  useEffect(() => {
    accessManager.attachLdClient(ldClient);
  }, [ldClient]);

  useEffect(() => {
    dispatch(setFlags(flags));
  }, [flags, dispatch]);

  useEffect(() => {
    if (isWindowsOS()) {
      document.body.classList.add(styles.isWindowsOS);
    }
  }, []);

  return (
    <AccessManagerContext.Provider value={accessManager}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider context={helmetContext}>
          <Helmet>
            <title>Firebolt Manager</title>
          </Helmet>
          <RelayEnvironmentProvider environment={environment}>
            <Suspense fallback={null}>
              <AppInner />
            </Suspense>
          </RelayEnvironmentProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </AccessManagerContext.Provider>
  );
};

export default App;
