import React from "react";
import styles from "./TableLabel.module.scss";

interface Props {
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const TableLabel = ({ children, icon }: Props) => {
  const [first, ...rest] = React.Children.toArray(children);
  return (
    <div className={styles.root}>
      {icon && <div className={styles.iconContainer}>{icon}</div>}
      <span className={styles.text}>{first}</span>
      {rest}
    </div>
  );
};

export default TableLabel;
