import { Action, handleActions } from "redux-actions";
import { omit } from "lodash";
import { ViewsAction } from "../actions/views";
import * as queryTypes from "../actions/queries";
import { isRefreshViewsCommand } from "../helpers";

export interface ViewsState {
  isLoading: any;
  didInvalidate: boolean;
  temporaryFetchFreezeByDatabase: Record<string, any>;
  viewsByDatabase: Record<string, any>;
  viewTablesByDatabase: Record<string, any>;
}

export const INITIAL_STATE: ViewsState = {
  isLoading: false,
  didInvalidate: false,
  viewsByDatabase: {},
  temporaryFetchFreezeByDatabase: {},
  viewTablesByDatabase: {},
};

const reducerMap = {};

reducerMap[ViewsAction.VIEWS_LOAD] = (
  state: ViewsState,
  action: Action<{ database: string }>
): ViewsState => {
  const { database } = action.payload;
  return {
    ...state,
    isLoading: {
      ...state.isLoading,
      [database]: true,
    },
    temporaryFetchFreezeByDatabase: {
      ...state.temporaryFetchFreezeByDatabase,
      [database]: true,
    },
  };
};

reducerMap[ViewsAction.VIEWS_LOAD_FAILED] = (
  state: ViewsState,
  action: Action<{ database: string }>
): ViewsState => {
  const { database } = action.payload;
  return {
    ...state,
    isLoading: {
      ...state.isLoading,
      [database]: false,
    },
  };
};

reducerMap[ViewsAction.VIEWS_REMOVE] = (
  state: ViewsState,
  action: Action<{ database: string }>
) => {
  const { database } = action.payload;
  return {
    ...state,
    viewsByDatabase: {
      ...omit(state.viewsByDatabase, [database]),
    },
  };
};

reducerMap[ViewsAction.VIEWS_LOAD_SUCCESS] = (
  state: ViewsState,
  action: Action<{ database: string; views: any }>
): ViewsState => {
  const { database, views } = action.payload;
  return {
    ...state,
    viewsByDatabase: {
      ...state.viewsByDatabase,
      [database]: views,
    },
    isLoading: {
      ...state.isLoading,
      [database]: false,
    },
  };
};

reducerMap[ViewsAction.VIEW_EXPLAIN] = (
  state: ViewsState,
  action: Action<{ database: string; name: string }>
): ViewsState => {
  const { database, name } = action.payload;
  const views = {
    ...(state.viewTablesByDatabase?.[database] || {}),
    [name]: { isLoading: true },
  };

  return {
    ...state,
    viewTablesByDatabase: {
      [database]: views,
    },
  };
};

reducerMap[ViewsAction.VIEW_EXPLAIN_FAILED] = (
  state: ViewsState,
  action: Action<{ database: string; name: string; error: string }>
): ViewsState => {
  const { database, name, error } = action.payload;
  const views = {
    ...(state.viewTablesByDatabase?.[database] || {}),
    [name]: { isLoading: false, error },
  };
  return {
    ...state,
    viewTablesByDatabase: {
      [database]: views,
    },
  };
};

reducerMap[ViewsAction.VIEW_EXPLAIN_SUCCESS] = (
  state: ViewsState,
  action: Action<{ database: string; name: string; tables: string[] }>
): ViewsState => {
  const { database, name, tables } = action.payload;
  const views = {
    ...(state.viewTablesByDatabase?.[database] || {}),
    [name]: { isLoading: false, tables },
  };
  return {
    ...state,
    viewTablesByDatabase: {
      [database]: views,
    },
  };
};

reducerMap[queryTypes.EXECUTE_QUERY_REQUEST] = (
  state: ViewsState
): ViewsState => {
  return {
    ...state,
    didInvalidate: false,
  };
};

reducerMap[queryTypes.EXECUTE_QUERY_SUCCESS] = (
  state: ViewsState,
  action: Action<{ query: any }>
): ViewsState => {
  const { query } = action.payload;
  return {
    ...state,
    didInvalidate: isRefreshViewsCommand(query),
  };
};

reducerMap[queryTypes.EXECUTE_QUERY_STATEMENT_REQUEST] = (
  state: ViewsState
): ViewsState => {
  return {
    ...state,
    didInvalidate: false,
  };
};

reducerMap[ViewsAction.VIEWS_UNFREEZE_FETCH] = (
  state: ViewsState,
  action: Action<{ database: string }>
): ViewsState => {
  const { database } = action.payload;
  return {
    ...state,
    temporaryFetchFreezeByDatabase: {
      ...state.temporaryFetchFreezeByDatabase,
      [database]: false,
    },
  };
};

export default handleActions<ViewsState, any>(reducerMap, INITIAL_STATE);
