import React, { useEffect, useRef, useState } from "react";
import { Select, SelectProps } from "@material-ui/core";
import classNames from "classnames";
import { ReactComponent as ChevronDown } from "assets/icons/ChevronDown.svg";
import { useKeyboardFocus } from "./useKeyboardFocus";
import { EmptyState } from "./EmptyState";
import { CustomMenu } from "./CustomMenu";
import { MenuWithSearch } from "./MenuWithSearch";
import { SearchOptions, MIN_WIDTH } from "./types";
import styles from "./Select.module.scss";

export interface InitialConfig {
  initialOpen?: boolean;
  initialDelay?: number;
  onDone?: () => void;
}

interface Props extends SelectProps {
  width?: "MD";
  paperWidth?: "MD";
  initialOpenConfig?: InitialConfig | null;
  searchOptions?: SearchOptions;
  renderMenu?: (closeMenu: () => void) => React.ReactNode;
  renderMenuAutoWidth?: boolean;
  testId?: string;
  pageSize?: number;
  customMenuProps?: any;
  placeholderIcon?: React.ReactNode;
  onClose?: () => void;
}

const renderChildren = props => {
  const { searchQuery, children } = props;

  if (!searchQuery) {
    return children || [];
  }

  const filteredItems = children
    .filter(item => {
      return item?.props?.searchOptions?.textForSearch
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
    })
    .map(item => {
      return {
        ...item,
        props: {
          ...item.props,
          searchOptions: {
            ...item.props?.searchOptions,
            searchQuery,
          },
        },
      };
    });

  return filteredItems;
};

const renderValue =
  ({ placeholder, children }) =>
  value => {
    if (!value) {
      return <span data-testid="placeholder">{placeholder}</span>;
    }

    const item = children.find(item => item.props.value === value);

    if (item) {
      return item.props.children;
    }

    return value;
  };

const renderValueWithIcon =
  ({ placeholderIcon, children }) =>
  value => {
    if (!value) return;

    const item = children.find(item => item.props.value === value);

    if (item) {
      return (
        <div className={styles.selectedValueWithIcon}>
          <div className={styles.placeholderIcon}>{placeholderIcon}</div>
          {item.props.children}
        </div>
      );
    }

    return value;
  };

const CustomSelect: React.FC<Props> = props => {
  const {
    className,
    width,
    paperWidth,
    classes = {},
    MenuProps = {},
    searchOptions,
    renderMenu,
    renderMenuAutoWidth,
    initialOpenConfig,
    testId,
    children,
    placeholder,
    pageSize,
    customMenuProps = {},
    placeholderIcon,
    onClose,
    onOpen,
    multiple,
    ...restProps
  } = props;
  const customizedMenuProps = { ...MenuProps };

  if (
    !customizedMenuProps.anchorEl &&
    !customizedMenuProps.getContentAnchorEl
  ) {
    customizedMenuProps.anchorOrigin = {
      vertical: "bottom",
      horizontal: "left",
    };
  }

  const selectRef = useRef<HTMLElement | null>(null);
  const menuMinWidth = useRef<number>(0);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { listRef, searchRef, handleKeyDown } = useKeyboardFocus(pageSize);

  const setInitialWidth = () => {
    if (renderMenuAutoWidth || searchOptions) {
      const { width = 0 } = selectRef.current?.getBoundingClientRect() || {};
      menuMinWidth.current = width;
    }
  };

  const dependencies = useRef({
    setInitialWidth,
  });

  useEffect(() => {
    dependencies.current = {
      setInitialWidth,
    };
  });

  useEffect(() => {
    if (initialOpenConfig?.initialOpen) {
      setTimeout(() => {
        const { setInitialWidth } = dependencies.current;
        setInitialWidth();
        setOpen(!!initialOpenConfig.initialOpen);
        onOpen && onOpen({} as any);

        initialOpenConfig?.onDone?.();
      }, initialOpenConfig.initialDelay);
    }
  }, [initialOpenConfig]);

  useEffect(() => {
    if (open === false) {
      setSearchQuery("");
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    props.onClose && props.onClose();
  };

  const handleOpen = e => {
    const { setInitialWidth } = dependencies.current;
    setInitialWidth();
    props.onOpen && props.onOpen(e);
    setOpen(true);
  };

  const handleChangeSearch = query => {
    setSearchQuery(query);
  };

  const onChange = (event, child) => {
    if (event.target.value !== null && event.target.value !== undefined) {
      if (!props.multiple && !child.props.disableClose) {
        handleClose();
      }

      if (props.onChange) {
        props.onChange(event, child);
      }
    }
  };

  const menuProps = {
    ...customizedMenuProps,
    classes: {
      ...customizedMenuProps?.classes,
      paper: classNames(
        styles.paper,
        styles[`paperWidth${paperWidth}`],
        customizedMenuProps?.classes?.paper
      ),
      list: classNames(styles.list, styles[`listWidth${width}`]),
    },
    open: renderMenu || searchOptions ? false : open,
    getContentAnchorEl: customizedMenuProps.getContentAnchorEl || null,
    onClose: handleClose,
    autoFocus: searchOptions ? false : true,
  };

  if (renderMenuAutoWidth) {
    Object.assign(menuProps, {
      PaperProps: {
        style: {
          minWidth: menuMinWidth.current,
          width: Math.max(menuMinWidth.current, MIN_WIDTH),
        },
      },
    });
  }

  const childrenItems = renderChildren({
    searchQuery,
    children,
  });

  const menuElement = renderMenu ? (
    <CustomMenu
      anchorEl={selectRef.current}
      handleClose={handleClose}
      open={open}
      renderMenu={renderMenu}
      {...customMenuProps}
    />
  ) : searchOptions ? (
    <MenuWithSearch
      handleClose={handleClose}
      open={open}
      childrenItems={childrenItems}
      searchQuery={searchQuery}
      searchOptions={searchOptions}
      handleChangeSearch={handleChangeSearch}
      multiple={multiple}
      value={restProps.value}
      onChange={onChange}
      menuMinWidth={menuMinWidth.current}
      paperWidth={paperWidth}
      selectRef={selectRef}
      listRef={listRef}
      searchRef={searchRef}
    />
  ) : null;

  const childrenElement = childrenItems.length ? (
    childrenItems
  ) : (
    <EmptyState
      key="empty"
      searchOptions={searchOptions}
    />
  );

  return (
    <div
      className={styles.container}
      onKeyDown={handleKeyDown}
    >
      <Select
        ref={selectRef}
        data-testid={props.testId}
        className={classNames(styles.selectRoot, className, {
          [styles.disabled]: props.disabled,
          [styles.open]: open,
        })}
        classes={{
          icon: classNames(styles.icon, classes?.icon),
          root: classNames(styles.select, classes?.root),
          select: classNames(classes?.select),
        }}
        MenuProps={menuProps}
        renderValue={
          Array.isArray(children)
            ? placeholderIcon
              ? renderValueWithIcon({ placeholderIcon, children })
              : renderValue({ placeholder, children })
            : undefined
        }
        IconComponent={ChevronDown}
        multiple={multiple}
        {...restProps}
        onChange={onChange}
        open={open}
        onOpen={handleOpen}
      >
        {childrenElement}
      </Select>
      {menuElement}
    </div>
  );
};

CustomSelect.defaultProps = {};
export default CustomSelect;
