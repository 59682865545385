import { createAction } from "redux-actions";
import { ReactNode } from "react";

export enum StatusMessagePosition {
  Top = "TOP",
  Bottom = "BOTTOM",
}

export interface StatusMessageOptions {
  insertToPosition: StatusMessagePosition;
  id: string | null;
  autoRemove: boolean;
  preventAutoClear?: boolean;
  autoClearDelay?: number;
  timeoutId?: number;
  preventDeletionOnReset?: boolean; // To remove the message you need manually dispatch REMOVE_MESSAGE
}

export enum StatusMessageType {
  Success = "success",
  Error = "error",
  Loading = "loading",
}

export interface StatusMessage {
  message: string | ReactNode;
  type: StatusMessageType;
  options: StatusMessageOptions;
}

export const StatusMessageActions = {
  PUT_MESSAGE: "STATUS_MESSAGE/PUT_MESSAGE",
  PUT_MESSAGE_SUCCESS: "STATUS_MESSAGE/PUT_MESSAGE_SUCCESS",
  REMOVE_MESSAGE: "STATUS_MESSAGE/REMOVE_MESSAGE",
  REMOVE_MESSAGE_BY_TIMEOUT_ID: "STATUS_MESSAGE/REMOVE_MESSAGE_BY_TIMEOUT_ID",
  RESET: "STATUS_MESSAGE/RESET",
};

export const putStatusMessage = createAction(
  StatusMessageActions.PUT_MESSAGE,
  (
    message: string | ReactNode,
    type: StatusMessageType,
    options: StatusMessageOptions = {
      insertToPosition: StatusMessagePosition.Bottom,
      id: null,
      autoRemove: true,
    }
  ) => {
    return {
      message,
      type,
      options,
    };
  }
) as unknown as (
  message: string | ReactNode,
  type: StatusMessageType,
  options?: StatusMessageOptions
) => StatusMessage;
// This a hack for redux-toolkit typings (unsupported optional arg)

export const removeStatusMessage = createAction(
  StatusMessageActions.REMOVE_MESSAGE,
  (id: string) => id
);

export const removeStatusMessageByTimeoutId = createAction(
  StatusMessageActions.REMOVE_MESSAGE_BY_TIMEOUT_ID,
  (id: number) => id
);

export const resetStatusMessages = createAction(
  StatusMessageActions.RESET,
  () => {}
);

export const putStatusMessageSuccess = createAction(
  StatusMessageActions.PUT_MESSAGE_SUCCESS,
  ({ message, type, options }) => {
    return {
      message,
      type,
      options,
    };
  }
);
