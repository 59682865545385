/**
 * @generated SignedSource<<6da74eb42677d7d6be9b8316878c9aa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScriptCreateInput = {
  userId: string;
  databaseId?: string | null;
  displayName: string;
  isStarred: boolean;
  data: string;
};
export type ScriptCreateMutation$variables = {
  input: ScriptCreateInput;
};
export type ScriptCreateMutation$data = {
  readonly scriptCreate: {
    readonly success: boolean;
    readonly script: {
      readonly id: string;
      readonly data: string;
      readonly databaseId: string | null;
      readonly isStarred: boolean;
      readonly displayName: string;
      readonly lastUpdateTime: any;
      readonly createTime: any;
    };
  };
};
export type ScriptCreateMutation = {
  variables: ScriptCreateMutation$variables;
  response: ScriptCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScriptCreatePayload",
    "kind": "LinkedField",
    "name": "scriptCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Script",
        "kind": "LinkedField",
        "name": "script",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "data",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isStarred",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScriptCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScriptCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f244a18d5bcbe2479d76c17069219a4c",
    "id": null,
    "metadata": {},
    "name": "ScriptCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ScriptCreateMutation(\n  $input: ScriptCreateInput!\n) {\n  scriptCreate(input: $input) {\n    success\n    script {\n      id\n      data\n      databaseId\n      isStarred\n      displayName\n      lastUpdateTime\n      createTime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "404c5d2476af5d4decf809f755de8831";

export default node;
