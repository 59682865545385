import React from "react";
import { Dialog, DialogProps } from "@material-ui/core";
import styles from "./FormModal.module.scss";
import omit from "lodash/omit";
import LoadingOverlap from "components/LoadingOverlap";

interface Props extends DialogProps {
  title?: string;
  dialogIsLoading?: boolean;
  headerContent?: React.ReactNode;
  bodyContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  children?: React.ReactNode;
  onEnterKeyPress?: () => void;
}

const FormModal = (props: Props) => {
  const {
    title,
    headerContent,
    bodyContent,
    footerContent,
    children,
    dialogIsLoading,
    onEnterKeyPress,
  } = props;

  const dialogProps = {
    ...omit(props, [
      "headerContent",
      "bodyContent",
      "footerContent",
      "dialogIsLoading",
      "onEnterKeyPress",
    ]),
  };

  const onKeyPressHandle = event => {
    if (onEnterKeyPress && event.key.toLowerCase() === "enter") {
      onEnterKeyPress();
    }
  };

  return (
    <Dialog
      scroll="body"
      {...dialogProps}
      title={undefined}
      onKeyPress={onKeyPressHandle}
    >
      {dialogIsLoading && <LoadingOverlap isLoading />}
      {!dialogIsLoading &&
        (children || (
          <>
            {(title || headerContent) && (
              <div className={styles.header}>
                <div className={styles.title}>{title}</div>
                <div className={styles.headerContent}>{headerContent}</div>
              </div>
            )}
            <div className={styles.body}>{bodyContent}</div>
            <div className={styles.footer}>{footerContent}</div>
          </>
        ))}
    </Dialog>
  );
};

export default FormModal;
