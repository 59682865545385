import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AuthLoginInput } from "./__generated__/LoginMutation.graphql";

const mutation = graphql`
  mutation LoginMutation($input: AuthLoginInput!) {
    authLogin(input: $input) {
      success
      accessToken
      refreshToken
      scopes
    }
  }
`;

function commit(environment, input: AuthLoginInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
  });
}

export default { commit };
