import { getMyUser } from "redux/user/selectors";
import UserUiStateMutation from "relay/mutations/user/UserUiStateMutation";
import environment from "relay/environment";
import { APP_ACTIONS_PREFIX } from "components/App/redux/actions";

export default ({ getState }) => {
  return next => action => {
    const returnValue = next(action);

    if (action.type.split("/")[0] === APP_ACTIONS_PREFIX) {
      const user = getMyUser(getState());

      if (user) {
        new Promise((resolve, reject) => {
          UserUiStateMutation.commit(
            environment,
            {
              id: user.id,
              data: JSON.stringify(getState().app)
            },
            resolve,
            reject
          );
        });
      }
    }

    return returnValue;
  };
};
