import React from "react";
import { ReactComponent as ScrewWrenchIcon } from "assets/icons/ScrewWrench.svg";
import ErrorScreen, {
  ErrorScreenImagePosition
} from "components/ErrorScreen/ErrorScreen";
import MaterialButton from "components/MaterialButton/MaterialButton";

const NewAppVersion = () => {
  return (
    <ErrorScreen
      imagePosition={ErrorScreenImagePosition.Top}
      image={<ScrewWrenchIcon />}
      title="Firebolt has been updated!"
      description={
        <>New possibilities arise and you don’t want to be left behind</>
      }
      footerSmallMargin
      footer={
        <div>
          <MaterialButton
            onClick={() => window.location.reload()}
            text={"Let's Reload the page"}
          />
        </div>
      }
    />
  );
};

export default NewAppVersion;
