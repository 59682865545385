import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { AccountMemberCreateInput } from "./__generated__/CreateAccountMembersMutation.graphql";

const mutation = graphql`
  mutation CreateAccountMembersMutation($input: AccountMemberCreateInput!) {
    accountMemberCreate(input: $input) {
      success
    }
  }
`;

function commit(
  environment,
  input: AccountMemberCreateInput,
  onCompleted,
  onError
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
