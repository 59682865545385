import i18n from "i18next";
import translationEn from "./en/translation.json";
import { initReactI18next } from "react-i18next";

// Type for verifying consistency of translation files
type TranslationKeys = typeof translationEn;

export const resources: {
  [key: string]: {
    translation: TranslationKeys;
  };
} = {
  en: {
    translation: translationEn
  }
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources
});

export default i18n;
