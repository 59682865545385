import * as queryTypes from "../actions/queries";
import * as _ from "lodash";
import { Action, handleActions } from "redux-actions";
import { TablesAction } from "../actions/tables";
import { isRefreshTablesCommand } from "../helpers";

export interface TablesState {
  isLoading: any;
  didInvalidate: boolean;
  tablesByDatabase: any;
  temporaryFetchFreezeByDatabase: Record<string, any>;
  selectedTable: Record<string, string>;
  error?: {};
}

export const INITIAL_STATE: TablesState = {
  isLoading: false,
  didInvalidate: false,
  tablesByDatabase: {},
  selectedTable: {},
  temporaryFetchFreezeByDatabase: {},
  error: {},
};

const reducerMap = {};

reducerMap[TablesAction.SELECT_TABLE] = (
  state: TablesState,
  action: Action<{ database: string; table: string }>
): TablesState => {
  const { database, table } = action.payload;
  return {
    ...state,
    selectedTable: {
      ...state.selectedTable,
      [database]: table,
    },
  };
};

reducerMap[TablesAction.UNSELECT_TABLE] = (
  state: TablesState,
  action: Action<{ database: string; table: string }>
): TablesState => {
  const { database } = action.payload;
  return {
    ...state,
    selectedTable: {
      ...state.selectedTable,
      [database]: "",
    },
  };
};

reducerMap[queryTypes.EXECUTE_QUERY_REQUEST] = (
  state: TablesState
): TablesState => {
  return {
    ...state,
    didInvalidate: false,
  };
};

reducerMap[queryTypes.EXECUTE_QUERY_SUCCESS] = (
  state: TablesState,
  action: Action<{ query: any }>
): TablesState => {
  const { query } = action.payload;
  return {
    ...state,
    didInvalidate: isRefreshTablesCommand(query),
  };
};

reducerMap[queryTypes.EXECUTE_QUERY_STATEMENT_REQUEST] = (
  state: TablesState
): TablesState => {
  return {
    ...state,
    didInvalidate: false,
  };
};

reducerMap[TablesAction.TABLES_LOAD] = (
  state: TablesState,
  action: Action<{ database: string; isRetry?: boolean }>
): TablesState => {
  const { database, isRetry } = action.payload;
  return {
    ...state,
    error: {
      ...state.error,
      [database]: null,
    },
    isLoading: {
      ...state.isLoading,
      [database]: isRetry ? true : state.isLoading[database],
    },
    temporaryFetchFreezeByDatabase: {
      ...state.temporaryFetchFreezeByDatabase,
      [database]: true,
    },
  };
};

reducerMap[TablesAction.START_LOADING] = (
  state: TablesState,
  action: Action<{ database: string }>
): TablesState => {
  const { database } = action.payload;
  return {
    ...state,
    error: {
      ...state.error,
      [database]: null,
    },
    isLoading: {
      ...state.isLoading,
      [database]: true,
    },
    didInvalidate: false,
  };
};

reducerMap[TablesAction.STOP_LOADING] = (
  state: TablesState,
  action: Action<{ database: string }>
): TablesState => {
  const { database } = action.payload;
  return {
    ...state,
    isLoading: {
      ...state.isLoading,
      [database]: false,
    },
    didInvalidate: false,
  };
};

reducerMap[TablesAction.TABLES_LOAD_FAILED] = (
  state: TablesState,
  action: Action<{ database: string; error: Error }>
): TablesState => {
  const { error, database } = action.payload;

  return {
    ...state,
    error: {
      ...state.error,
      [database]: error,
    },
    didInvalidate: false,
  };
};

reducerMap[TablesAction.TABLES_REMOVE] = (
  state: TablesState,
  action: Action<{ database: string }>
): TablesState => {
  const { database } = action.payload;
  return {
    ...state,
    tablesByDatabase: {
      ..._.omit(state.tablesByDatabase, [database]),
    },
  };
};

reducerMap[TablesAction.TABLES_LOAD_SUCCESS] = (
  state: TablesState,
  action: Action<{ database: string; tables: any }>
): TablesState => {
  const { database, tables } = action.payload;
  return {
    ...state,
    tablesByDatabase: {
      ...state.tablesByDatabase,
      [database]: tables,
    },
    didInvalidate: false,
  };
};

reducerMap[TablesAction.TABLES_UNFREEZE_FETCH] = (
  state: TablesState,
  action: Action<{ database: string }>
): TablesState => {
  return {
    ...state,
    temporaryFetchFreezeByDatabase: {
      ...state.temporaryFetchFreezeByDatabase,
      [action.payload.database]: false,
    },
  };
};

export default handleActions<TablesState, any>(reducerMap, INITIAL_STATE);
