import JSONbig from "json-bigint";
import * as fileHandler from "../../utils/file-handler";
import { getAllScripts } from "../../utils/scripts";
import { ProgressInfoInput } from "../QueryEditor.interfaces";

export const NEW_QUERY = "NEW_QUERY";
export const RENAME_QUERY = "RENAME_QUERY";
export const SELECT_QUERY = "SELECT_QUERY";
export const REMOVE_QUERY = "REMOVE_QUERY";
export const EXECUTE_QUERY_REQUEST = "EXECUTE_QUERY_REQUEST";
export const SAVE_QUERY_STATEMENTS = "SAVE_QUERY_STATEMENTS";
export const EXECUTE_QUERY_SUCCESS = "EXECUTE_QUERY_SUCCESS";
export const EXECUTE_QUERY_FAILURE = "EXECUTE_QUERY_FAILURE";
export const EXECUTE_QUERY_EXPLAIN_REQUEST = "EXECUTE_QUERY_EXPLAIN_REQUEST";
export const EXECUTE_QUERY_EXPLAIN_SUCCESS = "EXECUTE_QUERY_EXPLAIN_SUCCESS";
export const EXECUTE_QUERY_EXPLAIN_FAILURE = "EXECUTE_QUERY_EXPLAIN_FAILURE";
export const EXECUTE_QUERY_EXPLAIN_RESET = "EXECUTE_QUERY_EXPLAIN_RESET";
export const CANCEL_QUERY_REQUEST = "CANCEL_QUERY_REQUEST";
export const CANCEL_QUERY_SUCCESS = "CANCEL_QUERY_SUCCESS";
export const CANCEL_QUERY_FAILURE = "CANCEL_QUERY_FAILURE";
export const CANCEL_QUERY_EXECUTION = "CANCEL_QUERY_EXECUTION";
export const OPEN_QUERY_SUCCESS = "OPEN_QUERY_SUCCESS";
export const UPDATE_QUERY = "UPDATE_QUERY";
export const RESET_ALL_QUERIES = "RESET_ALL_QUERIES";
export const SAVE_TAB_NAV_POSITION = "SAVE_TAB_NAV_POSITION";
export const CHANGE_TAB_EDITOR_HEIGHT = "CHANGE_TAB_EDITOR_HEIGHT";
export const CHANGE_EDITOR_TAB_STATE = "CHANGE_EDITOR_TAB_STATE";
export const SET_SERVER_QUERY_ID = "SET_SERVER_QUERY_ID";
export const SET_QUERY_STATEMENT_PROGRESS = "SET_QUERY_STATEMENT_PROGRESS";
export const CLOSE_QUERY_DIALOG = "CLOSE_QUERY_DIALOG";
export const ADD_QUERY_SETTINGS = "ADD_QUERY_SETTINGS";
export const REMOVE_QUERY_SETTING = "REMOVE_QUERY_SETTING";
export const REMOVE_QUERY_SETTINGS = "REMOVE_QUERY_SETTINGS";
export const CLEAR_QUERIES_RESULT = "CLEAR_QUERIES_RESULT";
export const EXECUTE_QUERY_STATEMENT_REQUEST =
  "EXECUTE_QUERY_STATEMENT_REQUEST";
export const CLOSE_TABS = "CLOSE_TABS";
export const SAVE_EXPANDED_DB_ITEM = "SAVE_EXPANDED_DB_ITEM";
export const QUERY_TAB_CHANGED = "QUERY_TAB_CHANGED";
export const START_QUERY_STATEMENT_PROGRESS = "START_QUERY_STATEMENT_PROGRESS";
export const RESUME_QUERY = "RESUME_QUERY";
export const QUERY_SAVED_AS_SCRIPT = "QUERY_SAVED_AS_SCRIPT";
export const UPDATE_ACTIVE_DB_ID = "UPDATE_ACTIVE_DB_ID";
export const UPDATE_QUERY_ENGINE_ID = "UPDATE_QUERY_ENGINE_ID";
export const FETCH_ASYNC_DATA = "FETCH_ASYNC_DATA";
export const CHANGE_ORDER = "CHANGE_ORDER";
export const CLOSE_SCRIPT_WITH_CANCEL = "CLOSE_SCRIPT_WITH_CANCEL";
export const CLOSE_ALL_SCRIPTS_WITH_CANCEL = "CLOSE_ALL_SCRIPTS_WITH_CANCEL";
export const RUN_QUERY_IN_BACKGROUND = "QUERIES/RUN_QUERY_IN_BACKGROUND";
export const RESUME_QUERIES_IN_BACKGROUND =
  "QUERIES/RESUME_QUERIES_IN_BACKGROUND";
export const QUERY_RESUMED_IN_BACKGROUND =
  "QUERIES/QUERY_RESUMED_IN_BACKGROUND";
export const RESUME_QUERY_IN_BACKGROUND = "QUERIES/RESUME_QUERY_IN_BACKGROUND";
export const QUERY_STATEMENT_RESUMED_IN_BACKGROUND =
  "QUERIES/QUERY_STATEMENT_RESUMED_IN_BACKGROUND";

export const REHYDRATE_ALL_USER_QUERIES = "REHYDRATE_ALL_USER_QUERIES";
export const MARK_QUERY_AS_STOPPED = "QUERIES/MARK_QUERY_AS_STOPPED";

export function saveExpandedDbItem(dbItem, expanded) {
  return { type: SAVE_EXPANDED_DB_ITEM, payload: { dbItem, expanded } };
}

export function querySavedAsScript(queryId, database, userScriptId) {
  return {
    type: QUERY_SAVED_AS_SCRIPT,
    payload: { queryId, database, userScriptId },
  };
}

export function newQuery(payload) {
  return {
    type: NEW_QUERY,
    payload: payload,
  };
}

export function renameQuery(name, database, queryId = null) {
  return {
    type: RENAME_QUERY,
    payload: {
      name,
      queryId,
      database,
    },
  };
}

export function selectQuery(queryId, database) {
  return {
    type: SELECT_QUERY,
    payload: { queryId, database },
  };
}

export function resetQueries() {
  return { type: RESET_ALL_QUERIES };
}

export function removeQuery({ database, queryId, allScripts }) {
  return {
    type: REMOVE_QUERY,
    payload: { database, queryId, allScripts },
  };
}

export function closeScriptWithCancel(database, queryId) {
  return {
    type: CLOSE_SCRIPT_WITH_CANCEL,
    payload: { database, queryId },
  };
}

export function closeAllScriptsWithCancel({
  databaseName,
  queryIdsToCancel,
  scriptToKeepOpen,
}: {
  databaseName: string;
  queryIdsToCancel: string[];
  scriptToKeepOpen: any;
}) {
  return {
    type: CLOSE_ALL_SCRIPTS_WITH_CANCEL,
    payload: { databaseName, queryIdsToCancel, scriptToKeepOpen },
  };
}

export function closeTabs(
  database,
  excludeQuery = null,
  allScripts: any[] = []
) {
  return {
    type: CLOSE_TABS,
    payload: {
      excludeQuery,
      database,
      allScripts,
    },
  };
}

export function executeDefaultSelectQueryIfNeeded(
  database,
  queryDefaultSelect
) {
  return async (dispatch, getState) => {
    let currentState = getState().query;
    const dbName = database.name;

    if (!shouldExecuteQuery(queryDefaultSelect, currentState, dbName)) {
      return;
    }

    if (needNewQuery(currentState, dbName, queryDefaultSelect)) {
      const allScripts = getAllScripts(getState(), dbName);
      await dispatch({
        type: NEW_QUERY,
        payload: {
          database: dbName,
          allScripts,
        },
      });
    }

    currentState = getState().query;
    const curQuery = getCurrentQuery(currentState, dbName);
    dispatch({
      type: UPDATE_QUERY,
      payload: {
        sqlQuery: queryDefaultSelect,
        database: dbName,
        query: curQuery,
      },
    });

    dispatch(executeQuery(queryDefaultSelect, database.name, curQuery.id));
  };
}

export function updateQuery(sqlQuery, database, query) {
  return dispatch => {
    dispatch({
      type: UPDATE_QUERY,
      payload: {
        sqlQuery,
        database,
        query,
      },
    });
  };
}

export function shouldExecuteQuery(query, state, database) {
  const currentQuery = getCurrentQuery(state, database);
  if (!currentQuery) return true;
  return !currentQuery.isQueryRunning;
}

export function executeQuery(query, database, queryId) {
  return async dispatch => {
    dispatch({
      type: EXECUTE_QUERY_REQUEST,
      payload: { database, query, queryId },
    });
  };
}

export function cancelQuery(queryId, database) {
  return dispatch => {
    dispatch({ type: CANCEL_QUERY_REQUEST, payload: { queryId, database } });
  };
}

export function saveTabNavPosition(position, database) {
  return { type: SAVE_TAB_NAV_POSITION, payload: { position, database } };
}

function getCurrentQuery(state, database) {
  const queryDetails = state.queries.databasesQueries[database]
    ? state.queries.databasesQueries[database]
    : state.queries;
  return state.queries.databasesQueries?.[database].queriesById?.[
    queryDetails.currentQueryTabId
  ];
}

function needNewQuery(currentState, database, queryDefaultSelect) {
  const currentQuery = getCurrentQuery(currentState, database);
  if (!currentQuery) {
    return false;
  }

  const queryIsDifferentDB = currentQuery.database !== database;
  const queryIsNotDefault = currentQuery.query !== queryDefaultSelect;
  const queryIsNotEmpty = !!currentQuery.query.trim();

  return queryIsDifferentDB || (queryIsNotDefault && queryIsNotEmpty);
}

export function changeEditorHeight(editorHeight, database, queryId) {
  return async dispatch => {
    dispatch({
      type: CHANGE_TAB_EDITOR_HEIGHT,
      payload: { editorHeight, database, queryId },
    });
  };
}

export function changeEditorTabState({
  expanded,
  editorLastHeight,
  database,
  queryId,
}) {
  return async dispatch => {
    dispatch({
      type: CHANGE_EDITOR_TAB_STATE,
      payload: { expanded, editorLastHeight, database, queryId },
    });
  };
}

export async function saveToFile(rows, type, delimiter) {
  let value;
  if (type === "CSV") {
    value = await fileHandler.stringifyResultToCSV(rows, delimiter);
  } else {
    value = JSONbig.stringify(rows, null, 2);
  }
  fileHandler.downloadResults(value, type);
}

export function setServerQueryID({
  database,
  queryId,
  serverQueryId,
  asyncStatementId,
}: {
  database: any;
  queryId: any;
  serverQueryId: any;
  asyncStatementId?: any;
}) {
  return {
    type: SET_SERVER_QUERY_ID,
    payload: { database, queryId, serverQueryId, asyncStatementId },
  };
}

export function setQueryStatementProgress(
  database,
  queryId,
  progressInfo: ProgressInfoInput,
  queryStatementId: number
) {
  return {
    type: SET_QUERY_STATEMENT_PROGRESS,
    payload: { database, queryId, progressInfo, queryStatementId },
  };
}

export function addQuerySettings(database, queryId, settings) {
  return {
    type: ADD_QUERY_SETTINGS,
    payload: { database, queryId, settings },
  };
}

export function clearQueryResult() {
  return { type: CLEAR_QUERIES_RESULT };
}

export function closeQueryDialog() {
  return { type: CLOSE_QUERY_DIALOG };
}

export function queryTabChanged(query, database) {
  return { type: QUERY_TAB_CHANGED, payload: { query, database } };
}

export function resumeQueryInBackground(query, database) {
  return { type: RESUME_QUERY_IN_BACKGROUND, payload: { query, database } };
}

export function runQueryInBackground({
  database,
  serverQueryId,
  queryStatementId,
}) {
  return {
    type: RUN_QUERY_IN_BACKGROUND,
    payload: { serverQueryId, database, queryStatementId },
  };
}

export function resumeQueriesInBackground(database) {
  return { type: RESUME_QUERIES_IN_BACKGROUND, payload: { database } };
}

export function queryResumedInBackground(database) {
  return { type: QUERY_RESUMED_IN_BACKGROUND, payload: { database } };
}

export function queryStatementResumedInBackground(database, serverQueryId) {
  return {
    type: QUERY_STATEMENT_RESUMED_IN_BACKGROUND,
    payload: { database, serverQueryId },
  };
}

export function startQueryStatementProgress(
  queryId,
  database,
  queryStatementId
) {
  return {
    type: START_QUERY_STATEMENT_PROGRESS,
    payload: { queryId, database, queryStatementId },
  };
}

export function resumeMultiQuery(
  query,
  database,
  queryId,
  resumeFromQueryStatementId
) {
  return {
    type: RESUME_QUERY,
    payload: { database, query, queryId, resumeFromQueryStatementId },
  };
}

export function updateActiveDbId(dbId) {
  return { type: UPDATE_ACTIVE_DB_ID, payload: dbId };
}

export function updateQueryEngineId({ dbName, queryId, engineId }) {
  return {
    type: UPDATE_QUERY_ENGINE_ID,
    payload: { database: dbName, queryId, engineId },
  };
}

export function openNewScriptTabWithPredefinedQuery(database, queryTemplate) {
  return async (dispatch, getState) => {
    const dbName = database.name;

    await dispatch({
      type: NEW_QUERY,
      payload: {
        database: dbName,
        allScripts: getAllScripts(getState(), dbName),
      },
    });

    const currentState = getState().query;
    const curQuery = getCurrentQuery(currentState, dbName);
    dispatch({
      type: UPDATE_QUERY,
      payload: {
        sqlQuery: queryTemplate,
        database: dbName,
        query: curQuery,
      },
    });
  };
}

export function openNewDefaultScriptTab(database, queryDefaultSelect) {
  return async (dispatch, getState) => {
    const dbName = database.name;

    await dispatch({
      type: NEW_QUERY,
      payload: {
        database: dbName,
        allScripts: getAllScripts(getState(), dbName),
      },
    });

    const currentState = getState().query;
    const curQuery = getCurrentQuery(currentState, dbName);
    dispatch({
      type: UPDATE_QUERY,
      payload: {
        sqlQuery: queryDefaultSelect,
        database: dbName,
        query: curQuery,
      },
    });
  };
}

export const rehydrateUserQueries = databasesQueries => ({
  type: REHYDRATE_ALL_USER_QUERIES,
  payload: { databasesQueries },
});
