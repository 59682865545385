import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { UserRole } from "pages/Users/User.interface";
import { selectUserRole } from "redux/user/selectors";
import { ROUTES } from "businessLogic/routes.constants";

export const checkEnginesRoute = Component => props => {
  const userRole = useSelector(selectUserRole);

  if (userRole === UserRole.Viewer) {
    return (
      <Navigate
        to={ROUTES.DATABASES}
        replace
      />
    );
  }
  return <Component {...props} />;
};
