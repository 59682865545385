import React from "react";
import styles from "pages/Users/UserList/UserListFilters/UserListFilters.module.scss";
import ListView from "components/Table/ListView/ListView";
import MaterialSearchInput from "components/MaterialSearchInput/MaterialSearchInput";
import {
  ACCOUNT_STATUS_NAME,
  AccountsFiltersValues,
  DEFAULT_FILTERS,
} from "pages/Accounts/Accounts";
import MaterialButton, {
  ButtonSize,
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import { FormControl, InputLabel } from "@material-ui/core";
import CustomSelect from "components/Select/Select";
import MultiSelectMenuItem from "components/Select/SelectMenuItem/MultiSelectMenuItem";
import { AccountStatus } from "businessLogic/model";

const SELECT_ALL_VALUE = "all";

interface Props {
  filters: AccountsFiltersValues;
  onFiltersChanged: (values: AccountsFiltersValues) => void;
}

const AccountsFilters = ({ filters, onFiltersChanged }: Props) => {
  const filtersAreActive =
    filters.name || filters.domain || filters.statuses.length;

  return (
    <div className={styles.root} data-testid="user-list-filters">
      <ListView
        direction="horizontal"
        spacingPx={32}
        justifyContent={true}
        childClassNames={styles.control}
        equalElemWidth={true}
      >
        <MaterialSearchInput
          onChange={(name, value) => {
            onFiltersChanged({
              ...filters,
              [name]: value,
            });
          }}
          placeholder="Name"
          name="name"
          label="Name"
          defaultValue={filters.name || ""}
          testId="accounts-search-name-input"
        />

        <MaterialSearchInput
          onChange={(name, value) => {
            onFiltersChanged({
              ...filters,
              [name]: value,
            });
          }}
          placeholder="All Domains"
          name="domain"
          label="Domain"
          defaultValue={filters.domain || ""}
          testId="accounts-search-domain-input"
        />

        <FormControl fullWidth={true}>
          <InputLabel shrink={true}>Status</InputLabel>
          <CustomSelect
            multiple={true}
            fullWidth={true}
            testId="select-statuses"
            onChange={e => {
              let newStatuses = e.target.value as string[];

              if (newStatuses.includes(SELECT_ALL_VALUE)) {
                if (newStatuses.length > 1) {
                  newStatuses = (newStatuses as string[]).filter(
                    r => r !== SELECT_ALL_VALUE
                  );
                }
              }

              onFiltersChanged({
                ...filters,
                statuses: newStatuses as AccountStatus[],
              });
            }}
            value={
              filters.statuses.length ? filters.statuses : [SELECT_ALL_VALUE]
            }
            renderValue={(val: Array<AccountStatus | string>) => {
              const statuses = val.filter(s => s !== SELECT_ALL_VALUE);

              return (
                <div className={styles.selectHeaderItem}>
                  {statuses.length
                    ? statuses.map(s => ACCOUNT_STATUS_NAME[s]).join(", ")
                    : "All Statuses"}
                </div>
              );
            }}
          >
            <MultiSelectMenuItem
              key={SELECT_ALL_VALUE}
              value={SELECT_ALL_VALUE}
              testId="all-statuses"
            >
              All Statuses
            </MultiSelectMenuItem>
            {Object.values(AccountStatus).map(status => {
              return (
                <MultiSelectMenuItem
                  key={status}
                  value={status}
                  testId={status}
                >
                  {ACCOUNT_STATUS_NAME[status]}
                </MultiSelectMenuItem>
              );
            })}
          </CustomSelect>
        </FormControl>
      </ListView>

      <div className={styles.buttonClearContainer}>
        <MaterialButton
          text="Clear filters"
          startIcon={<CloseIcon />}
          size={ButtonSize.Small}
          template={ButtonTemplate.Secondary}
          onClick={() => onFiltersChanged(DEFAULT_FILTERS)}
          disabled={!filtersAreActive}
          dataTestid="clear-filters"
        />
      </div>
    </div>
  );
};

export default AccountsFilters;
