import { FlagsActions } from "./actions";
import { Action, handleActions } from "redux-actions";

interface FlagsState {
  flags: any;
}

export const INITIAL_STATE: FlagsState = {
  flags: null,
};

const reducerMap = {};

reducerMap[FlagsActions.SET_FLAGS] = (
  state: FlagsState,
  action: Action<{ flags: any }>
): FlagsState => {
  return { ...state, flags: action.payload.flags };
};

export default handleActions<FlagsState, any>(reducerMap, INITIAL_STATE);
