import cn from "classnames";
import { useTranslation } from "react-i18next";
import { ReactComponent as StartIcon } from "assets/icons/StartIcon.svg";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";
import MaterialButton, {
  ButtonSize,
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import { canStartEngine } from "components/EnginesList/helpers";
import { EngineItemType } from "components/EnginesList/EngineItem/EngineItem";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";
import { useDisabledStart } from "./useDisabledStart";
import styles from "../index.module.scss";
import { useContext } from "react";
import { AccessManagerContext } from "../../App/App";
import { GQLRbacaction } from "../../../graphql.types";

type Props = {
  engine: any;
  onClick: ((e) => void) | undefined;
  accountAllowedActions: string[];
  hovered?: boolean;
  engines: EngineItemType[];
};

function StartEngineButton({
  engine,
  engines,
  onClick,
  accountAllowedActions,
  hovered,
}: Props) {
  const { t } = useTranslation();
  const { isAllowedTo } = useContext(AccessManagerContext);
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    engine.computeRegion
  );
  const { disabledStart, disabledStartTooltip } = useDisabledStart(
    engines,
    engine
  );
  const render =
    !!engine.status &&
    engine.status === UIEngineStatus.STATUS_STOPPED &&
    onClick &&
    canStartEngine(accountAllowedActions) &&
    isAllowedTo(engine.id, GQLRbacaction.ENGINE_OPERATE);

  if (!render) {
    return null;
  }

  const tooltip = disabledStartTooltip || readOnlyTooltip || undefined;

  return (
    <div
      className={cn(styles.actionBtn, {
        [styles.visible]: hovered,
      })}
    >
      <MaterialButton
        template={ButtonTemplate.Secondary}
        startIcon={<StartIcon />}
        size={ButtonSize.Small}
        text={t("engines.start")}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.(e);
        }}
        dataTestid="start-engine"
        disabled={readOnlyMode || disabledStart}
        tooltip={tooltip}
      />
    </div>
  );
}

export default StartEngineButton;
