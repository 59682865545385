import { useRef, useEffect } from "react";
import { Navigate, Route, useLocation, useMatch } from "react-router-dom";
import { ROUTES, GUEST_ROUTES } from "businessLogic/routes.constants";
import { InvitationFlow } from "pages/Users/InvitationTokenVerification";
import {
  AsyncLoginPage,
  AsyncActiveResetPassword,
  AsyncCreateAccountPage,
  AsyncResetPassword,
  AsyncInvitationTokenVerification,
} from "./routes";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { LoginError } from "../LoginError/LoginError";

const guestRoute = Component => props => {
  const location = useLocation();
  const { isLoggedIn } = useLoggedInUser();
  const wasLoggedIn = useRef(isLoggedIn);

  useEffect(() => {
    wasLoggedIn.current = isLoggedIn;
  }, [isLoggedIn]);

  if (isLoggedIn && wasLoggedIn.current) {
    return <Navigate to={`/${location.search}`} />;
  }
  return <Component {...props} />;
};

const TokenExtractor = () => {
  const match = useMatch(GUEST_ROUTES.ACCEPT_INVITATION);

  return (
    <AsyncInvitationTokenVerification
      invitationFlow={InvitationFlow.NewUser}
      token={match?.params.token as string}
    />
  );
};

export const getGuestRoutes = () => {
  const ProtectedAsyncLoginPage = guestRoute(AsyncLoginPage);
  const ProtectedAsyncCreateAccountPage = guestRoute(AsyncCreateAccountPage);
  const ProtectedAsyncGettingStarted = guestRoute(() => (
    <AsyncCreateAccountPage showExtraFields={true} />
  ));

  return [
    <Route
      key={GUEST_ROUTES.LOGIN_ERROR}
      path={GUEST_ROUTES.LOGIN_ERROR}
      element={<LoginError />}
    />,
    <Route
      key={ROUTES.LOGIN}
      path={ROUTES.LOGIN}
      element={<ProtectedAsyncLoginPage />}
    />,
    <Route
      key={GUEST_ROUTES.CREATE_ACCOUNT}
      path={GUEST_ROUTES.CREATE_ACCOUNT}
      element={<ProtectedAsyncCreateAccountPage />}
    />,
    <Route
      key={GUEST_ROUTES.GETTING_STARTED}
      path={GUEST_ROUTES.GETTING_STARTED}
      element={<ProtectedAsyncGettingStarted />}
    />,
    <Route
      key={GUEST_ROUTES.RESET_PASSWORD}
      path={GUEST_ROUTES.RESET_PASSWORD}
      element={<AsyncResetPassword />}
    />,
    <Route
      key={GUEST_ROUTES.RESET_PASSWORD_TOKEN}
      path={GUEST_ROUTES.RESET_PASSWORD_TOKEN}
      element={<AsyncActiveResetPassword />}
    />,
    <Route
      key={GUEST_ROUTES.ACCEPT_INVITATION}
      path={GUEST_ROUTES.ACCEPT_INVITATION}
      element={<TokenExtractor />}
    />,
  ];
};
