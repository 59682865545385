import { Action } from "redux-actions";
import _ from "lodash";
import {
  all,
  takeEvery,
  takeLatest,
  select,
  delay,
  put,
} from "redux-saga/effects";
import {
  queryIndicatorSubject,
  QueryIndicatorStatus,
} from "services/queryIndicator/subject";
import { isQueryStatementRunning } from "components/QueryEditor/utils/queries";
import { getQueryStatus } from "businessLogic/services/query";
import {
  getQuery,
  getQueries,
  getEngineProxyVersion,
} from "redux/query/selectors";
import { STATUS_CHECK_TIMEOUT } from "services/queryIndicator/constants";
import {
  ServerQueryStatusesV1,
  ServerQueryStatusesV2,
} from "../../constants/queries";
import * as QueryActions from "../actions/queries";
import { getDBEndpoint } from "./getDBEndpoint";
import { getServerQueryid } from "./helpers";
import { getStatusKeyByProxyVersion } from "../../../../businessLogic/helpers/Version";
import { runQueryInBackground } from "../actions/queries";

const statusesBeingFetchedByQueryId = {};

function* shouldRunStatusCheck({ database, queryId, currentQueryStatementId }) {
  const dbQueries = yield select(getQueries);
  const multiQueryStatement = _.get(
    dbQueries,
    `databasesQueries.${database}.queriesById[${queryId}].multiQueryStatement`,
    []
  );
  return multiQueryStatement.find(
    ({ status, queryStatementId }) =>
      currentQueryStatementId === queryStatementId &&
      isQueryStatementRunning(status)
  );
}

const getTrackingIdentifier = ({ databaseName, queryId, queryStatementId }) =>
  `${databaseName}-${queryId}-${queryStatementId}`;

const startTrackingForQueryStatus = ({
  databaseName,
  queryId,
  queryStatementId,
}) => {
  const trackingId = getTrackingIdentifier({
    databaseName,
    queryId,
    queryStatementId,
  });
  statusesBeingFetchedByQueryId[trackingId] = true;
};

const stopTrackingForQueryStatus = ({
  databaseName,
  queryId,
  queryStatementId,
}) => {
  const trackingId = getTrackingIdentifier({
    databaseName,
    queryId,
    queryStatementId,
  });
  delete statusesBeingFetchedByQueryId[trackingId];
};

const getTrackingForQueryStatus = ({
  databaseName,
  queryId,
  queryStatementId,
}) => {
  const trackingId = getTrackingIdentifier({
    databaseName,
    queryId,
    queryStatementId,
  });
  return statusesBeingFetchedByQueryId[trackingId];
};

function* notifyRunning(action) {
  const {
    queryId,
    database: databaseName,
    queryStatementId: currentQueryStatementId,
  } = action.payload;
  queryIndicatorSubject.notify({ type: QueryIndicatorStatus.RUNNING, queryId });

  const engineEndpoint = yield getDBEndpoint(databaseName, queryId);
  const queryAlreadyTracked = getTrackingForQueryStatus({
    databaseName,
    queryId,
    queryStatementId: currentQueryStatementId,
  });
  if (!engineEndpoint || queryAlreadyTracked) {
    return;
  }

  const queryState = yield select(getQuery, databaseName, queryId);

  const engineProxyVersion = yield select(
    getEngineProxyVersion,
    queryState?.engineId
  );
  const queryServerStatusKey = getStatusKeyByProxyVersion(engineProxyVersion);

  let runStatusCheck = yield shouldRunStatusCheck({
    database: databaseName,
    queryId,
    currentQueryStatementId,
  });

  if (runStatusCheck) {
    startTrackingForQueryStatus({
      databaseName,
      queryId,
      queryStatementId: currentQueryStatementId,
    });
  }

  while (runStatusCheck) {
    const serverQueryId = yield getServerQueryid(databaseName, queryId);

    if (serverQueryId) {
      const statusResponse = yield getQueryStatus(
        serverQueryId,
        databaseName,
        engineEndpoint
      ).catch(error => {
        console.error("Failed to get query status:", error);
      });

      const status = statusResponse?.[queryServerStatusKey];

      runStatusCheck = yield shouldRunStatusCheck({
        database: databaseName,
        queryId,
        currentQueryStatementId,
      });

      if (!runStatusCheck) {
        stopTrackingForQueryStatus({
          databaseName,
          queryId,
          queryStatementId: currentQueryStatementId,
        });
        return;
      }

      const query = yield select(getQuery, databaseName, queryId);

      if (!query) {
        return;
      }

      if (
        !statusResponse ||
        status === ServerQueryStatusesV1.EXCEPTION_BEFORE_START
      ) {
        queryIndicatorSubject.notify({
          type: QueryIndicatorStatus.ERROR,
          queryId,
        });
        return;
      }

      if (
        [
          ServerQueryStatusesV1.FINISHED,
          ServerQueryStatusesV2.FINISHED,
        ].includes(status)
      ) {
        yield put(
          runQueryInBackground({
            database: databaseName,
            serverQueryId,
            queryStatementId: currentQueryStatementId,
          })
        );

        yield notifyFinished(action);
      }
    }

    yield delay(STATUS_CHECK_TIMEOUT);

    runStatusCheck = yield shouldRunStatusCheck({
      database: databaseName,
      queryId,
      currentQueryStatementId,
    });

    if (!runStatusCheck) {
      stopTrackingForQueryStatus({
        databaseName,
        queryId,
        queryStatementId: currentQueryStatementId,
      });
    }
  }
}

const notifyCancel = action => {
  const { queryId } = action.payload;
  queryIndicatorSubject.notify({ type: QueryIndicatorStatus.CANCEL, queryId });
};

const notifyError = action => {
  const { queryId } = action.payload;
  queryIndicatorSubject.notifyAsync({
    type: QueryIndicatorStatus.ERROR,
    queryId,
  });
};

function* notifyFinished(
  action: Action<{
    database: string;
    queryId: string;
    queryStatementId: number;
  }>
) {
  const { database, queryId } = action.payload;
  const query = yield select(getQuery, database, queryId);
  const { multiQueryStatement, querySqlStatements } = query;
  if (multiQueryStatement.length === querySqlStatements.length) {
    queryIndicatorSubject.notify({
      type: QueryIndicatorStatus.SUCCESS,
      queryId,
    });
  }
}

export function* QueryStateSagas() {
  return yield all([
    takeEvery(
      [
        QueryActions.EXECUTE_QUERY_STATEMENT_REQUEST,
        QueryActions.EXECUTE_QUERY_REQUEST,
        QueryActions.RESUME_QUERY,
        QueryActions.START_QUERY_STATEMENT_PROGRESS,
      ],
      notifyRunning
    ),
    takeEvery([QueryActions.CANCEL_QUERY_SUCCESS], notifyCancel),
    takeEvery(
      [QueryActions.CANCEL_QUERY_FAILURE, QueryActions.EXECUTE_QUERY_FAILURE],
      notifyError
    ),
    takeLatest([QueryActions.EXECUTE_QUERY_SUCCESS], notifyFinished),
  ]);
}
