import React, { useContext, useState } from "react";
import { SortDirection } from "@material-ui/core";
import { WidthContext } from "./context";
import { HeaderCell } from "./HeaderCell";
import { ColumnType } from "./types";
import styles from "./styles.module.scss";

const COLUMN_MIN_WIDTH = 20;

type ResizingColumn = {
  id: string;
  dragOffset: number;
};

type ColumnWidths = Record<string, number>;

type HeaderProps = {
  columns: ColumnType[];
  columnWidths: ColumnWidths;
  finishResize: (widths: ColumnWidths) => void;
  wrapper: React.RefObject<HTMLDivElement>;
  order?: SortDirection;
  disableResize?: boolean;
  orderBy?: string | null;
  toggleSorting?: (fieldId: string) => void;
};

export const Header: React.FC<HeaderProps> = props => {
  const {
    columns,
    columnWidths,
    finishResize,
    order,
    orderBy,
    disableResize,
    toggleSorting,
    wrapper,
  } = props;
  const { leadingWidth } = useContext(WidthContext);

  const [resizingColumn, setResizingColumn] = useState<ResizingColumn | null>(
    null
  );
  const [resizeLineHeight, setResizeLineHeight] = useState(0);

  const lastColumnWidth = `calc(100% - ${leadingWidth}px)`;

  const onResizeFinish = () => {
    if (resizingColumn) {
      const { id, dragOffset } = resizingColumn;
      const currentWidth = columnWidths[id];
      const width = Math.max(currentWidth + dragOffset, COLUMN_MIN_WIDTH);
      const newWidths = { ...columnWidths };
      newWidths[id] = width;
      finishResize(newWidths);
      setResizingColumn(null);
    }
    setResizeLineHeight(0);
  };

  const onResizeStart = (column: ColumnType) => () => {
    const height = wrapper.current?.offsetHeight ?? 0;
    const resizeLineHeight = height ? height - 10 : 0;
    setResizingColumn({ ...column, dragOffset: 0 });
    setResizeLineHeight(resizeLineHeight);
  };

  const onResize = (dragOffset: number) => {
    setResizingColumn(column => {
      if (column) {
        return { ...column, dragOffset };
      }
      return column;
    });
  };

  return (
    <div className={styles.header}>
      {columns.map((column, index) => {
        const { id, fillAvailableWidth } = column;
        const width = columnWidths[id];
        const isDragging = id === resizingColumn?.id;
        let dragOffset = 0;

        if (isDragging) {
          dragOffset = resizingColumn?.dragOffset ?? 0;
        }

        const style = {
          minWidth: width,
          width: fillAvailableWidth ? lastColumnWidth : width,
        };

        return (
          <HeaderCell
            key={`${column.name}-${index}`}
            column={column}
            style={style}
            isDragging={isDragging}
            fillAvailableWidth={fillAvailableWidth}
            onResizeFinish={onResizeFinish}
            onResizeStart={onResizeStart(column)}
            onResize={onResize}
            dragOffset={dragOffset}
            resizeLineHeight={resizeLineHeight}
            order={order}
            orderBy={orderBy}
            disableResize={disableResize}
            toggleSorting={toggleSorting}
          />
        );
      })}
    </div>
  );
};
