import React from "react";
import cn from "classnames";
import CustomTooltip from "components/Tooltip";
import { engineCanBeEdited } from "components/EngineForm/helpers";
import { ReactComponent as EditIcon } from "assets/icons/selectMenuIcons/EditIcon.svg";
import { canEditEngine } from "components/EnginesList/helpers";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";
import { useTranslation } from "react-i18next";
import styles from "../index.module.scss";

type Props = {
  engine: any;
  onClick: ((e) => void) | undefined;
  accountAllowedActions: string[];
  hovered?: boolean;
};

const EditEngineButton: React.FC<Props> = ({
  engine,
  onClick,
  accountAllowedActions,
  hovered,
}) => {
  const { t } = useTranslation();
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    engine.computeRegion
  );

  if (!canEditEngine(accountAllowedActions)) {
    return null;
  }

  return (
    <CustomTooltip
      title={
        readOnlyMode
          ? readOnlyTooltip.title
          : engineCanBeEdited(engine.status)
          ? t("engines.edit")
          : t("engines.stop_engine_to_edit")
      }
      placement="top"
      classes={{ tooltip: "editEngineButtonTooltip" }}
    >
      <EditIcon
        data-testid="engine-item-edit"
        className={cn(styles.actionBtn, {
          [styles.disabled]: !engineCanBeEdited(engine.status) || readOnlyMode,
          [styles.visible]: hovered,
        })}
        onClick={e => {
          if (readOnlyMode || !engineCanBeEdited(engine.status)) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();
          onClick?.(e);
        }}
      />
    </CustomTooltip>
  );
};

export default EditEngineButton;
