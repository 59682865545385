import { UserActions, UserData } from "./actions";
import { Action, handleActions } from "redux-actions";
import { AuthActions } from "pages/Auth/redux/actions";

interface UserState {
  userData: UserData | null;
  errorMessage: string | null;
  setupError?: boolean;
  isLoading: boolean;
  selectedAccountId: string | null;
}

export const INITIAL_STATE: UserState = {
  userData: null,
  errorMessage: null,
  isLoading: false,
  selectedAccountId: null,
};

const reducerMap = {};

reducerMap[UserActions.LOAD_USER_DATA] = (state: UserState): UserState => {
  return { ...state, errorMessage: null, isLoading: true };
};

reducerMap[UserActions.LOAD_USER_DATA_SUCCESS] = (
  state: UserState,
  action: Action<UserData>
): UserState => {
  return {
    ...state,
    userData: action.payload,
    errorMessage: null,
    isLoading: false,
    setupError: undefined,
  };
};

reducerMap[UserActions.PUT_USER_DATA] = (
  state: UserState,
  action: Action<UserData>
): UserState => {
  return { ...state, userData: action.payload };
};

reducerMap[UserActions.LOAD_USER_DATA_FAILURE] = (
  state: UserState,
  action: Action<string>
): UserState => {
  return { ...state, errorMessage: action.payload, isLoading: false };
};
reducerMap[UserActions.INCOMPLETE_SETUP] = (state: UserState): UserState => {
  return {
    ...state,
    isLoading: false,
    setupError: true,
  };
};

reducerMap[AuthActions.LOGOUT_SUCCESS] = (): UserState => {
  return INITIAL_STATE;
};

reducerMap[UserActions.PUT_SELECTED_ACCOUNT_ID] = (
  state: UserState,
  action: Action<{ accountId: string }>
): UserState => {
  return { ...state, selectedAccountId: action.payload.accountId };
};

reducerMap[UserActions.REMOVE_SELECTED_ACCOUNT_ID] = (
  state: UserState
): UserState => {
  return { ...state, selectedAccountId: null };
};

export default handleActions<UserState>(reducerMap, INITIAL_STATE);
