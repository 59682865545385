import { createAction } from "redux-actions";
import { TableColumn, TableIndex } from "../reducers/tableDetails";

export const TableDetailsAction = {
  TABLE_DETAILS_LOAD: "TABLE_DETAILS/LOAD_REQUEST",
  TABLE_DETAILS_LOAD_SUCCESS: "TABLE_DETAILS/LOAD_SUCCESS",
  TABLE_DETAILS_LOAD_FAILED: "TABLE_DETAILS/LOAD_FAILED",
  EMPTY_TABLE_DETAILS: "TABLE_DETAILS/EMPTY_TABLE_DETAILS"
};

export const LoadTableDetails = createAction(
  TableDetailsAction.TABLE_DETAILS_LOAD,
  (database: string, table: any) => ({ database, table })
);

export const LoadTableDetailsSuccess = createAction(
  TableDetailsAction.TABLE_DETAILS_LOAD_SUCCESS,
  (
    database: string,
    table: string,
    columns: TableColumn | null,
    indexes: TableIndex | null
  ) => ({
    database,
    table,
    tableDetails: {
      columns,
      indexes
    }
  })
);

export const EmptyTableDetails = createAction(
  TableDetailsAction.EMPTY_TABLE_DETAILS,
  (database: string, table: string) => ({
    database,
    table
  })
);

export const LoadTableDetailsFailed = createAction(
  TableDetailsAction.TABLE_DETAILS_LOAD_FAILED,
  (database: string, table: string, error: string) => ({
    database,
    table,
    error
  })
);
