import React, { ReactElement, useRef, useEffect } from "react";
import CustomTooltip from "../../Tooltip";
import { clickOnSpaceOrEnterPress } from "../../common/keyboardUtils";
import cn from "classnames";
import { TooltipProps } from "@material-ui/core";
import styles from "./ActionMenuItem.module.scss";
import commonStyles from "styles/common.module.scss";

export enum ActionMenuItemSize {
  Default = "Default",
  Mid = "Mid",
}

export interface ActionMenuItemProps {
  text?: string | React.ReactNode;
  icon?: ReactElement;
  endIcon?: ReactElement;
  selectedIcon?: ReactElement;
  tooltip?: string;
  onClick?: (e?: any) => void;
  selected?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  size?: ActionMenuItemSize;
  className?: string;
  classes?: { [key: string]: string };
  testId?: string;
  showEndIconOnlyOnHover?: boolean;
  tooltipClasses?: any;
  to?: string;
  title?: string;
  tooltipPlacement?: TooltipProps["placement"];
  tabIndex?: number;
}

const ActionMenuItem = (props: ActionMenuItemProps) => {
  const {
    text,
    icon,
    endIcon,
    selectedIcon,
    tooltip,
    tooltipClasses,
    onClick,
    selected,
    autoFocus = false,
    disabled,
    buttons,
    size,
    className,
    classes = {},
    showEndIconOnlyOnHover,
    to,
    title,
    testId = null,
    tabIndex = 0,
    tooltipPlacement,
  } = props;

  const element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (autoFocus && element.current) {
      element.current.focus();
    }
  }, [autoFocus]);

  const wrapByLink = element => {
    if (!to || disabled) {
      return element;
    }

    return (
      <a
        href={to}
        className={commonStyles.noFocus}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
        onKeyPress={e => clickOnSpaceOrEnterPress(e)}
      >
        {element}
      </a>
    );
  };

  const endIconClassNames = cn(styles.iconContainer, {
    [styles.showOnlyOnHover]: showEndIconOnlyOnHover,
  });

  const testAttributes = !!testId
    ? {
        "data-testid": `${testId}`,
      }
    : {};

  const content = wrapByLink(
    <div
      {...testAttributes}
      className={cn(
        styles.listItem,
        commonStyles.noFocus,
        styles[`size${size}`],
        className,
        {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
          [styles.buttons]: buttons,
          [classes.selected]: selected,
          [classes.disabled]: disabled,
          [classes.buttons]: buttons,
        }
      )}
      ref={element}
      title={title}
      tabIndex={tabIndex}
      onKeyPress={e => {
        if (disabled) return;
        clickOnSpaceOrEnterPress(e);
      }}
      onClick={!disabled && !buttons ? onClick : () => {}}
    >
      {icon && <span className={styles.iconContainer}>{icon}</span>}
      <span className={styles.text}>{text}</span>
      {buttons}
      <span className={styles.selectIconContainer}>
        {!!selectedIcon && selected && selectedIcon}
      </span>

      {endIcon && <span className={endIconClassNames}>{endIcon}</span>}
    </div>
  );

  if (!tooltip) {
    return content;
  }

  return (
    <CustomTooltip
      title={tooltip}
      interactive={false}
      placement={tooltipPlacement || "left"}
      classes={tooltipClasses}
    >
      {content}
    </CustomTooltip>
  );
};

ActionMenuItem.defaultProps = {
  size: ActionMenuItemSize.Default,
};

export default ActionMenuItem;
