import React from "react";

// App routes
export const AsyncLoginPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Login" */ "pages/Auth/Login"
    )
);

export const AsyncResetPassword = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ResetPassword" */ "pages/Auth/ResetPassword"
    )
);

export const AsyncActiveResetPassword = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "NewPassword" */ "pages/Auth/ResetPassword/SetNewPassword"
    )
);

export const AsyncCreateAccountPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "CreateAccount" */ "pages/Account/CreateAccount"
    )
);

export const AsyncError404 = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Error404" */ "pages/ErrorPages"
    )
);

export const AsyncQueryEditor = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "QueryEditor" */ "pages/SqlWorkspace"
    )
);

export const AsyncEngines = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Engines" */ "pages/Engines/Engines"
    )
);

export const AsyncDatabasesNew = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Databases" */ "pages/Database/Databases"
    )
);

export const AsyncUsers = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Users" */ "pages/Users/Users"
    )
);

export const AsyncRoles = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Roles" */ "pages/Roles/Roles"
    )
);

export const AsyncUnsubscribe = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Unsubscribe" */ "pages/Unsubscribe"
    )
);

export const AsyncInvitationTokenVerification = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "InvitationTokenVerification" */ "pages/Users/InvitationTokenVerification"
    )
);

export const AsyncAccountInfo = React.lazy(
  () =>
    /* webpackPrefetch: true, webpackChunkName: "AccountInfo" */ import(
      "pages/Account/AccountInfo/AccountInfo"
    )
);
