import * as _ from "lodash";
import { select } from "redux-saga/effects";
import { engineCanBeQueried } from "components/EnginesList/helpers";

const getDatabases = state => _.get(state, "database.databasesList");
const getQuery = (state, database, queryId) =>
  _.get(
    state,
    `query.queries.databasesQueries[${database}].queriesById[${queryId}]`
  );

const getEngineEndpoint = (dbList, database, queryState) => {
  if (!queryState || !queryState.engineId) return false;
  const db = _.find(dbList, { name: database });
  const engine = _.find(db.engines, e => e.id === queryState.engineId);

  if (!engine || !engineCanBeQueried(engine.status)) {
    return false;
  }

  return `https://${engine.endpoint}`;
};

export function* getDBEndpoint(database: string, queryId: any) {
  const dbList = yield select(getDatabases);
  const queryState = yield select(getQuery, database, queryId);
  return getEngineEndpoint(dbList, database, queryState);
}

export const endpointSelector = (state, database, queryId) => {
  const dbList = getDatabases(state);
  const queryState = getQuery(state, database, queryId);
  return getEngineEndpoint(dbList, database, queryState);
};
