/**
 * @generated SignedSource<<6d43365210fba0f146514bcd1b63049c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStatus = "UNSPECIFIED" | "PENDING_PROVISIONING" | "ACTIVE" | "BLOCKED" | "PROVISIONING_FAILED" | "DELETED" | "APPROVAL_PENDING" | "%future added value";
export type TenantStatus = "UNSPECIFIED" | "CREATED" | "CREATING" | "FAILED" | "ACTIVE" | "%future added value";
export type AccountQuery$variables = {
  id: string;
  desiredActions: ReadonlyArray<string>;
};
export type AccountQuery$data = {
  readonly account: {
    readonly id: string;
    readonly title: string;
    readonly businessDomainNames: ReadonlyArray<string>;
    readonly status: AccountStatus;
    readonly allowedActions: ReadonlyArray<string>;
    readonly createTime: any;
    readonly tenantStatus: TenantStatus;
    readonly rbacPolicies: ReadonlyArray<string>;
  };
};
export type AccountQuery = {
  variables: AccountQuery$variables;
  response: AccountQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "desiredActions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "account",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "businessDomainNames",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "desiredActions",
            "variableName": "desiredActions"
          }
        ],
        "kind": "ScalarField",
        "name": "allowedActions",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tenantStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rbacPolicies",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8b37ebf3a18477589e76151a58e5ed74",
    "id": null,
    "metadata": {},
    "name": "AccountQuery",
    "operationKind": "query",
    "text": "query AccountQuery(\n  $id: ID!\n  $desiredActions: [String!]!\n) {\n  account(id: $id) {\n    id\n    title\n    businessDomainNames\n    status\n    allowedActions(desiredActions: $desiredActions)\n    createTime\n    tenantStatus\n    rbacPolicies\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f79bcb32f7706a508f3993b454fdeb8";

export default node;
