import { createAction } from "redux-actions";

export type CancelableAction = {
  id: string;
  running?: boolean;
  aborted?: boolean;
  elapsedTime?: number;
  predictedTime?: number;
  startedAt?: number;
  doneAt?: number;
};

export const START_FORMAT = "START_FORMAT";
export const ABORT_FORMAT = "ABORT_FORMAT";
export const END_FORMAT = "END_FORMAT";
export const UPDATE_FORMATTING_TIME = "UPDATE_FORMATTING_TIME";

export const start = createAction<CancelableAction>(START_FORMAT);
export const updateTime = createAction<CancelableAction>(
  UPDATE_FORMATTING_TIME
);
export const abort = createAction<CancelableAction>(ABORT_FORMAT);
export const cancel = createAction<CancelableAction>(END_FORMAT);
