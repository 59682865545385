import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  select,
} from "redux-saga/effects";
import * as Sentry from "@sentry/browser";
import * as crypto from "crypto";
import {
  incompleteSetup,
  loadUserDataFailure,
  loadUserDataSuccess,
  putSelectedAccountId,
  putUserData,
  removeSelectedAccountId,
  UserActions,
} from "./actions";
import { IAMService } from "businessLogic/services/iam";
import { AccountService } from "businessLogic/services/account";
import { saveAccountData } from "pages/Account/redux/actions";
import { loadSubscription } from "../subscription/actions";
import { getSelectedAccountId } from "./selectors";
import { fetchAppState } from "components/App/redux/actions";
import { rehydrateUserQueries } from "components/QueryEditor/redux/actions/queries";
import { clearSystemEngine } from "../../components/QueryEditor/redux/actions/systemEngine";
import { SYSTEM_ENGINE_ID } from "../../components/QueryEditor/redux/helpers";

export const ROLE_ACCOUNT_ADMIN_ID = "Admin";
export const ROLE_DATABASE_ADMIN_ID = "Editor";
export const ROLE_VIEWER_ID = "Viewer";

export function* loadUserDataSaga(action, skipAppStateLoading = false) {
  try {
    const isLogoutLoading = yield select(state => state.auth.isLogoutLoading);
    if (isLogoutLoading) {
      return;
    }
    let { user, accountMembers } = yield call(IAMService.getMyMemberships);
    const userData = {
      user,
      members: accountMembers,
    };

    if (!skipAppStateLoading) {
      yield put(fetchAppState());
    }

    yield put(putUserData(userData));

    if (accountMembers.length === 0) {
      yield put(incompleteSetup());
      return;
    }

    let accountId = accountMembers[0].accountId;

    // If user has multiple membership
    if (accountMembers.length > 1) {
      const selectedAccountId = yield select(getSelectedAccountId);

      if (!selectedAccountId) {
        // Stop data fetching until account will be selected
        return;
      }

      // Validate if persisted 'selectedAccountId' exists in members list
      // Needed in case when membership was removed
      if (
        !accountMembers.find(member => member.accountId === selectedAccountId)
      ) {
        yield put(putSelectedAccountId(null));
        return;
      }

      accountId = selectedAccountId;
    } else {
      yield put(removeSelectedAccountId());
    }

    const account = yield call(AccountService.getAccountDetails, accountId);

    yield put(loadSubscription(account.id, false));
    yield put(saveAccountData(account));

    // if user just logged in or selected account
    if (action?.payload?.login || action?.payload?.accountId) {
      const hash = crypto.createHash("sha256").update(user.id).digest("hex");

      try {
        const savedDrafts = window.localStorage.getItem(hash);
        if (savedDrafts) {
          const databasesQueries = JSON.parse(savedDrafts);
          yield put(rehydrateUserQueries(databasesQueries));
        }
      } catch (error) {
        console.log(error);
      }
    }

    const accessManager = action?.payload?.accessManager;

    const userId = atob(user.id).split(";")[1];

    if (accessManager && account.rbacPolicies) {
      const rbacPoliciesWithSystemEngine = [
        `p,/roles/00000000-0000-0000-0000-000000000000,${SYSTEM_ENGINE_ID},ACTION_ENGINE_USAGE`,
        `g,${userId},/roles/00000000-0000-0000-0000-000000000000`,
        ...account.rbacPolicies,
      ];

      yield call(
        accessManager.initAccessManager,
        user.id,
        rbacPoliciesWithSystemEngine.join("\n")
      );
    }

    yield put(loadUserDataSuccess(userData));
  } catch (err) {
    console.log(err);
    yield put(loadUserDataFailure(err.message || "errors.general"));
  }
}

export function* loadUserDataSuccessSaga(action) {
  const { user } = action.payload;

  yield call([Sentry, "configureScope"], (scope: Sentry.Scope) => {
    scope.setUser({ id: user.id });
  });

  if ((window as any).heap) {
    (window as any).heap.identify(user.id);
  }
}

function* putSelectedAccountIdSaga(action) {
  const { resolve } = action.payload;
  yield put(clearSystemEngine());
  yield loadUserDataSaga(action, true);

  resolve?.();
}

export function* UserSagas() {
  return yield all([
    takeLatest(UserActions.LOAD_USER_DATA, loadUserDataSaga),
    takeLatest(UserActions.PUT_SELECTED_ACCOUNT_ID, putSelectedAccountIdSaga),
    takeEvery(UserActions.LOAD_USER_DATA_SUCCESS, loadUserDataSuccessSaga),
  ]);
}
