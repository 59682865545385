export interface ResetPass {
  email?: string;
  emailSent?: boolean;
  errorMessage?: string;
  password: string;
  tokenId: string;
}

export enum AccountStatus {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  PendingProvisioning = "PENDING_PROVISIONING",
  ProvisioningFailed = "PROVISIONING_FAILED",
  ApprovalPending = "APPROVAL_PENDING",
  Deleted = "DELETED",
}

export enum TenantStatus {
  Unspecified = "UNSPECIFIED",
  Created = "CREATED",
  Creating = "CREATING",
  Failed = "FAILED",
  Active = "ACTIVE",
}

export interface AccountDetails {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  invitationToken?: string;
  status?: AccountStatus;
  DomainNames: string[];
  title?: string;
  tenantStatus?: TenantStatus;
  additionalInfo?: {
    company: string;
    jobTitle: string;
    phone: string;
  } | null;
  rbacPolicies?: string;
}

export type DatabasesList = any[];

export enum View {
  LIST = "LIST",
  CARD = "CARD",
}

/* remove after new login complete release */
export interface SessionData {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  userid: string;
}

export interface UserDetails {
  username: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}
