import graphql from "babel-plugin-relay/macro";

export const dbQuery = graphql`
  query DbQuery($dbId: ID!, $accountId: ID!) {
    database(id: $dbId) {
      id
      name
      emoji
      bindings {
        edges {
          node {
            id
            engineIsDefault
            engine {
              id
              name
              emoji
              computeRegion {
                id
                name
              }
              endpoint
              currentStatusSummary
              allowedActions(
                desiredActions: [
                  "core.engines.start"
                  "core.engines.stop"
                  "core.engines.update"
                  "core.engines.delete"
                ]
              )
              latestRevision {
                id
                specification {
                  dbComputeInstancesCount
                  dbComputeInstancesUseSpot
                  dbComputeInstancesType {
                    id
                    pricePerHourCents(accountId: $accountId)
                    name
                    cpuVirtualCoresCount
                    memorySizeBytes
                    storageSizeBytes
                  }
                }
              }
              settingsPreset
              settings {
                autoStopDelayDurationSeconds
                warmUpLevel
              }
              createActor {
                __typename
                ... on User {
                  fullName
                  displayName
                }
                ... on ServiceAccount {
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;
