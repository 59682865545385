import { Subscription } from "./actions";
import {
  AccountDetails,
  AccountStatus,
  TenantStatus,
} from "businessLogic/model";

export const isSubscriptionExpired = (subscription: Subscription): boolean =>
  subscription?.expiresTime && new Date(subscription.expiresTime) < new Date()
    ? true
    : false;

export const isAccountReadyForUse = (account: AccountDetails) => {
  return (
    account &&
    account.status === AccountStatus.Active &&
    account.tenantStatus !== TenantStatus.Failed
  );
};
