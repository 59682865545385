/**
 * @generated SignedSource<<14037871e8941b8f40e9a2454f912a36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DatabaseStatus = "UNSPECIFIED" | "CREATED" | "PROVISIONING_PENDING" | "PROVISIONING_STARTED" | "PROVISIONING_FINISHED" | "PROVISIONING_FAILED" | "RUNNING" | "TERMINATION_PENDING" | "TERMINATION_STARTED" | "TERMINATION_FINISHED" | "TERMINATION_FAILED" | "DELETED" | "%future added value";
export type Emoji = "UNSPECIFIED" | "KICK_SCOOTER" | "BULLET_TRAIN" | "ROCKET" | "TRACTOR" | "AUTOMOBILE" | "SPEEDBOAT" | "SAILBOAT" | "CANOE" | "LOCOMOTIVE" | "BICYCLE" | "DELIVERY_TRUCK" | "PASSENGER_SHIP" | "RACING_CAR" | "HELICOPTER" | "MINIBUS" | "POLICE_CAR" | "AERIAL_TRAMWAY" | "MOTOR_SCOOTER" | "ONCOMING_AUTOMOBILE" | "SMALL_AIRPLANE" | "GEM_STONE" | "CREDIT_CARD" | "MONEY_BAG" | "MOBILE_PHONE" | "BRIEFCASE" | "CROWN" | "GRADUATION_CAP" | "SKULL" | "JACK_O_LANTERN" | "SMILING_FACE_WITH_HORNS" | "FACE_SCREAMING_IN_FEAR" | "FIRE" | "ALIEN" | "ALIEN_MONSTER" | "ROBOT" | "MOVIE_CAMERA" | "%future added value";
export type DatabaseQuery$variables = {
  id: string;
};
export type DatabaseQuery$data = {
  readonly account: {
    readonly id: string;
    readonly databases: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly computeRegion: {
            readonly id: string;
            readonly name: string;
          };
          readonly description: string;
          readonly emoji: Emoji;
          readonly currentStatus: DatabaseStatus;
        };
      } | null> | null;
    };
  };
};
export type DatabaseQuery = {
  variables: DatabaseQuery$variables;
  response: DatabaseQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "account",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          }
        ],
        "concreteType": "DatabaseConnection",
        "kind": "LinkedField",
        "name": "databases",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DatabaseEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Database",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "computeRegion",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "databases(first:1000)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DatabaseQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DatabaseQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "dad04ba0a7cdf932ad7d4b4c74f86207",
    "id": null,
    "metadata": {},
    "name": "DatabaseQuery",
    "operationKind": "query",
    "text": "query DatabaseQuery(\n  $id: ID!\n) {\n  account(id: $id) {\n    id\n    databases(first: 1000) {\n      edges {\n        node {\n          id\n          name\n          computeRegion {\n            id\n            name\n          }\n          description\n          emoji\n          currentStatus\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44fa0ece4baa860f246fd1ab4637aa54";

export default node;
