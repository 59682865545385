import { useState, useCallback } from "react";
import { STEP_CHECK_EMAIL, STEP_REGISTER } from "./constants";
import CheckEmailPage from "./steps/Email";
import Register from "./steps/Register";
import styles from "../styles.module.scss";

interface Props {
  email?: string;
  invitationToken?: string;
  showExtraFields?: boolean;
}

const CreateAccount: React.FC<Props> = props => {
  const { email, invitationToken, showExtraFields = false } = props;
  const [activeStep, setActiveStep] = useState(STEP_REGISTER);

  const goToStep = useCallback((step: string) => {
    setActiveStep(step);
  }, []);

  return (
    <div className={styles.accountRoot}>
      {activeStep === STEP_REGISTER && (
        <Register
          email={email}
          invitationToken={invitationToken}
          showExtraFields={showExtraFields}
          goToStep={goToStep}
        />
      )}
      {activeStep === STEP_CHECK_EMAIL && <CheckEmailPage />}
    </div>
  );
};

export default CreateAccount;
