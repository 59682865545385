import * as _ from "lodash";
import { QUERY_SETTINGS } from "components/QueryEditor/redux/saga/constants";
import { isHybridEnabled } from "featureFlags/helpers";
import { isFirefoxBrowser } from "services/browser";
import { stripComments } from "./strip-comments";
import { canUseQueryHybridFlag } from "../Version";

export enum QUERY_OUTPUT {
  JSON = "JSON",
  JSON_COMPACT = "JSON_Compact",
  JSON_COMPACT_LIMITED = "JSON_CompactLimited",
  TAB_SEPARATED = "TabSeparated",
}

export async function stripQueryComments(query: string): Promise<string> {
  // Remove all block comments delimited by /* <comment> */
  // Remove inline comments delimited with --
  return stripComments(query);
}

export function splitQueries(query: string): any[] | { error: string } {
  /**
   * this regex will ignore ; sign if this is between following chars
   * 1) ""
   * 2) ''
   */
  // eslint-disable-next-line no-control-regex
  const expression =
    /([^;"']+(("((\\\\)|(\\")|[^"])*")|('((\\\\)|(\\')|[^'])*')|[^;'"]*)*);?/gm;
  let queryArray;

  try {
    queryArray = query.match(expression);
  } catch (e) {
    const chromeRangeError = e.name === "RangeError";
    const firefoxRangeError = isFirefoxBrowser() && e.name === "InternalError";

    if (chromeRangeError || firefoxRangeError) {
      return { error: "errors.editor_range" };
    } else {
      throw e;
    }
  }

  return _.chain(queryArray)
    .map(item => _.trim(item))
    .filter(item => !!item)
    .value();
}

export async function getQueryList(sqlText: string): Promise<any> {
  const query = await stripQueryComments(sqlText);
  return splitQueries(query);
}

export function formatQuery(query: string): string {
  // remove tail with "; "
  query = query.replace(/;\s*$/, "").trim();

  return query;
}

const QUERY_START_DEAD_CHARACTERS = [
  "(",
  "\n",
  "\r\n",
  "\r",
  "\t",
  "\u00a0",
  "\u0020",
];

function normalizeQuery(query: string): string {
  const upperCaseQuery = query.trim().toUpperCase();
  let startIndex = 0;
  while (QUERY_START_DEAD_CHARACTERS.includes(upperCaseQuery[startIndex])) {
    startIndex++;
  }
  return upperCaseQuery.substr(startIndex);
}

export function isDataQuery(query: string): boolean {
  if (!query) return false;

  const result = normalizeQuery(query);
  const isShow = result.startsWith("SHOW");
  const isDescribe = result.startsWith("DESCRIBE");
  const isExplain = result.startsWith("EXPLAIN");
  const isInsert = result.startsWith("INSERT");
  const isCall = result.startsWith("CALL");
  const isSelect = !isInsert && result.startsWith("SELECT");
  const isWith = !isInsert && result.startsWith("WITH");

  return isSelect || isWith || isExplain || isShow || isDescribe || isCall;
}

export const isExplainQuery = (query: string): boolean => {
  if (!query) return false;

  const result = normalizeQuery(query);
  return result.startsWith("EXPLAIN");
};

export function isSystemQuery(query: string): boolean {
  if (!query) return false;

  const result = normalizeQuery(query);
  return (
    result.startsWith("SHOW DATABASES") ||
    result.startsWith("SHOW ENGINES") ||
    result.startsWith("SHOW TABLES")
  );
}

export function isSetQuery(query: string): boolean {
  if (!query) return false;

  const result = normalizeQuery(query);
  return result.startsWith("SET");
}

const SEQUENCED_SPACES_AFTER_WORD = /(\S\u0020)(\u0020+)/gm;
const NON_BREAKING_SPACE = "\u00a0";
const FOUR_SPACES = "\u0020\u0020\u0020\u0020";

export const formatErrorForHtml = (error: string) => {
  try {
    error = error.replace(/\t/gm, FOUR_SPACES);
    return error;
  } catch (e) {
    return error;
  }
};

export const formatQueryForHtml = (query: string, expand: boolean) => {
  if (expand) {
    return query;
  }

  const lines = (query ?? "").split(/\r\n|\r|\n/).map(line => {
    return line.replace(SEQUENCED_SPACES_AFTER_WORD, (match, p1, p2) => {
      return p1 + p2.replace(/\u0020/g, NON_BREAKING_SPACE);
    });
  });
  return lines.join(" ");
};

export const getStatisticVisibility = ({
  query,
  scannedBytesStorage,
  scannedBytesCache,
}: {
  query?: string;
  scannedBytesStorage?: number;
  scannedBytesCache?: number;
}) => {
  let showRowCount = true;
  let showElapsedTime = true;
  let showBytesRead = true;
  let showRowsPerSec = true;
  let showQueryWarm = true;

  if (query) {
    query = normalizeQuery(query);
    if (query.startsWith("INSERT")) {
      showRowCount = false;
    }

    if (query.startsWith("DROP") || query.startsWith("CREATE")) {
      showRowCount = false;
      showBytesRead = false;
      showRowsPerSec = false;
    }
  }

  if (scannedBytesCache == null || scannedBytesStorage == null) {
    showQueryWarm = false;
  }

  return {
    showRowCount,
    showElapsedTime,
    showBytesRead,
    showRowsPerSec,
    showQueryWarm,
  };
};

export const canAffectEnginesList = queryText => {
  const result = normalizeQuery(queryText);
  return (
    result.startsWith("ATTACH ENGINE") || result.startsWith("DETACH ENGINE")
  );
};

export const queryShouldBeSync = queryText => {
  const result = normalizeQuery(queryText);
  return (
    result.startsWith("ALTER ENGINE") ||
    result.startsWith("ATTACH ENGINE") ||
    result.startsWith("CREATE ENGINE") ||
    result.startsWith("DETACH ENGINE") ||
    result.startsWith("DROP ENGINE") ||
    result.startsWith("SHOW CACHE") ||
    result.startsWith("SHOW ENGINES") ||
    result.startsWith("START ENGINE") ||
    result.startsWith("STOP ENGINE") ||
    result.startsWith("CREATE DATABASE") ||
    result.startsWith("DROP DATABASE")
  );
};

export function withOutputFormat(querySettings) {
  if (
    querySettings?.output_format === QUERY_OUTPUT.TAB_SEPARATED ||
    !querySettings?.output_format
  ) {
    return {
      ...querySettings,
      output_format: QUERY_OUTPUT.JSON_COMPACT_LIMITED,
    };
  }

  return querySettings;
}

function formatSettingsAsURLParams(querySettings) {
  return Object.keys(querySettings)
    .map(key => `${key}=${querySettings[key]}`)
    .join("&");
}

export const formatQuerySettingsForRegularEngine = ({
  querySettings: qSettings,
  engineProxyVersion,
  queryId,
  accountFlags,
}) => {
  const querySettings = withOutputFormat({ ...qSettings });

  if (
    isHybridEnabled(accountFlags) &&
    queryId &&
    !Number.isInteger(parseInt(querySettings[QUERY_SETTINGS.asyncExecution])) &&
    canUseQueryHybridFlag(engineProxyVersion)
  ) {
    querySettings[QUERY_SETTINGS.hybridMode] = "1";
  }

  if (queryId && canUseQueryHybridFlag(engineProxyVersion)) {
    querySettings[QUERY_SETTINGS.preserveResponseData] = "1";
  }

  const settings = formatSettingsAsURLParams(querySettings);

  return !!settings ? `&${settings}` : settings;
};

export const formatQuerySettingsForSystemEngine = ({
  querySettings: qSettings,
  database,
}) => {
  const querySettings = withOutputFormat({ ...qSettings });

  if (database && database !== "system") {
    querySettings.database = database;
  }

  const settings = formatSettingsAsURLParams(querySettings);

  return !!settings ? `?${settings}` : settings;
};
