const BANNER = {
  timestamp: 0, // to enable/disable the banner change the timestamp 0 means disabled
  title: "Firebolt scheduled maintenance - May 4/5th",
  content: `
Firebolt will undergo scheduled maintenance on May 4th/5th, depending on your time zone:

\u2022 Pacific time: May 4th, 9PM
\u2022 Eastern time: May 5th, 12AM
\u2022 Central European time: May 5th, 6AM

`,
  action: {
    content: "More information",
    url: "https://www.notion.so/Firebolt-scheduled-maintenance-May-4th-5th-e01627048854419aa323cf14c6227b4d",
  },
};

export const fetchLastReleaseNotes = async () => {
  try {
    return BANNER;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
