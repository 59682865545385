/**
 * @generated SignedSource<<a734eac4d9ebbb6f123297636f71810a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineStartInput = {
  id: string;
};
export type EngineStartMutation$variables = {
  input: EngineStartInput;
};
export type EngineStartMutation$data = {
  readonly engineStart: {
    readonly success: boolean;
    readonly engine: {
      readonly id: string;
      readonly currentStatusSummary: EngineStatusSummary;
      readonly endpoint: string | null;
      readonly latestRevision: {
        readonly id: string;
        readonly specification: {
          readonly dbComputeInstancesCount: number;
          readonly dbComputeInstancesUseSpot: boolean;
          readonly dbComputeInstancesType: {
            readonly id: string;
            readonly pricePerHourCents: number;
            readonly name: string;
          };
        };
      };
    };
  };
};
export type EngineStartMutation = {
  variables: EngineStartMutation$variables;
  response: EngineStartMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EngineStartPayload",
    "kind": "LinkedField",
    "name": "engineStart",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Engine",
        "kind": "LinkedField",
        "name": "engine",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentStatusSummary",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endpoint",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EngineRevision",
            "kind": "LinkedField",
            "name": "latestRevision",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EngineRevisionSpecification",
                "kind": "LinkedField",
                "name": "specification",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dbComputeInstancesCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dbComputeInstancesUseSpot",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstanceType",
                    "kind": "LinkedField",
                    "name": "dbComputeInstancesType",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pricePerHourCents",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngineStartMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngineStartMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5da8a1e83039644fef6ec9f4ffe62376",
    "id": null,
    "metadata": {},
    "name": "EngineStartMutation",
    "operationKind": "mutation",
    "text": "mutation EngineStartMutation(\n  $input: EngineStartInput!\n) {\n  engineStart(input: $input) {\n    success\n    engine {\n      id\n      currentStatusSummary\n      endpoint\n      latestRevision {\n        id\n        specification {\n          dbComputeInstancesCount\n          dbComputeInstancesUseSpot\n          dbComputeInstancesType {\n            id\n            pricePerHourCents\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd71e856afbe24fce77232fb9fa94b0f";

export default node;
