import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { UserSetupInput } from "relay/mutations/iam/__generated__/SetupUserMutation.graphql";

const mutation = graphql`
  mutation SetupUserMutation($input: UserSetupInput!) {
    userSetup(input: $input) {
      success
    }
  }
`;

function commit(environment, input: UserSetupInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
