import axios from "axios";
import JSONbig from "json-bigint";
import { EngineClient } from "businessLogic/api";
import {
  formatQuery,
  formatQuerySettingsForRegularEngine,
  formatQuerySettingsForSystemEngine,
} from "businessLogic/helpers/Query";
import {
  Version,
  canUseNewWSSHeaders,
  canUseQueryHybridFlag,
} from "businessLogic/helpers/Version";
import { AccountFlags } from "components/QueryEditor/redux/types";
import { isResponseValid, normalizeResponse } from "./helpers";
import { QueryResponse, AsyncQueryResponse } from "./types";
import { runningQueries } from "./runningQueries";

const CancelToken = axios.CancelToken;

export const executeQuery = async ({
  query,
  database,
  queryId,
  engineEndpoint,
  querySettings,
  browserTabId,
  engineProxyVersion,
  accountFlags,
  engine,
}: {
  query: string;
  database: string;
  queryId: string | null;
  engineEndpoint: string;
  querySettings: any;
  browserTabId?: string;
  engineProxyVersion?: Version;
  accountFlags?: AccountFlags;
  engine?: any;
}): Promise<QueryResponse & AsyncQueryResponse> => {
  const engineClient = EngineClient(engineEndpoint);

  const settings = engine?.isSystem
    ? formatQuerySettingsForSystemEngine({ querySettings, database })
    : formatQuerySettingsForRegularEngine({
        querySettings,
        engineProxyVersion,
        queryId,
        accountFlags,
      });

  const url = engine?.isSystem
    ? `/query${settings}`
    : `/?database=${database}${settings}`;

  const { data: response, status: responseStatusCode } =
    await engineClient.post(url, formatQuery(query), {
      cancelToken: new CancelToken(function executor(c) {
        if (queryId) {
          // An executor function receives a cancel function as a parameter
          runningQueries[queryId] = c;
        }
      }),
      transformResponse: [
        function (data) {
          if (typeof data === "string") {
            try {
              data = JSONbig.parse(data);
            } catch (e) {
              console.error(e);
            }
          }
          return data;
        },
      ],
      headers:
        queryId && canUseNewWSSHeaders(engineProxyVersion as Version)
          ? {
              "F-Wss-Client": browserTabId,
              "F-Wss-Script-Tab": queryId,
            }
          : {},
    });
  if (queryId) {
    delete runningQueries[queryId];
    const validResponse = isResponseValid(query, response);

    if (!validResponse) {
      // eslint-disable-next-line
      throw {
        request: { response: "Query failed - internal execution error" },
      };
    }
  }

  return normalizeResponse({ response, querySettings, responseStatusCode });
};

export const getAsyncQueryResult = async ({
  dbName,
  queryId,
  engineEndpoint,
  querySettings,
  engineProxyVersion,
  sqlStatement,
}) => {
  if (!canUseQueryHybridFlag(engineProxyVersion)) return null;

  const engineClient = EngineClient(engineEndpoint);
  const { data: response, status: responseStatusCode } = await engineClient.get(
    `/response?database=${dbName}&query_id=${queryId}`
  );

  const validResponse = isResponseValid(sqlStatement, response);

  if (!validResponse) {
    // eslint-disable-next-line
    throw {
      request: {
        response: response.length
          ? response
          : "Query failed - internal execution error",
      },
    };
  }

  return normalizeResponse({ response, querySettings, responseStatusCode });
};
