import { Costs, CostsActions } from "./actions";
import { Action, handleActions } from "redux-actions";

export interface CostsState {
  costs: Costs | null;
  errorMessage: string | null;
  isLoading: boolean;
}

export const INITIAL_STATE: CostsState = {
  costs: null,
  errorMessage: null,
  isLoading: false
};

const reducerMap = {};

reducerMap[CostsActions.LOAD_COSTS] = (state: CostsState): CostsState => {
  return { ...state, errorMessage: null, isLoading: true };
};

reducerMap[CostsActions.LOAD_COSTS_SUCCESS] = (
  state: CostsState,
  action: Action<Costs>
): CostsState => {
  return { costs: action.payload, errorMessage: null, isLoading: false };
};

reducerMap[CostsActions.LOAD_COSTS_FAILURE] = (
  state: CostsState,
  action: Action<string>
): CostsState => {
  return { ...state, errorMessage: action.payload, isLoading: false };
};

export default handleActions<CostsState, any>(reducerMap, INITIAL_STATE);
