import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { CostsActions, loadCostsFailure, loadCostsSuccess } from "./actions";
import { AWSService } from "businessLogic/services/aws";
import { selectUserAccountId } from "../user/selectors";
import {
  putStatusMessage,
  StatusMessageType
} from "components/StatusMessage/redux/actions";

export function* loadCostsSaga() {
  try {
    const accountId = yield select(selectUserAccountId);

    const response = yield call(AWSService.getCosts, accountId);
    const costs = response?.account?.awsMonthToDateCosts;

    yield put(loadCostsSuccess(costs));
  } catch (err) {
    yield put(
      putStatusMessage(err.message || "errors.general", StatusMessageType.Error)
    );
    yield put(loadCostsFailure(err.message || "errors.general"));
  }
}

export function* CostsSagas() {
  return yield all([takeEvery(CostsActions.LOAD_COSTS, loadCostsSaga)]);
}
