import { all, put, select, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { Action } from "redux-actions";
import { executeQuery } from "businessLogic/services/query/execute";
import * as QueryActions from "../actions/queries";
import { getDBEndpoint } from "./getDBEndpoint";
import {
  putStatusMessage,
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import { resultTab } from "services/subject/resultTabs";
import { withQuerySettingsForExecute } from "./helpers";
import { QUERY_SETTINGS } from "./constants";

export function* executeExplain(
  action: Action<{
    database: string;
    explainType: string;
    queryStatementId: string;
    isLQP2?: boolean;
  }>
) {
  const { database, queryStatementId, explainType, isLQP2 } = action.payload;

  const queryId = yield select(state => {
    return state.query.queries.databasesQueries?.[database]?.currentQueryTabId;
  });

  const queryState = yield select(state => {
    return _.get(
      state,
      `query.queries.databasesQueries[${database}].queriesById[${queryId}]`
    );
  });

  const { multiQueryStatement, settings, engineId } = queryState;
  const queryStatement = multiQueryStatement[queryStatementId];

  const { query } = queryStatement;

  const explainPrefix =
    explainType === "text" ? "explain " : "explain using json ";

  const queryWithExplain = query
    .trim()
    .replace(/^explain\s*(using\s*json)?|^/i, explainPrefix);

  const isExplainQuery = query.trim().match(/^explain/i);
  const isExplainJsonQuery = query.trim().match(/^explain\s+using\s+json/i);

  setTimeout(() => {
    resultTab.notify({
      type: "focus",
      queryStatementId,
    });
  }, 0);

  try {
    const result = yield select(state => {
      return _.get(state, [
        "query",
        "queries",
        "queryResultInfo",
        database,
        queryId,
        "results",
        queryStatementId,
      ]);
    });

    const getCachedExplain = () => {
      const { list, diagram } = result?.explain || {};

      if (explainType === "text" && isExplainQuery && !isExplainJsonQuery) {
        return result;
      }

      if (explainType === "diagram" || explainType === "list") {
        const isLQP2 = result?.data?.[0]?.output_lqp === "";
        return diagram || list || (isExplainJsonQuery && !isLQP2 && result);
      }

      return result?.explain?.[explainType];
    };

    const cachedExplain = getCachedExplain();
    let explain = cachedExplain;

    if (!cachedExplain) {
      const engineEndpoint = yield getDBEndpoint(database, queryId);
      if (!engineEndpoint) {
        throw new Error("no engine running");
      }
      const querySettings = yield withQuerySettingsForExecute({
        settings,
        engineId,
      });
      explain = yield executeQuery({
        query: queryWithExplain,
        database,
        queryId,
        engineEndpoint,
        querySettings: {
          ...querySettings,
          [QUERY_SETTINGS.asyncExecution]: "0",
        },
      });

      if (explain?.data?.[0]?.output_lqp === "") {
        yield put({
          type: QueryActions.EXECUTE_QUERY_EXPLAIN_REQUEST,
          payload: {
            database,
            queryStatementId,
            explainType: "text",
            isLQP2: true,
          },
        });
        return;
      }
    }

    yield put({
      type: QueryActions.EXECUTE_QUERY_EXPLAIN_SUCCESS,
      payload: {
        database,
        queryStatementId,
        explainType,
        explain,
        isLQP2,
      },
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: QueryActions.EXECUTE_QUERY_EXPLAIN_FAILURE,
      payload: {
        explainType,
        database,
        queryStatementId,
      },
    });
    yield put(
      putStatusMessage(
        "There was a problem running explain query.",
        StatusMessageType.Error,
        {
          id: null,
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: false,
        }
      )
    );
  }
}

export function* ExplainSagas() {
  return yield all([
    takeEvery(QueryActions.EXECUTE_QUERY_EXPLAIN_REQUEST, executeExplain),
  ]);
}
