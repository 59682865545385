import { Action } from "redux-actions";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { DatabaseService } from "businessLogic/services/database";
import {
  DatabaseActions,
  EngineUpdateParams,
  saveDatabase,
  setDbError,
  updateEngineSuccess,
} from "pages/Database/redux/actions";
import { selectUserAccountId } from "redux/user/selectors";
import { closeTabs } from "components/QueryEditor/redux/actions/queries";
import {
  removeTables,
  UnselectTable,
} from "components/QueryEditor/redux/actions/tables";
import { removeViews } from "components/QueryEditor/redux/actions/views";
import { DatabaseCurrentStatus } from "../databaseHelpers";

function* loadDatabaseListAsync(action: Action<{ enableVirtualDb?: boolean }>) {
  const { enableVirtualDb } = action.payload;

  try {
    const accountId = yield select(selectUserAccountId);
    const data = yield DatabaseService.listDatabases(accountId);

    const databases = [...data];

    if (enableVirtualDb) {
      const virtualDbId = btoa(
        `Database;/accounts/${accountId}/databases/00000000-0000-0000-0000-000000000000`
      );

      const virtualDb = {
        id: virtualDbId,
        name: "system",
        title: "System Workspace",
        emoji: "⚙️️️️",
        description: "",
        engines: [],
        isVirtual: true,
      };

      databases.push(virtualDb);
    }

    yield put(saveDatabase(databases));
  } catch (err) {
    yield put(setDbError(err.message));
    console.error("Failed to load database list:", err);
  }
}

function* releaseUiStateFlow(
  action: Action<{ id: string; currentStatus: string; name: string }>
) {
  /*
  if we use `deleted` status this flow is unreachable
  when application is closed before receiving `deleted` status
    */
  if (
    action.payload.currentStatus !== DatabaseCurrentStatus.TERMINATION_STARTED
  ) {
    return;
  }
  const name = action.payload.name;
  try {
    yield put(closeTabs(name));
    yield put(removeTables(name));
    yield put(UnselectTable(name));
    yield put(removeViews(name));
  } catch {
    // some clients do not have this state
    return;
  }
}

function* updateEngineSaga(action: Action<EngineUpdateParams>) {
  const { databaseId, engine } = action.payload;

  yield put(updateEngineSuccess({ databaseId, engine }));
}

export function* DatabaseSagas() {
  return yield all([
    takeEvery(DatabaseActions.LOAD_DATABASES, loadDatabaseListAsync),
    takeEvery(
      DatabaseActions.LOAD_DATABASES_IN_BACKGROUND,
      loadDatabaseListAsync
    ),
    takeEvery(DatabaseActions.UPDATE_STATUS, releaseUiStateFlow),
    takeEvery(DatabaseActions.UPDATE_ENGINE, updateEngineSaga),
  ]);
}
