import {
  refreshToken as refreshTokenMutation,
  getRefreshToken,
} from "businessLogic/services/auth/refreshToken";
import { SessionData } from "businessLogic/model";
import { logoutSuccess, setSession } from "pages/Auth/redux/actions";
import { authService } from "services/auth";
import environment from "./environment";
import { history } from "index";

export const refreshToken = async store => {
  try {
    const response = await refreshTokenMutation(store, environment);
    const { authRefreshToken } = response;
    const { accessToken } = authRefreshToken;
    const refreshToken = getRefreshToken(store);
    const sessionData: SessionData = {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: new Date(
        response.authRefreshToken.expiresAt as string
      ).getMinutes(),
      token_type: "",
      userid: "",
    };
    store.dispatch(setSession(sessionData));
    return accessToken;
  } catch (error) {
    console.error("failed to refresh token", error.message);
    const loginType = authService.getLoginType();
    store.dispatch(logoutSuccess(loginType));
    history.push("/");
  }
};
