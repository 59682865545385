import { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

export const useInitLD = account => {
  const client = useLDClient();

  useEffect(() => {
    if (account && account.id) {
      const accountId = atob(account.id).split("/").pop();
      const createDate = new Date(account.createTime).getTime();

      if (accountId) {
        client?.identify({
          key: accountId,
          name: account.name,
          custom: {
            create_date: createDate,
          },
        });
      }
    }
  }, [account, client]);
};
