import { fetchQuery } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";
import { DatabaseDetailsQuery } from "./__generated__/DatabaseDetailsQuery.graphql";
import { DatabaseQuery } from "./__generated__/DatabaseQuery.graphql";

export const databaseStatusQuery = graphql`
  query DatabaseStatusQuery($id: ID!) {
    database(id: $id) {
      id
      currentStatus
    }
  }
`;

export const databaseDetailsQuery = graphql`
  query DatabaseDetailsQuery($id: ID!, $accountId: ID!) {
    database(id: $id) {
      id
      name
      computeRegion {
        id
        name
      }
      description
      emoji
      currentStatus
      bindings(first: 1000) {
        edges {
          node {
            id
            engineIsDefault
            engine {
              id
              name
              emoji
              endpoint
              computeRegion {
                id
                name
              }
              currentStatusSummary
              allowedActions(
                desiredActions: [
                  "core.engines.start"
                  "core.engines.stop"
                  "core.engines.update"
                  "core.engines.delete"
                ]
              )
              latestRevision {
                id
                currentStatus
                specification {
                  dbComputeInstancesCount
                  dbComputeInstancesUseSpot
                  dbComputeInstancesType {
                    id
                    pricePerHourCents(accountId: $accountId)
                    costPerHour: pricePerHourCents(accountId: $accountId)
                    name
                    cpuVirtualCoresCount
                    memorySizeBytes
                    storageSizeBytes
                  }
                }
              }
              settingsPreset
              settings {
                autoStopDelayDurationSeconds
                warmUpLevel
              }
              createActor {
                __typename
                ... on User {
                  fullName
                  displayName
                }
                ... on ServiceAccount {
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const fetchDatabaseDetails = (environment, params) => {
  return fetchQuery<DatabaseDetailsQuery>(
    environment,
    databaseDetailsQuery,
    params
  ).toPromise();
};

export const fetchDatabases = (environment, params) => {
  return fetchQuery<DatabaseQuery>(
    environment,
    graphql`
      query DatabaseQuery($id: ID!) {
        account(id: $id) {
          id
          databases(first: 1000) {
            edges {
              node {
                id
                name
                computeRegion {
                  id
                  name
                }
                description
                emoji
                currentStatus
              }
            }
          }
        }
      }
    `,
    params
  ).toPromise();
};
