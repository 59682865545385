import React from "react";
import styles from "./SnackMessage.module.scss";

import { ReactComponent as ErrorIcon } from "assets/icons/Exclamation.svg";
import { ReactComponent as SucessIcon } from "assets/icons/SucessIcon.svg";
import { ReactComponent as InfoIcon } from "assets/icons/InfoIcon.svg";

import cn from "classnames";

export enum SnackMessageType {
  Error = "error",
  Success = "success",
  Info = "info",
}

interface ISnackMessageProps {
  type: SnackMessageType;
  message: string | React.ReactNode;
  preformatted?: boolean;
  maxHeight?: number;
  scrollableContent?: boolean;
  testId?: string;
}

const renderIcon = type => {
  if (type === SnackMessageType.Error) {
    return <ErrorIcon className={styles.icon} />;
  }

  if (type === SnackMessageType.Success) {
    return <SucessIcon className={styles.icon} />;
  }

  return <InfoIcon className={styles.icon} />;
};

const SnackMessage = ({
  message,
  type,
  preformatted,
  maxHeight,
  scrollableContent = false,
  testId,
}: ISnackMessageProps) => {
  return (
    <div
      className={cn(styles.msgBox, {
        [styles.errBox]: type === SnackMessageType.Error,
        [styles.successBox]: type === SnackMessageType.Success,
      })}
      data-testid={testId}
    >
      {message && preformatted ? (
        <div
          className={styles.messageTextWrap}
          style={{
            maxHeight: maxHeight ? maxHeight + "px" : "none",
            overflow: scrollableContent ? "auto" : "hidden",
          }}
        >
          {renderIcon(type)}
          <span className={styles.textMessage}>{message}</span>
        </div>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

SnackMessage.defaultProps = {
  preformatted: true,
};

export default SnackMessage;
