import {
  AccountActions,
  registerAccountSuccess,
  saveAccountData,
  setAccountError,
} from "./actions";
import { Action } from "redux-actions";
import { all, put, takeEvery, select, call } from "redux-saga/effects";
import { AccountDetails } from "businessLogic/model";
import { AccountService } from "businessLogic/services/account";
import { IAMService } from "businessLogic/services/iam";
import { selectUserAccountId } from "redux/user/selectors";

function* registerAsync(
  action: Action<{
    accountDetails: AccountDetails;
    recaptcha: string;
    awsLinkingToken?: string;
  }>
) {
  try {
    const { accountDetails, recaptcha, awsLinkingToken } = action.payload;

    if (action.type === AccountActions.REGISTER_FROM_AWS) {
      yield IAMService.createUserFromAws(
        accountDetails,
        awsLinkingToken,
        recaptcha
      );
    } else {
      if (accountDetails.invitationToken) {
        yield IAMService.submitUser(
          accountDetails,
          accountDetails.invitationToken,
          recaptcha
        );
      } else {
        yield IAMService.createUser(accountDetails, recaptcha);
      }
    }

    yield put(registerAccountSuccess());
  } catch (err) {
    console.error("Failed to register:", err);
    yield put(setAccountError(err.message || "login.invalid_register_attempt"));
  }
}
export function* getAccountDetailsSaga() {
  try {
    const accountId = yield select(selectUserAccountId);
    const accountDetails = yield call(
      AccountService.getAccountDetails,
      accountId
    );

    if ((window as any).heap) {
      (window as any).heap?.addUserProperties?.({
        accountId: accountDetails.id,
      });
    }

    yield put(saveAccountData(accountDetails));
    return accountDetails;
  } catch (err) {
    console.error("Failed to get account details:", err);
    throw new Error("Failed to get account details");
  }
}

export function* AccountSagas() {
  return yield all([
    takeEvery(AccountActions.REGISTER, registerAsync),
    takeEvery(AccountActions.REGISTER_FROM_AWS, registerAsync),
    takeEvery(AccountActions.GET_ACCOUNT_DETAILS, getAccountDetailsSaga),
  ]);
}
