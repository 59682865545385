import { Action, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import * as _ from "lodash";
import {
  ABORT_FORMAT,
  CancelableAction,
  END_FORMAT,
  START_FORMAT,
  UPDATE_FORMATTING_TIME,
} from "../actions/scriptActions";

const reducerMap = {};
export interface ScriptActionsState {
  actions: {
    [id: string]: CancelableAction;
  };
}

export const INITIAL_STATE: ScriptActionsState = {
  actions: {},
};

export const formatSelector = getEditorId =>
  createSelector(
    state => _.get(state, "query.scriptActions.actions", {}),
    r => {
      const id = getEditorId();
      return {
        ...(r[id] || {}),
      };
    }
  );

const getTimeDiff = (
  state,
  id
): Pick<CancelableAction, "elapsedTime" | "doneAt"> => {
  const doneAt = new Date().getTime();
  const startedAt = _.get(state, `actions.${id}.startedAt`, 0);

  return {
    doneAt,
    elapsedTime: doneAt - startedAt,
  };
};

reducerMap[START_FORMAT] = (
  state: ScriptActionsState,
  action: Action<CancelableAction>
) => {
  const { id, predictedTime } = action.payload;
  const formatAction = state.actions[id] || {
    id,
  };

  const newState = {
    [id]: {
      ...formatAction,
      aborted: false,
      running: true,
      startedAt: new Date().getTime(),
      elapsedTime: 0,
      predictedTime,
    },
  };

  return {
    actions: {
      ...state.actions,
      ...newState,
    },
  };
};

reducerMap[UPDATE_FORMATTING_TIME] = (
  state: ScriptActionsState,
  action: Action<CancelableAction>
) => {
  const { id } = action.payload;
  const formatAction = state.actions[id] || {
    id,
  };
  const { elapsedTime } = getTimeDiff(state, id);

  const newState = {
    [id]: {
      ...formatAction,
      elapsedTime,
    },
  };

  return {
    actions: {
      ...state.actions,
      ...newState,
    },
  };
};

reducerMap[ABORT_FORMAT] = (
  state: ScriptActionsState,
  action: Action<CancelableAction>
) => {
  const { id } = action.payload;
  const formatAction = state.actions[id] || {
    id,
  };

  const newState = {
    [id]: {
      ...formatAction,
      aborted: true,
      running: false,
      elapsedTime: 0,
      doneAt: new Date().getTime(),
    },
  };

  return {
    actions: {
      ...state.actions,
      ...newState,
    },
  };
};
reducerMap[END_FORMAT] = (
  state: ScriptActionsState,
  action: Action<CancelableAction>
) => {
  const { id } = action.payload;
  const formatAction = state.actions[id] || {
    id,
  };

  const newState = {
    [id]: {
      ...formatAction,
      aborted: false,
      running: false,
      ...getTimeDiff(state, id), // for checking execution time
    },
  };

  return {
    actions: {
      ...state.actions,
      ...newState,
    },
  };
};

export default handleActions<ScriptActionsState, any>(
  reducerMap,
  INITIAL_STATE
);
