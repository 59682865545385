export const getUsedTablesFromExplain = explain => {
  const tables: { name: string }[] = [];

  for (const step of explain.steps) {
    if (step.operation.table) {
      tables.push(step.operation.table);
    }

    const walk = children => {
      for (const step of children) {
        if (step.operation.table) {
          tables.push(step.operation.table);
        }
        if (Array.isArray(step.children)) {
          walk(step.children);
        }
      }
    };

    if (Array.isArray(step.children)) {
      walk(step.children);
    }
  }

  const tableNames = tables.map(({ name }) => name);
  return Array.from(new Set(tableNames));
};
