import { statusCodes } from "businessLogic/constants";
import { store } from "store";
import { getAccessToken } from "businessLogic/services/auth/getAccessToken";

const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT_URL as string;
const GRAPHQL_PATH = "/graphql/";
const LOGIN_PATH = "/ui/auth/login";
const LOGOUT_PATH = "/ui/auth/logout";
const TOKEN_PATH = "/ui/auth/token";

class AuthService {
  token: string;

  response: any;

  tokenPromise: Promise<string | null>;

  bc?: BroadcastChannel;

  constructor() {
    if (global.BroadcastChannel) {
      this.bc = new BroadcastChannel("logout_channel");

      this.bc.onmessage = event => {
        const { data } = event;
        if (data && data.type === "logout") {
          this.redirectToLogout(true);
        }
      };
    }
  }

  redirectToLogout(terminated = false, redirectQuery = "") {
    const url = endpoint.replace(GRAPHQL_PATH, LOGOUT_PATH);
    const redirectUrl = `${window.location.protocol}//${window.location.host}/login${redirectQuery}`;
    if (!terminated) {
      this.broadcastOtherTabs();
    }
    window.location.assign(`${url}?returnTo=${redirectUrl}`);
  }

  broadcastOtherTabs() {
    if (this.bc) {
      this.bc.postMessage({
        type: "logout",
      });
    }
  }

  redirectToLogin(redirectPath = window.location.pathname, email = "") {
    const url = endpoint.replace(GRAPHQL_PATH, LOGIN_PATH);
    const redirectUrl = `${window.location.protocol}//${window.location.host}${redirectPath}`;
    const params = new URLSearchParams();
    params.set("returnTo", redirectUrl);
    if (email) {
      params.set("email", email);
    }

    const location = `${url}?${params}`;

    window.location.assign(location);
  }

  getLoginType() {
    try {
      const loginType = localStorage.getItem("loginType") || "classic";
      return loginType;
    } catch (error) {
      console.log(error);
      return "classic";
    }
  }

  removeLoginType() {
    try {
      localStorage.removeItem("loginType");
    } catch (error) {
      console.log(error);
    }
  }

  setLoginType(loginType) {
    try {
      localStorage.setItem("loginType", loginType);
    } catch (error) {
      console.log(error);
    }
  }

  async getToken() {
    const localStorageToken = getAccessToken(store);
    if (localStorageToken) {
      return localStorageToken;
    }
    if (this.token) {
      return this.token;
    }
    if (this.tokenPromise) {
      return this.tokenPromise;
    }
    const initiate = async () => {
      const url = endpoint.replace(GRAPHQL_PATH, TOKEN_PATH);
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response && response.status === statusCodes.UNAUTHORIZED) {
        this.redirectToLogout();
        return null;
      }
      if (!response) {
        return null;
      }
      const json = await response.json();

      const { access_token } = json;
      this.token = access_token;
      return this.token;
    };
    this.tokenPromise = initiate();
    return this.tokenPromise;
  }
}

export const authService = new AuthService();
