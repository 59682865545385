import { all, take, delay, race, takeEvery, put } from "redux-saga/effects";
import * as ScriptActions from "../actions/scriptActions";
import { Action } from "redux-actions";
import { CancelableAction, updateTime } from "../actions/scriptActions";

export const FORMATTING_TIME_THRESHOLD_MS = 2 * 1000; // 2 sec

export function* updateExecutionTime(action: Action<CancelableAction>) {
  const { timeout, endFormat, abortFormat } = yield race({
    timeout: delay(FORMATTING_TIME_THRESHOLD_MS),
    endFormat: take(ScriptActions.END_FORMAT),
    abortFormat: take(ScriptActions.ABORT_FORMAT),
  });

  if (endFormat || abortFormat) {
    return;
  }

  if (timeout) {
    yield put(updateTime({ id: action.payload.id }));
  }
}

export function* ScriptFormattingSagas() {
  return yield all([
    takeEvery(ScriptActions.START_FORMAT, updateExecutionTime),
  ]);
}
