import React from "react";
import SearchInput from "components/SearchInput/SearchInput";
import { SearchOptions } from "./types";
import styles from "./Select.module.scss";

export const Search = React.forwardRef<
  HTMLDivElement,
  {
    handleChange: any;
    searchOptions?: SearchOptions;
    children: React.ReactElement | null;
  }
>((props, ref) => {
  const { searchOptions, handleChange, children } = props;

  const handleKeydown = event => {
    if (event.key === "ArrowDown") {
      return;
    }
    if (event.key === "PageUp" || event.key === "PageDown") {
      event.preventDefault();
    }
    event.stopPropagation();
  };

  const placeholder = searchOptions?.searchPlaceholder || "";

  return (
    <div
      key="search"
      className={styles.searchBoxContainer}
      data-testid="aqa-searchbox-container"
      ref={ref}
    >
      <SearchInput
        testId="aqa-searchbox-input"
        autoFocus
        onChange={handleChange}
        placeholder={placeholder}
        handleKeyDown={handleKeydown}
        defaultValue=""
      />
      {children}
    </div>
  );
});
