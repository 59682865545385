import { useEffect, useState, useRef, useCallback } from "react";
import { fetchLastReleaseNotes } from "./fetchNotes";
import { REFETCH_TIMEOUT } from "./constants";

type ReleaseNotesData = {
  timestamp: number;
  content: string;
  title?: string;
  action?: {
    content?: string;
  };
};

export const useReleaseNotes = ({
  lastSeenReleaseDate,
  refetchTimeout = REFETCH_TIMEOUT
}: {
  lastSeenReleaseDate: number;
  refetchTimeout?: number;
}) => {
  const loading = useRef(false);
  const [data, setData] = useState<ReleaseNotesData | null>(null);
  const [error, setError] = useState(null);

  const fetchNotes = useCallback(async () => {
    if (loading.current) {
      return;
    }
    try {
      loading.current = true;
      const data = await fetchLastReleaseNotes();
      const { timestamp } = data;
      if (
        !timestamp ||
        (lastSeenReleaseDate && lastSeenReleaseDate > +timestamp)
      ) {
        setData(null);
      } else {
        setData(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      loading.current = false;
    }
  }, [lastSeenReleaseDate]);

  useEffect(() => {
    fetchNotes();
    const interval = setInterval(() => {
      fetchNotes();
    }, refetchTimeout);
    return () => {
      clearInterval(interval);
    };
  }, [lastSeenReleaseDate, fetchNotes, refetchTimeout]);

  return {
    data,
    error
  };
};
