export const AQA_SQLWORKSPACE = "sqlworkspace-menu";
export const AQA_DATABASE = "database-menu";
export const AQA_USER = "user-management-menu";
export const AQA_ENGINE = "engine-menu";
export const AQA_LINK_HEADER = "header-logo";

export const AQA_DOCUMENTATION_USER = "documentation-link";
export const AQA_FIREBOLT_STATUS_PAGE = "status-page-link";
export const AQA_RELEASE_NOTES = "release-notes-link";
export const AQA_ACCOUNT_BILLING_LINK = "account-billing-link";
export const AQA_LOGOUT_LINK = "logout-link";
export const AQA_SWITCH_ACCOUNT_LINK = "switch-account-link";
export const AQA_AVATAR_NAME = "avatar-name";
export const AQA_CONTACT_SUPPORT = "contact-support-link";
export const AQA_HELP_ICON = "help-icon";
