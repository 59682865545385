import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

interface LoaderProps {
  btnLoader?: boolean;
}
const Balloon = ({ btnLoader, className }) => (
  <div
    className={cn(styles.ballLoaderBall, className, {
      [styles.smallBall]: btnLoader,
      [styles.defaultBall]: !btnLoader
    })}
  />
);

const EllipsisLoader: React.FC<LoaderProps> = props => {
  const { btnLoader } = props;
  return (
    <div
      className={`${styles.ballLoader} ${
        btnLoader ? styles.smallLoader : styles.defaultLoader
      }`}
    >
      <Balloon btnLoader={btnLoader} className={styles.ball1} />
      <Balloon btnLoader={btnLoader} className={styles.ball2} />
      <Balloon btnLoader={btnLoader} className={styles.ball3} />
    </div>
  );
};

export default EllipsisLoader;
