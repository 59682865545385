import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { SetIPAllowDenyListsInput } from "relay/mutations/iam/__generated__/SetIpAllowDenyListsMutation.graphql";

const mutation = graphql`
  mutation SetIpAllowDenyListsMutation($input: SetIPAllowDenyListsInput!) {
    setIpAllowDenyLists(input: $input) {
      success
    }
  }
`;

function commit(
  environment,
  input: SetIPAllowDenyListsInput,
  onCompleted,
  onError,
  updater
) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input,
    },
    updater,
  });
}

export default { commit };
