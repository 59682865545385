import React from "react";
import cn from "classnames";
import { SUPPORT_EMAIL } from "businessLogic/constants";
import ErrorScreen, {
  ErrorScreenImagePosition,
} from "components/ErrorScreen/ErrorScreen";
import MaterialButton, {
  ButtonSize,
} from "components/MaterialButton/MaterialButton";
import Compass from "./Compass";
import buttonStyles from "styles/buttons.module.scss";
import styles from "./styles.module.scss";

const AppCrash = () => {
  return (
    <ErrorScreen
      title="Something weird happened"
      image={<Compass />}
      imagePosition={ErrorScreenImagePosition.Top}
      classes={{
        image: cn(styles.image, styles.forced, styles.forced2),
        footer: cn(styles.footer, styles.forced),
      }}
      description={
        <>
          Contact&nbsp;
          <a href={`mailto:${SUPPORT_EMAIL}`} className={buttonStyles.btn_link}>
            {SUPPORT_EMAIL}
          </a>
          &nbsp;if it happens again
        </>
      }
      footer={
        <div>
          <MaterialButton
            onClick={() => window.location.reload()}
            text="Reload the Page"
            size={ButtonSize.Medium}
          />
        </div>
      }
    />
  );
};

export default AppCrash;
