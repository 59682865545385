export enum QueryStatus {
  Running = "running",
  ExplainRunning = "explainRunning",
  Success = "success",
  Failed = "failed",
  Canceled = "canceled",
}

export enum ServerQueryStatusesV1 {
  RUNNING = "QueryStart",
  FINISHED = "QueryFinish",
  CANCELED = "ExceptionWhileProcessing",
  EXCEPTION_BEFORE_START = "ExceptionBeforeStart",
}

export enum ServerQueryStatusesV2 {
  RUNNING = "STARTED_EXECUTION",
  FINISHED = "ENDED_SUCCESSFULLY",
  CANCELED = "CANCELED_EXECUTION",
  PARSE_ERROR = "PARSE_ERROR",
  EXECUTION_ERROR = "EXECUTION_ERROR",
}

export enum ClientSideQueryStatuses {
  RUNNING = "CLIENT_STATUS/QUERY_RUNNING",
  FINISHED = "CLIENT_STATUS/QUERY_FINISHED",
  CANCELED = "CLIENT_STATUS/QUERY_CANCELED",
  FAILED = "CLIENT_STATUS/QUERY_FAILED",
}

export const ServerQueryStatusesFormatted = {
  [ServerQueryStatusesV1.RUNNING]: ClientSideQueryStatuses.RUNNING,
  [ServerQueryStatusesV2.RUNNING]: ClientSideQueryStatuses.RUNNING,
  [ServerQueryStatusesV1.FINISHED]: ClientSideQueryStatuses.FINISHED,
  [ServerQueryStatusesV2.FINISHED]: ClientSideQueryStatuses.FINISHED,
  [ServerQueryStatusesV1.CANCELED]: ClientSideQueryStatuses.CANCELED,
  [ServerQueryStatusesV2.CANCELED]: ClientSideQueryStatuses.CANCELED,
  [ServerQueryStatusesV1.EXCEPTION_BEFORE_START]:
    ClientSideQueryStatuses.FAILED,
  [ServerQueryStatusesV2.PARSE_ERROR]: ClientSideQueryStatuses.FAILED,
  [ServerQueryStatusesV2.EXECUTION_ERROR]: ClientSideQueryStatuses.FAILED,
};
