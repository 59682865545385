import React from "react";
import { SUPPORT_EMAIL } from "businessLogic/constants";
import buttonStyles from "styles/buttons.module.scss";
import { ReactComponent as PendingSubscriptionError } from "assets/images/awsWelcome/pendingSubscriptionError.svg";
import styles from "./PendingSubscription.module.scss";
import Spinner from "components/common/Spinner";

export enum PendingSubscriptionTemplate {
  Loading = "LOADING",
  Error = "ERROR",
}

const TEMPLATE_ICONS = {
  [PendingSubscriptionTemplate.Loading]: (
    <div className={styles.loadingIcon}>
      <div className={styles.loading}>
        <Spinner
          thickness={4}
          size={40}
          color="colorSuccess"
        />
      </div>
      <div className={styles.innerIcon} />
    </div>
  ),
  [PendingSubscriptionTemplate.Error]: <PendingSubscriptionError />,
};

interface Props {
  template: PendingSubscriptionTemplate;
  title: React.ReactNode;
  description: React.ReactNode;
  showContactSupportButton: boolean;
}

class PendingSubscription extends React.Component<Props> {
  static defaultProps = {
    showContactSupportButton: false,
  };

  render() {
    const { title, template, description, showContactSupportButton } =
      this.props;

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.loaderContainer}>
            {TEMPLATE_ICONS[template]}
          </div>

          <div className={styles.title}>{title}</div>

          <div className={styles.description}>{description}</div>
        </div>
        {showContactSupportButton && (
          <div className={styles.contactButtonContainer}>
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              className={buttonStyles.btn_link}
            >
              Contact Support
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default PendingSubscription;
