export enum DatabaseCurrentStatus {
  TERMINATION_PENDING = "TERMINATION_PENDING",
  TERMINATION_STARTED = "TERMINATION_STARTED",
  TERMINATION_FAILED = "TERMINATION_FAILED",
  PROVISIONING_FAILED = "PROVISIONING_FAILED"
}

export const isDropping = status =>
  status === DatabaseCurrentStatus.TERMINATION_PENDING ||
  status === DatabaseCurrentStatus.TERMINATION_STARTED;

export const isFailed = status =>
  status === DatabaseCurrentStatus.TERMINATION_FAILED ||
  status === DatabaseCurrentStatus.PROVISIONING_FAILED;

export const isDroppingOrFailed = (status): boolean =>
  isDropping(status) || isFailed(status);
