/**
 * @generated SignedSource<<47a6a9c5a3f98c3a9d5728f944fba503>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthRefreshTokenInput = {
  refreshToken: string;
};
export type RefreshTokenMutation$variables = {
  input: AuthRefreshTokenInput;
};
export type RefreshTokenMutation$data = {
  readonly authRefreshToken: {
    readonly success: boolean;
    readonly accessToken: string;
    readonly expiresAt: any;
    readonly scopes: ReadonlyArray<string>;
  };
};
export type RefreshTokenMutation = {
  variables: RefreshTokenMutation$variables;
  response: RefreshTokenMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthRefreshTokenPayload",
    "kind": "LinkedField",
    "name": "authRefreshToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiresAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scopes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefreshTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefreshTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e738d5efd97fb3487aded49d5a428ac0",
    "id": null,
    "metadata": {},
    "name": "RefreshTokenMutation",
    "operationKind": "mutation",
    "text": "mutation RefreshTokenMutation(\n  $input: AuthRefreshTokenInput!\n) {\n  authRefreshToken(input: $input) {\n    success\n    accessToken\n    expiresAt\n    scopes\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3add98e8b82c8dee2d468cdd9607d9e";

export default node;
