import { createAction } from "redux-actions";
import {
  AppState,
  ScrollPayload,
  SearchDetailsPayload,
  SaveLastSeenReleaseDatePayload,
  SortPayload,
} from "./reducer";
import { View } from "../../../businessLogic/model";

// Dispatching actions with "APP/" prefix
// triggers saving UI state on the backend
export const APP_ACTIONS_PREFIX = "APP";

export const AppActions = {
  SAVE_SORT_DETAILS: `${APP_ACTIONS_PREFIX}/SAVE_SORT_DETAILS`,
  SAVE_SCROLL_POSITION: `${APP_ACTIONS_PREFIX}/SAVE_SCROLL_POSITION`,
  SAVE_DATABASE_DETAILS: `${APP_ACTIONS_PREFIX}/SAVE_DATABASE_DETAILS`,
  SAVE_SEARCH_DETAILS: `${APP_ACTIONS_PREFIX}/SAVE_SEARCH_DETAILS`,
  SAVE_LAST_SEEN_RELEASE_DATE: `${APP_ACTIONS_PREFIX}/SAVE_LAST_SEEN_RELEASE_DATE`,
  PUT_APP_STATE: "PUT_APP_STATE",
  FETCH_APP_STATE: "FETCH_APP_STATE",
  CHANGE_DATABASE_VIEW: `${APP_ACTIONS_PREFIX}/CHANGE_DATABASE_VIEW`,
};

export const saveSortDetails = createAction(
  AppActions.SAVE_SORT_DETAILS,
  (sortDetails: SortPayload) => sortDetails
);
export const saveSearchDetails = createAction(
  AppActions.SAVE_SEARCH_DETAILS,
  (payload: SearchDetailsPayload) => payload
);

export const saveScrollPosition = createAction(
  AppActions.SAVE_SCROLL_POSITION,
  (topOffset: ScrollPayload) => topOffset
);

export const putAppState = createAction(
  AppActions.PUT_APP_STATE,
  (state: AppState | null) => state
);

export const fetchAppState = createAction(
  AppActions.FETCH_APP_STATE,
  () => null
);

export const changeView = createAction(
  AppActions.CHANGE_DATABASE_VIEW,
  (view: View) => view
);

export const saveLastSeenReleaseDate = createAction(
  AppActions.SAVE_LAST_SEEN_RELEASE_DATE,
  (lastSeenReleaseDatePayload: SaveLastSeenReleaseDatePayload) =>
    lastSeenReleaseDatePayload
);
