import React, { ReactElement } from "react";
import cn from "classnames";
import { ActionMenuItemProps } from "../../ActionMenu/ActionMenuItem/ActionMenuItem";
import Checkbox from "components/Checkbox";
import Highlighter from "components/Highligher";
import styles from "./MultiSelectMenuItem.module.scss";

interface Props extends ActionMenuItemProps {
  value: string;
  selected?: boolean;
  icon?: ReactElement;
  selectedIcon?: ReactElement;
  renderSelectedIcon?: (selected: boolean) => ReactElement;
  children?: any;
  title?: string;
  searchOptions?: {
    textForSearch: string;
    searchQuery?: string;
    renderContent: (formattedText: React.ReactNode) => React.ReactElement;
  };
  className?: string;
}
/* wrapped component in React.forwardRef to get rid of javascript ref error
   the ref attribute is not currently used
 */
const MultiSelectMenuItem = React.forwardRef((props: Props) => {
  const selectedIcon =
    props.renderSelectedIcon?.(props.selected || false) || props.selectedIcon;
  // ts-ignore
  const { searchQuery, textForSearch, renderContent } =
    props.searchOptions || {};

  const formattedText =
    searchQuery && textForSearch && renderContent ? (
      <Highlighter
        searchWords={[searchQuery]}
        textToHighlight={textForSearch}
      />
    ) : (
      textForSearch
    );

  return (
    <div
      className={cn(styles.root, props.className)}
      onClick={props.onClick}
    >
      <span className={styles.icon}>{selectedIcon}</span>
      {props.icon && <span className={styles.icon}>{props.icon}</span>}
      <span className={styles.text}>
        {renderContent ? renderContent(formattedText) : props.children}
      </span>
    </div>
  );
});

MultiSelectMenuItem.defaultProps = {
  renderSelectedIcon: (selected: boolean) => (
    <Checkbox
      checked={selected}
      stopClickPropagation
    />
  ),
};

export default MultiSelectMenuItem;
