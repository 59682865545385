import React, { useCallback, useEffect, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useTranslation } from "react-i18next";
import clx from "classnames";
import { ReactComponent as HelpIcon } from "assets/icons/Help.svg";
import { ReactComponent as Documentation } from "assets/icons/Documentation.svg";
import { ReactComponent as ReleaseNotes } from "assets/icons/ReleaseNotes.svg";
import { ReactComponent as MessageIcon } from "assets/icons/Message.svg";
import { ReactComponent as StatusPageIcon } from "assets/icons/StatusPageIcon.svg";
import ActionMenuItem from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import ActionMenu from "components/ActionMenu/ActionMenu";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";

import {
  FIREBOLT_DOCS_URL,
  FIREBOLT_RELEASE_NOTES_URL,
  FIREBOLT_RELEASE_NOTES_URL_NEW,
  FIREBOLT_STATUS_PAGE_URL,
} from "businessLogic/constants";
import Tooltip from "../../Tooltip/Tooltip";
import {
  AQA_HELP_ICON,
  AQA_CONTACT_SUPPORT,
  AQA_DOCUMENTATION_USER,
  AQA_RELEASE_NOTES,
  AQA_FIREBOLT_STATUS_PAGE,
} from "../AqaIds";
import { clickOnSpaceOrEnterPress } from "../../common/keyboardUtils";
import ContactSupport from "../ContactSupport/ContactSupport";
import { Translations } from "../ContactSupport/translations";
import sidebarStyles from "../MainSidebar.module.scss";
import styles from "../sidebarItemWithDropdown.module.scss";
import helpBarStyles from "./HelpBar.module.scss";
import { FeatureFlag } from "../../../featureFlags/constants";

type Props = {
  isLoggedIn: boolean;
};

const HelpBar: React.FunctionComponent<Props> = props => {
  const { isLoggedIn } = props;
  const flags = useAllFlags();
  const { t } = useTranslation();
  const [anchorEl, setAnchorElement] = useState<HTMLElement | null>(null);
  const [formOpen, setFormOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoggedIn) {
      setFormOpen(false);
    }
  }, [isLoggedIn]);

  const handleClick = useCallback((event: React.MouseEvent) => {
    setAnchorElement(event.currentTarget as HTMLElement);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const openForm = useCallback(() => {
    handleClose();
    setFormOpen(true);
  }, [handleClose]);

  const closeForm = useCallback(() => {
    setFormOpen(false);
  }, []);

  const releaseNotesUrl = flags?.[
    FeatureFlag.FireboltAppLinkToNewReleaseNotesSite
  ]
    ? FIREBOLT_RELEASE_NOTES_URL_NEW
    : FIREBOLT_RELEASE_NOTES_URL;
  const showContactItem =
    flags?.[FeatureFlag.FireboltAppEnableContactSupportFormLoggedIn] &&
    isLoggedIn;
  const documentationItems = [
    <ActionMenuItem
      text="Status Page"
      key="statusPage"
      testId={AQA_FIREBOLT_STATUS_PAGE}
      to={FIREBOLT_STATUS_PAGE_URL}
      onClick={handleClose}
      icon={<StatusPageIcon />}
      className={clx(styles.forced, styles.statusPage)}
    />,
    showContactItem ? (
      <ActionMenuItem
        text="Contact Support"
        key="contact_support"
        testId={AQA_CONTACT_SUPPORT}
        onClick={openForm}
        icon={<MessageIcon />}
      />
    ) : null,
    <ActionMenuItem
      text="Release Notes"
      key="release_notes"
      testId={AQA_RELEASE_NOTES}
      to={releaseNotesUrl}
      onClick={handleClose}
      icon={<ReleaseNotes />}
      className={clx(styles.forced, styles.releaseNotes)}
    />,
    <ActionMenuItem
      text="Documentation"
      key="documentation"
      testId={AQA_DOCUMENTATION_USER}
      to={FIREBOLT_DOCS_URL}
      onClick={handleClose}
      icon={<Documentation />}
    />,
  ];

  const menuItems = [documentationItems];

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip
            title={`${t(Translations.helpTooltipTitle)}`}
            placement="right"
            classes={{
              popper: clx(sidebarStyles.menuItemTooltip, sidebarStyles.forced),
            }}
            disableFocusListener
          >
            <div
              className={clx(styles.root, styles["root--noBorderBottom"])}
              onClick={handleClick}
              tabIndex={0}
              onKeyPress={e => clickOnSpaceOrEnterPress(e)}
            >
              <div className={styles.container}>
                <span data-testid={AQA_HELP_ICON}>
                  <HelpIcon />
                </span>
              </div>
            </div>
          </Tooltip>
          {!!anchorEl && (
            <ActionMenu
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              lists={menuItems.filter(list => list.length)}
              open={true}
              anchorEl={anchorEl}
              onClose={handleClose}
              classes={{
                paper: helpBarStyles.paper,
              }}
            />
          )}
        </div>
      </ClickAwayListener>
      {formOpen && <ContactSupport onClose={closeForm} />}
    </>
  );
};

export default HelpBar;
