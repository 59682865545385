import _ from "lodash";

/**
 * @param database Database name
 * @param resource state.query.[tables|views] object from store
 * @returns
 */
export function shouldFetchResource(database, resource): boolean {
  const {
    didInvalidate,
    isLoading,
    resourceByDatabase,
    temporaryFetchFreezeByDatabase,
  } = resource;
  if (_.get(isLoading, `${database}`, false)) return false;
  if (_.get(temporaryFetchFreezeByDatabase, `${database}`, false)) return false;
  if (_.isEmpty(resourceByDatabase)) return true;
  if (resourceByDatabase?.[database] === null && !didInvalidate) {
    // it's already fetched
    return false;
  }
  if (_.isEmpty(resourceByDatabase?.[database])) return true;
  return didInvalidate;
}

export const shouldFetchTables = (state, database, force = false): boolean => {
  if (force) {
    return true;
  }
  const {
    didInvalidate,
    isLoading,
    tablesByDatabase,
    temporaryFetchFreezeByDatabase,
  } = state.query.tables;
  return shouldFetchResource(database, {
    isLoading,
    didInvalidate,
    resourceByDatabase: tablesByDatabase,
    temporaryFetchFreezeByDatabase,
  });
};

export function shouldFetchViews(
  state,
  database,
  { force = false, isDatabaseViewsEnabled = false } = {}
) {
  if (!isDatabaseViewsEnabled) {
    return false;
  }

  if (force) {
    return true;
  }

  const {
    isLoading,
    viewsByDatabase,
    didInvalidate,
    temporaryFetchFreezeByDatabase,
  } = state.query.views;
  return shouldFetchResource(database, {
    isLoading,
    didInvalidate,
    resourceByDatabase: viewsByDatabase,
    temporaryFetchFreezeByDatabase,
  });
}

export const shouldFetchExplain = ({ state, database, name }) => {
  const { didInvalidate, viewTablesByDatabase } = state.query.views;
  const view = _.get(viewTablesByDatabase, `${database}.${name}`);

  if (didInvalidate) {
    return true;
  }

  if (_.get(view, "isLoading") || _.get(view, "tables")) {
    return false;
  }
  if (!_.get(view, "tables")) {
    return true;
  }
  return true;
};
