/**
 * @generated SignedSource<<3a833256d76cd68cc72db355fcfe7d79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EngineStatusSummary = "UNSPECIFIED" | "STOPPED" | "STARTING" | "STARTING_INITIALIZING" | "RUNNING" | "UPGRADING" | "RESTARTING" | "RESTARTING_INITIALIZING" | "REPAIRING" | "STOPPING" | "DELETING" | "DELETED" | "FAILED" | "%future added value";
export type EngineRestartInput = {
  id: string;
};
export type EngineRestartMutation$variables = {
  input: EngineRestartInput;
};
export type EngineRestartMutation$data = {
  readonly engineRestart: {
    readonly success: boolean;
    readonly engine: {
      readonly id: string;
      readonly currentStatusSummary: EngineStatusSummary;
      readonly endpoint: string | null;
    };
  };
};
export type EngineRestartMutation = {
  variables: EngineRestartMutation$variables;
  response: EngineRestartMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EngineRestartPayload",
    "kind": "LinkedField",
    "name": "engineRestart",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Engine",
        "kind": "LinkedField",
        "name": "engine",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentStatusSummary",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endpoint",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngineRestartMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngineRestartMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0dbe56b3457fc46bab34078830e56746",
    "id": null,
    "metadata": {},
    "name": "EngineRestartMutation",
    "operationKind": "mutation",
    "text": "mutation EngineRestartMutation(\n  $input: EngineRestartInput!\n) {\n  engineRestart(input: $input) {\n    success\n    engine {\n      id\n      currentStatusSummary\n      endpoint\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c7850206bc4700546aeb84152cecf0e";

export default node;
