import React from "react";
import { AWS_MARKETPLACE_URL } from "businessLogic/constants";
import InputField from "../Input/InputFiled";
import MaterialButton from "../MaterialButton/MaterialButton";
import { UserRole } from "pages/Users/User.interface";
import {
  putStatusMessage,
  StatusMessageType,
} from "../StatusMessage/redux/actions";
import { createMembers } from "pages/Users/redux/actions";
import { connect } from "react-redux";
import { Validations } from "businessLogic/helpers/Validation";
import { AccountDetails } from "businessLogic/model";
import styles from "./WelcomeAwsScreen.module.scss";
import { ReactComponent as AwsPlugUrl } from "assets/images/awsWelcome/AwsPlug.svg";
import { ReactComponent as ChevronRightWhite } from "assets/icons/Chevron-Right-White.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/Arrow-Left.svg";
import { Invitation } from "components/UserInvitationModal/UserInvitationModal.helper";

interface Props {
  createMembers: (
    invitations: Invitation[],
    resolve,
    reject,
    type: string
  ) => void;
  putStatusMessage: (message: string, type: StatusMessageType) => void;
  account: AccountDetails;
}

enum Step {
  Welcome,
  Connect,
}

interface State {
  step: Step;
  inviteEmailError: string | null;
  inviteIsLoading: boolean;
  inviteEmail: string;
}

class WelcomeAwsScreen extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      step: Step.Welcome,
      inviteEmail: "",
      inviteEmailError: null,
      inviteIsLoading: false,
    };
  }

  handleContinueClick = () => {
    this.setState({ step: Step.Connect });
  };

  handleBackClick = () => {
    this.setState({ step: Step.Welcome });
  };

  renderWelcome = () => {
    return (
      <div className={styles.root}>
        <div className={styles.welcomeWrapper}>
          <div className={styles.title}>Welcome to Firebolt</div>
          <div className={styles.description}>
            To get started, Firebolt needs to be activated through your AWS
            marketplace account, which is also where you will be billed for
            Firebolt usage.
          </div>
          <div className={styles.imageContainer}>
            <AwsPlugUrl className={styles.image} />
          </div>

          <div className={styles.buttonContainer}>
            <MaterialButton
              endIcon={<ChevronRightWhite />}
              text="Continue"
              onClick={this.handleContinueClick}
              dataTestid="continue-aws"
            />
          </div>
        </div>
      </div>
    );
  };

  sendInvite = () => {
    const { inviteEmail } = this.state;

    if (!Validations.isValidEmail(inviteEmail)) {
      this.setState({ inviteEmailError: "Email is invalid" });
      return;
    }

    if (!Validations.isValidWorkEmail(inviteEmail)) {
      this.setState({
        inviteEmailError: `Email domain is not valid!`,
      });
      return;
    }

    this.setState({
      inviteIsLoading: true,
    });

    return new Promise((resolve, reject) => {
      this.props.createMembers(
        [{ email: inviteEmail, role: UserRole.AccountAdmin }],
        resolve,
        reject,
        "aws"
      );
    })
      .then(() => {
        this.setState({ inviteEmail: "", inviteEmailError: "" });
        this.props.putStatusMessage(
          "The invitation has been sent",
          StatusMessageType.Success
        );
      })
      .catch(errorMessage => {
        this.props.putStatusMessage(errorMessage, StatusMessageType.Error);
      })
      .finally(() => {
        this.setState({
          inviteIsLoading: false,
        });
      });
  };

  renderConnect = () => {
    const { inviteEmail, inviteEmailError, inviteIsLoading } = this.state;

    return (
      <>
        <div className={styles.backIcon} onClick={this.handleBackClick}>
          <ArrowLeft className={styles.backIcon__icon} /> Back
        </div>

        <div className={styles.root}>
          <div className={styles.integrateWrapper}>
            <div className={styles.title}>
              Integrate Firebolt with AWS marketplace
            </div>

            <div className={styles.description}>
              Once integrated with the AWS Marketplace, you will be able to
              start using databases and Firebolt engines, and pay as you go only
              for the resources you consume.
            </div>

            <div className={styles.buttonContainer}>
              <MaterialButton
                text="Go to AWS Marketplace"
                onClick={() =>
                  ((document.location.href as any) = AWS_MARKETPLACE_URL)
                }
                dataTestid="go-to-aws"
              />
            </div>

            <div className={styles.formContainer}>
              <div className={styles.formTitle}>
                Need help from your AWS admin? Invite him to Firebolt to
                complete the flow.
              </div>

              <form
                className={styles.form}
                onSubmit={e => {
                  e.preventDefault();
                }}
                noValidate
                autoComplete="off"
              >
                <div className={styles.inputContainer}>
                  <InputField
                    testId="email-aws-field"
                    disabled={inviteIsLoading}
                    type="text"
                    name="email"
                    value={inviteEmail}
                    label={inviteEmailError || "AWS Admin email"}
                    error={!!inviteEmailError}
                    onChange={(name, val) => {
                      this.setState({
                        inviteEmail: val,
                        inviteEmailError: null,
                      });
                    }}
                    fullWidth
                  />
                </div>

                <div className={styles.buttonContainer}>
                  <MaterialButton
                    fullWidth={true}
                    isLoading={inviteIsLoading}
                    text="Invite to Firebolt"
                    onClick={e => {
                      e.stopPropagation();
                      this.sendInvite();
                    }}
                    dataTestid="invite-to-firebolt"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { step } = this.state;

    return (
      <>
        {step === Step.Welcome && this.renderWelcome()}
        {step === Step.Connect && this.renderConnect()}
      </>
    );
  }
}

export { WelcomeAwsScreen };
export default connect(
  state => {
    return {
      account: (state as any).account.accountDetails,
    };
  },
  {
    putStatusMessage,
    createMembers,
  }
)(WelcomeAwsScreen);
