/**
 * @generated SignedSource<<d5489ae4bb46be9abf8c2284d9d3c64a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountMemberAcceptInvitationInput = {
  accountId?: string | null;
  userId?: string | null;
  token: string;
};
export type AcceptInvitationMutation$variables = {
  input: AccountMemberAcceptInvitationInput;
};
export type AcceptInvitationMutation$data = {
  readonly accountMemberAcceptInvitation: {
    readonly success: boolean;
    readonly accountMember: {
      readonly accountId: string;
    };
  };
};
export type AcceptInvitationMutation = {
  variables: AcceptInvitationMutation$variables;
  response: AcceptInvitationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountMemberAcceptInvitationPayload",
        "kind": "LinkedField",
        "name": "accountMemberAcceptInvitation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountMember",
            "kind": "LinkedField",
            "name": "accountMember",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptInvitationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountMemberAcceptInvitationPayload",
        "kind": "LinkedField",
        "name": "accountMemberAcceptInvitation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountMember",
            "kind": "LinkedField",
            "name": "accountMember",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cd8e9338daaa66fff7c47f4e3a7e2e3",
    "id": null,
    "metadata": {},
    "name": "AcceptInvitationMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptInvitationMutation(\n  $input: AccountMemberAcceptInvitationInput!\n) {\n  accountMemberAcceptInvitation(input: $input) {\n    success\n    accountMember {\n      accountId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "23178f4a3c9e5b04035ffde8ff2157c5";

export default node;
