export const CHANGE_SIDEBAR_WIDTH = "CHANGE_SIDEBAR_WIDTH";
export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";
export const COLLAPSE_LEFT_SIDEBAR = "COLLAPSE_LEFT_SIDEBAR";
export const OPEN_DATABASE_SEARCH = "OPEN_DATABASE_SEARCH";
export const SEARCH_COLUMNS = "SEARCH_COLUMNS";
export const SET_COLUMNS = "SET_COLUMNS";

export function changeSidebarWidth(sidebarWidth, position) {
  return async dispatch => {
    dispatch({
      type: CHANGE_SIDEBAR_WIDTH,
      payload: { sidebarWidth, position }
    });
  };
}

export function collapseSidebar(expanded) {
  return dispatch => {
    dispatch({
      type: COLLAPSE_SIDEBAR,
      payload: { expanded }
    });
  };
}

export function collapseLeftSidebar(expanded) {
  return async dispatch => {
    dispatch({
      type: COLLAPSE_LEFT_SIDEBAR,
      payload: { expanded }
    });
  };
}

export function openDatabaseSearch(expanded) {
  return async dispatch => {
    dispatch({
      type: OPEN_DATABASE_SEARCH,
      payload: { expanded }
    });
  };
}
