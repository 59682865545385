import { useSelector } from "react-redux";

export const useLastSeenReleaseDate = () => {
  const lastSeenReleaseDateSelector = state => state.app.lastSeenReleaseDate;

  const loadingSelector = state => {
    if ("appStateLoading" in state.app) {
      return !!state.app.appStateLoading;
    }
    return true;
  };

  const lastSeenReleaseDate = useSelector(lastSeenReleaseDateSelector);
  const loading = useSelector(loadingSelector);

  return {
    loading,
    lastSeenReleaseDate
  };
};
