import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import classNames from "classnames";
import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import { ReactComponent as DatabaseIcon } from "assets/icons/mainMenuIcons/DatabaseIconMenu.svg";
import { ReactComponent as SqlIcon } from "assets/icons/mainMenuIcons/SqlWorkspaceIconMenu.svg";
import { ReactComponent as EngineIcon } from "assets/icons/Engin.svg";
import UserBar from "components/MainSidebar/UserBar/UserBar";
import MenuItem from "components/MainSidebar/MenuItem/MenuItem";
import { ROUTES } from "businessLogic/routes.constants";
import { AccountDetails } from "businessLogic/model";
import { useEnginesPageFlagByUser } from "featureFlags/hooks/useEnginePageFlag";
import { getUserName, selectUserRole } from "redux/user/selectors";
import { UserData } from "redux/user/actions";
import { isAccountReadyForUse } from "redux/subscription/helpers";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import HelpBar from "./HelpBar/HelpBar";
import { UserRole } from "pages/Users/User.interface";
import { UserManagement } from "./UserManagement";
import styles from "./MainSidebar.module.scss";
import commonStyles from "styles/common.module.scss";

import {
  AQA_SQLWORKSPACE,
  AQA_ENGINE,
  AQA_DATABASE,
  AQA_LINK_HEADER,
} from "./AqaIds";
import ThemeSwitcher from "components/ThemeSwitcher/ThemeSwitcher";

type Props = {
  userData: UserData | null;
  userRole: UserRole | null;
  account: AccountDetails;
};

export const MainSidebar: React.FunctionComponent<Props> = ({
  userRole,
  userData,
  account,
}) => {
  const accountIsReadyForUse = isAccountReadyForUse(account);
  const { isEnginesPageEnabled } = useEnginesPageFlagByUser();
  const { isLoggedIn } = useLoggedInUser();
  const location = useLocation();

  return (
    <div className={styles.root}>
      <div className={styles.sidebarHead}>
        <Link
          key="app_header"
          to={ROUTES.DATABASES}
          data-testid={AQA_LINK_HEADER}
        >
          <Logo className={classNames(styles.logo, commonStyles.noFocus)} />
        </Link>
        {isLoggedIn && (
          <UserBar
            userRole={userRole}
            accountIsReadyForUse={accountIsReadyForUse}
            name={userData ? getUserName(userData) : ""}
          />
        )}

        <div className={styles.mt12}>
          {isLoggedIn && (
            <MenuItem
              testId={AQA_DATABASE}
              icon={<DatabaseIcon />}
              tooltip="Databases"
              disabled={!userRole || !accountIsReadyForUse}
              selected={location.pathname === ROUTES.DATABASES}
              to={ROUTES.DATABASES}
            />
          )}
          {isEnginesPageEnabled &&
            isLoggedIn &&
            userRole &&
            userRole !== UserRole.Viewer && (
              <MenuItem
                testId={AQA_ENGINE}
                icon={<EngineIcon />}
                tooltip="Engines"
                disabled={!userRole || !accountIsReadyForUse}
                selected={location.pathname === ROUTES.ENGINES}
                to={ROUTES.ENGINES}
              />
            )}

          {isLoggedIn && (
            <MenuItem
              testId={AQA_SQLWORKSPACE}
              icon={<SqlIcon />}
              tooltip="SQL Workspace"
              disabled={!userRole || !accountIsReadyForUse}
              selected={location.pathname.indexOf(ROUTES.SQLWORKSPACE) >= 0}
              to={ROUTES.SQLWORKSPACE}
            />
          )}

          {userRole && userRole === UserRole.AccountAdmin && (
            <UserManagement accountIsReadyForUse={accountIsReadyForUse} />
          )}
        </div>
      </div>
      <div className={styles.sidebarFooter}>
        <HelpBar isLoggedIn={isLoggedIn} />
        <ThemeSwitcher isLoggedIn={isLoggedIn} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: _.get(state, "user.userData"),
    userRole: selectUserRole(state),
    account: _.get(state, "account.accountDetails"),
  };
};

export default connect(mapStateToProps, {})(MainSidebar);
