import React from "react";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import { Banner } from "./Banner";
import { useReleaseNotes } from "./useReleaseNotes";
import { useLastSeenReleaseDate } from "./useLastSeenReleaseDate";

interface Props {
  lastSeenReleaseDate: number;
}

const BannerWithSubscribe: React.FC<Props> = props => {
  const { lastSeenReleaseDate } = props;
  const { data } = useReleaseNotes({ lastSeenReleaseDate });

  if (data) {
    const { content, title, action } = data;
    return (
      <Banner
        content={content}
        title={title}
        action={action}
        initialOpen={true}
      />
    );
  }
  return null;
};

export const ProductUpdatesBanner = () => {
  const { loading, lastSeenReleaseDate } = useLastSeenReleaseDate();
  const { isLoggedIn } = useLoggedInUser();

  if (loading || !isLoggedIn) {
    return null;
  }

  if (process.env.REACT_APP_DISABLE_UPDATES_BANNER) {
    return null;
  }

  return <BannerWithSubscribe lastSeenReleaseDate={lastSeenReleaseDate} />;
};
