import React from "react";
import { FormControl } from "@material-ui/core";
import cn from "classnames";
import { TFunction } from "react-i18next";
import InputField, { ErrorMessage } from "components/Input/InputFiled";
import { useLoggedInUser } from "hooks/useLoggedInUser";
import SplitRow from "./SplitRow";
import Severity from "./Severity";
import {
  ContactFormField,
  DisabledFields,
  ErrorFields,
  Fields,
  TEST_IDS,
} from "../constants";
import { Translations } from "../translations";
import Company from "./Company";
import styles from "../ContactSupport.module.scss";
import { Member } from "pages/Users/User.interface";
import Textarea from "components/Textarea";

interface Props {
  fields: Fields;
  disabledFields: DisabledFields;
  errors: ErrorFields;
  handleChange: (name: string, value: string) => void;
  t: TFunction;
  members: Member[];
}

const Form: React.FunctionComponent<Props> = (props: Props) => {
  const { fields, disabledFields, handleChange, errors, t, members } = props;
  const { isLoggedIn } = useLoggedInUser();
  return (
    <>
      <SplitRow
        cols={[
          <InputField
            testId={TEST_IDS.firstNameInput}
            type="text"
            label={t(Translations[ContactFormField.FirstName])}
            name={ContactFormField.FirstName}
            value={fields[ContactFormField.FirstName]}
            disabled={disabledFields[ContactFormField.FirstName]}
            onChange={handleChange}
            errorText={errors[ContactFormField.FirstName]}
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk,
              },
            }}
            fullWidth
            required={!isLoggedIn}
            autoFocus
          />,
          <InputField
            testId={TEST_IDS.lastNameInput}
            type="text"
            label={t(Translations[ContactFormField.LastName])}
            name={ContactFormField.LastName}
            value={fields[ContactFormField.LastName]}
            disabled={disabledFields[ContactFormField.LastName]}
            onChange={handleChange}
            errorText={errors[ContactFormField.LastName]}
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk,
              },
            }}
            fullWidth
            required={!isLoggedIn}
          />,
        ]}
      />
      <SplitRow
        cols={[
          <div className={styles.select}>
            <Company
              t={t}
              fields={fields}
              disabledFields={disabledFields}
              handleChange={handleChange}
              members={members}
              autoFocus={!disabledFields[ContactFormField.Company]}
            />
          </div>,
          <InputField
            testId={TEST_IDS.emailInput}
            type="text"
            label={t(Translations[ContactFormField.Email])}
            name={ContactFormField.Email}
            value={fields[ContactFormField.Email]}
            disabled={disabledFields[ContactFormField.Email]}
            onChange={handleChange}
            errorText={errors[ContactFormField.Email]}
            InputLabelProps={{
              classes: {
                asterisk: styles.asterisk,
              },
            }}
            fullWidth
            required={!isLoggedIn}
          />,
        ]}
      />
      <SplitRow
        cols={[
          <div className={styles.select}>
            <Severity
              t={t}
              fields={fields}
              disabledFields={disabledFields}
              handleChange={handleChange}
              autoFocus={disabledFields[ContactFormField.Company]}
            />
          </div>,
          <InputField
            testId={TEST_IDS.engineNameInput}
            type="text"
            label={t(Translations[ContactFormField.EngineName])}
            name={ContactFormField.EngineName}
            value={fields[ContactFormField.EngineName]}
            disabled={disabledFields[ContactFormField.EngineName]}
            onChange={handleChange}
            errorText={errors[ContactFormField.EngineName]}
            fullWidth
          />,
        ]}
      />
      <div className={styles.row}>
        <InputField
          testId={TEST_IDS.subjectInput}
          type="text"
          label={t(Translations[ContactFormField.Subject])}
          name={ContactFormField.Subject}
          value={fields[ContactFormField.Subject]}
          disabled={disabledFields[ContactFormField.Subject]}
          onChange={handleChange}
          errorText={errors[ContactFormField.Subject]}
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          fullWidth
          required
        />
      </div>
      <div className={cn(styles.row, styles.mb30)}>
        <FormControl fullWidth>
          <div className={styles.label}>
            {t(Translations[ContactFormField.Description])}
          </div>
          <Textarea
            name={ContactFormField.Description}
            testId={TEST_IDS.descriptionInput}
            className={styles.description}
            placeholder={t(Translations.descriptionPlaceholder)}
            value={fields[ContactFormField.Description]}
            disabled={disabledFields[ContactFormField.Description]}
            onChange={e =>
              handleChange(ContactFormField.Description, e.target.value)
            }
          />
          <ErrorMessage
            errorText={errors[ContactFormField.Description]}
            addWrapper
          />
        </FormControl>
      </div>
    </>
  );
};

export default Form;
