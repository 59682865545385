import React from "react";
import styles from "./styles.module.scss";

export default (message, link, href = "/") => {
  return (
    <span className={styles.messageWrap}>
      <span data-testid="link-container">
        {message}{" "}
        <a href={href} data-testid="link-href">
          {link}
        </a>
      </span>
    </span>
  );
};
