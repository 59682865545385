import { combineReducers } from "redux";
import appReducer from "components/App/redux/reducer";
import authReducer from "pages/Auth/redux/reducer";
import accountReducer from "pages/Account/redux/reducer";
import databaseReducer from "pages/Database/redux/reducer";
import userReducer from "./user/reducer";
import subscriptionReducer from "./subscription/reducer";
import statusMessageReducer from "components/StatusMessage/redux/reducer";
import queryReducer from "components/QueryEditor/redux/reducers/index";
import confirmationModalReducer from "components/ConfirmationDialog/redux/reducer";
import costsReducer from "./costs/reducer";
import flagsReducer from "./flags/reducer";

const createRootReducer = () =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    account: accountReducer,
    database: databaseReducer,
    user: userReducer,
    subscription: subscriptionReducer,
    statusMessage: statusMessageReducer,
    query: queryReducer,
    costs: costsReducer,
    confirmationModal: confirmationModalReducer,
    flags: flagsReducer,
  });

export default createRootReducer;
