import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { AccountApproveInput } from "relay/mutations/account/__generated__/ApproveAccountMutation.graphql";

const mutation = graphql`
  mutation ApproveAccountMutation($input: AccountApproveInput!) {
    accountApprove(input: $input) {
      success
    }
  }
`;

function commit(environment, input: AccountApproveInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
