import { createAction } from "redux-actions";
import { AccountDetails } from "businessLogic/model";

export const AccountActions = {
  REGISTER: "ACCOUNT/REGISTER",
  SET_ERROR: "ACCOUNT/SET_ERROR",
  REGISTER_SUCCESS: "ACCOUNT/REGISTER_SUCCESS",
  SAVE_ACCOUNT_DATA: "SAVE_ACCOUNT_DATA",
  GET_ACCOUNT_DETAILS: "GET_ACCOUNT_DETAILS",
  REGISTER_FROM_AWS: "REGISTER_FROM_AWS",
};

export const registerAccount = createAction(
  AccountActions.REGISTER,
  (accountDetails: AccountDetails, recaptcha: string) => ({
    accountDetails,
    recaptcha,
  })
);
export const registerAccountFromAws = createAction(
  AccountActions.REGISTER_FROM_AWS,
  (
    accountDetails: AccountDetails,
    awsLinkingToken: string,
    recaptcha: string
  ) => ({
    accountDetails,
    awsLinkingToken,
    recaptcha,
  })
);
export const registerAccountSuccess = createAction(
  AccountActions.REGISTER_SUCCESS,
  () => {}
);
export const setAccountError = createAction(
  AccountActions.SET_ERROR,
  (errorRegister: string) => errorRegister
);

export const saveAccountData = createAction(
  AccountActions.SAVE_ACCOUNT_DATA,
  data => data
);
export const getAccountDetails = createAction(
  AccountActions.GET_ACCOUNT_DETAILS,
  () => {}
);
