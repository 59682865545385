import { all, takeEvery } from "redux-saga/effects";
import * as SidebarActions from "../actions/sidebar";
import { resizeSubject } from "services/subject/resizeSubject";

const ANIMATION_TIMEOUT = 300;
const handleResizeDelayed = () => {
  setTimeout(() => {
    resizeSubject.notifyAsync(
      {
        type: "sidebar"
      },
      ANIMATION_TIMEOUT
    );
  }, 0);
};

const handleResize = () => {
  resizeSubject.notify({
    type: "sidebar"
  });
};

export function* SidebarSagas() {
  return yield all([
    takeEvery(
      [SidebarActions.COLLAPSE_LEFT_SIDEBAR, SidebarActions.COLLAPSE_SIDEBAR],
      handleResizeDelayed
    ),
    takeEvery(SidebarActions.CHANGE_SIDEBAR_WIDTH, handleResize)
  ]);
}
