import {
  AuthActions,
  emailSent,
  logoutFailure,
  logoutSuccess,
  passwordReset,
  setResetPassError,
  setSession,
  setLoggedInUser,
  setLoginError,
  clearUserDetails,
} from "./actions";
import * as Sentry from "@sentry/browser";
import * as _ from "lodash";
import { resetQueries } from "components/QueryEditor/redux/actions/queries";
import { Action } from "redux-actions";
import { all, put, takeEvery, call, select } from "redux-saga/effects";
import { ResetPass, SessionData, UserDetails } from "businessLogic/model";
import { subscriptionClient } from "relay/environment";
import { AuthService } from "businessLogic/services/auth";
import { resetDbState } from "pages/Database/redux/actions";
import { authService } from "services/auth";
import { history } from "index";

function* loginSaga(
  action: Action<{
    redirectPath: string;
    userDetails: UserDetails;
    recaptchaChallenge: string;
    email?: string;
    loginType?: string;
  }>
) {
  const {
    redirectPath = "",
    loginType,
    userDetails,
    recaptchaChallenge,
  } = action.payload;

  const url = new URL(redirectPath, window.origin);
  url.searchParams.append("login", "true");
  const redirect = `${url.pathname || ""}?${url.searchParams}`;

  if (loginType === "classic") {
    try {
      const { authLogin } = yield AuthService.login(
        userDetails,
        recaptchaChallenge
      );
      const sessionData: SessionData = {
        access_token: authLogin.accessToken,
        expires_in: 1000,
        refresh_token: authLogin.refreshToken,
        userid: "",
        token_type: "",
      };
      yield put(setSession(sessionData));
      yield put(setLoggedInUser(userDetails));

      history.push(redirect);
    } catch (error) {
      console.error("Failed to login:", error);
      yield put(setLoginError(error.message || "login.invalid_login_attempt"));
    }
  } else {
    yield authService.redirectToLogin(redirect, userDetails.username);
  }
}

export function* logoutSaga(action: Action<{ preventRedirect: boolean }>) {
  try {
    const { session } = yield select(state => _.get(state, "auth"));

    if (session?.refreshToken) {
      yield AuthService.logout(session.refreshToken);
    }

    yield put(clearUserDetails());
    yield put(resetQueries());
    yield put(resetDbState());

    if (subscriptionClient) {
      yield subscriptionClient.dispose();
    }

    const loginType = authService.getLoginType();

    yield put(logoutSuccess(loginType));

    if (loginType === "auth0") {
      authService.redirectToLogout();
      return;
    }

    if (!action.payload.preventRedirect) {
      history.push("/");
    }
  } catch (err) {
    yield put(logoutFailure());
    console.error("Failed to logout:", err.message);
  }
}

export function* resetPassSaga(
  action: Action<{ email: string; recaptchaChallenge: string }>
) {
  try {
    const { email, recaptchaChallenge } = action.payload;

    yield AuthService.sendResetPassEmail(email, recaptchaChallenge);
    yield put(emailSent());
  } catch (err) {
    console.error("Failed to send email:", err);
    yield put(setResetPassError(err.message || "login.failed_to_send_email"));
  }
}
function* setNewPasswordSaga(action: Action<ResetPass>) {
  try {
    const { password, tokenId } = action.payload;
    yield AuthService.setNewPass({ password, tokenId });
    yield put(passwordReset());
  } catch (err) {
    console.error("Failed to set new password:", err);
    yield put(
      setResetPassError(err.message || "login.failed_to_set_new_password")
    );
  }
}

function* logoutSuccessSaga() {
  yield call([Sentry, "configureScope"], (scope: Sentry.Scope) => {
    scope.setUser({});
    if ((window as any).heap) {
      (window as any).heap.resetIdentity();
    }
  });
}

export function* AuthSagas() {
  return yield all([
    takeEvery(AuthActions.LOGIN, loginSaga),
    takeEvery(AuthActions.LOGOUT, logoutSaga),
    takeEvery(AuthActions.RESET_PASSWORD, resetPassSaga),
    takeEvery(AuthActions.SET_NEW_PASSWORD, setNewPasswordSaga),
    takeEvery(AuthActions.LOGOUT_SUCCESS, logoutSuccessSaga),
  ]);
}
