import { all, put, takeLatest } from "redux-saga/effects";
import { AppActions, putAppState } from "components/App/redux/actions";
import { fetchUserUiState } from "relay/queries/iam/User";
import environment from "relay/environment";

function* fetchAppStateSaga() {
  try {
    const response = yield fetchUserUiState(environment, {});

    const uiAppStateJSON = response.me.uiState.data;
    const uiAppState = JSON.parse(uiAppStateJSON);
    if (uiAppState) {
      yield put(putAppState(uiAppState));
    }
  } catch (e) {
    yield put(putAppState(null));
  }
}

export function* AppSagas() {
  return yield all([takeLatest(AppActions.FETCH_APP_STATE, fetchAppStateSaga)]);
}
