import { createAction } from "redux-actions";

export const TablesAction = {
  TABLES_LOAD: "TABLES/LOAD_REQUEST",
  TABLES_LOAD_SUCCESS: "TABLES/LOAD_SUCCESS",
  TABLES_LOAD_FAILED: "TABLES/LOAD_FAILED",
  TABLES_REMOVE: "TABLES/REMOVE",
  SELECT_TABLE: "TABLES/SELECT",
  UNSELECT_TABLE: "TABLES/UNSELECT",
  STOP_LOADING: "TABLES/STOP_LOADING",
  START_LOADING: "TABLES/START_LOADING",
  TABLES_UNFREEZE_FETCH: "TABLES/UNFREEZE_FETCH",
};

export const selectTable = createAction(
  TablesAction.SELECT_TABLE,
  (database: string, table: string) => ({ database, table })
);

export const UnselectTable = createAction(
  TablesAction.UNSELECT_TABLE,
  (database: string) => ({ database })
);

export const LoadTables = createAction(
  TablesAction.TABLES_LOAD,
  (database: string, isRetry: boolean) => ({ database, isRetry })
);

export const UnfreezeFetchTables = createAction(
  TablesAction.TABLES_UNFREEZE_FETCH,
  (database: string) => ({ database })
);

export const LoadTablesFailed = createAction(
  TablesAction.TABLES_LOAD_FAILED,
  (database: string, error) => ({ database, error })
);

export const LoadTablesSuccess = createAction(
  TablesAction.TABLES_LOAD_SUCCESS,
  (database: string, tables: any) => ({ database, tables })
);

export const startLoading = createAction(
  TablesAction.START_LOADING,
  (database: string) => ({ database })
);

export const stopLoading = createAction(
  TablesAction.STOP_LOADING,
  (database: string) => ({ database })
);

export const removeTables = createAction(
  TablesAction.TABLES_REMOVE,
  (database: string) => ({ database })
);
