import * as EngineFormHelpers from "components/EngineForm/helpers";
import { generateInstanceTypeFromEngine } from "components/EnginesList/helpers";
import { UIEngineStatus } from "components/EngineStatusIcon/EngineStatusIcon";
import { getUiEngineStatus } from "components/EngineStatusIcon/helpers";
import { deletingEngineIds } from "index";

export const getDatabasesFromAccount = account => {
  return account && account.databases && account.databases.edges
    ? account.databases.edges
        .filter(node => {
          const database = node?.node as any;
          return database.currentStatus !== "DELETED";
        })
        .map(node => node?.node as any)
    : [];
};

export const getEnginesFromDbBindings = db => {
  const edges = db?.bindings?.edges || [];
  return edges
    .map(node => {
      const binding = node?.node;

      return binding || null;
    })
    .filter(
      node =>
        node && node.engine && node.engine.currentStatusSummary !== "DELETED"
    )
    .map(node => {
      const engine = EngineFormHelpers.formatEngineForUI(node);
      const { instanceType } = generateInstanceTypeFromEngine(node.engine);

      const deletingEngineStatus = deletingEngineIds?.get(engine.id);
      const newEngineStatus =
        deletingEngineStatus &&
        ![
          UIEngineStatus.STATUS_DROPPING,
          UIEngineStatus.STATUS_STOPPING,
        ].includes(engine.status)
          ? getUiEngineStatus(deletingEngineStatus)
          : engine.status;

      const engineCost =
        node?.engine.latestRevision?.specification?.dbComputeInstancesType
          ?.costPerHour ||
        node?.engine.latestRevision?.specification?.dbComputeInstancesType
          ?.pricePerHourCents;
      return {
        ...engine,
        status: newEngineStatus,
        instanceType: EngineFormHelpers.getEngineInstanceType(
          instanceType,
          engine
        ),
        engineCost: engineCost / 100,
      };
    });
};

export const dbCanBeDeleted = db => {
  const engines = getEnginesFromDbBindings(db);
  let canDelete = true;
  engines.forEach(en => {
    if (
      ![
        UIEngineStatus.STATUS_ON,
        UIEngineStatus.STATUS_STOPPED,
        UIEngineStatus.STATUS_FAILED,
      ].includes(en.status)
    ) {
      canDelete = false;
    }
  });
  return canDelete;
};
