import * as React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles.module.scss";

import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const DEFAULT_SPINNER_SIZE = 50;

export interface SpinnerProps {
  size?: number;
  text?: string;
  className?: string;
  color?:
    | "colorPrimary"
    | "colorSuccess"
    | "colorWarning"
    | "colorDisabled"
    | "colorGeneral";
  thickness?: number;
}

export default function Spinner(props: SpinnerProps) {
  return (
    <div className={cx(styles.spinner, props.className)}>
      <CircularProgress
        thickness={props.thickness || 6}
        classes={{
          colorPrimary:
            styles[props.color || "colorPrimary"] || styles.colorPrimary,
        }}
        size={props.size || DEFAULT_SPINNER_SIZE}
      />
      {props.text && <span className={styles.text}>{props.text}</span>}
    </div>
  );
}
