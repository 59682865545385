import React, { useRef, useCallback, useMemo } from "react";
import {
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
} from "@material-ui/core";
import cn from "classnames";
import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import MaterialButton, {
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import LoadingOverlap from "components/LoadingOverlap";
import styles from "./styles.module.scss";

type DialogType = "error" | "success" | "confirmation" | "info";

interface DialogComponentProps {
  openModal: boolean;
  closeModal(): void;
  item?: any;
  title: string | JSX.Element;
  confirmationText?: any;
  secondaryText?: any;
  cancelText?: string;
  onConfirm?: () => void;
  secondaryAction?: () => void;
  type?: DialogType;
  style?: any;
  confirmationButtonProps?: ButtonProps;
  confirmTooltip?: {
    title: string;
  };
  maxWidth?: DialogProps["maxWidth"];
  confirmIsLoading?: boolean;
  cancelIsLoading?: boolean;
  cancelIsDisabled?: boolean;
  secondaryIsLoading?: boolean;
  confirmIsDisabled?: boolean;
  showCloseIcon?: boolean;
  contentMaxWidth?: string;
  classes?: any;
  autoFocus?: boolean;
  dialogIsLoading?: boolean;
  testIds?: any;
  children: React.ReactNode;
  allowConfirmByEnter?: boolean;

  confirmIsHidden?: boolean;
}

const DialogComponent: React.FC<DialogComponentProps> = props => {
  const {
    openModal,
    closeModal,
    onConfirm,
    secondaryAction,
    children,
    cancelText,
    secondaryText,
    confirmationText,
    title,
    style,
    confirmIsLoading,
    cancelIsLoading,
    cancelIsDisabled,
    secondaryIsLoading,
    maxWidth,
    confirmIsDisabled,
    contentMaxWidth,
    autoFocus = true,
    classes = {},
    dialogIsLoading,
    testIds = {},
    confirmTooltip,
    showCloseIcon,
    allowConfirmByEnter = true,
    confirmIsHidden,
  } = props;

  const primaryButton = useRef<HTMLElement | null>(null);
  const secondaryButton = useRef<HTMLElement | null>(null);

  const backdropEscapeHandlers = !showCloseIcon
    ? {
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      }
    : {};

  const handleDialogEnter = useCallback(() => {
    if (autoFocus) {
      if (primaryButton.current) {
        primaryButton.current.focus();
        return;
      }
      if (secondaryButton.current) {
        secondaryButton.current.focus();
        return;
      }
    }
  }, [autoFocus]);

  const { dialogTitleCn, ...dialogClasses } = classes;

  const TransitionProps = useMemo(() => {
    return {
      onEntered: handleDialogEnter,
    };
  }, [handleDialogEnter]);

  const handleCloseModal = useCallback(
    event => {
      event.preventDefault();

      closeModal();
      return false;
    },
    [
      backdropEscapeHandlers?.disableBackdropClick,
      backdropEscapeHandlers?.disableEscapeKeyDown,
      closeModal,
    ]
  );

  const handleKeyPress = event => {
    if (event.key.toLowerCase() === "enter") {
      const isConfirmButtonAvailable = !confirmIsLoading && !confirmIsDisabled;
      const canConfirmByEnter =
        allowConfirmByEnter && isConfirmButtonAvailable && onConfirm;

      return canConfirmByEnter ? onConfirm() : null;
    }
  };

  return (
    <Dialog
      open={openModal}
      classes={{
        ...dialogClasses,
        paper: cn(styles.paper, dialogClasses?.paper),
        paperWidthSm: style ? style : styles.paperWidthSm,
        paperWidthMd: cn(styles.paperWidthMd, dialogClasses?.paperWidthMd),
      }}
      PaperProps={{
        style: contentMaxWidth ? { maxWidth: contentMaxWidth } : {},
      }}
      fullWidth
      maxWidth={maxWidth}
      onClose={handleCloseModal}
      TransitionProps={TransitionProps}
      onKeyPress={handleKeyPress}
    >
      <DialogContent
        classes={{
          root: styles.dialogContent,
        }}
      >
        <div
          className={styles.contentWrapper}
          data-testid={testIds?.contentWrapper}
        >
          <div className={cn(styles.dialogTitle, dialogTitleCn)}>
            <h2
              className={styles.titleText}
              data-testid="dialog-title"
            >
              {title}
            </h2>
            {showCloseIcon && (
              // button added as interactive element for automation tests
              <button
                className={styles.closeButton}
                data-testid="close-modal-button"
              >
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={closeModal}
                  data-testid="close-modal-icon"
                />
              </button>
            )}
          </div>
          {dialogIsLoading && <LoadingOverlap isLoading />}
          {!dialogIsLoading && children}
          {!dialogIsLoading &&
            (confirmationText || secondaryText || cancelText) && (
              <div className={styles.dialogActions}>
                {confirmationText && !confirmIsHidden && (
                  <div className={styles.buttonContainer}>
                    <MaterialButton
                      ref={primaryButton}
                      text={confirmationText}
                      isLoading={confirmIsLoading}
                      disabled={confirmIsDisabled}
                      onClick={() => onConfirm && onConfirm()}
                      dataTestid="confirm"
                      tooltip={confirmTooltip}
                      disablePortal
                    />
                  </div>
                )}

                {secondaryText && (
                  <div className={styles.buttonContainer}>
                    <MaterialButton
                      ref={secondaryButton}
                      text={secondaryText}
                      isLoading={secondaryIsLoading}
                      template={ButtonTemplate.Secondary}
                      onClick={() => secondaryAction && secondaryAction()}
                      dataTestid="secondary"
                    />
                  </div>
                )}

                {cancelText && (
                  <div className={styles.buttonContainer}>
                    <MaterialButton
                      text={cancelText}
                      isLoading={cancelIsLoading}
                      disabled={cancelIsDisabled}
                      template={ButtonTemplate.Secondary}
                      onClick={() => closeModal()}
                      dataTestid="close-modal"
                    />
                  </div>
                )}
              </div>
            )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default DialogComponent;
