export enum FeatureFlag {
  FireboltAppReadOnlyMaintenanceMode = "fireboltAppReadOnlyMaintenanceMode",
  FireboltAppRunMultipleReadWriteEngines = "fireboltAppRunMultipleReadWriteEngines",
  FireboltAppFullMaintenanceMode = "fireboltAppFullMaintenanceMode",
  FireboltAppSpotInstances = "fireboltAppSpotInstances",
  FireboltAppShowViews = "fireboltAppShowViews",
  FireboltAppEnginesPage = "fireboltAppEnginesPage",
  FireboltAppQueryExplain = "fireboltAppQueryExplain",
  UseSingleGpEnginePerDb = "useSingleGpEnginePerDb",
  UseFireboltDisplayNameForEngineSpec = "useFireboltDisplayNameForEngineSpec",
  FireboltAppBypassQueryQueue = "fireboltAppBypassQueryQueue",
  FireboltAppUseSpotInstancesByDefault = "fireboltAppUseSpotInstancesByDefault",
  FireboltAppEnableContactSupportFormLoggedIn = "fireboltAppEnableContactSupportFormLoggedIn",
  FireboltAppEnableEditRunningScript = "fireboltAppEnableEditRunningScript",
  FireboltAppLinkToNewReleaseNotesSite = "fireboltAppLinkToNewReleaseNotesSite",
  FireboltAppEnableBillingEngineChart = "fireboltAppEnableBillingEngineChart",
  FireboltAppDefaultHybridQueryExecution = "fireboltAppDefaultHybridQueryExecution",
  FireboltAppEnableEditUsersMfa = "fireboltAppEnableEditUsersMfa",
  FireboltAppEnableEditUsersIpLists = "fireboltAppEnableEditUsersIpLists",
  FireboltFirenzeEnabled = "firenzeEnabled",
  FireboltEnableSystemEngine = "enableSystemEngine",
}

export const FEATURE_FLAG_INFO_MESSAGE = {
  [FeatureFlag.FireboltAppReadOnlyMaintenanceMode]:
    "Application is in read-only mode",
};
