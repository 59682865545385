import React from "react";
import styles from "./Label.module.scss";
import cn from "classnames";

const Label = ({ children, ...otherProps }) => {
  const { bordered, className, ...props } = otherProps;
  return (
    <div
      {...props}
      className={cn(styles.root, className, {
        [styles.bordered]: bordered
      })}
    >
      {children}
    </div>
  );
};

export default Label;
