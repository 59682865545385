import React, { useCallback } from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import CustomSelect from "components/Select/Select";
import SelectMenuItem from "components/Select/SelectMenuItem/SelectMenuItem";
import { ContactFormField, SEVERITY_OPTIONS, TEST_IDS } from "../constants";
import { Translations } from "../translations";

const Severity = props => {
  const { fields, disabledFields, handleChange, t, autoFocus } = props;

  const handleSelectChange = useCallback(
    event => {
      handleChange(ContactFormField.Severity, event.target.value);
    },
    [handleChange]
  );

  const items = [
    ...SEVERITY_OPTIONS.map(item => (
      <SelectMenuItem
        key={`${item.value}`}
        value={`${item.value}`}
        testId={`${TEST_IDS.severityValue}${item.value}`}
      >
        {t(item.text)}
      </SelectMenuItem>
    )),
  ];

  return (
    <FormControl fullWidth>
      <InputLabel>{t(Translations[ContactFormField.Severity])}</InputLabel>
      <CustomSelect
        testId={TEST_IDS.severitySelect}
        fullWidth
        value={fields[ContactFormField.Severity]}
        disabled={disabledFields[ContactFormField.Severity]}
        onChange={handleSelectChange}
        autoFocus={autoFocus}
      >
        {items}
      </CustomSelect>
    </FormControl>
  );
};

export default Severity;
