import { EngineClient } from "businessLogic/api";
import ScriptQuery from "relay/queries/userScript/Script";
import environment from "relay/environment";
import ScriptDeleteMutation from "relay/mutations/userScript/ScriptDeleteMutation";
import ScriptUpdateMutation from "relay/mutations/userScript/ScriptUpdateMutation";
import { ScriptUpdateMutation$data } from "relay/mutations/userScript/__generated__/ScriptUpdateMutation.graphql";
import { ScriptCreateMutation$data } from "relay/mutations/userScript/__generated__/ScriptCreateMutation.graphql";
import ScriptCreateMutation from "relay/mutations/userScript/ScriptCreateMutation";
import { runningQueries } from "./runningQueries";

export const getQueryProgress = async (
  queryId: string,
  engineEndpoint: string
) => {
  const engineClient = EngineClient(engineEndpoint);
  const res = await engineClient.get(`/progress?progress_query_id=${queryId}`);
  return res.data;
};

export const getQueryStatus = async (
  queryId: string,
  database: string,
  engineEndpoint: string
) => {
  const engineClient = EngineClient(engineEndpoint);
  const res = await engineClient.get(
    `/status?database=${database}&query_id=${queryId}`
  );
  return res.data;
};

export const cancelQuery = async (
  queryIds: string[],
  engineEndpoint: string,
  database: string,
  serverQueryId: string
) => {
  if (serverQueryId) {
    const engineClient = EngineClient(engineEndpoint);
    engineClient
      .get(`/cancel?database=${database}&query_id=${serverQueryId}`)
      .catch(() => {
        console.warn("Failed to cancel the query");
      });
  }

  for (const queryId of queryIds) {
    if (!runningQueries[queryId]) {
      return;
    }
    const cancelToken = runningQueries[queryId];
    cancelToken();
    delete runningQueries[queryId];
  }
};

const mapToReduxUserScript = script => {
  return {
    id: script.id,
    user_id: script.userId,
    database_id: script.databaseId,
    name: script.displayName,
    query: script.data,
    lastUpdateTime: script.lastUpdateTime,
    createTime: script.createTime,
    state: "closed",
    isStarred: script.isStarred,
  };
};

export const saveUserQueryItem = async (
  userQueries: any,
  databaseId,
  userId
) => {
  const script = userQueries[0];

  return new Promise<ScriptCreateMutation$data>((resolve, reject) => {
    ScriptCreateMutation.commit(
      environment,
      {
        databaseId: databaseId,
        displayName: script.name,
        userId: userId,
        isStarred: false,
        data: script.query,
      },
      resolve,
      reject
    );
  }).then(response => {
    return [mapToReduxUserScript(response.scriptCreate.script)];
  });
};

export const updateUserQueryItem = async (userQueries: any) => {
  const script = userQueries[0];

  return new Promise<ScriptUpdateMutation$data>((resolve, reject) => {
    ScriptUpdateMutation.commit(
      environment,
      {
        id: script.id,
        displayName: script.name,
        isStarred: script.isStarred,
        data: script.query,
        databaseId: script.databaseId,
      },
      resolve,
      reject
    );
  }).then(response => {
    return [mapToReduxUserScript(response.scriptUpdate.script)];
  });
};

export const deleteUserQuery = async (userQueryId: string) => {
  return new Promise((resolve, reject) => {
    ScriptDeleteMutation.commit(
      environment,
      {
        id: userQueryId,
      },
      resolve,
      reject
    );
  });
};

export const getUserQueries = async (databaseId, globalQueries) => {
  return ScriptQuery.fetch(environment, {
    id: databaseId,
    databaseIdNull: globalQueries,
  })
    .then(response => {
      return response.me.editorScripts.edges.map(script =>
        mapToReduxUserScript(script.node)
      );
    })

    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
};
