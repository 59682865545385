import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Snackbar, Fade } from "@material-ui/core";
import { saveLastSeenReleaseDate } from "components/App/redux/actions";
import MaterialButton, {
  ButtonTemplate
} from "components/MaterialButton/MaterialButton";
import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import { DEFAULT_TITLE, DEFAULT_ACTION } from "../constants";
import { toTimestamp } from "../utils";
import styles from "./styles.module.scss";

interface IAction {
  content?: string;
  url?: string;
}

interface ActionProps {
  action: IAction;
  onClick: () => void;
}

interface BannerProps {
  initialOpen: boolean;
  title?: string;
  content: string;
  action?: IAction;
}

const Action: React.FC<ActionProps> = props => {
  const { action, onClick } = props;
  const updateAction = { ...DEFAULT_ACTION, ...action };
  const { content, url } = updateAction;

  return (
    <MaterialButton
      href={url}
      onClick={onClick}
      text={content}
      template={ButtonTemplate.Secondary}
      dataTestid="banner-action"
    />
  );
};

export const Banner: React.FC<BannerProps> = props => {
  const { initialOpen, title = DEFAULT_TITLE, content, action = {} } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(initialOpen);

  const handleClose = () => {
    dispatch(
      saveLastSeenReleaseDate({ lastSeenReleaseDate: toTimestamp(new Date()) })
    );
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      ClickAwayListenerProps={{ mouseEvent: false }}
      classes={{ root: styles.snackbar }}
      TransitionComponent={Fade}
    >
      <div className={styles.paper} data-testid="banner">
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <CloseIcon className={styles.closeIcon} onClick={handleClose} />
        </div>
        <p className={styles.content}>{content}</p>
        <div className={styles.action}>
          <Action action={action} onClick={handleClose} />
        </div>
      </div>
    </Snackbar>
  );
};
