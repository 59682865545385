import graphql from "babel-plugin-relay/macro";

const subscription = graphql`
  subscription EngineStatusSummaryChangedSubscription(
    $id: ID!
    $accountId: ID!
  ) {
    engineStatusSummaryChanged(id: $id) {
      id
      currentStatusSummary
      endpoint
      settingsPreset
      latestRevision {
        id
        specification {
          dbComputeInstancesCount
          dbComputeInstancesUseSpot
          dbComputeInstancesType {
            id
            pricePerHourCents(accountId: $accountId)
            name
            cpuVirtualCoresCount
            memorySizeBytes
            storageSizeBytes
          }
        }
      }
      ...EngineRow_engine
      ...EngineModal_engine
    }
  }
`;

export default subscription;
