import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormModal from "components/FormModal/FormModal";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import MaterialButton, {
  ButtonTemplate,
} from "components/MaterialButton/MaterialButton";
import {
  putStatusMessage,
  StatusMessageOptions,
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import { BasicUser, Member } from "pages/Users/User.interface";
import { getQueries } from "redux/query/selectors";
import Form from "./components/Form";
import { DisabledFields, ErrorFields, Fields, TEST_IDS } from "./constants";
import {
  generateDisabledFields,
  generateInitialState,
  prepareDataForRequest,
  getContactSupportURL,
  canSubmit,
  getErrorFromField,
  getAllErrors,
  getDefaultEngineName,
  getBackendErrorMessage,
} from "./helpers";
import { Translations } from "./translations";
import styles from "./ContactSupport.module.scss";

interface Props {
  onClose: () => void;
  preCompletedFields?: Fields;
}

interface ConnectedProps {
  user?: BasicUser;
  accountName?: string;
  accountNameDisabled: boolean;
  members: Member[];
  queries: any;
  databasesList: any[];
  putStatusMessage: (message, type, options?: StatusMessageOptions) => void;
}

const ContactSupport: React.FunctionComponent<Props & ConnectedProps> = (
  props: Props & ConnectedProps
) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    user,
    accountName,
    members,
    accountNameDisabled,
    queries,
    databasesList,
    preCompletedFields,
  } = props;
  const defaultEngineName = getDefaultEngineName({
    pathName: location.pathname,
    queries,
    databasesList,
  });
  const [fields, setFields] = useState<Fields>(
    generateInitialState({
      preCompletedFields,
      user,
      accountName,
      defaultEngineName,
    })
  );
  const [disabledFields, setDisabledFields] = useState<DisabledFields>(
    generateDisabledFields(user, accountNameDisabled)
  );
  const [errors, setErrors] = useState<ErrorFields>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setDisabledFields(oldState => ({
      ...oldState,
      ...generateDisabledFields(user, accountNameDisabled),
    }));
  }, [user, accountNameDisabled]);

  const handleChange = (name, value) => {
    setFields(oldState => ({
      ...oldState,
      [name]: value,
    }));
    const error = getErrorFromField(name, value);
    const errorMessage = error
      ? t(`${Translations.errors}${error.errorType}`, {
          inputForErrorMessage: t(error.inputForErrorMessage),
        })
      : null;
    setErrors(oldState => ({
      ...oldState,
      [name]: errorMessage,
    }));
  };

  const onSubmitSuccess = () => {
    props.putStatusMessage(
      <>
        <div>{t(Translations.successSubmitMessage1)}</div>
        <div>{t(Translations.successSubmitMessage2)}</div>
      </>,
      StatusMessageType.Success
    );
    props.onClose();
  };

  const onSubmitError = (errMessage?: string) => {
    props.putStatusMessage(
      errMessage || t(Translations.errorSubmitMessage),
      StatusMessageType.Error,
      {
        insertToPosition: StatusMessagePosition.Bottom,
        id: null,
        autoRemove: true,
        autoClearDelay: 10000,
      }
    );
    setLoading(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const errors = getAllErrors(fields);

    if (errors.length) {
      const errorState = {};
      errors.forEach(error => {
        const { fieldName, errorType, inputForErrorMessage } = error;
        errorState[fieldName] = t(`${Translations.errors}${errorType}`, {
          inputForErrorMessage,
        });
      });
      setErrors({ ...errorState });
      return;
    }

    const params = prepareDataForRequest(fields);
    const contactSupportUrl = getContactSupportURL();
    setLoading(true);

    fetch(contactSupportUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: params,
    })
      .then(response => {
        if (response?.status !== 201) {
          console.error("Failed to submit the support ticket", response);
        }
        return response.json();
      })
      .then(data => {
        if (data?.success) {
          onSubmitSuccess();
        } else {
          const errorMessage = getBackendErrorMessage(data, t);
          onSubmitError(errorMessage);
          console.error("Failed to submit the support ticket", data);
        }
      })
      .catch(error => {
        onSubmitError();
        console.error("Failed to submit the support ticket", error);
      });
  };

  return (
    <FormModal
      open={true}
      classes={{
        paper: cn(styles.contactModal, styles.paper),
      }}
      onClose={() => {
        if (loading) return;
        props.onClose();
      }}
    >
      <div className={styles.contentContainer}>
        <h2 className={styles.title}>{t(Translations.pageTitle)}</h2>
        <form
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          name="contactForm"
        >
          <div className={styles.content}>
            <Form
              t={t}
              fields={fields}
              disabledFields={disabledFields}
              errors={errors}
              handleChange={handleChange}
              members={members}
            />
          </div>
          <div className={styles.footer}>
            <MaterialButton
              text="Submit"
              isLoading={loading}
              disabled={!canSubmit(fields)}
              className={styles.submitBtn}
              template={ButtonTemplate.Primary}
              onClick={handleSubmit}
              dataTestid={TEST_IDS.submitForm}
              id="contactSubmit"
            />
            <MaterialButton
              text="Cancel"
              template={ButtonTemplate.Secondary}
              onClick={props.onClose}
              disabled={loading}
              dataTestid={TEST_IDS.cancelForm}
            />
          </div>
        </form>
      </div>
    </FormModal>
  );
};

const mapStateToProps = state => {
  const accountName = get(state, "account.accountDetails.name");
  const defaultAccountName = get(
    state,
    "user.userData.members[0].accountTitle"
  );
  return {
    user: get(state, "user.userData.user"),
    accountName: accountName || defaultAccountName,
    accountNameDisabled: !!accountName,
    members: get(state, "user.userData.members"),
    databasesList: get(state, "database.databasesList"),
    queries: getQueries(state),
  };
};

export default connect(mapStateToProps, {
  putStatusMessage,
})(ContactSupport);
