import Highlighter from "components/Highligher";
import fonts from "styles/variables.fonts.module.scss";

export const highlightText = ({
  searchWord,
  textToHighlight,
}: {
  searchWord?: string;
  textToHighlight: string;
}) => {
  return searchWord ? (
    <Highlighter
      searchWords={[searchWord]}
      textToHighlight={textToHighlight}
    />
  ) : (
    textToHighlight
  );
};

export const FONTS = {
  base: fonts.baseFontFamily,
  secondary: fonts.secondaryFontFamily,
  code: fonts.codeFontFamily,
};
let context: CanvasRenderingContext2D | null;

const ellipsis = "…";

export const getTextMetrics =
  (font: keyof typeof FONTS, sizePx: string, fontWeight: string = "regular") =>
  (text: string, withEllipsis = false) => {
    if (!context) {
      context = document.createElement("canvas").getContext("2d");
    }

    if (context) {
      context.font = `${fontWeight} ${sizePx} ${FONTS[font]}`;
      return context?.measureText(withEllipsis ? `${text}${ellipsis}` : text);
    }

    return null;
  };
