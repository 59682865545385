import _ from "lodash";
import React from "react";
import clx from "classnames";
import InputField from "components/Input/InputFiled";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as SearchIcon } from "assets/icons/Search.svg";
import { ReactComponent as CrossIcon } from "assets/icons/Cross.svg";
import styles from "./MaterialSearchInput.module.scss";

interface Props {
  defaultValue: string;
  onChange: (name: string, value: string) => void;
  placeholder: string;
  testId: string;
  disabled?: boolean;
  objectFilter?: string;
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  label?: string;
  name: string;
  debounceMs?: number;
}

interface State {
  value: string;
}

class MaterialSearchInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    };

    if (props.debounceMs) {
      this.debouncedOnChange = _.debounce(
        this.props.onChange,
        props.debounceMs
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.onChange !== this.props.onChange) {
      this.debouncedOnChange = _.debounce(
        this.props.onChange,
        this.props.debounceMs
      );
    }

    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  public debouncedOnChange;

  static defaultProps = {
    disabled: false,
    autoFocus: false,
  };

  public reset = () => {
    this.setState({ value: "" });
    this.props.onChange(this.props.name, "");
  };

  public handleChange = (name, value) => {
    const { onChange } = this.props;

    this.setState({ value: value });

    if (!this.debouncedOnChange) {
      onChange(name, value);
      return;
    }

    this.debouncedOnChange(name, value);
  };

  render() {
    const {
      placeholder,
      disabled,
      inputClassName,
      autoFocus,
      testId,
      label,
      name,
    } = this.props;

    const { value } = this.state;

    return (
      <InputField
        name={name}
        testId={testId}
        type="text"
        placeholder={placeholder}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        disabled={disabled}
        autoFocus={autoFocus}
        onChange={this.handleChange}
        fullWidth
        errorText={undefined}
        showErrorContainer={false}
        required={false}
        onKeyDown={e => {
          e.stopPropagation();
        }}
        InputProps={{
          classes: {
            root: clx(inputClassName),
          },
          endAdornment: (
            <InputAdornment
              position="end"
              classes={{ root: styles.iconContainer }}
              disablePointerEvents={!value}
              onClick={this.reset}
            >
              {value ? <CrossIcon /> : <SearchIcon />}
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default MaterialSearchInput;
