import { newModelFromString } from "casbin/lib/cjs/model/model";
import { Enforcer, StringAdapter } from "casbin";
import { LDClient } from "launchdarkly-js-client-sdk";
import { GQLRbacaction } from "../../graphql.types";

const FIRENZE_FLAG_NAME = "firenze-enabled";

const ACTION_PREFIX = "ACTION_";

const RBAC_MODEL = `[request_definition]
r = principal, resource, action

[policy_definition]
p = role, resource, action

[role_definition]
g = _, _

[matchers]
m = g(r.principal, p.role) && keyMatch(r.resource, p.resource) && r.action == p.action

[policy_effect]
e = some(where (p.eft == allow))`;

const convertGqlResourceId = (resourceId: string) => {
  if (!resourceId) {
    return resourceId;
  }

  const lastSegment: string = atob(resourceId).split(";")[1];

  if (!lastSegment.length) {
    throw new Error("Wrong resource id format");
  }

  const [id, resourceName] = lastSegment.split("/").reverse();

  return `/${resourceName}/${id}`;
};

export interface AccessManager {
  attachLdClient: (ldClient?: LDClient) => void;
  initAccessManager: (
    activeUserId: string,
    policyString: string
  ) => Promise<void>;
  isAllowedTo: (resourceId: string, action: GQLRbacaction) => boolean;
}

const createAccessManager = (): AccessManager => {
  let featureFlagClient: LDClient | undefined;
  let enforcer: Enforcer | undefined;
  let userId: string | undefined;

  return {
    attachLdClient: (ldClient?: LDClient) => {
      featureFlagClient = ldClient;
    },
    initAccessManager: async (activeUserId: string, policyString: string) => {
      const model = newModelFromString(RBAC_MODEL);

      const adapter = new StringAdapter(policyString || " ");
      const enf = new Enforcer();

      await enf.initWithModelAndAdapter(model, adapter);

      enforcer = enf;
      userId = convertGqlResourceId(activeUserId);
    },
    isAllowedTo: (resourceId: string, action: GQLRbacaction): boolean => {
      const restResourceId = convertGqlResourceId(resourceId);

      const normalizedRestResourceId = restResourceId?.startsWith("/accounts/")
        ? "/"
        : restResourceId;

      if (
        enforcer &&
        featureFlagClient &&
        featureFlagClient.variation(FIRENZE_FLAG_NAME)
      ) {
        const response = enforcer.enforceSync(
          userId,
          normalizedRestResourceId,
          ACTION_PREFIX + action
        );

        return response;
      }

      return true;
    },
  };
};

export { createAccessManager };
