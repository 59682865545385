const createBeforeUnloadEventController = () => {
  let activeRequest = 0;

  const actualizeEventHandler = () => {
    if (activeRequest) {
      window.onbeforeunload = function(e) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      };
      return;
    }
    window.onbeforeunload = null;
  };

  return {
    addRequest: () => {
      activeRequest += 1;
      actualizeEventHandler();
    },
    removeRequest: () => {
      activeRequest = Math.max(activeRequest - 1, 0);
      actualizeEventHandler();
    }
  };
};

export { createBeforeUnloadEventController };
