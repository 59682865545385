import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import { ScriptDeleteInput } from "relay/mutations/userScript/__generated__/ScriptDeleteMutation.graphql";

const mutation = graphql`
  mutation ScriptDeleteMutation($input: ScriptDeleteInput!) {
    scriptDelete(input: $input) {
      success
    }
  }
`;

function commit(environment, input: ScriptDeleteInput, onCompleted, onError) {
  return commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      input: input
    }
  });
}

export default { commit };
