import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Validations } from "businessLogic/helpers/Validation";
import InputField from "components/Input/InputFiled";
import { MembersActions } from "pages/Users/redux/actions";
import MaterialButton from "components/MaterialButton/MaterialButton";
import {
  putStatusMessage,
  StatusMessageType,
} from "components/StatusMessage/redux/actions";
import { UserRole } from "pages/Users/User.interface";
import styles from "components/WelcomeScreen/SubscriptionError/styles.module.scss";
import { getMyUser } from "redux/user/selectors";

export const Invite = () => {
  const dispatch = useDispatch();
  const myUser = useSelector(getMyUser);
  const { t } = useTranslation();
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteError, setInviteError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setInviteEmail(value);
    setInviteError(null);
  };

  const sendInvite = async event => {
    event.stopPropagation();

    if (!Validations.isValidEmail(inviteEmail)) {
      setInviteError(t("errors.invalid_email"));
      return;
    }

    if (!Validations.isValidWorkEmail(inviteEmail)) {
      setInviteError(t("errors.invalid_email_domain"));
      return;
    }

    if (inviteEmail === myUser.email) {
      setInviteError(t("errors.cannot_use_your_email"));
      return;
    }

    try {
      setLoading(true);
      setInviteError(null);
      await new Promise((resolve, reject) => {
        dispatch({
          type: MembersActions.CREATE_MEMBERS,
          payload: {
            invitations: [{ email: inviteEmail, role: UserRole.AccountAdmin }],
            resolve,
            reject,
            type: "aws",
          },
        });
      });
      dispatch(
        putStatusMessage(
          t("subscription.invite_success"),
          StatusMessageType.Success
        )
      );
      setInviteEmail("");
    } catch (errorMessage) {
      dispatch(putStatusMessage(errorMessage, StatusMessageType.Error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.invite}>
      <h3 className={styles.title}>
        Need help from your AWS admin? Invite him to Firebolt to complete the
        flow.
      </h3>
      <div className={styles.form}>
        <div className={styles.input}>
          <InputField
            testId="email-aws-field"
            disabled={isLoading}
            type="text"
            name="email"
            value={inviteEmail}
            label={t("subscription.invite_label")}
            errorText={inviteError || ""}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div className={styles.button}>
          <MaterialButton
            isLoading={isLoading}
            text={t("subscription.invite")}
            onClick={sendInvite}
            dataTestid="invite-to-firebolt"
          />
        </div>
      </div>
    </div>
  );
};
