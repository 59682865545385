import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import MaterialButton, {
  ButtonSize,
  ButtonTemplate,
  Props as MaterialButtonProps,
} from "components/MaterialButton/MaterialButton";
import styles from "./index.module.scss";

export type PromptConfiguration = {
  confirmProps: Partial<MaterialButtonProps>;
  rejectProps: Partial<MaterialButtonProps>;
  action: Function;
  message?: string;
};

type Props = {
  hovered?: boolean;
  promptTestId: string;
  configuration: PromptConfiguration | null;
};
function EngineActionsPromptPanel(props: PropsWithChildren<Props>) {
  const { promptTestId, hovered, configuration = null } = props;
  const [prompt, setPrompt] = useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (configuration) {
      setPrompt(true);
    }
  }, [configuration]);

  return (
    <div
      data-testid={prompt ? promptTestId : "engine-actions-container"}
      className={cn(styles.root, {
        [styles.visible]: hovered || prompt,
      })}
    >
      {!prompt && props.children}
      {prompt && configuration && (
        <>
          <div className={styles.message}>
            {configuration.message || t("confirmation.are_you_sure")}
          </div>
          <div className={cn(styles.actionBtn, styles.visible)}>
            <MaterialButton
              size={ButtonSize.Small}
              text={t("confirmation.yes")}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                Promise.resolve(true)
                  .then(() => {
                    if (configuration) {
                      return configuration.action();
                    }
                    return true;
                  })
                  .finally(() => setPrompt(false));
              }}
              {...((configuration || {}).confirmProps ?? {})}
            />
          </div>
          <div className={cn(styles.actionBtn, styles.visible)}>
            <MaterialButton
              template={ButtonTemplate.Secondary}
              size={ButtonSize.Small}
              text={t("confirmation.no")}
              onClick={() => {
                setPrompt(false);
              }}
              {...((configuration || {}).rejectProps ?? {})}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default EngineActionsPromptPanel;
