import { ReactComponent as SignupGeneral } from "assets/images/errorScreen/signupGeneral.svg";
import { SUPPORT_EMAIL } from "businessLogic/constants";
import { useTranslation } from "react-i18next";
import buttonStyles from "styles/buttons.module.scss";
import styles from "./styles.module.scss";

export const AWSProvisioningError = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.general}>
      <SignupGeneral />
      <h3 className={styles.title}>{t("errors.aws_provisioning")}</h3>
      <div className={styles.support}>
        {t("errors.aws_provisioning_description")}
        <br />
        {t("errors.aws_provisioning_solution")}
      </div>

      <div className={styles.footer}>
        Please reach out to{" "}
        <a
          href={`mailto:${SUPPORT_EMAIL}`}
          className={buttonStyles.btn_link}
        >
          {SUPPORT_EMAIL}
        </a>{" "}
        for more details
      </div>
    </div>
  );
};
