import { QueryRenderer } from "react-relay";
import ErrorScreen from "components/ErrorScreen/ErrorScreen";
import {
  engineGqlToUi,
  getEngineInstanceType,
} from "components/EngineForm/helpers";
import { EngineFormField } from "components/EngineForm/constants";
import { generateInstanceTypeFromEngine } from "components/EnginesList/helpers";
import environment from "relay/environment";
import { EngineQuery } from "../queries/__generated__/EngineQuery.graphql";
import { engineQuery } from "../queries/EngineQuery";
import Dialog from "./Dialog";

const EngineQueryRenderer = ({
  entityId,
  accountId,
}: {
  entityId: string;
  accountId: string;
}) => {
  return (
    <QueryRenderer<EngineQuery>
      environment={environment}
      query={engineQuery}
      variables={{
        engineId: entityId,
        accountId,
      }}
      fetchPolicy="store-or-network"
      render={({ error, props }) => {
        if (error) {
          return (
            <ErrorScreen
              title="Failed to load data"
              description={error.message}
            />
          );
        }

        if (!props) {
          return (
            <Dialog
              dialogIsLoading
              key="withFetch"
            />
          );
        }

        const edges = props?.engine?.bindings?.edges || [];
        const engineIsDefault = edges.length
          ? edges[0]?.node?.engineIsDefault
          : false;
        const entity: any = engineGqlToUi(props.engine, engineIsDefault);
        const { instanceTypeInfo, instanceType } =
          generateInstanceTypeFromEngine(props.engine);

        entity.engineCost =
          (instanceType?.price || 0) * entity[EngineFormField.Scale];
        entity.instanceTypeIcon = instanceTypeInfo && instanceTypeInfo.icon;
        entity.instanceType = instanceType
          ? getEngineInstanceType(instanceType, entity)
          : null;

        return (
          <Dialog
            entity={entity}
            accountId={accountId}
          />
        );
      }}
    />
  );
};

export default EngineQueryRenderer;
