import { createSelector } from "reselect";
import { UserData } from "./actions";
import {
  Member,
  MemberJoinFlow,
  MemberStatus,
  UserRole,
} from "pages/Users/User.interface";
import {
  ROLE_ACCOUNT_ADMIN_ID,
  ROLE_DATABASE_ADMIN_ID,
  ROLE_VIEWER_ID,
} from "./sagas";

export const USER_ROLE_NAME_TO_ID_MAP = {
  [UserRole.AccountAdmin]: ROLE_ACCOUNT_ADMIN_ID,
  [UserRole.DatabaseAdmin]: ROLE_DATABASE_ADMIN_ID,
  [UserRole.Viewer]: ROLE_VIEWER_ID,
};

export const getUserData = state => state.user.userData;
export const getMyUser = state => state.user?.userData?.user;
export const getSelectedAccountId = state => state.user.selectedAccountId;

export const getUserRoleFromAccount = (member: Member): UserRole | null => {
  const assignedRole = member.assignedRole;
  switch (assignedRole.displayName) {
    case ROLE_ACCOUNT_ADMIN_ID:
      return UserRole.AccountAdmin;
    case ROLE_VIEWER_ID:
      return UserRole.Viewer;
    case ROLE_DATABASE_ADMIN_ID:
      return UserRole.DatabaseAdmin;
    default:
      return null;
  }
};

export const getUserRole = (
  userData: UserData,
  accountId: string
): UserRole | null => {
  if (!userData || !userData.members || !userData.members.length) {
    return null;
  }

  const member = userData.members.find(
    member => member.accountId === accountId
  );

  if (!member) {
    return null;
  }

  return getUserRoleFromAccount(member);
};

export const selectUserAccountId = state => state.account?.accountDetails?.id;
export const selectUserAccountCreateTime = state =>
  state.account?.accountDetails?.createTime;
export const selectUserAccountAllowedActions = (state): string[] =>
  state.account?.accountDetails?.allowedActions;

export const selectUserRole = createSelector(
  getUserData,
  selectUserAccountId,
  getUserRole
);

export const selectUserIsAccepted = createSelector(
  getUserData,
  selectUserAccountId,
  (userData: UserData, accountId: string): boolean => {
    if (!userData || !userData.members || !userData.members.length) {
      return false;
    }

    const member = userData.members.find(
      member => member.accountId === accountId
    );

    if (member?.status !== MemberStatus.ACCOUNT_MEMBER_STATUS_ACCEPTED) {
      return false;
    }

    return true;
  }
);

export const selectPendingInvite = createSelector(
  getUserData,
  selectUserAccountId,
  (userData: UserData, accountId: string): boolean => {
    const member = userData?.members.find(
      member => member.accountId === accountId
    );

    if (!member) {
      return false;
    }

    return (
      member.status === MemberStatus.ACCOUNT_MEMBER_STATUS_CREATED &&
      member.joinFlow === MemberJoinFlow.ACCOUNT_MEMBER_JOIN_FLOW_INVITATION
    );
  }
);

export const getUserName = (userData: UserData) =>
  userData?.user?.firstName && userData?.user?.lastName
    ? userData.user.firstName + " " + userData.user.lastName
    : userData.user.email;
