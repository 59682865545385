import React from "react";
import { QueryRenderer } from "react-relay";
import ErrorScreen from "components/ErrorScreen/ErrorScreen";
import { getEnginesFromDbBindings } from "pages/Database/DatabaseList/v2/common/helpers";
import environment from "relay/environment";
import { DbQuery } from "../queries/__generated__/DbQuery.graphql";
import { dbQuery } from "../queries/DbQuery";
import Dialog, { DBEntity } from "./Dialog";

const DbQueryRenderer = ({
  entityId,
  accountId,
}: {
  entityId: string;
  accountId: string;
}) => {
  return (
    <QueryRenderer<DbQuery>
      environment={environment}
      query={dbQuery}
      variables={{
        dbId: entityId,
        accountId,
      }}
      render={({ error, props }) => {
        if (error) {
          return (
            <ErrorScreen
              title="Failed to load data"
              description={error.message}
            />
          );
        }

        if (!props) {
          return <Dialog dialogIsLoading key="withFetch" />;
        }

        if (props) {
          const { database } = props;
          const entity = {
            id: database.id,
            name: database.name,
            emoji: database.emoji,
            engines: getEnginesFromDbBindings(database).map(en => ({
              ...en,
              engineCost: en.engineCost * en.scale,
            })),
          };

          return <Dialog entity={entity as DBEntity} accountId={accountId} />;
        }
      }}
    />
  );
};

export default DbQueryRenderer;
