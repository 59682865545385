import React from "react";
import { useTranslation } from "react-i18next";
import onClickOutside from "react-onclickoutside";
import uniqueid from "lodash/uniqueId";
import { connect } from "react-redux";
import cn from "classnames";
import { SnackbarContent } from "@material-ui/core";
import Spinner from "components/common/Spinner";
import {
  resetStatusMessages,
  StatusMessage,
  StatusMessageType,
} from "./redux/actions";
import { ReactComponent as ErrorIcon } from "assets/icons/Exclamation.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/SucessIcon.svg";
import snackbarStyles from "components/SnackBar/styles.module.scss";
import styles from "./StatusMessageContainer.module.scss";

interface Props {
  statusMessages: StatusMessage[];
  resetStatusMessages: () => void;
}

function StatusMessageContainer({
  statusMessages,
  resetStatusMessages,
}: Props) {
  const { t } = useTranslation();
  if (resetStatusMessages) {
    StatusMessageContainer.handleClickOutside = () => {
      resetStatusMessages();
    };
  }

  if (!statusMessages.length) {
    return null;
  }

  const renderIcon = type => {
    switch (type) {
      case "error":
        return <ErrorIcon className={snackbarStyles.messageIcon} />;
      case "success":
        return <SuccessIcon className={snackbarStyles.messageIcon} />;
      case "loading":
        return <Spinner size={22} />;
    }
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.transparentClick]:
          statusMessages.length === 1 &&
          statusMessages[0].type === StatusMessageType.Loading,
      })}
    >
      {statusMessages.map(statusMessage => {
        const { message, type } = statusMessage;
        return (
          <div
            className={styles.snackbarContainer}
            key={uniqueid()}
          >
            <SnackbarContent
              classes={{
                root: cn(snackbarStyles.snackBarContent, {
                  [snackbarStyles.errorSnackBar]: type === "error",
                  [snackbarStyles.successSnackBar]: type === "success",
                  [snackbarStyles.loadingSnackBar]: type === "loading",
                }),
              }}
              message={
                <div className={snackbarStyles.messageWrap}>
                  {renderIcon(type)}
                  <span className={snackbarStyles.messageText}>
                    {typeof message === "string" ? t(message as any) : message}
                  </span>
                </div>
              }
            />
          </div>
        );
      })}
    </div>
  );
}

StatusMessageContainer.handleClickOutside = () => {};

const clickOutsideConfig = {
  handleClickOutside: () => StatusMessageContainer.handleClickOutside,
};

export default connect(
  (state: any) => {
    return {
      statusMessages: state.statusMessage.statusMessages,
    };
  },
  { resetStatusMessages }
)(onClickOutside(StatusMessageContainer, clickOutsideConfig));
