import { Subject } from "../subject";

export const queryIndicatorSubject = new Subject();
export const UPDATE_TITLE_PREFIX = "update_title_prefix";

export enum QueryIndicatorStatus {
  RUNNING = "running",
  ERROR = "failed",
  SUCCESS = "success",
  CANCEL = "cancel",
  NEUTRAL = "neutral"
}
