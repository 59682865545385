import cn from "classnames";
import { useTranslation } from "react-i18next";
import CustomTooltip from "components/Tooltip";
import { ReactComponent as RefreshIcon } from "assets/icons/RefreshIcon.svg";
import {
  canStartEngine,
  engineCanBeRestarted,
} from "components/EnginesList/helpers";
import { EngineItemType } from "components/EnginesList/EngineItem/EngineItem";
import { useReadOnlyMode } from "featureFlags/hooks/useReadOnlyMode";
import { useDisabledStart } from "./useDisabledStart";
import styles from "../index.module.scss";

type Props = {
  engines: EngineItemType[];
  engine: any;
  onClick: (() => void) | undefined;
  accountAllowedActions: string[];
  hovered?: boolean;
};
const RestartEngineButton = ({
  engines,
  accountAllowedActions,
  engine,
  onClick,
  hovered,
}: Props) => {
  const { t } = useTranslation();
  const { readOnlyMode, readOnlyTooltip } = useReadOnlyMode(
    engine.computeRegion
  );

  const { disabledStart, disabledStartTooltip } = useDisabledStart(
    engines,
    engine
  );

  if (!canStartEngine(accountAllowedActions)) {
    return null;
  }

  const title =
    disabledStartTooltip?.title ||
    readOnlyTooltip.title ||
    t("engines.restart");

  return (
    <CustomTooltip title={title} placement="top">
      <RefreshIcon
        data-testid="engine-item-restart"
        className={cn(styles.actionBtn, {
          [styles.disabled]:
            !engineCanBeRestarted(engine.status) ||
            readOnlyMode ||
            disabledStart,
          [styles.visible]: hovered,
        })}
        onClick={e => {
          if (readOnlyMode || disabledStart) {
            return;
          }
          if (!engineCanBeRestarted(engine.status)) {
            e.stopPropagation();
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          onClick?.();
        }}
      />
    </CustomTooltip>
  );
};

export default RestartEngineButton;
