import React from "react";
import { EngineSettingsPreset } from "pages/Engines/EnginesTable/__generated__/EngineRow_engine.graphql";
import { ReactComponent as GeneralPurpose } from "assets/icons/instanceCategories/GeneralPurpose.svg";
import { ReactComponent as DataAnalytics } from "assets/icons/instanceCategories/DataAnalytics.svg";

export enum EngineTypeDisplay {
  Small = "displaySmall",
  Regular = "displayRegular",
}

export enum EngineFormField {
  Name = "name",
  RegionId = "regionId",
  Scale = "scale",
  IsSpot = "isSpot",
  InstanceTypeId = "instanceTypeId",
  SettingsPreset = "settingsPreset",
  AutoStopAfter = "autoStopAfter",
  WarmUp = "warmup",
}

export interface Fields {
  [EngineFormField.Name]: string;
  [EngineFormField.RegionId]: string;
  [EngineFormField.Scale]: number;
  [EngineFormField.IsSpot]: boolean;
  [EngineFormField.InstanceTypeId]: string;
  [EngineFormField.SettingsPreset]: SettingsPreset;
  [EngineFormField.AutoStopAfter]: number;
  [EngineFormField.WarmUp]: string;
}

export enum WarmupValues {
  Minimal = "MINIMAL",
  Indexes = "INDEXES",
  All = "ALL",
}

export enum AutoStopValues {
  On = 0,
  TwentyMinutes = 1200,
  OneHour = 3600,
}

export const AUTO_STOP_OPTIONS = [
  { value: AutoStopValues.On, text: "Always On" },
  { value: AutoStopValues.TwentyMinutes, text: "20 min of inactivity" },
  { value: AutoStopValues.OneHour, text: "1 hour of inactivity" },
];

export const AutoStopTooltipMap = {
  [AutoStopValues.On]: "Always on",
  [AutoStopValues.TwentyMinutes]: "20 min",
  [AutoStopValues.OneHour]: "1 hour",
};

export const WARMUP_OPTIONS = [
  { value: WarmupValues.Minimal, text: "Minimal" },
  { value: WarmupValues.Indexes, text: "Preload indexes" },
  { value: WarmupValues.All, text: "Preload all data" },
];

export interface InstanceType {
  id: string;
  regionId: string;
  availableSettingsPresets: EngineSettingsPreset[];
  name: string;
  cores: number;
  ram: number;
  storage: number;
  price: number;
}

export const DISABLED_ALL_ENGINE_FIELDS = {
  [EngineFormField.Name]: true,
  [EngineFormField.RegionId]: true,
  [EngineFormField.Scale]: true,
  [EngineFormField.IsSpot]: true,
  [EngineFormField.InstanceTypeId]: true,
  [EngineFormField.SettingsPreset]: true,
  [EngineFormField.AutoStopAfter]: true,
  [EngineFormField.WarmUp]: true,
};

export enum SettingsPreset {
  GeneralPurpose = "GENERAL_PURPOSE",
  DataAnalytics = "DATA_ANALYTICS",
}

export const SETTING_PRESET_DESCRIPTION = {
  [SettingsPreset.GeneralPurpose]: {
    name: "General Purpose",
    description: "Read/Write",
    icon: <GeneralPurpose />,
    readOnly: false,
    optimisedFor: "Memory (RAM)",
    tooltipLists: [
      [
        {
          value: "General Purpose Engine",
          name: "",
        },
      ],
      [
        {
          name: "Best for: ",
          value: "",
        },
        {
          name: "",
          value: "New Database creation",
        },
        {
          name: "",
          value: "Data ingestion",
        },
        {
          name: "",
          value: "ELT",
        },
      ],
    ],
  },
  [SettingsPreset.DataAnalytics]: {
    name: "Data Analytics",
    description: "Read only",
    icon: <DataAnalytics />,
    readOnly: true,
    optimisedFor: "Memory (RAM)",
    tooltipLists: [
      [
        {
          value: "Data Analytics Engine",
          name: "",
        },
      ],
      [
        {
          name: "Best for: ",
          value: "",
        },
        {
          name: "",
          value: "Querying existing databases",
        },
        {
          name: "",
          value: "High performance querying",
        },
        {
          name: "",
          value: "BI & reporting",
        },
      ],
    ],
  },
};
