import React from "react";
import cn from "classnames";
import { ReactComponent as CopyIcon } from "assets/icons/selectMenuIcons/CopyIcon.svg";
import { ReactComponent as CopiedIcon } from "assets/icons/selectMenuIcons/CopiedIcon.svg";
import CustomTooltip from "components/Tooltip";
import { useCopy } from "components/CopyProvider";
import styles from "../index.module.scss";

type CopyButtonProps = {
  text: string;
  dataTestId: string;
  hovered?: boolean;
};

export default function CopyButton({
  text,
  dataTestId,
  hovered,
}: CopyButtonProps) {
  const { copied, handleCopy } = useCopy();

  return (
    <span
      data-testid={dataTestId}
      key="copy"
      className={cn(styles.actionBtn, {
        [styles.visible]: hovered,
      })}
      onClick={() => handleCopy(text)}
    >
      {!copied && (
        <CustomTooltip
          title={text}
          placement="top"
          classes={{ tooltip: "copyButtonTooltip" }}
        >
          <CopyIcon data-testid="copy-icon" />
        </CustomTooltip>
      )}
      {copied && <CopiedIcon data-testid="copied-icon" />}
    </span>
  );
}
